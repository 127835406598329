import React, { FC, ReactNode } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { alpha, lighten, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout1MI: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();

  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    isMobileView
    ?
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          width: '100vw',
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? alpha('#ffffff', 0.02)
                : alpha('#ffffff', 0.5),
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten('#5569ff', 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  '#223354',
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  '#223354',
                  0.05
                )}`
          }
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `0px`,
            width: '100%',
            [theme.breakpoints.up('lg')]: {
              ml: `260px`
            }
          }}
        >
          <Box display="block" mb={2}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
    :
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? alpha('#ffffff', 0.02)
                : alpha('#ffffff', 0.5),
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten('#5569ff', 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  '#223354',
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  '#223354',
                  0.05
                )}`
          }
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `0px`,
          
            [theme.breakpoints.up('lg')]: {
              ml: `260px`
            }
          }}
        >
          <Box display="block" mb={2}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout1MI;

import {
  Box,
  Paper,
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  ButtonGroup,
  TextField,
  Modal,
  InputAdornment,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  InputBase,
  IconButton,
  FormControl,
  InputLabel,
  Checkbox,
  useMediaQuery,
  CircularProgress,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Tune, Search, StarOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/redux-store";
import Style from "../DrProjects/DrProjectsList.module.css";
import {
  getAllCities,
  initiatePayment,
  getProjectsByArea,
  capturePaymentStatus,
  createOrders,
} from "../../../redux/thunk/thunks";
import drfavicon from "../../../theme/drfavicon.png";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  favUnFavProjectThunk,
  uploadFile,
} from "../../../redux/thunk/thunks";
import { LoadingButton } from "../../../layouts/LoadingButton";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { BiddingDialog } from "./BiddingDialog";
import { getProjectBid, getUploadURL } from "../../../redux/data/data-actions";
import { setSuccessAction } from '../../../redux/display/display-actions';
import BgImg from "../../../theme/residential.png";
import MessageIcon from '@mui/icons-material/Message';
import { _getHeaders } from "../../../redux/api-headers";

// import { PhonePe } from 'phonepesdk-web';

// let sdk: any = '';
// PhonePe.PhonePe.build(PhonePe.Constants.Species.web).then((ppSdk: any) => {
//   sdk = ppSdk;
// })

var axios = require("axios");

const CustomizeSelect = styled(Select)({
  color: "#788195 !important",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#788195 !important",
    },
    "& .Mui-focused MuiOutlinedInput-notchedOutline": {
      color: "#788195 !important",
    },
  },
});
const CustomizeTextField = styled(OutlinedInput)({
  borderRadius: 0,
  background: "#fff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#788195 !important",
    },
    "& .Mui-focused MuiOutlinedInput-notchedOutline": {
      color: "#788195 !important",
    },
  },
});

const submitButton = {
  minWidth: "120px",
  padding: "5px 10px",
  backgroundColor: "#993399",
  color: "#fff",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#993399",
    color: "#fff",
  },
};
const cancelButton = {
  minWidth: "120px",
  padding: "5px 10px",
  backgroundColor: "#fff",
  color: "#993399",
  border: "1px solid #993399",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#993399",
  },
};

const CustomizeMenuItem = styled(MenuItem)({
  padding: 0,
  background: "#fff",
  "&.MuiButtonBase-root:hover": {
    background: "#fff",
  },
  "&.MuiButtonBase-root": {
    background: "#fff",
  },
  "&.Mui-selected.MuiButtonBase-root:hover": {
    background: "#fff",
  },
  "&.Mui-selected.MuiButtonBase-root": {
    background: "#fff",
  },
});


declare global {
  interface Window {
    Razorpay: any;
  }
}


const DrProjectList = () => {
  const isMobileView = useMediaQuery("(max-width:600px)");

  const style = {
    textAlign: "center",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobileView ? '90vw' : 400,
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "2px solid #993399",
    boxShadow: 24,
    p: 2,
  };

  const Razorpay = useRazorpay();
  const [search, setSearch] = useState("");
  const handleSearch = (event: any) => { };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user: any = sessionStorage.getItem("DREAMUSERAPP");
  const userInfo: any = JSON.parse(user);
  const [openModal, setOpenModel] = useState(false);
  const [openModal1, setOpenModel1] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const searchAreaRef = useRef<HTMLSelectElement>();
  const [openBidding, setOpenBidding] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [no, setNo] = useState("");

  const success = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.dataSaveSuccessfully);
  const bidingTransaction: any = useSelector((state: RootState) => state.DATA_REDUCER.bidingTransaction);

  const projectBids: any = useSelector((state: RootState) => state.DATA_REDUCER.projectBid);
  const subscriptionBid: any = useSelector((state: RootState) => state.DATA_REDUCER.subscriptionBid);
  const projectBid: any = projectBids ? projectBids : subscriptionBid ? subscriptionBid : null;


  const [success1, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const ownerId = userInfo?.owner.ownerId;
  const id: any = searchParams.get("id");
  
  useEffect(() => {
    if (success) {
      dispatch(setSuccessAction('dataSaveSuccessfully', false));
      const url = bidingTransaction?.data?.instrumentResponse?.redirectInfo?.url;
      // window.open(url, '_blank');
      setOpenModel1(false);
    }
  }, [success])

  // useEffect(() => {
  //   const check = typeof window !== "undefined" && sessionStorage.getItem("type");
  //   if (check && check === "SUCCESS") {
  //     navigate('/');
  //   }
  // }, [])

   /**Go to home after 14:30 min */
  //  useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     const clearTime = sessionStorage.getItem('clear');
  //     typeof window !== "undefined" && sessionStorage.removeItem("type");
  //     if (!clearTime) {
  //       navigate('/');;
  //     }
  //   }, 870000);
  //   return () => clearTimeout(timeoutId);
  // }, []);


  // const handleReturnBidDailog = async () => {
  //   // if (no !== '') {
  //   //   setDisabled(true);
  //   //   await dispatch(
  //   //     saveBidding(
  //   //       info?.biddingAmount,
  //   //       info?.projectId,
  //   //       userInfo?.developer?.developerId,
  //   //       no,
  //   //       uploadURL
  //   //     )
  //   //   );
  //   //   setNo("");
  //   //   setOpenModel1(false);
  //   //   setOpenBidding(false);
  //   //   setDisabled(false);
  //   //   dispatch(getProjectBid(null));
  //   // }
  //   setDisabled(true);
  //   const data = {
  //     "amount": projectBid?.totalBidAmount ? parseInt(projectBid?.totalBidAmount) : parseInt(info?.biddingAmount),
  //     "amountType": "CREDIT",
  //     "additionalInfo": "Project bid",
  //     "persona": "developer",
  //     "projectId": info?.projectId,
  //     "personaId": userInfo?.developer?.developerId,
  //     "category": "BIDDING",
  //     "paymentStatus": "INITIATED",
  //     "imageUrl": "",
  //     "approvalStatus": "PENDING"
  //   }
  //   await dispatch(initiatePayment(data));
  //   setOpenModel1(false);
  //   setOpenBidding(false);
  //   setDisabled(false);
  //   dispatch(getProjectBid(null));
  // };

  const cities = useSelector((state: RootState) => state.DATA_REDUCER.cities);

  const stages = ["website_creation", "bids"];
  const maxSalePotentialRanges: any = {
    "None": {min: 0},
    "0 - 5000": { max: 5000 },
    "2.5k - 10k": { min: 2500, max: 10000 },
    "10k - 20k": { min: 10000, max: 20000 },
    "20k - 50k": { min: 20000, max: 50000 },
    "50k - 100k": { min: 50000, max: 100000 },
    "100k - 500k": { min: 100000, max: 500000 },
    "500k - 1000k": { min: 500000, max: 1000000 },
    "1000k and Above": { min: 1000000 },
  };

  const [city, setCity] = useState("None");
  const [selectedStages, setSelectedStages] = useState<string | string[]>([
    "website_creation",
    "bids",
  ]);
  const [selectedMaxSalePotentialRange, setSelectedMaxSalePotentialRange] =
    useState("None");
  const [area, setArea] = useState("");
  const drProjects = useSelector(
    (state: RootState) => state.DATA_REDUCER.drProjects
  );
  const uploadURL = useSelector(
    (state: RootState) => state.DATA_REDUCER.uploadURL
  );
  const loadingData = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading
  );
  const favouriteSuccess = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.favouriteSuccess
  );

  useEffect(() => {
    dispatch(getProjectsByArea(city, area, 0, 0, selectedStages.length ? selectedStages : stages));
  }, [city]);

  useEffect(() => {
    dispatch(getProjectsByArea(city, area, 0, 0, selectedStages.length ? selectedStages : stages));
  }, [selectedStages]);

  useEffect(() => {
    dispatch(
      getProjectsByArea(
        city,
        area,
        maxSalePotentialRanges[selectedMaxSalePotentialRange].min,
        maxSalePotentialRanges[selectedMaxSalePotentialRange].max,
        selectedStages.length ? selectedStages : stages
      )
    );
  }, [selectedMaxSalePotentialRange]);

  useEffect(() => {
    dispatch(
      getProjectsByArea(
        city,
        area,
        maxSalePotentialRanges[selectedMaxSalePotentialRange].min,
        maxSalePotentialRanges[selectedMaxSalePotentialRange].max,
        selectedStages.length ? selectedStages : stages
      )
    );
    dispatch(getAllCities());
  }, []);

  const handelFavourite = async () => {
    setIsSubmit(true);
    if (isFav) {
      info?.favouriteId.push(info?.projectId);
      const data = {
        projectId: info?.favouriteId,
        userType: "developer",
        developerId: userInfo?.developer?.developerId,
      };
      await dispatch(favUnFavProjectThunk(data));
      setIsSubmit(false);
    } else {
      const filterData =
        info &&
        info?.favouriteId.filter((item: any) => item !== info?.projectId);
      const data = {
        projectId: filterData,
        userType: "developer",
        developerId: userInfo?.developer?.developerId,
      };
      await dispatch(favUnFavProjectThunk(data));
      setIsSubmit(false);
    }
    setOpenModel(false);
  };


  //   const orderData = info;
  //   const orderInfo = {
  //     projectId: orderData.projectId,
  //     amount: orderData.biddingAmount.toString(),
  //     developerId: userInfo?.developer?.developerId,
  //   };
  //   const order: any = await dispatch(createOrder(orderInfo));

  //   const options: RazorpayOptions = {
  //     key: "",
  //     amount: orderData.biddingAmount,
  //     currency: "INR",
  //     name: userInfo?.developer?.name,
  //     description: "Test Transaction",
  //     image: userInfo?.developer?.image,
  //     order_id: order.id,
  //     handler: (res: any) => {
  //       console.log(res);
  //     },
  //     prefill: {
  //       name: "Piyush Garg",
  //       email: userInfo?.developer?.email,
  //       contact: userInfo?.developer?.mobile,
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#662e91",
  //     },
  //   };

  //   const rzpay = new Razorpay(options);
  //   rzpay.open();
  // }, [Razorpay]);

  const citiesJSX =
    cities &&
    cities.map((item: any) => {
      return (
        <MenuItem className={Style["menu-item"]} value={item}>
          {item}
        </MenuItem>
      );
    });

  if (citiesJSX && citiesJSX.length)
    citiesJSX.unshift(
      <MenuItem className={Style["menu-item"]} value="None">
        None
      </MenuItem>
    );

  const handleClickBidding = (value: any) => {
    setOpenBidding(true);
    setInfo(value);
  };

  const handleClose = () => {
    setOpenBidding(false);
    setInfo(null);
    dispatch(getProjectBid(null));
  };

  const handleNext = () => {
    setOpenModel1(true);
  };

  const handleUpload = async (value: any) => {
    setLoading(true);
    await dispatch(uploadFile(value));
    setLoading(false);
  };

  const handleRemoveURL = () => {
    dispatch(getUploadURL(null));
    setNo("");
  }

  const [isMutating, setIsMutating] = useState(false);
  
  // const handleCreateOrder = async () => {
  //     sessionStorage.setItem('clear', 'YES');
  //     setDisabled(true)
  //     const info1: any = {}
  //     info1.personaId = userInfo?.developer?.developerId
  //     info1.projectId= info?.projectId
  //     info1.amountType= "CREDIT"
  //     info1.category = "BIDDING"
  //     info1.persona = "developer"
  //     info1.amount = projectBid?.totalBidAmount ? parseInt(projectBid?.totalBidAmount) : parseInt(info?.biddingAmount);
  //     // info.userId = parseInt(userInfo?.userId);
  //     console.log("info1",info1);
      
  //     const orderDetails: any = await dispatch(createOrders(info1));
  //     console.log("orderdetails",orderDetails);
      
  //     if (orderDetails?.status === 200) {
  //       makePayment(orderDetails?.data?.data);
  //       console.log("orderDetails id",orderDetails?.data?.data?.id);
        
  //     }
  //     setDisabled(false)
  //     // setIsMutating(false);
  //   // }0
  // }


  // const initializeRazorpay = () => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = "https://checkout.razorpay.com/v1/checkout.js";

  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };

  //     document.body.appendChild(script);
  //   });
  // };

  // const makePayment = async (order: any) => {
  //   const orderId = order?.id;
  //   console.log("orderId",orderId);
    
  //   const res = await initializeRazorpay();
  //   console.log("470", res, orderId);
  //   if (!res) {
  //     alert("Razorpay SDK Failed to load");
  //     return;
  //   }
  //   // Make API call to the serverless API
  // console.log("log",res);
  
  //     const razorpayResult = await fetch("/api/razorpay", {
  //       method: "POST",
  //       headers: _getHeaders(),
  //       body: JSON.stringify({ orderId }),
  //     }).then((t) => { }); 
  //     // const orderId :any= razorpayResult.orderId 
  //     console.log("razerpay",razorpayResult);
      
  //   const options = {
  //     name: "Dreams Redeveloped",
  //     currency: order?.currency,
  //     amount: order?.amount,
  //     order_id: orderId,
  //     description: "Thank You for booking a seat.",
  //     image: "",
  //     handler: async function (response:any) {
  //       console.log("rrr",response);
        
  //       try {
  //         setSuccess(true);;
  //         const info = {
  //           "razorpay_payment_id": response?.razorpay_payment_id,
  //           "razorpay_order_id": response?.razorpay_order_id,
  //           "razorpay_signature": response?.razorpay_signature,
  //           // "orderId": order?.id,
  //           "code": 200
  //         }
  //         console.log("info",info);
  //         const updateOrderStatus: any = await capturePaymentStatus(info);
  //       } catch (err) {
  //         console.log('error is', err);
  //       }
  //     },
  //     modal: {
  //       confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
  //       // This function is executed when checkout modal is closed
  //       // There can be 3 reasons when this modal is closed.
  //       ondismiss: async (reason:any) => {
  //         // When modal is auto closed because of time out
  //         if (reason === 'timeout') {
  //           sessionStorage.removeItem('clear');
  //           // router.push('/');
  //           navigate('/');
  //         } else {
  //           const info = {
  //             "orderId": orderId,
  //             "status": 400
  //           }
  //           const updateOrderStatus: any = await capturePaymentStatus(info);
  //           // const updateOrderStatus = await triggerCheck(info);
  //         }
  //       },
  //     },
  //     timeout: 300,
  //     theme: {
  //       color: "#662e91",
  //     },
  //     prefill: {
  //       // name: `${userInfo?.lastName}${' '}${userInfo?.firstName}`,
  //       email: userInfo?.emailId,
  //       contact: userInfo?.mobile,
  //     },
  //   };
  //   console.log("options",options);
    
  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  
  // // catch (error) {
  // //   console.error('Error fetching /api/razorpay:', error);
  // //   alert('Failed to initiate payment');
  // // }
  // };

  const handlePayment = async () => {
    try{
    sessionStorage.setItem('clear', 'YES');
      setDisabled(true)
      const info1: any = {}
      info1.personaId = userInfo?.developer?.developerId
      info1.projectId= info?.projectId
      info1.amountType= "CREDIT"
      info1.category = "BIDDING"
      info1.persona = "developer"
      info1.amount = projectBid?.totalBidAmount ? parseInt(projectBid?.totalBidAmount) : parseInt(info?.biddingAmount);
      // info.userId = parseInt(userInfo?.userId);
      console.log("info1",info1);
      const orderDetails: any = await dispatch(createOrders(info1));
      console.log("orderdetails",orderDetails);
      
      setDisabled(false)
   
    const options:any = {
      key:"rzp_live_kh7YrXfgjdYbk9", 
      amount: orderDetails?.data?.data?.amount,
      currency: orderDetails?.data?.data?.currency,
      name: 'Dreams Redeveloped',
      description: 'Test Transaction',
      image: '',
      order_id: orderDetails?.data?.data?.id,
      handler: async function (response:any) {
        console.log("reeee",response)
        
          console.log("1111",response)
                  const info = {
                    "razorpay_payment_id": response?.razorpay_payment_id,
                    "razorpay_order_id": response?.razorpay_order_id,
                    "razorpay_signature": response?.razorpay_signature,
                    "orderId": orderDetails?.data?.data?.id,
                    "code": 200
                  }
                  console.log("info606",info);
                  try{
                  const updateOrderStatus: any = await dispatch(capturePaymentStatus(info));
                  console.log("updateorderstatus",updateOrderStatus);
                  setSuccess(true);
                  setOpenModel(false);
                  setDisabled(false);
                  navigate('/developers-transaction/bidding');
                  
                } catch (err) {
                  console.log('error is', err);
                }
              },
     
              modal: {
                confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
                // This function is executed when checkout modal is closed
                // There can be 3 reasons when this modal is closed.
                ondismiss: async (reason:any) => {
                  // When modal is auto closed because of time out
                  if (reason === 'timeout') {
                    sessionStorage.removeItem('clear');
                    // router.push('/');
                    navigate('/');
                  } else {
                    const info = {
                      "orderId": orderDetails?.data?.data?.id,
                      "code": 400
                    }
                    console.log("modelinfo",info);
                    
                    const updateOrderStatus: any = await capturePaymentStatus(info);
                    // const updateOrderStatus = await triggerCheck(info);
                    console.log("9899",updateOrderStatus);
                    
                  }
                },
              },
              timeout: 500,
              theme: {
                color: "#662e91",
              },
      
      prefill: {
        name: userInfo?.developer?.name,
        email: userInfo?.emailId,
        contact: userInfo?.mobile
      },
     
      
      // notes: {
      //   address: 'Your Address'
      // },
      
    };
    console.log("prefill",options?.prefill);
    console.log("optionssss",options);

    // const paymentObject = new window.Razorpay(options);
    // paymentObject.open();
    const paymentObject = new Razorpay(options);
    paymentObject.open();
    console.log("paymentobject",paymentObject);
  }catch (error) {
    console.error('Error during payment:', error);
    alert('Payment failed');
  }
  };







  const filterCode = (
    <Grid
      item container
      gap={isMobileView ? 4 : 0}
      xs="auto"
      alignItems="center"
      flexDirection={isMobileView ? 'column' : 'row'}
      sx={{ p: isMobileView ? '30px' : '0px'}}
    >
      <FormControl>
        <InputLabel
          sx={{ fontFamily: "Montserrat-Medium", opacity: 0.8 }}
        >
          City
        </InputLabel>
        <CustomizeSelect
          label="City"
          value={city}
          name="Cities"
          sx={{
            bgcolor: "white",
            width: isMobileView ? '55vw' : '13.8vw', 
            height: "40px",
            borderRadius: 0,
            fontFamily: "Montserrat-Regular",
          }}
          onChange={(event: any) => setCity(event.target.value)}
        >
          {citiesJSX}
        </CustomizeSelect>
      </FormControl>

      <Box ml={isMobileView ? 0 : 3}>
        <FormControl>
          <InputLabel
            shrink
            sx={{
              fontFamily: "Montserrat-Regular",
              opacity: 0.8,
              color: "gray",
              fontSize: "15px",
            }}
          >
            Area
          </InputLabel>
          <CustomizeTextField
            name="no"
            value={area}
            placeholder="Search by area..."
            label="Name"
            inputProps={{ shrink: true }}
            onChange={(e) => {
              dispatch(
                getProjectsByArea(
                  city,
                  e.target.value,
                  maxSalePotentialRanges[selectedMaxSalePotentialRange]
                    .min,
                  maxSalePotentialRanges[selectedMaxSalePotentialRange]
                    .max,
                  selectedStages.length ? selectedStages : stages
                )
              );
              setArea(e.target.value);
            }}
            sx={{width: isMobileView ? '55vw' : '13.8vw'}}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <Search sx={{ color: "gray", opacity: 0.8 }} />
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>

      <Box ml={isMobileView ? 0 : 3}>
        <FormControl>
          <InputLabel
            sx={{
              fontFamily: "Montserrat-Medium", opacity: 0.8
            }}
          >
            Project Stages
          </InputLabel>
          <CustomizeSelect
            value={selectedStages}
            name="Stages"
            label="Project Stages"
            renderValue={(selectedStage: any) => {
              console.log('selected stage is', selectedStage, 'and', typeof selectedStage);
              return (selectedStage.map((selectedStage: string) => (selectedStage?.substring(0, 1).toUpperCase() + selectedStage?.substring(1, selectedStage.length)).replace('_', ' ')).join(", "))
            }}
            multiple
            sx={{
              bgcolor: "white",
              width: isMobileView ? '55vw' : '13.8vw',
              height: "40px",
              borderRadius: 0,
              fontFamily: "Montserrat-Regular",
            }}
            onChange={(event: any) => {
              setSelectedStages(event.target.value);
            }}
          >
            {stages.map((stage: string) => (
              <CustomizeMenuItem
                className={Style["menu-item"]}
                sx={{
                  bgcolor: "white",
                  textTransform: 'capitalize',
                  "&:hover": { bgcolor: "white" },
                  ".Mui-selected": { bgcolor: "white" },
                }}
                value={stage}
              >
                <Checkbox checked={selectedStages.indexOf(stage) > -1} />
                {stage.replace('_', ' ')}
              </CustomizeMenuItem>
            ))}
          </CustomizeSelect>
        </FormControl>
      </Box>
      <Box ml={isMobileView ? 0 : 3}>
        <FormControl>
          <InputLabel
            sx={{ fontFamily: "Montserrat-Medium", opacity: 0.8 }}
          >
            Plot Size (Sq. Ft.)
          </InputLabel>
          <CustomizeSelect
            value={selectedMaxSalePotentialRange}
            name="Cities"
            label="Plot Size (Sq. Ft.)"
            sx={{
              bgcolor: "white",
              width: isMobileView ? '55vw' : "13.8vw",
              height: "40px",
              borderRadius: 0,
              fontFamily: "Montserrat-Regular",
            }}
            onChange={(event: any) =>
              setSelectedMaxSalePotentialRange(event.target.value)
            }
          >
            {Object.keys(maxSalePotentialRanges).map(
              (maxSalePotentialRange) => (
                <MenuItem
                  className={Style["menu-item"]}
                  value={maxSalePotentialRange}
                >
                  {maxSalePotentialRange}
                </MenuItem>
              )
            )}
          </CustomizeSelect>
        </FormControl>
      </Box>
    </Grid>
  )
  return isMobileView ? (
    <Box>
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Box
        pb={0.5}
        style={{
          backgroundColor: "white",
          minHeight: "90vh",
        }}
      >
        <Paper
          sx={{
            mb: "20px",
            borderRadius: "0",
            backgroundColor: "white",
            boxShadow: 'none',
          }}
        >
          <Box
            className={Style["cardContainer"]}
            sx={{ padding: "20px 40px", justifyContent: "center" }}
          >
            <Button
              variant='contained'
              sx={{px: '0px', minWidth: '40px', ml: 'auto'}}
              onClick={() => setFilterDialogOpen((previousValue: boolean) => !previousValue)}
            >
              <Tune />
            </Button>
            <Dialog open={filterDialogOpen && isMobileView}>
              {filterCode}

              <Button variant='contained' onClick={() => setFilterDialogOpen(false)} sx={{width: '20vw', mx: 'auto', mb: '30px'}}>Ok</Button>
            </Dialog>
            <Grid container spacing={2.5}>
              {drProjects?.data?.count
                ? drProjects?.data?.allProject?.map(
                  (item: any, index: number) => {
                    return (
                      <Grid item xs={12} sm={4} md={3}>
                        <Box
                          sx={{
                            display: "flex",
                            lineHeight: "2vh",
                            fontSize: "12px",
                            p: "0.74vh 1.62vw",
                            bgcolor: "#00DB2D",
                            color: "white",
                            position: "relative",
                            top: "1.9vh",
                            ml: "18px",
                            width: "fit-content",
                            borderRadius: "30px",
                            fontFamily: "Montserrat-Regular",
                            textTransform: 'capitalize',
                          }}
                        >
                          {item.projectStages.replace('_', ' ')}
                        </Box>
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "space-between",
                            borderRadius: "10px",
                            borderTop: "1px solid #f2f2f2",
                            boxShadow: "2px 2px 10px #00000029",
                          }}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "10px 12px 0px 12px",
                            }}
                          >
                            <img
                              src={drfavicon}
                              style={{
                                alignSelf: "flex-end",
                                width: "14%",
                                height: "auto",
                              }}
                            />

                            <div className={Style["title-ellipsis"]}>
                              {item.projectName}
                            </div>

                            <img
                              className={Style["imgcard"]}
                              src={item?.mainImage || BgImg}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "fit-content",
                              }}
                            >
                              <div className={Style["block-ellipsis"]}>
                                {item.address.formatted_address}
                              </div>
                              <span className={Style["sub-text"]}>
                                Total Size:
                              </span>

                              <Box sx={{ display: "flex" }}>
                                <span className={Style["sub-text"]}>
                                  No of Flats: {item.noOfFlats}
                                </span>
                              </Box>

                              <Box sx={{ display: "flex" }}>
                                <span className={Style["sub-text"]}>
                                  No of Shops: {item.noOfShops}
                                </span>
                                {item.maxPotential ? (
                                  <span className={Style["sub-text"]}>
                                    Max. Potential: {item.maxPotential}
                                  </span>
                                ) : null}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                            >
                              {item.frontRoad ? (
                                <span className={Style["sub-text"]}>
                                  Road Size: {item.frontRoad} mt
                                </span>
                              ) : (
                                <span></span>
                              )}
                              {item?.favouriteId.includes(item.projectId) ? (
                                <span
                                  style={{
                                    textAlign: "right",
                                    color: "#F07F25",
                                    cursor: "pointer",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  <StarIcon
                                    sx={{ width: "0.8em", height: "0.8em" }}
                                    onClick={() => {
                                      setInfo(item);
                                      setOpenModel(true);
                                      setIsFav(false);
                                    }}
                                  />
                                </span>
                              ) : (
                                <span
                                  style={{
                                    textAlign: "right",
                                    color: "#788195",
                                    cursor: "pointer",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  <StarOutline
                                    sx={{ width: "0.8em", height: "0.8em" }}
                                    onClick={() => {
                                      setInfo(item);
                                      setOpenModel(true);
                                      setIsFav(true);
                                    }}
                                  />
                                </span>
                              )}
                            </Box>
                          </CardContent>
                          <CardActions
                            sx={{
                              display: "flex",
                              width: "100%",
                              padding: 0,
                            }}
                          >
                            {item?.projectStages !== "bids" ? (
                              <Button
                                onClick={() =>
                                  navigate(`/project/${item?.drProjectUrl}`, {
                                    state: { projectId: item?.drProjectUrl },
                                  })
                                }
                                className={Style["card-button"]}
                              >
                                View Details
                              </Button>
                            ) : (
                              <ButtonGroup sx={{ width: "100%" }}>
                                <Button
                                  onClick={() =>
                                    navigate(
                                      `/project/${item?.drProjectUrl}`,
                                      {
                                        state: {
                                          projectId: item?.drProjectUrl,
                                        },
                                      }
                                    )
                                  }
                                  className={Style["card-button"]}
                                >
                                  View Details
                                </Button>
                                <Button
                                  onClick={() => handleClickBidding(item)}
                                  className={Style["card-button"]}
                                >
                                  Bid
                                </Button>
                              </ButtonGroup>
                            )}
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  }
                )
                : null}

              {!drProjects?.data?.count ? (
                <span style={{ margin: "auto", color: "#788195", marginTop: '50px' }}>
                  No Projects Found
                </span>
              ) : null}
            </Grid>
          </Box>
        </Paper>
        {openModal && (
          <Modal open={openModal}>
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to{" "}
                {isFav ? "favourite" : "remove favourite"} project?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {info?.projectName}
              </Typography>
              <Box pt={2} display="flex" justifyContent="space-between">
                <LoadingButton
                  sx={cancelButton}
                  onClick={() => {
                    setOpenModel(false);
                    setInfo(null);
                  }}
                >
                  No
                </LoadingButton>
                <LoadingButton
                  disabled={isSubmit}
                  loading={isSubmit}
                  sx={submitButton}
                  onClick={(e) => handelFavourite()}
                >
                  Yes
                </LoadingButton>
              </Box>
            </Box>
          </Modal>
        )}
        {openModal1 && (
          <Modal open={openModal1}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontSize: "18px" }}
              >
                Are you sure you want to bid for this project?{" "}
                {/*todo: change font size: 18px*/}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontSize: "22px" }}
              >
                {info?.projectName}
                {/*todo: change font size: 22px*/}
              </Typography>
              {/* <Box m={2} display="flex" alignItems="center">
                <TextField disabled={isDisabled}
                  name="no"
                  value={no}
                  onChange={(e) => setNo(e.target.value)}
                  size="small"
                  placeholder="Enter Transaction / Cheque No."
                />
                <Box ml={1} mr={1} sx={{ cursor: "pointer" }}>
                  <input
                    accept="*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={(e: any) => handleUpload(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      sx={{ padding: 0 }}
                      disabled={loading}
                    >
                      <img
                        src={AttachFile}
                        alt="attach"
                        style={{ width: "40px" }}
                      />
                    </Button>
                  </label>
                </Box>
                {uploadURL && (
                  <a href={uploadURL} target="_blank">
                    View
                  </a>
                )}
              </Box> */}
              <Box pt={2} display="flex" justifyContent="space-between">
                <LoadingButton
                  sx={cancelButton}
                  onClick={() => {
                    setOpenModel1(false);
                  }}
                >
                  No
                </LoadingButton>
                <LoadingButton
                  disabled={isDisabled}
                  loading={isDisabled}
                  sx={submitButton}
                  // onClick={(e) => {
                  //   handleReturnBidDailog();
                  // }}
                  onClick={() => handlePayment()}
                >
                  Yes
                </LoadingButton>
              </Box>
            </Box>
          </Modal>
        )}
        {openBidding && (
          <BiddingDialog
            info={info}
            handleClose={handleClose}
            handleNext={handleNext}
          />
        )}
      </Box>
    </Box>
  ) : (
    <Box>
      <Box
        style={{
          marginTop: "10px",
          marginBottom: "12px",
          marginRight: "20px",
          height: "40px",
        }}
      ></Box>
      <Box
        pb={0.5}
        style={{
          backgroundColor: "#F2F3F6",
          minHeight: "90vh",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 30px 0",
          }}
        >
          <Typography className={Style["page-title"]}>
            Redevelopment Projects
          </Typography>
          {filterCode}
        </Box>
        <Paper
          style={{
            margin: "0 30px 20px 30px",
            borderRadius: "0",
            backgroundColor: "white",
          }}
        >
          <Box
            className={Style["cardContainer"]}
            sx={{ padding: "40px", justifyContent: "center" }}
          >
            <Grid container spacing={2.5}>
              {drProjects?.data?.count
                ? drProjects?.data?.allProject?.map(
                  (item: any, index: number) => {
                    return (
                      <Grid item xs={12} sm={4} md={3}>
                        <Box
                          sx={{
                            display: "flex",
                            lineHeight: "2vh",
                            fontSize: "12px",
                            p: "0.74vh 1.62vw",
                            bgcolor: "#00DB2D",
                            color: "white",
                            textTransform: 'capitalize',
                            position: "relative",
                            top: "1.9vh",
                            ml: "18px",
                            width: "fit-content",
                            borderRadius: "30px",
                            fontFamily: "Montserrat-Regular",
                          }}
                        >
                          {item.projectStages.replace('_', ' ')}
                        </Box>
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "space-between",
                            borderRadius: "10px",
                            borderTop: "1px solid #f2f2f2",
                            boxShadow: "2px 2px 10px #00000029",
                          }}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "10px 12px 0px 12px",
                            }}
                          >
                            <img
                              src={drfavicon}
                              style={{
                                alignSelf: "flex-end",
                                width: "14%",
                                height: "auto",
                              }}
                            />

                            <div className={Style["title-ellipsis"]}>
                              {item.projectName}
                            </div>

                            <img
                              className={Style["imgcard"]}
                              src={item?.mainImage || BgImg}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "fit-content",
                              }}
                            >
                              <div className={Style["block-ellipsis"]}>
                                {item.address.formatted_address}
                              </div>
                              <span className={Style["sub-text"]}>
                                Total Size:
                              </span>

                              <Box sx={{ display: "flex" }}>
                                <span className={Style["sub-text"]}>
                                  No of Flats: {item.noOfFlats}
                                </span>
                              </Box>

                              <Box sx={{ display: "flex" }}>
                                <span className={Style["sub-text"]}>
                                  No of Shops: {item.noOfShops}
                                </span>
                                {item.maxPotential ? (
                                  <span className={Style["sub-text"]}>
                                    Max. Potential: {item.maxPotential}
                                  </span>
                                ) : null}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                            >
                              {item.frontRoad ? (
                                <span className={Style["sub-text"]}>
                                  Road Size: {item.frontRoad} mt
                                </span>
                              ) : (
                                <span></span>
                              )}
                              {item?.favouriteId.includes(item.projectId) ? (
                                <span
                                  style={{
                                    textAlign: "right",
                                    color: "#F07F25",
                                    cursor: "pointer",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  <StarIcon
                                    sx={{ width: "0.8em", height: "0.8em" }}
                                    onClick={() => {
                                      setInfo(item);
                                      setOpenModel(true);
                                      setIsFav(false);
                                    }}
                                  />
                                </span>
                              ) : (
                                <span
                                  style={{
                                    textAlign: "right",
                                    color: "#788195",
                                    cursor: "pointer",
                                    alignSelf: "flex-end",
                                  }}
                                >
                                  <StarOutline
                                    sx={{ width: "0.8em", height: "0.8em" }}
                                    onClick={() => {
                                      setInfo(item);
                                      setOpenModel(true);
                                      setIsFav(true);
                                    }}
                                  />
                                </span>
                              )}
                            </Box>
                          </CardContent>
                          <CardActions
                            sx={{
                              display: "flex",
                              width: "100%",
                              padding: 0,
                            }}
                          >
                            {item?.projectStages !== "bids" ? (
                              <Button
                                onClick={() =>
                                  navigate(`/project/${item?.drProjectUrl}`, {
                                    state: { projectId: item?.drProjectUrl },
                                  })
                                }
                                className={Style["card-button"]}
                              >
                                View Details
                              </Button>
                            ) : (
                              <ButtonGroup sx={{ width: "100%" }}>
                                <Button
                                  onClick={() =>
                                    navigate(
                                      `/project/${item?.drProjectUrl}`,
                                      {
                                        state: {
                                          projectId: item?.drProjectUrl,
                                        },
                                      }
                                    )
                                  }
                                  className={Style["card-button"]}
                                >
                                  View Details
                                </Button>
                                <Button
                                  onClick={() => handleClickBidding(item)}
                                  className={Style["card-button"]}
                                >
                                  Bid
                                </Button>
                              </ButtonGroup>
                            )}
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  }
                )
                : null}

              {!drProjects?.data?.count ? (
                <span style={{ margin: "auto", color: "#788195" }}>
                  No Projects Found
                </span>
              ) : null}
            </Grid>
          </Box>
        </Paper>
        {openModal && (
          <Modal open={openModal}>
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure you want to{" "}
                {isFav ? "favourite" : "remove favourite"} project?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {info?.projectName}
              </Typography>
              <Box pt={2} display="flex" justifyContent="space-between">
                <LoadingButton
                  sx={cancelButton}
                  onClick={() => {
                    setOpenModel(false);
                    setInfo(null);
                  }}
                >
                  No
                </LoadingButton>
                <LoadingButton
                  disabled={isSubmit}
                  loading={isSubmit}
                  sx={submitButton}
                  onClick={(e) => handelFavourite()}
                >
                  Yes
                </LoadingButton>
              </Box>
            </Box>
          </Modal>
        )}
        {openModal1 && (
          <Modal open={openModal1}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontSize: "18px" }}
              >
                Are you sure you want to bid for this project?{" "}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontSize: "22px" }}
              >
                {info?.projectName}
              </Typography>
              {/* <Box m={2} display="flex" alignItems="center">
                <TextField
                  name="no"
                  value={no}
                  onChange={(e) => setNo(e.target.value)}
                  size="small"
                  placeholder="Enter Transaction / Cheque No."
                />
                <Box ml={1} mr={1} sx={{ cursor: "pointer" }}>
                  <input
                    accept="*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={(e: any) => handleUpload(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      component="span"
                      sx={{ padding: 0 }}
                      disabled={loading}
                    >
                      <img
                        src={AttachFile}
                        alt="attach"
                        style={{ width: "40px" }}
                      />
                      {loading && <CircularProgress size={20} />}
                    </Button>
                  </label>
                </Box>
                {uploadURL && (
                  <a href={uploadURL} target="_blank">
                    View
                  </a>
                )}
              </Box> */}
              <Box pt={2} display="flex" justifyContent="space-between">
                <LoadingButton disabled={isDisabled}
                  sx={cancelButton}
                  onClick={() => {
                    { setOpenModel1(false); handleRemoveURL(); }
                  }}
                >
                  No
                </LoadingButton>
                <LoadingButton
                  disabled={isDisabled}
                  loading={isDisabled}
                  sx={submitButton}
                  // onClick={(e) => {
                  //   handleReturnBidDailog();
                  // }}
                  // disabled={isMutating}
                  onClick={() => handlePayment()}
                >
                  Yes
                </LoadingButton>
              </Box>
            </Box>
          </Modal>
        )}
        {/* {success1 && <SuccessModal />} */}
        {openBidding && (
          <BiddingDialog
            info={info}
            handleClose={handleClose}
            handleNext={handleNext}
          />
        )}
      </Box>
      <div
        style={{
          position: 'fixed',
          cursor: 'pointer',
          padding: '8px 8px 2px 8px',
          backgroundColor: '#662e91',
          borderRadius: '5px',
          bottom: isMobileView ? '20px' : '20px',
          right: isMobileView ? '20px' : '100px',
        }}
        onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}
      >
        <MessageIcon style={{ color: 'white' }} />
      </div>
    </Box>
  );
};
export default DrProjectList;

import { Box, Button, Grid } from '@mui/material';
import '../styling/GlobalStyles.css';

export default function Section(props: any) {

  return (
  <Box
    sx={{
      px: 'clamp(1rem, 2.19vw, 2.69rem)',
      width: props.sectionWidth,
      bgcolor: 'white',
      fontSize: '18px',//'1.15vw',
      color: '#788195',
      mb: 'clamp(1.25rem, 2.08vw, 2.08vw)',
      border: props.removeBorder ? 'none' : '1px solid #e2e2e2',
      borderRadius: '3px',
      alignSelf: props.alignSelf ? props.alignSelf : 'auto',
      fontFamily: 'Montserrat-Medium'
    }}
    >
      <Grid
        container
        sx={{
        color: "#788195",
        fontSize: "clamp(1rem, 2.04vh, 2.04vh)",
        fontWeight: 500,
        fontFamily: "Montserrat-Medium"}}
      >
        <Grid
          item container
          sx={{
            width: '100%',
            minHeight: '3rem',
            height: '3.65vw',
            borderBottom: '1px solid #f0f0f0'
          }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid
            item
            sx={{ fontFamily: 'Montserrat-Medium'}}
          >
            {props.sectionTitle}
          </Grid>
          {
            props.buttonTitle
            ?
            <Grid
              item
            >
              <Button
                className='button1'
                onClick={props.onButtonClick}
                variant='contained'
                sx={{
                  borderRadius: '3px',
                  bgcolor: '#662e91',
                  fontFamily: 'Montserrat-Medium'
                }}
              >
                {props.buttonTitle}
              </Button>
            </Grid>
            :
            null
          }
        </Grid>
        <Grid
          item container
          sx={{py: 'clamp(1rem, 1.15vw, 2.69rem)'}}
        >
          {props.children}
        </Grid>
      </Grid>
      
  </Box>)
}
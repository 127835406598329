import "./AdminApproval.css";
import { Box, Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import routineicon from "../../theme/routineicon.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/redux-store";
import { useDispatch, useSelector } from "react-redux";
import { getAllWings, setSearchProjectName, getAllProject } from '../../redux/data/data-actions';
import MessageIcon from '@mui/icons-material/Message';

function AdminApproval() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobileView = useMediaQuery("(max-width:480px)");
    const user: any = sessionStorage.getItem('DREAMUSERAPP');
    const userInfo: any = JSON.parse(user);

    const selectedProjectInfo: any = useSelector((state: RootState) => state.DATA_REDUCER.selectedProjectInfo);

    const handelChange = async () => {
        dispatch(setSearchProjectName(null));
        await dispatch(getAllWings(null));
        await dispatch(getAllProject(null));
        userInfo?.owner && navigate(`/search/searchproject/${userInfo?.owner?.ownerId}`);
    }

    return (
        <Box className='mobile-view'>
            <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
            <Grid container>
                <Grid item xs={12} md={3} onClick={() => handelChange()}
                    sx={{
                        width: isMobileView ? "20px" : "27.5vw",
                        display: "flex",
                        justifyContent: isMobileView ? "flex-start" : "flex-end",
                        alignItems: "flex-start",
                        cursor: 'pointer'
                    }}>
                    <ArrowBackIosNewIcon sx={{ fontSize: '35px' }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                    <span className="adminaprovetext">Project Not Verified</span>
                    <br />
                    <span className="secondsmalltext">
                        The admin will approve and verify the Project
                    </span>
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="imgbox">
                            <img className="adminaproveimg" src={routineicon} />
                            <span className="amnoratext">{selectedProjectInfo?.projectName}</span>
                            <span className="belowamnora">Awaits admin approval and verification</span>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
          <div
            style={{
              position: 'fixed',
              cursor: 'pointer',
              padding: '8px 8px 2px 8px',
              backgroundColor: '#662e91',
              borderRadius: '5px',
              bottom: isMobileView ? '20px' : '20px',
              right: isMobileView ? '20px' : '100px',
            }}
            onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
          >
            <MessageIcon style={{color: 'white'}} />
          </div>
        </Box>
    );
}

export default AdminApproval;

import * as Yup from 'yup';

export const EditArchitectSchema = Yup.object({
    architectName: Yup.string().min(2).required('Please enter a name'),
    
    email: Yup.string().email('Enter a valid email').required('Please enter an email').nullable(true),

    email2: Yup.string().email('Enter a valid email').nullable(true),

    email3: Yup.string().email('Enter a valid email').nullable(true),
    
    mobile: Yup.string().matches(/^[1-9]{1}?[0-9]{9,11}/, 'Must be a valid number').min(10, 'Must be a valid number').max(12, 'Must be a valid number').required('Please enter a number'),

    mobile2: Yup.string().matches(/^[1-9]{1}?[0-9]{9,11}/, 'Must be a valid number').min(10, 'Must be a valid number').max(12, 'Must be a valid number').nullable(true),
  //.matches(/[1-9]\d{11}/, 'Must be a valid number')
    mobile3: Yup.string().matches(/^[1-9]{1}?[0-9]{9,11}/, 'Must be a valid number').min(10, 'Must be a valid number').max(12, 'Must be a valid number').nullable(true),

    contactUs: Yup.string().matches(/^[1-9]{1}?[0-9]{9,11}/, 'Must be a valid number').min(10, 'Must be a valid number').max(12, 'Must be a valid number').nullable(true),
    
    completedProjects: Yup.string().matches(/^[0-9]*$/, 'Please enter a number').nullable(true),
    ongoingProjects: Yup.string().matches(/^[0-9]*$/, 'Please enter a number').nullable(true),
    establishedYear: Yup.string().matches(/^[0-9]*$/, 'Please enter a number').nullable(true).min(4, 'Too short!').max(4, 'Too long!'),
    description: Yup.string().required('Please enter description'),
    licenseNo:Yup.string().required('Please enter the registration no.'),
});
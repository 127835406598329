import {
  DisplayAction,
  SNACKBAR_ALERT_A,
  SnackbarAlertAction,
  SET_GENERIC_ERROR_A,
  SetErrorAction,
  SET_GENERIC_SUCCESS_A,
  SetSuccessAction,
  SET_GENERIC_LOADING_A,
  SetLoadingAction,
} from "./display-actions";
import { DisplayReduxState } from "./display-reducer-type";

// manage transient states like loading and success responses

const defaultDisplayState = {
  displayErrors: {
    loginError: null,
    signupError: null,
    updateUserRoleError: null,
    getLoggedInUserError: null,
    internalActionError: null,
    deleteUserError: null,
    inviteUserError: null,
    getDataError: null,
    editDeveloperError: null,
    editUserError: null,
  },
  displaySuccess: {
    searchSuccess: false,
    signupSuccess: false,
    logoutSuccess: false,
    changePasswordSuccess: false,
    deleteUserSuccess: false,
    dataSaveSuccessfully: false,
    sendUserAccess: false,
    getProjectAccess: false,
    favouriteSuccess: false,
    propertyMapSuccess: false,
    developerVerificationSuccess: false,
    editDeveloperSuccess: false,
    createMemberSuccess: false,
    editMemberSuccess: false,
    deleteMemberSuccess: false,
    editUserSuccess: false,
  },
  displayLoading: {
    updateUserRoleLoading: false,
    getLoggedInUserLoading: false,
    getDataLoading: false,
    deleteUserLoading: false,
    inviteUserLoading: false,
    editDeveloperLoading: false,
    unlinkingProject: false,
    createMemberLoading: false,
    editMemberLoading: false,
    editUserLoading: false,
  },

  snackbarAlertMessage: null,
  snackbarAlertSeverity: "info",  // TODO  Color  import { Color } from '@material-ui/lab/Alert';
};

export const displayReducer = (
  state: DisplayReduxState = defaultDisplayState,
  action: DisplayAction
): DisplayReduxState => {
  switch (action.type) {
    case SET_GENERIC_ERROR_A:
      const errorResult = { ...state };
      const errorAction = action as SetErrorAction;
      errorResult.displayErrors[errorAction.reduxField] = errorAction.errorMsg;
      return errorResult;
    case SET_GENERIC_SUCCESS_A:
      const successResult = { ...state };
      const successAction = action as SetSuccessAction;
      successResult.displaySuccess[successAction.reduxField] =
        successAction.success;
      return successResult;
    case SET_GENERIC_LOADING_A:
      const loadingResult = { ...state };
      const loadingAction = action as SetLoadingAction;
      loadingResult.displayLoading[loadingAction.reduxField] =
        loadingAction.loading;
      return loadingResult;
    case SNACKBAR_ALERT_A:
      return {
        ...state,
        snackbarAlertMessage: (action as SnackbarAlertAction)
          .snackbarAlertMessage,
        snackbarAlertSeverity: (action as SnackbarAlertAction)
          .snackbarAlertSeverity,
      };
    default:
      return state;
  }
};

import GoogleMapReact from 'google-map-react';
import { Box } from '@mui/material';

import markerImage from '../theme/marker.png';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/redux-store';
import { useEffect } from 'react';

interface AnyReactComponentProps {
  pinImage ?: string;
  lat ?: number;
  lng ?: number;
  locationName ?: string;
}
const AnyReactComponent = (props: AnyReactComponentProps) => <div style={{display: 'flex', alignItems: 'center'}}><img src={props.pinImage} alt='Location' /> <span style={{color: '#662E91', marginLeft: '4px'}}>{props.locationName}</span></div>;

interface MapLocationProps {
  lat : number;
  lng : number;
  pinImage ?: string;
  zoom ?: any;
  locationName ?: string;
};

const MapLocation = ({lat, lng, pinImage, zoom, locationName }: MapLocationProps) => {

  console.log('values are', zoom, lat, lng);

  const defaultProps = {
    center: {
      lat ,
      lng
    },
    zoom:  zoom == undefined ? 11 : zoom
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      {
        typeof defaultProps.center.lat == 'number' && typeof defaultProps.center.lng == 'number'
        ?
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAjm7oLhCvIeol2k8kNlj62c07cgNim3XU' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={lat} lng={lng} pinImage={pinImage ? pinImage : markerImage} locationName={locationName}/>
        </GoogleMapReact>
        :
        null
      }
    </Box>
  );
}

export default MapLocation;
import { Box, MenuItem } from '@mui/material';
import './AddDRProjectItem.css';
import projectFallbackImage from '../theme/residential.png';

interface AddDRProjectItemProps {
    index?: number,
    projectImage?: string,
    projectName?: string,
    projectAddress?: string,
    selectedIndex?: number | null,
    setSelectedIndex?: any
}

const AddDRProjectItem = (props: AddDRProjectItemProps) => {
    return (
        <MenuItem
          id='temp1'
          selected={props.index === props.selectedIndex}
          className='Project-item'
          sx={{
            minHeight: '8.5vw',
            height: '8.5vw',
            borderRadius: '3px',
            gap: '1.25vw',
            '& .MuiMenuItem-root': {
              whiteSpace: 'normal'
            },
            '& .Mui-selected': {
              backgroundColor: '#b4b4b4'
            }
          }}
          onClick={() => props.setSelectedIndex(props.index)}
        >
            <Box sx={{width: '16%', height: '100%'}}>
              <Box sx={{height: '100%', backgroundImage: `url(${props.projectImage})`, backgroundSize: 'cover'}}>
                <img
                  src={props.projectImage ? props.projectImage : projectFallbackImage}
                  alt='building'
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Box>
            </Box>
            <Box className='Project-text-content-container' alignSelf='stretch' sx={{p: '0px'}}>
                <Box
                  className='Project-name'
                  sx={{
                    my: '0.8vw',
                    lineHeight: '2.22vh'
                  }}
                >
                  {props.projectName}
                </Box>
                <Box className='Project-description' sx={{fontSize: '2.22vh'}}>
                  {props.projectAddress}
                  
                </Box>
            </Box>
        </MenuItem>
    )
};

export default AddDRProjectItem;
import { Box, Card, useMediaQuery, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import FooterLogos from "../theme/FooterLogos.png";
import { RootState } from "../redux/redux-store";
import "../pages/ProjectProfile/Maps.css";
import MapLocation from "./MapLocation";
import { useEffect } from "react";
import drfavicon from "../theme/drfavicon.png";
import Styles from './ContactInfo.module.css';

interface contactInfoProps {
  page?: string;
}

const ContactInfo = (props: contactInfoProps) => {
  const isMobileView = useMediaQuery("(max-width: 600px)");

  const vastuData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.vastuProfileData
  );

  const lawyerData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.lawyerProjects
  );

  const architectContact: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.architectProjects
  );

  const developerCompanyLogo = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerCompanyLogo
  );
  const projectName = useSelector(
    (state: RootState) => state.DATA_REDUCER.projectName
  );

  const projectSalesNumber = useSelector(
    (state: RootState) => state.DATA_REDUCER.projectSalesNumber
  );

  const developerTelephone = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerTelephone
  );

  const developerEmail = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerEmail
  );

  const developerAddress = useSelector(
    (state: RootState) => state.DATA_REDUCER.formatted_address
  );

  const projectAddress = useSelector(
    (state: RootState) => state.DATA_REDUCER.projectAddress
  );

  const developerData = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerData
  );

  const mapDetails = useSelector(
    (state: RootState) =>
      props.page === "developer"
        ? state.DATA_REDUCER.developerMapDetails
        : state.DATA_REDUCER.projectMapDetails

  );

  const developerName = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerName
  );
  const developerLocation = useSelector(
    (state: RootState) => state.DATA_REDUCER
  );



  return isMobileView
    ?
    (
      <Box className="mapdiv">
        <Card
          className="carddiv"
          elevation={0}
          style={{
            width: isMobileView ? "calc(100% + 10vw)" : "100%",
            marginLeft: isMobileView ? "-5vw" : "initial",
          }}
        >
          <Box
            className="logo"
            sx={{ width: isMobileView ? "100%" : "auto", }}
          >
            <Box sx={{ height: '51px' }}>
              <img
                style={{
                  width: isMobileView ? "initial" : "initial",//mobile: 20.23vw
                  height: "51px",//12.82vh
                  visibility: developerCompanyLogo || (props.page === 'lawyer') || (props.page === 'architect') || (props.page === 'vastu') ? 'visible' : 'hidden'
                }}
                src={(props.page === 'vastu') ?
                  vastuData?.data?.companyLogo : (props.page === 'architect') ?
                    architectContact?.data?.companyLogo
                    :
                    (props.page === 'lawyer') ?
                      lawyerData?.data?.companyLogo
                      :
                      developerCompanyLogo ? developerCompanyLogo : null}
              />
            </Box>
            {props.page === "developer" || (props.page === 'vastu') || (props.page === 'lawyer') || (props.page === 'architect') ? null : (
              <Typography
                style={{
                  fontFamily: 'Montserrat-Medium',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '3vh',
                  opacity: 0.9,
                  height: 'auto',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '75vw',
                  color: '#878787'
                }}
                title={projectName}
              >
                {projectName}
              </Typography>
            )}
            <Typography
              // className="info"
              sx={{
                fontFamily: 'Montserrat-Medium',
                fontSize: '16px',
                lineHeight: '3.4vh',
                opacity: 0.9,
                maxHeight: '17vh',
                display: '-webkit-box',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '75vw',
                color: isMobileView ? "#878787" : "initial",
                fontWeight: 600,

              }}
            >

              {
                props.page === 'architect'
                  ?
                  architectContact?.data?.address[0]?.formatted_address
                  :
                  props.page === 'vastu'
                    ?
                    vastuData?.data?.address[0]?.formatted_address
                    :
                    props.page === 'lawyer'
                      ?
                      lawyerData?.data?.address[0]?.formatted_address
                      :
                      props.page === "developer"
                        ? developerAddress
                        : projectAddress /*projectLocationDetails.address*/
              }
            </Typography>
            {
              props.page === 'project' && !projectSalesNumber
                ?
                null
                :
                <Typography
                  // className="info"
                  sx={{ fontFamily: 'Montserrat-Medium', fontSize: '16px', fontWeight: 600, color: isMobileView ? "#878787" : "initial", opacity: 0.9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '75vw', }}
                >
                  {" "}
                  Tel :{" "}
                  {
                    props.page === 'architect'
                      ?
                      architectContact?.data?.mobile
                      :
                      props.page === 'lawyer'
                        ?
                        lawyerData?.data?.mobile
                        :
                        props.page === 'vastu'
                          ?
                          vastuData?.data?.mobile
                          :
                          props.page === "developer"
                            ? developerTelephone
                            : projectSalesNumber}
                </Typography>
            }

            {
              props.page === 'developer' || props.page === 'vastu' || props.page === 'lawyer' || props.page === 'architect'
                ?
                <Typography
                  // className="info"
                  sx={{
                    // mb: '20px',
                    fontFamily: 'Montserrat-Medium',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: 600,
                    color: isMobileView ? "#878787" : "initial",
                    opacity: 0.9,
                    width: '75vw',
                    maxHeight: '40px',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                  }}
                >
                  {" "}
                  {
                    props.page === 'architect' ? (architectContact?.data?.email) :
                      props.page === 'lawyer' ? (lawyerData?.data?.email) : props.page === 'vastu' ? (vastuData?.data?.email) : developerEmail
                  }
                </Typography>
                :
                null
            }
            {
              (props.page === 'developer' && developerData?.website) || (props.page === 'lawyer' && lawyerData?.data?.website) || (props.page === 'vastu' && vastuData?.data?.website) || (props.page === 'architect' && architectContact?.data?.website)
                ?
                <a href={props.page === 'architect'
                  ?
                  architectContact?.data?.website
                  :
                  props.page === 'lawyer'
                    ?
                    lawyerData?.data?.website
                    :
                    props.page === 'vastu'
                      ?
                      vastuData?.data?.website
                      :
                      developerData?.website
                } target='_blank'>
                  <Typography
                    // className="info"
                    sx={{
                      mb: '20px',
                      fontFamily: 'Montserrat-Medium',
                      fontSize: '16px',
                      lineHeight: '20px',
                      fontWeight: 600,
                      color: isMobileView ? "#878787" : "initial",
                      opacity: 0.9,
                      width: '75vw',
                      maxHeight: '40px',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                    }}
                  >
                    {" "}
                    {
                      props.page === 'architect'
                        ?
                        architectContact?.data?.website
                        :
                        props.page === 'vastu'
                          ?
                          vastuData?.data?.website
                          :
                          props.page === 'lawyer'
                            ?
                            lawyerData?.data?.website : developerData?.website}
                  </Typography>
                </a>
                :
                null
            }
          </Box>
          <div
            style={{
              minWidth: isMobileView ? "100%" : "63.15%",
              height: '447px',
              float: isMobileView ? "right" : "initial",
            }}
          >
            <MapLocation
              lat={
                (props.page === 'architect' &&
                  architectContact?.data?.address[0]?.geometry?.location?.lat)
                  ?
                  architectContact?.data?.address[0]?.geometry?.location?.lat
                  :
                  (props.page === 'lawyer' &&
                    lawyerData?.data?.address[0]?.geometry?.location?.lat)
                    ?
                    lawyerData?.data?.address[0]?.geometry?.location?.lat
                    :
                    (props.page === 'vastu' &&
                      vastuData?.data?.address[0]?.geometry?.location?.lat)
                      ?
                      vastuData?.data?.address[0]?.geometry?.location?.lat
                      :
                      mapDetails.lat}
              lng={(
                props.page === 'architect' &&
                architectContact?.data?.address[0]?.geometry?.location?.lng)
                ?
                architectContact?.data?.address[0]?.geometry?.location?.lng
                :
                (props.page === 'lawyer' &&
                  lawyerData?.data?.address[0]?.geometry?.location?.lng)
                  ?
                  lawyerData?.data?.address[0]?.geometry?.location?.lng
                  :
                  (props.page === 'vastu' &&
                    vastuData?.data?.address[0]?.geometry?.location?.lng)
                    ?
                    vastuData?.data?.address[0]?.geometry?.location?.lng
                    :
                    mapDetails.lng}
              zoom={14}
              locationName={
                props.page === 'architect'
                  ?
                  architectContact?.data?.architectName
                  :
                  props.page === 'lawyer'
                    ?
                    lawyerData?.data?.lawyerName
                    :
                    props.page === 'vastu'
                      ?
                      vastuData?.data?.vastuName
                      :
                      props.page === 'developer' ? developerName
                        :
                        projectName}
            />
          </div>
        </Card>
      </Box>
    )

    :

    (
      <Box className="mapdiv">
        <Card
          className="carddiv"
          elevation={0}
          style={{
            width: isMobileView ? "calc(100% + 10vw)" : "100%",
            marginLeft: isMobileView ? "-5vw" : "initial",
          }}
        >
          <Box
            className="logo"
            sx={{ width: isMobileView ? "100%" : "auto", }}
          >
            <Box>
              <img
                style={{
                  width: isMobileView ? "20.23vw" : "initial",
                  height: "12.82vh",
                  visibility: developerCompanyLogo || (props.page === 'lawyer') || (props.page === 'vastu') || (props.page === 'architect') ? 'visible' : 'hidden'
                }}
                src={developerCompanyLogo ? developerCompanyLogo : (props.page === 'architect' && architectContact?.data?.companyLogo) ? architectContact?.data?.companyLogo : (props.page === 'lawyer' && lawyerData?.data?.companyLogo) ? lawyerData?.data?.companyLogo : (props.page === 'vastu' && vastuData?.data?.companyLogo) ? vastuData?.data?.companyLogo : null}
              />
            </Box>
            {props.page === "architect" || props.page === "developer" || props.page === 'lawyer' || props.page === 'vastu' ? null : (
              <Typography
                style={{
                  fontFamily: 'Montserrat-Medium',
                  fontSize: '18px',
                  fontWeight: 600,
                  lineHeight: '3vh',
                  opacity: 0.9,
                  height: 'auto',
                  margin: '40px 0px 10px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '28.65vw',
                }}
                title={projectName}
              >
                {projectName}
              </Typography>
            )}
            <Typography
              sx={{
                fontFamily: 'Montserrat-Medium',
                fontSize: '19px',
                lineHeight: '3.4vh',
                opacity: 0.9,
                maxHeight: '17vh',
                display: '-webkit-box',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '28.65vw',
                color: isMobileView ? "#878787" : "initial",
                fontWeight: 600,
              }}
            >
              {
                (props.page === 'architect')
                  ?
                  architectContact?.data?.address[0]?.formatted_address
                  :
                  (props.page === 'lawyer')
                    ?
                    lawyerData?.data?.address[0]?.formatted_address
                    :
                    (props.page === 'vastu')
                      ?
                      vastuData?.data?.address[0]?.formatted_address
                      :
                      props.page === "developer"
                        ? developerAddress
                        : projectAddress
              }
            </Typography>
            {
              props.page === 'project' && !projectSalesNumber
                ?
                null
                :
                <Typography
                  sx={{ fontFamily: 'Montserrat-Medium', fontSize: '18px', fontWeight: 600, color: isMobileView ? "#878787" : "initial", opacity: 0.9, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '28.65vw', mt: '10px' }}
                >
                  {" "}
                  Tel :{" "}
                  {
                    props.page === 'architect' && architectContact?.data?.mobile
                      ?
                      architectContact?.data?.mobile
                      :
                      props.page === "developer" && developerTelephone
                        ? developerTelephone
                        :
                        (props.page === 'lawyer' && lawyerData?.data?.mobile)
                          ?
                          lawyerData?.data?.mobile
                          :
                          (props.page === 'vastu' && vastuData?.data?.mobile)
                            ?
                            vastuData?.data?.mobile
                            :
                            projectSalesNumber}
                </Typography>
            }
            <Typography
              // className="info"
              sx={{ fontFamily: 'Montserrat-Medium', fontSize: '18px', fontWeight: 600, color: isMobileView ? "#878787" : "initial", opacity: 0.9 }}
            >
              {" "}
              {
                (props.page) === 'architect' && architectContact?.data?.email
                  ?
                  architectContact?.data?.email
                  :
                  (props.page === 'lawyer' && lawyerData?.data?.email)
                    ?
                    lawyerData?.data?.email
                    :
                    (props.page === 'vastu' && vastuData?.data?.email)
                      ?
                      vastuData?.data?.email
                      :
                      (developerEmail || '')}
            </Typography>

            {
              (props.page === 'developer' && developerData?.website) || (props.page === 'lawyer' && lawyerData?.data?.website) || (props.page === 'vastu' && vastuData?.data?.website) || (props.page === 'architect' && architectContact?.data?.website)
                ?

                <a href={(props.page === 'architect' &&
                  architectContact?.data?.website)
                  ?
                  architectContact?.data?.website
                  :
                  (props.page === 'lawyer' &&
                    lawyerData?.data?.website)
                    ?
                    lawyerData?.data?.website
                    :
                    (props.page === 'vastu' &&
                      vastuData?.data?.website)
                      ?
                      vastuData?.data?.website
                      :
                      developerData?.website} target='_blank'>
                  <Typography
                    // className="info"
                    sx={{
                      fontFamily: 'Montserrat-Medium', fontSize: '18px', fontWeight: 600, color: isMobileView ? "#878787" : "initial", opacity: 0.9,
                      width: '28.65vw',
                      overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                    }}
                  >
                    {" "}
                    {
                      (props.page === 'architect')
                        ?
                        architectContact?.data?.website
                        :
                        (props.page === 'lawyer')
                          ?
                          lawyerData?.data?.website
                          :
                          (props.page === 'vastu')
                            ?
                            vastuData?.data?.website
                            :
                            developerData?.website}
                  </Typography>
                </a>
                :
                null
            }
          </Box>
          <div
            style={{
              minWidth: isMobileView ? "100%" : "63.15%",
              height: '100%',
              float: isMobileView ? "right" : "initial",
            }}
          >
            <MapLocation
              lat={
                (props.page === 'architect' &&
                  architectContact?.data?.address[0]?.geometry?.location?.lat)
                  ?
                  architectContact?.data?.address[0]?.geometry?.location?.lat
                  :
                  (props.page === 'lawyer' &&
                    lawyerData?.data?.address[0]?.geometry?.location?.lat)
                    ?
                    lawyerData?.data?.address[0]?.geometry?.location?.lat
                    :
                    (props.page === 'vastu' &&
                      vastuData?.data?.address[0]?.geometry?.location?.lat)
                      ?
                      vastuData?.data?.address[0]?.geometry?.location?.lat
                      :
                      mapDetails.lat}
              lng={
                (props.page === 'architect' &&
                  architectContact?.data?.address[0]?.geometry?.location?.lng)
                  ?
                  architectContact?.data?.address[0]?.geometry?.location?.lng
                  :
                  (props.page === 'lawyer' &&
                    lawyerData?.data?.address[0]?.geometry?.location?.lng)
                    ?
                    lawyerData?.data?.address[0]?.geometry?.location?.lng
                    :
                    (props.page === 'vastu' &&
                      vastuData?.data?.address[0]?.geometry?.location?.lng)
                      ?
                      vastuData?.data?.address[0]?.geometry?.location?.lng
                      :
                      mapDetails.lng}
              zoom={14}
              locationName={
                props.page === 'architect'
                  ?
                  architectContact?.data?.architectName
                  :
                  props.page === 'lawyer'
                    ?
                    lawyerData?.data?.lawyerName
                    :
                    props.page === 'vastu'
                      ?
                      vastuData?.data?.vastuName
                      :
                      props.page === 'developer' ? developerName : projectName}
            />
          </div>
        </Card>
      </Box>
    );
};

export default ContactInfo;

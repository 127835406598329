import { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { editDeveloper } from "../../../redux/thunk/developer-thunk";
import { setLoadingAction } from "../../../redux/display/display-actions";
import styles from "./EditDeveloper.module.css";
import Styles from './ProjectListItem.module.css';

export default function ProjectListItem(props: any) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  function handleMoreButtonClick(e: any) {
    setAnchorEl(e.target);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleDelete() {
    console.log('deleting mapped project');
    dispatch(setLoadingAction("unlinkingProject", true));
    if (props.role === "Developer") {
      console.log('role is developer role');
      await dispatch(
        editDeveloper(props.id, {
          initialProjectId: props.mappedProjects
            .filter(
              (project: any) =>
                project.projectId !== props.projectDetails.projectId
            )
            .map((project: any) => project.projectId),
          redevelopProjectId: props.redevelopProjectId,
        })
      );
    }
    handleClose();
  }

  const handleEdit = () => {
    props.setNonDRProjectMode(true);
    props.setEditProject(props.projectDetails)
    handleClose();
  };

  return (
    <Grid
      item
      container
      justifyContent="flex-start"
      alignItems="center"
      sx={{ pl: '2%', width: "100%", borderBottom: props.showBorderBottom ? '1px solid #c1c1c1' : 'none', }}
    >
      <Grid item className={Styles['Project-name']}>
        {props.projectDetails.projectName}
      </Grid>
      <Grid item sx={{width: '13vw', fontFamily: 'Montserrat-Regular', fontSize: "14px", pl: '2%'}}>
        { props.projectDetails.developerProject ? 'Non-DR' : 'DR' }
      </Grid>
      <Grid item sx={{width: '11.5vw', fontFamily: 'Montserrat-Regular', fontSize: "14px", pl: '2%'}}>
        {props.projectDetails.isLandmark ? 'Yes' : 'No'}
      </Grid>
      <Grid
        item
        className={Styles['Project-address']}
        sx={{
          width: '20.67vw',
          // paddingRight: '40px',
          display: '-webkit-box',
          webkitLineClamp: '3',
          webkitBoxOrient: 'vertical',
          maxHeight: '115px',//'175px',
          // width: '31.66vw',
          fontFamily: 'Montserrat-Regular',
          lineHeight: '18px',
          overflow: 'hidden',
          margin: '30px 0px',
          fontSize: "14px"
          // textOverflow: 'ellipsis',
        }}
      >
        {props.projectDetails.address.formatted_address}
      </Grid>
      <Grid item container justifyContent="flex-end" sx={{ width: "4.5vw" }}>
        <IconButton sx={{display: props.projectDetails.developerProject ? 'initial' : 'none'}} onClick={handleMoreButtonClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          sx={{ padding: "1px" }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* {!props.isDRProject && ( */}
            <MenuItem onClick={handleEdit} style={{ padding: "8px" }}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              Edit
            </MenuItem>
          {/* )} */}
          <MenuItem
            onClick={handleDelete}
            style={{ color: "#ff6767", padding: "8px" }}
          >
            <ListItemIcon>
              <Delete sx={{ color: "#ff6767" }} fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}

import { Grid, useMediaQuery } from "@mui/material";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/redux-store';

const BannerContent = (props: { page?: string }) => {
  const isMobileView = useMediaQuery("(max-width:600px)");

  const displayName = useSelector(
    (state: RootState) => props.page === 'developer' ? state.DATA_REDUCER.developerName : props.page === 'vastu' ? state?.DATA_REDUCER?.vastuProfileData?.vastuName : state.DATA_REDUCER.projectName
  );

  return (
    isMobileView
    ?
    (
      <Grid container
      alignItems={isMobileView ? "center" : "flex-start"}
      justifyContent='center'
      sx={{
        position: 'relative',
        width: isMobileView ? "100%" : "63.5%",
        height: "24%",
        paddingRight: isMobileView ? 0 : "30px",
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0.9) 85%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 95%)",
      }}
    >
      <Grid item xs={12}
        sx={{
          display: '-webkit-box',
          fontFamily: 'Montserrat-Medium',
          fontSize: isMobileView ? "30px" : "4.5vh",
          opacity: 0.8,
          lineHeight: "34px",
          maxHeight: '68px',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center'
        }}
      >
        {displayName}
      </Grid>
    </Grid>
    )
    :
    (<Grid container
      alignItems={isMobileView ? "center" : "flex-start"}
      gap="10px"
      sx={{
        position: 'relative',
        width: isMobileView ? "100%" : "63.5%",
        height: "24%",
        paddingRight: isMobileView ? 0 : "30px",
        paddingTop: isMobileView ? "30px" : 0,
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0.9) 85%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 95%)",
        mb: isMobileView ? '15px' : 0,
        pb: '2vh',
        pt: '20px'
      }}
    >
      <Grid item xs={12}
        sx={{
          display: '-webkit-box',
          fontSize: isMobileView ? "3.22vh" : "4.5vh",
          opacity: 0.8,
          lineHeight: "6.85vh",
          maxHeight: '13.7vh',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <div style={{ width: '85%', fontFamily: 'Montserrat-Bold' }}>{displayName}</div>
      </Grid>
    </Grid>)
  );
};

export default BannerContent;

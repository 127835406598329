import { useRef } from "react";
import { IconButton, Paper, useMediaQuery } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import InputBase from "@mui/material/InputBase";
import "./Search.css";
import { useDispatch } from "react-redux";
import { setSearchProjectName } from "../../redux/data/data-actions";

interface SearchProps {
  placeholder?: string;
}

const Search = (props: SearchProps) => {
  const searchFieldRef = useRef<any>();
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery('(max-width:480px)');

  return (
    <Paper
      component="form"
      className="Search-paper"
      sx={{
        p: isMobileView ? 0 : '2px 4px',
        bgcolor: isMobileView ? '#f3f4f6' : 'white',
        height: isMobileView ? '5vh' : '8.61vh',
        cursor: 'pointer'
      }}
    >
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <IconButton disabled={isMobileView ? false : true} type="button" sx={{ bgcolor: isMobileView ? '#662e91' : 'white', height: '100%', p: isMobileView ? 'initial' : '10px', order: isMobileView ? 2 : 1, width: isMobileView ? '40px' : 'initial', borderRadius: '2px' }} aria-label="search">
        <SearchIcon sx={{ height: isMobileView ? '5vh' : '8.61vh', color: isMobileView ? 'white' : '#afafaf', }} />
      </IconButton>
      <InputBase
        onChange={(e) => dispatch(setSearchProjectName(e.target.value))}
        sx={{ ml: 1, flex: 1, '& .MuiMenuItem-root': {border: 'none'}, fontSize: isMobileView ? '2vh' : '2.31vh', order: isMobileView ? 1 : 2 }}
        placeholder={props.placeholder}
        inputProps={{ "aria-label": "search google maps" }}
        ref={searchFieldRef}
      />
    </Paper>
  );
};

export default Search;

import {
  Box, CircularProgress,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import Banner from "../ArchitectProfile/Banner/Banner";
import drfavicon from "../../theme/drfavicon.png";
import { RootState } from "../../redux/redux-store";
import { getArchitectByUrl } from "../../redux/thunk/thunks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LandmarkImage from '../../theme/LandmarkDeveloper.png';
import defaultProjectMainImage from '../../theme/residential.png';
import DownloadBrochure from "../../layouts/DownloadBrochure";
import PartnerCard from "../DeveloperProfile/PartnerCard";
import ContactInfo from "../../layouts/ContactInfo";
import NotFound from '../../theme/not-found.png';

function ArchitectProfile() {
  const isMobileView = useMediaQuery("(max-width:480px)");
  const dispatch = useDispatch();
  let architectId = useParams();

  const loading = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);
  const architectProjects: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.architectProjects
  );

  useEffect(() => {
    dispatch(getArchitectByUrl(architectId.id));
  }, []);

  const navigate = useNavigate();

  const dateConvert = (value: any) => {
    const date = new Date(value);
    return date ? (date.toLocaleDateString('en-us', { day: 'numeric' }) + ' ' + date.toLocaleDateString('en-us', { month: 'short' }) + ' ' + date.toLocaleDateString('en-us', { year: 'numeric' })) : 'NA'
  }

  return (
    loading ? <Box display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Box> :
      architectProjects ? <Box
        sx={{
          padding: isMobileView ? 0 : "0px 7.08vw",
          width: isMobileView ? "100%" : "initial",
        }}
      >
        <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
        <Box>
          {isMobileView ? "" : null}
          {
            isMobileView
              ?
              <>
                <img src={architectProjects?.data?.companyLogo} style={{ height: '51px', margin: 'auto', display: 'block' }} />
                <div
                  style={{
                    fontFamily: 'Montserrat-Medium',
                    fontSize: '30px',
                    textAlign: 'center',
                    margin: '19px 0px 32px 0px',
                    lineHeight: '34px',
                    maxHeight: '68px',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {architectProjects?.data?.architectName}
                </div>
              </>
              :
              null
          }
          <Banner />
        </Box>
        {/* Members */}
        <Typography
          variant="h1"
          align={"center"}
          sx={{
            fontFamily: 'Montserrat-Medium',
            mt: isMobileView ? 0 : "21vh",
            mb: "4.8vh",
            fontSize: isMobileView ? "2.15vh" : "30px",
            opacity: 0.8,
          }}
        >
          About Team Members
        </Typography>
        {
          architectProjects?.data?.member?.length
            ?
            <Box className="aboutMain">
              {architectProjects?.data?.member &&
                architectProjects?.data?.member?.map((partner: any, index: number) => (
                  <PartnerCard
                    partnerPhoto={partner.images[0]}
                    partnerName={partner.name}
                    partnerDesignation={partner.designation}
                    partnerDescription={partner.description}
                    partnerNumber={index + 1}
                  />
                )
                )
              }
            </Box>
            :
            <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
        }

        <>
          <Typography
            variant="h1"
            align="center"
            sx={{
              mt: isMobileView ? '66px' : (architectProjects?.data?.member?.length ? "21vh" : '9vh'),
              mb: "19px",
              fontFamily: 'Montserrat-Medium',
              fontSize: isMobileView ? "20px" : "30px",
              opacity: 0.8,
            }}
          >
            Projects
          </Typography>
          <Box className="cardContainer">
            {
              (architectProjects?.data?.redevelopProject?.length && architectProjects?.data?.redevelopProject.filter((project: any) => project.drProjectUrl).length > 0) || (architectProjects?.data?.initialProject?.length && architectProjects?.data?.initialProject.filter((project: any) => project.drProjectUrl).length > 0)
                ?
                <Grid container spacing={6}>
                  {architectProjects?.data?.redevelopProject && architectProjects?.data?.redevelopProject?.length > 0 &&
                    architectProjects?.data?.redevelopProject?.map((item: any) => {
                      if (item.drProjectUrl)
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                              {item.isLandmark ?
                                <img src={LandmarkImage} alt='Landmark' style={{ height: '100px', position: 'absolute', top: '95px', right: '-20px', zIndex: 1 }} />
                                : null
                              }
                              <Box
                                sx={{
                                  display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                                  bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                                  width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                                }}
                              >
                                {item.constructionType}
                              </Box>
                              <Card
                                sx={{
                                  display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                                }}
                              >
                                <CardContent
                                  sx={{
                                    height: "92.5%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    p: "10px 14px",
                                    position: 'relative',
                                  }}
                                >
                                  <img
                                    src={drfavicon}
                                    style={{ position: 'relative', bottom: '7px', left: '10px', alignSelf: "flex-end", width: "37px", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                                  />

                                  <Typography
                                    variant="h5"
                                    className="project-grid-title-ellipsis"
                                    sx={{
                                      fontWeight: 800,
                                      width: '100%',
                                      fontSize: isMobileView ? "20px" : "2.22vh",
                                      mb: isMobileView ? '26px' : "3.52vh",
                                      fontFamily: 'Montserrat-Medium',
                                      opacity: 0.9
                                    }}
                                  >
                                    {item.projectName}
                                  </Typography>

                                  <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                                  <Typography
                                    className="cardText"
                                    sx={{
                                      // paddingBottom: "1.25vw",
                                      fontSize: isMobileView ? "18px" : "2.22vh",
                                      fontWeight: 100,
                                      mb: "5px",
                                    }}
                                  >
                                    <div className="project-grid-block-ellipsis">
                                      {item.address?.formatted_address}
                                    </div>
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontStyle: "lighter",
                                      fontSize: isMobileView ? "18px" : "15px",
                                      fontFamily: 'Montserrat-Regular',
                                      lineHeight: isMobileView ? "22px" : "1.5vw",
                                      color: "#838383",
                                      opacity: 0.8,
                                      mb: '25px',
                                      height: '44px',
                                      display: '-webkit-box',
                                      webkitLineClamp: 2,
                                      webkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                                  </Typography>
                                </CardContent>
                                <CardActions sx={{ height: "7.5%", padding: 0 }}>
                                  <Button
                                    onClick={() =>
                                      navigate(`/project/${item.drProjectUrl}`, {
                                        state: { projectId: item.drProjectUrl },
                                      })
                                    }
                                    className="card-button"
                                  >
                                    View Details
                                  </Button>
                                </CardActions>
                              </Card>
                            </Box>
                          </Grid>
                        )
                    })
                  }

                  {architectProjects?.data?.initialProject && architectProjects?.data?.initialProject?.length > 0 &&
                    architectProjects?.data?.initialProject?.map((item: any) => {
                      if (item.drProjectUrl)
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                              {item.isLandmark ?
                                <img src={LandmarkImage} alt='Landmark' style={{ height: '100px', position: 'absolute', top: '95px', right: '-20px', zIndex: 1 }} />
                                : null
                              }
                              <Box
                                sx={{
                                  display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                                  bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                                  width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                                }}
                              >
                                {item.constructionType}
                              </Box>
                              <Card
                                sx={{
                                  display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                                }}
                              >
                                <CardContent
                                  sx={{
                                    height: "92.5%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    p: "10px 14px",
                                    position: 'relative',
                                  }}
                                >
                                  <img
                                    src={drfavicon}
                                    style={{ position: 'relative', bottom: '7px', left: '10px', alignSelf: "flex-end", width: "37px", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                                  />

                                  <Typography
                                    variant="h5"
                                    className="project-grid-title-ellipsis"
                                    sx={{
                                      fontWeight: 800,
                                      width: '100%',
                                      fontSize: isMobileView ? "20px" : "2.22vh",
                                      mb: isMobileView ? '26px' : "3.52vh",
                                      fontFamily: 'Montserrat-Medium',
                                      opacity: 0.9
                                    }}
                                  >
                                    {item.projectName}
                                  </Typography>

                                  <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                                  <Typography
                                    className="cardText"
                                    sx={{
                                      // paddingBottom: "1.25vw",
                                      fontSize: isMobileView ? "18px" : "2.22vh",
                                      fontWeight: 100,
                                      mb: "5px",
                                    }}
                                  >
                                    <div className="project-grid-block-ellipsis">
                                      {item.address?.formatted_address}
                                    </div>
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontStyle: "lighter",
                                      fontSize: isMobileView ? "18px" : "15px",
                                      fontFamily: 'Montserrat-Regular',
                                      lineHeight: isMobileView ? "22px" : "1.5vw",
                                      color: "#838383",
                                      opacity: 0.8,
                                      mb: '25px',
                                      height: '44px',
                                      display: '-webkit-box',
                                      webkitLineClamp: 2,
                                      webkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                                  </Typography>
                                </CardContent>
                                <CardActions sx={{ height: "7.5%", padding: 0 }}>
                                  <Button
                                    onClick={() =>
                                      navigate(`/project/${item.drProjectUrl}`, {
                                        state: { projectId: item.drProjectUrl },
                                      })
                                    }
                                    className="card-button"
                                  >
                                    View Details
                                  </Button>
                                </CardActions>
                              </Card>
                            </Box>
                          </Grid>
                        )
                    })
                  }
                </Grid>
                :
                <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
            }
          </Box>
        </>

        {/* ongoing project */}
        <Typography
          variant="h1"
          align={"center"}
          sx={{
            mt: isMobileView ? "94px" : ((architectProjects?.data?.redevelopProject?.length && architectProjects?.data?.redevelopProject.filter((project: any) => project.drProjectUrl).length > 0) || (architectProjects?.data?.initialProject?.length && architectProjects?.data?.initialProject.filter((project: any) => project.drProjectUrl).length > 0) ? "21vh" : '9vh'),
            mb: isMobileView ? "19px" : "4.8vh",
            fontFamily: 'Montserrat-Medium',
            fontSize: isMobileView ? "20px" : "30px",
            opacity: 0.8,
            letterSpacing: '1px'
          }}
        >
          Ongoing Projects
        </Typography>
        <Box className="cardContainer">
          {(architectProjects?.data?.redevelopProject && architectProjects?.data?.redevelopProject.length > 0 && architectProjects?.data?.redevelopProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) || (architectProjects?.data?.initialProject && architectProjects?.data?.initialProject.length > 0 && architectProjects?.data?.initialProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) ?
            <Grid container spacing={6}>
              {architectProjects?.data?.redevelopProject && architectProjects?.data?.redevelopProject?.length > 0 &&
                architectProjects?.data?.redevelopProject.map((item: any) => {
                  return (item.projectStatus === 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                      <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                        {item.isLandmark ?
                          <img src={LandmarkImage} alt='Landmark' style={{ height: '100px', position: 'absolute', top: '95px', right: '-20px', zIndex: 1 }} />
                          : null
                        }
                        <Box
                          sx={{
                            display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                            bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                            width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                          }}
                        >
                          {item.constructionType}
                        </Box>
                        <Card
                          sx={{
                            display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                          }}
                        >
                          <CardContent
                            sx={{
                              height: "92.5%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              p: "10px 14px",
                              position: 'relative',
                            }}
                          >
                            <img
                              src={drfavicon}
                              style={{ position: 'relative', bottom: '7px', left: '10px', alignSelf: "flex-end", width: "37px", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                            />

                            <Typography
                              variant="h5"
                              className="project-grid-title-ellipsis"
                              sx={{
                                fontWeight: 800,
                                width: '100%',
                                fontSize: isMobileView ? "20px" : "2.22vh",
                                mb: isMobileView ? '26px' : "3.52vh",
                                fontFamily: 'Montserrat-Medium',
                                opacity: 0.9
                              }}
                            >
                              {item.projectName}
                            </Typography>

                            <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                            <Typography
                              className="cardText"
                              sx={{
                                // paddingBottom: "1.25vw",
                                fontSize: isMobileView ? "18px" : "2.22vh",
                                fontWeight: 100,
                                mb: "5px",
                              }}
                            >
                              <div className="project-grid-block-ellipsis">
                                {item.address?.formatted_address}
                              </div>
                            </Typography>

                            <Typography
                              sx={{
                                fontStyle: "lighter",
                                fontSize: isMobileView ? "18px" : "15px",
                                fontFamily: 'Montserrat-Regular',
                                lineHeight: isMobileView ? "22px" : "1.5vw",
                                color: "#838383",
                                opacity: 0.8,
                                mb: '49px'
                              }}
                            >
                              Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                            </Typography>
                          </CardContent>
                          <CardActions sx={{ height: "7.5%", padding: 0 }}>
                            <Button
                              onClick={() =>
                                navigate(`/project/${item.drProjectUrl}`, {
                                  state: { projectId: item.drProjectUrl },
                                })
                              }
                              className="card-button"
                            >
                              View Details
                            </Button>
                          </CardActions>
                        </Card>
                      </Box>
                    </Grid>
                  )
                })
              }

              {architectProjects?.data?.initialProject && architectProjects?.data?.initialProject?.length > 0 &&
                architectProjects?.data?.initialProject.map((item: any) => {
                  return (item.projectStatus === 0 &&
                    <Grid item xs={12} sm={6} md={4}>
                      <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                        {item.isLandmark ?
                          <img src={LandmarkImage} alt='Landmark' style={{ height: '100px', position: 'absolute', top: '95px', right: '-20px', zIndex: 1 }} />
                          : null
                        }
                        <Box
                          sx={{
                            display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                            bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                            width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                          }}
                        >
                          {item.constructionType}
                        </Box>
                        <Card
                          sx={{
                            display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                          }}
                        >
                          <CardContent
                            sx={{
                              height: "92.5%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              p: "10px 14px",
                              position: 'relative',
                            }}
                          >
                            <img
                              src={drfavicon}
                              style={{ position: 'relative', bottom: '7px', left: '10px', alignSelf: "flex-end", width: "37px", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                            />

                            <Typography
                              variant="h5"
                              className="project-grid-title-ellipsis"
                              sx={{
                                fontWeight: 800,
                                width: '100%',
                                fontSize: isMobileView ? "20px" : "2.22vh",
                                mb: isMobileView ? '26px' : "3.52vh",
                                fontFamily: 'Montserrat-Medium',
                                opacity: 0.9
                              }}
                            >
                              {item.projectName}
                            </Typography>

                            <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                            <Typography
                              className="cardText"
                              sx={{
                                // paddingBottom: "1.25vw",
                                fontSize: isMobileView ? "18px" : "2.22vh",
                                fontWeight: 100,
                                mb: "5px",
                              }}
                            >
                              <div className="project-grid-block-ellipsis">
                                {item.address?.formatted_address}
                              </div>
                            </Typography>

                            <Typography
                              sx={{
                                fontStyle: "lighter",
                                fontSize: isMobileView ? "18px" : "15px",
                                fontFamily: 'Montserrat-Regular',
                                lineHeight: isMobileView ? "22px" : "1.5vw",
                                color: "#838383",
                                opacity: 0.8,
                                mb: '49px'
                              }}
                            >
                              Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                            </Typography>
                          </CardContent>
                          <CardActions sx={{ height: "7.5%", padding: 0 }}>
                            <Button
                              onClick={() =>
                                navigate(`/project/${item.drProjectUrl}`, {
                                  state: { projectId: item.drProjectUrl },
                                })
                              }
                              className="card-button"
                            >
                              View Details
                            </Button>
                          </CardActions>
                        </Card>
                      </Box>
                    </Grid>
                  )
                })
              }
            </Grid>
            :
            <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
          }
        </Box>



        {/* Our Brochure */}

        <Typography
          variant="h1"
          align={"center"}
          sx={{
            mt: isMobileView ? '75px' : ((architectProjects?.data?.redevelopProject && architectProjects?.data?.redevelopProject.length > 0 && architectProjects?.data?.redevelopProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) || (architectProjects?.data?.initialProject && architectProjects?.data?.initialProject.length > 0 && architectProjects?.data?.initialProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) ? "21vh" : '9vh'),
            mb: "29px",
            fontFamily: 'Montserrat-Medium',
            fontSize: isMobileView ? "20px" : "30px",
            opacity: 0.8,
          }}
        >
          Our Brochure
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {architectProjects?.data?.brochure ? <DownloadBrochure fileURL={architectProjects?.data?.brochure} /> :
            <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
          }
        </Box>
        {/*Contact Us */}
        <Typography
          variant="h1"
          textAlign={"center"}
          sx={{ mt: isMobileView ? "96px" : (architectProjects?.data?.brochure ? "21vh" : '9vh'), mb: isMobileView ? '22px' : '4.8vh', fontSize: isMobileView ? '20px' : '30px', fontWeight: 700, opacity: 0.8, fontFamily: 'Montserrat-Medium' }}
        >
          Contact Us
        </Typography>
        <Box sx={{ width: "100%", height: '63.15vh', pb: '50px', boxSizing: 'content-box' }}>
          <ContactInfo page="architect" />
        </Box>
      </Box> :
        <Box mt={10} display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Sorry, Page Not Found!
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 5, width: '700px', textAlign: 'center' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
            sure to check your spelling.
          </Typography>
          <img src={NotFound} alt='not found!' />
        </Box>
  );
}
export default ArchitectProfile;

import { getAllTransaction, getProjectBid, getUploadURL, getSubBid, bidingTransactionAction, orderTransactionAction, capturePaymentAction } from "../data/data-actions";
import { NABHThunkDispatch } from "../redux-store";
import { _getHeaders } from "../api-headers";
import { url } from "../url";
import axios, { AxiosRequestConfig } from 'axios';
import { setSuccessAction, snackbarAlertAction } from "../display/display-actions";

/**Get all transactions by userId */
export const getTransactionData = (pageNumber: number, pageSize: number, category: string) =>
    async (dispatch: NABHThunkDispatch) => {
        const user: any = sessionStorage.getItem('DREAMUSERAPP');
        const userInfo: any = JSON.parse(user);
        var config: AxiosRequestConfig = {
            method: "get",
            url: `${url}/transaction?category=${category}&pageNumber=${pageNumber}&pageSize=${pageSize}&personaId=${userInfo?.developer?.developerId}`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        await axios(config)
            .then((response: any) => {
                dispatch(getAllTransaction(response.data.data));
            })
            .catch((e: any) => {
            });
    };

/**Get project bid information */
export const getProjectBidTotal = (projectId: string) =>
    async (dispatch: NABHThunkDispatch) => {
        var config: AxiosRequestConfig = {
            method: "get",
            url: `${url}/transaction/getBidTotal?projectId=${projectId}`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        await axios(config)
            .then((response: any) => {
                dispatch(getProjectBid(response.data.data));
            })
            .catch((e: any) => {
                dispatch(snackbarAlertAction(`Error: ${e?.response?.data.message}`, "error"));
            });
    };

/**Get Subscription bid information */
export const getSubscriptionTotal = () =>
    async (dispatch: NABHThunkDispatch) => {
        var config: AxiosRequestConfig = {
            method: "get",
            url: `${url}/transaction/getSubscriptionTotal`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        await axios(config)
            .then((response: any) => {
                dispatch(getSubBid(response.data.data));
            })
            .catch((e: any) => {
                dispatch(snackbarAlertAction(`Error: ${e?.response?.data.message}`, "error"));
            });
    };

/**Upload File */
export const uploadFile = (file: any) => async (dispatch: NABHThunkDispatch) => {
    var data = new FormData();
    data.append("images", file);
    var config: any = {
        method: "post",
        url: `${url}/uploads`,
        headers: _getHeaders({ "Content-Type": "multipart/form-data" }),
        data: data,
    };
    await axios(config)
        .then((response: any) => {
            dispatch(getUploadURL(response.data?.url?.length > 0 && response.data?.url[0]));
        })
        .catch((e: any) => {
            dispatch(snackbarAlertAction(`Error: ${e?.response?.data.message}`, "error"));
        });
};

export const saveSubscription = (transactionNumber: string, imageUrl: any) =>
    async (dispatch: NABHThunkDispatch) => {
        const user: any = sessionStorage.getItem('DREAMUSERAPP');
        const userInfo: any = JSON.parse(user);
        var config: any = {
            method: "post",
            url: `${url}/transaction`,
            data: {
                "amountType": "CREDIT",
                "transactionNumber": transactionNumber,
                "additionalInfo": `Dummy text- ${transactionNumber}`,
                "persona": "developer",
                "personaId": userInfo?.developer?.developerId,
                "category": "SUBSCRIPTION",
                "paymentStatus": "SUCCESS",
                "imageUrl": imageUrl,
                "isPaymentOnline": true
            },
            headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        await axios(config)
            .then((response: any) => {
                dispatch(snackbarAlertAction(`Project bidding successful`, "success"));
                dispatch(setSuccessAction('dataSaveSuccessfully', true));
                dispatch(getUploadURL(null));
                dispatch(getSubBid(null));
            })
            .catch((e: any) => {
                dispatch(snackbarAlertAction(`Error: ${e?.response?.data?.message}`, "error"));
                dispatch(getSubBid(null));
            });
    };

export const initiatePayment = (data: any) =>
    async (dispatch: NABHThunkDispatch) => {
        const user: any = sessionStorage.getItem('DREAMUSERAPP');
        const userInfo: any = JSON.parse(user);
        var config: any = {
            method: "post",
            url: `${url}/transaction/phonepe/createOrder`,
            data: data,
            headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        await axios(config)
            .then(async (response: any) => {
                await dispatch(bidingTransactionAction(response.data?.data));
                await dispatch(setSuccessAction('dataSaveSuccessfully', true));
            })
            .catch((e: any) => {
                dispatch(snackbarAlertAction(`Error: ${e?.response?.data?.message}`, "error"));
                dispatch(getSubBid(null));
            });
    };

    export const createOrders = (data: any) => async (dispatch: NABHThunkDispatch) => {
        const user: any = sessionStorage.getItem('DREAMUSERAPP');
        const userInfo: any = JSON.parse(user);
        const config: AxiosRequestConfig = {
          method: "post",
          url: `${url}/payment/razorpay/createOrder`,
          data: data,
          headers: _getHeaders({ "Content-Type": "application/json" }),
        };
      
        try {
          const response = await axios(config);
          await dispatch(orderTransactionAction(response));
          await dispatch(setSuccessAction('dataSaveSuccessfully', true));
          return response; // Return response data to be used in handleCreateOrder
        } catch (e: any) {
          dispatch(snackbarAlertAction(`Error: ${e?.response?.data?.message}`, "error"));
          throw e;
        }
      };

    export const capturePaymentStatus =
      (data: any) => async (dispatch: NABHThunkDispatch) => {
        const config: AxiosRequestConfig = {
          method: "post",
          url: `${url}/payment/razorpay/capturePaymentStatus`,
          data: data,
          headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        console.log("config123",config);
        
        try {
          const response = await axios(config);
          console.log("capresponse",response);
          await dispatch(capturePaymentAction(response));
          await dispatch(setSuccessAction("dataSaveSuccessfully", true));
          console.log("capresponse123",response);
          return response; // Return response data to be used in handleCreateOrder
          
        } catch (e: any) {
          dispatch(
            snackbarAlertAction(`Error: ${e?.response?.data?.message}`, "error")
          );
          throw e;
        }
      };

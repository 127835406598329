import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SidebarProvider } from './layouts/SidebarContext';
import './fonts/HelveticaNeue.ttf';
import './fonts/HelveticaNeue-Medium.ttf';
import './fonts/HelveticaNeue-Bold.ttf';
import './fonts/Montserrat/static/Montserrat-Bold.ttf';
import './fonts/Montserrat/static/Montserrat-Light.ttf';
import './fonts/Montserrat/static/Montserrat-Italic.ttf';
import './fonts/Montserrat/static/Montserrat-SemiBold.ttf';
import './fonts/Montserrat/static/Montserrat-Regular.ttf';
import './fonts/Montserrat/static/Montserrat-Medium.ttf';
import './fonts/Montserrat/static/Montserrat-BoldItalic.ttf';

ReactDOM.render(
  <React.StrictMode>
    <SidebarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SidebarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

import { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';

import BannerContent from "./BannerContent";
import MaskGroup76 from "../../../theme/MaskGroup76.png";
import "./Banner.css";
import { getAllLawyers } from '../../../redux/thunk/thunks';
import { useParams } from 'react-router-dom';
import defaultLawyerMainImage from '../../../theme/lawBg.png';
//font sizes and weights pairs on project page
// 60/74 bold(700), 40/49 medium(500),28/41 normal(400), 22/41 normal(400), 30/37 medium(500), 60/74 medium(500), 50/61 medium(500), 26/32 medium(500), 28/36 medium(500)
export default function Banner() {

  const [developerImageState, setDeveloperImageState] = useState('');
  const dispatch = useDispatch();
  let  lawyerId  = useParams();


  const lawyerData = useSelector(
    (state: RootState) => state.DATA_REDUCER.lawyerProjects
  );
const datas = lawyerData?.data
useEffect(() => {
    dispatch(getAllLawyers(lawyerId.id));
    
    // async function getProjects() {
    //   await dispatch(getAllLawyers({}));
    // }
  }, []);

  const isMobileView = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Grid
        container
        className="Banner-container"
        sx={{
          backgroundImage: `url('${datas?.image ? datas.image : defaultLawyerMainImage}')`,
          backgroundSize: isMobileView ? "cover" : datas?.image ? "48.83% 100%" : "41.83% 100%",
          backgroundPosition: isMobileView ? 'center center' : 'right',
          height: isMobileView ? "40.87vh" : "80.93vh",
          width: '100%',
        
        }}
      >
        {isMobileView ? null : <BannerContent />}
      </Grid>
      {isMobileView ? <BannerContent /> : null}
      
    </>
  );
}



import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
} from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import "../styling/GlobalStyles.css";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getAllAmenities } from "../redux/thunk/project-thunks";
import { RootState } from "../redux/redux-store";

const AmenitiesModal = (props: any) => {

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props.open) console.log("hi");
    dispatch(getAllAmenities())
  }, [props.open]);

  const allAmenities = useSelector((state: RootState) => state.DATA_REDUCER.allAmenities);

  const [initialValues, setInitialValues] = useState({ amenities:props.currentAmenities});

  const capitalizeFirstLetter = (word: string) => {
    const words = word.split(" ");
    return words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
      
  };

  return (
    <Dialog
      open={props.open}
      sx={{ "& .MuiDialog-paper": { maxWidth: "100vw" } }}
    >
      <Grid  container alignItems="center">
        <Grid item>
          <IconButton onClick={() => props.setOpen(false)}>
            <ArrowBackIosNew />
          </IconButton>
        </Grid>
        <Grid item>Select Amenities</Grid>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        sx={{ width: "60vw", padding: "30px" }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            props.setAmenity(values.amenities)
            props.setOpen(false);
          }}
        >
          {({ values }) => (
            <Form style={{ width: "100%" }}>
              <div role="group" aria-labelledby="checkbox-group">
              <Grid
                  container
                >
                  {allAmenities && allAmenities.map((amenity: any) =>
                  <Grid
                    item
                    xs={6}
                    p={1}
                    paddingLeft={12}
                  >
                    <label>
                      <Field type="checkbox" name="amenities" value={amenity.amenitiesId} style={{padding: "2px"}}/>
                       {" "+capitalizeFirstLetter(amenity.name)}
                    </label>
                  </Grid>)}
              </Grid>
              </div>
              <Grid item container justifyContent="flex-end" display="flex" spacing="1vw" xs={12} mt={6} width="100%">
                <Button
                  variant="outlined"
                  onClick={() => props.setOpen(false)}
                  sx={{
                    border: "1px solid 788195",
                    borderRadius: "3px",
                    color: "#788195",
                  }}
                  className="button2"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    borderRadius: "3px",
                    bgcolor: "#662e91",
                    marginLeft:'20px'
                  }}
                  className="button1"
                >
                  Save
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  );
};

export default AmenitiesModal;

export const colors = {
  background1: '#F5F6FA', //blue
  background2: '#F9FAFB', //gray
  background3: '#FFFFFF', //white
  background4: '#000000', //black
  background5: '#C6CFD8', //gray
  background6: '#F4F5F7', //light gray
  border1: '#C9CDD6', //gray
  border2: '#DEE4EC', //gray2
  blue1: '#0060B2', //blue
  blue2: '#65B1FF', //light blue
  blue3: '#1E0CB2', //dark blue
  green1: '#DEF7EC', //green
  green2: '#15B200', //light green
  green3: '#04543F', //dark green
  text1: '#000000', //black
  text2: '#04543F', //black
  text3: '#374151', //light black
  text4: '#6B7281', //light black
  text5: '#7b828c', //gray
  text6: '#9ba0a8', //gray
  text7: '#575757', //gray
  error1: '#FF0000', //red
  error2: '#911717' //brownish red
};

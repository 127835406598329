import { LoadingButton } from "@mui/lab";
import { Box, Typography, useMediaQuery } from "@mui/material";

interface downloadBrochureProps {
  fileURL: string;
}

export default function DownloadBrochure(props: downloadBrochureProps) {
  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    props.fileURL
    ?
    <a
      // ref={brochureRef}
      href={props.fileURL}
      // style={{display: 'none'}}
      target='_blank'
      style={{ margin: 'auto auto' }}
      download
    >
      {
        
        isMobileView
        ?
        <LoadingButton
          className="btn"
          type="submit"
          variant="contained"
          aria-label="sign in"
          sx={{ fontSize: '16px', fontFamily: 'Montserrat-Regular', letterSpacing: '1px', fontWeight: 'unset', opacity: 0.8 }}
        >
          Download Brochure
        </LoadingButton>
        :
        <LoadingButton
          className="btn"
          type="submit"
          variant="contained"
          aria-label="sign in"
          sx={{ fontSize: '2.6vh', fontFamily: 'Montserrat-Regular', letterSpacing: '1px', fontWeight: 'unset', opacity: 0.8 }}
        >
          Download Brochure
        </LoadingButton>
      }
    </a>
    :
    <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7, textAlign: 'center' }}>NA</Typography></Box>
  )
}
import { useState } from "react";
import { useCookies } from 'react-cookie';
import {
  Box, ListItemText, ListItemButton, ListItemIcon,
  SwipeableDrawer, Button, styled, useMediaQuery, Collapse, List
} from "@mui/material";
import { RootState } from "../../../redux/redux-store";
import { useDispatch, useSelector } from "react-redux";
import { Close, ExpandLess, ExpandMore, FiberManualRecord } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import Apartment from '@mui/icons-material/Apartment';
import Logo from '../../../theme/Logo.png';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getProjectUpdatesByID } from '../../../redux/thunk/thunks';
import Styles from './index.module.css'
import { getProjectAccessByUserID, setLoggedInUser } from "../../../redux/data/data-actions";
import offers from '../../../theme/offer-icon.png'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    fontFamily: 'Montserrat-Regular'
  },
}));

const textFont = {
  color: '#000000',
  opacity: 1,
  fontSize: '20px',
  lineHeight: 'initial',
  fontFamily: 'Montserrat-Medium',
};
const textFont1 = {
  color: '#788195',
  opacity: 1,
  fontSize: '15px',
  lineHeight: 2,
  fontFamily: 'Montserrat-Regular'
};

const blockEllipsis = {
  display: 'block',
  width: '210px',
  height: '42px',
  marginBottom: '5px',
  marginTop: '3px',
  lineHeight: 1.4,
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  fontFamily: 'Montserrat-Medium',
  opacity: 0.8,
}

const menuStyle = {
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0px 1px 16px #01010133, 0px 2px 2px #00000033',
  }
}

const menuStyle2 = {
  cursor: 'pointer',
  color: 'black',
  '&:hover': {
    boxShadow: '0px 1px 16px #01010133, 0px 2px 2px #00000033',
  }
}

function Sidebar(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookie, removeCookie] = useCookies();
  const param = useParams();
  const location = useLocation();
  const user: any = localStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);
  const [no, setNo] = useState(param && param.no ? parseInt(param.no) : 0);
  const projectList: any = useSelector((state: RootState) => state.DATA_REDUCER.projectAccessList);
  const projectInfo = projectList && projectList.length > 0 ? projectList[0] : {}
  const [open, setOpen] = useState(false);

  const newproNo: any = projectInfo?.property?.length > 0 ? 0 : null;
  const [proNo, setProNo] = useState(param && param.proNo ? parseInt(param.proNo) : newproNo);

  const words = location.pathname.split('/');
  const word = words && words.length > 4 && words[4];
  const isMobileView = useMediaQuery("(max-width:480px)");

  const setProject = (no: any, proNo: any) => {
    navigate(`/owner/owner-dashboard/${param.id}/${no}/${proNo}`)
    setNo(no); setProNo(proNo);
  }

  const handleNavigate = (tab: string) => {
    if (tab === 'Developers')
      navigate(`/owner/owner-dashboard/${param.id}/developers`);
    else if (tab === 'Profile')
      navigate(`/owner/owner-dashboard/${param.id}/profile`);
  }

  const handleClick = (value: any) => {
    dispatch(getProjectUpdatesByID(value.projectId));
  }

  const handleProjectClick = () => {
    setOpen(!open);
  };

  const logOut = async () => {
    dispatch(getProjectAccessByUserID(null));
    dispatch(setLoggedInUser(null));
    navigate('/');
    sessionStorage.removeItem('DREAMUSERAPP');
    localStorage.removeItem('role');
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    removeCookie('token', { path: '/', domain: '*.dreamsredeveloped.com' });
    removeCookie('token', { path: '/', domain: '.dreamsredeveloped.com' });
  }

  return (
    <SwipeableDrawer
      anchor="left"
      open={props.open}
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
    >
      <Box p={2} sx={{ background: '#fff', height: '100%', width: '320px' }}>
        <Box className={Styles['sidebar-header']} >
          <Box>
            <img src={Logo} alt="Dreams Redeveloped" style={{ width: '250px' }} />
          </Box>
          <Box>
            <Close onClick={() => props.setOpen(false)} sx={menuStyle} />
          </Box>
        </Box>
        <Box className={Styles['sidebar-header-mobile']} >
          <Box>
            <img src={Logo} alt="Dreams Redeveloped" style={{ width: '250px' }} />
          </Box>
          <Box>
            <Close onClick={() => props.setOpen(false)} sx={menuStyle2} />
          </Box>
        </Box>
        <Button sx={{ marginTop: '20px' }} variant="outlined" fullWidth onClick={() => navigate(`/search/searchproject/${param.id}`)}>Add Project</Button>
        <Box pt={2}>
          <ListItemButton sx={{ py: 0, minHeight: 45, color: word === 'profile' ? '#662e91' : '#000000' }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '40px', opacity: 0.8 }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={'Profile'} primaryTypographyProps={{ fontSize: 16, fontFamily: 'Montserrat-Medium' }} sx={{ opacity: 0.8 }} onClick={() => { handleNavigate('Profile'); props.setOpen(false); }} />
          </ListItemButton>

          {isMobileView && <ListItemButton sx={{ py: 0, minHeight: 45, color: '#000000' }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '40px', opacity: 0.8 }}>
              {/* <PersonIcon /> */}
              <img style={{ height: '22px' }} src={offers}></img>
            </ListItemIcon>
            <a href='https://dreamsredeveloped.com/tag/newsletter/' target='_blank' style={{ textDecoration: 'none', color: 'inherit' }}><ListItemText primary={'Offers'} primaryTypographyProps={{ fontSize: 16, fontFamily: 'Montserrat-Medium' }} sx={{ opacity: 0.8 }} /></a>
          </ListItemButton>}

          {!isMobileView && <ListItemButton sx={{ py: 0, minHeight: 45, color: '#000000' }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '40px', opacity: 0.8 }}>
              <Apartment />
            </ListItemIcon>
            <ListItemText primary={'My Projects'} primaryTypographyProps={{ fontSize: 16, fontFamily: 'Montserrat-Medium' }} sx={{ opacity: 0.8 }} />
          </ListItemButton>}

          {isMobileView && <ListItemButton sx={{ py: 0, minHeight: 45, color: '#000000' }} onClick={handleProjectClick}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '40px', opacity: 0.8 }}>
              <Apartment />
            </ListItemIcon>
            <ListItemText primary={'My Projects'} primaryTypographyProps={{ fontSize: 16, fontFamily: 'Montserrat-Medium' }} sx={{ opacity: 0.8 }} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>}

          {isMobileView && <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {projectList && projectList.length > 0 && projectList.map((item: any, i: any) =>
                item?.property && item?.property.length > 0 ? item?.property.map((proItem: any, index: any) =>
                  <ListItemButton sx={{ pl: 4 }}>
                    <HtmlTooltip placement="right" arrow title={item?.projectName + '- ' + proItem?.nameOfWing + '-' + proItem?.unitName}>
                      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: (no === i && proNo === index) ? (word === "profile" || word === "developers" ? '#000' : '#662e91') : '#000', fontFamily: 'Montserrat-Medium' }} onClick={() => { setProject(i, index); props.setOpen(false); handleClick(item) }}>
                        <FiberManualRecord sx={{ fontSize: '13px', mr: '8px' }} /><span className={Styles['block-ellipsis']}>{item?.projectName}-{proItem?.nameOfWing + '-' + proItem?.unitName}</span>
                      </div>
                    </HtmlTooltip>
                  </ListItemButton>
                ) :
                  <ListItemButton sx={{ pl: 4 }}>
                    <HtmlTooltip title={item?.projectName} placement="right" arrow>
                      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: no === i ? (word === "profile" || word === "developers" ? '#000' : '#662e91') : '#000', fontFamily: 'Montserrat-Medium' }} onClick={() => { setProject(i, ''); props.setOpen(false); handleClick(item) }}>
                        <FiberManualRecord sx={{ fontSize: '13px', mr: '8px' }} /><span className={Styles['block-ellipsis']}>{item?.projectName}</span>
                      </div>
                    </HtmlTooltip>
                  </ListItemButton>
              )}
            </List>
          </Collapse>}
        </Box>
        {!isMobileView && <Box pt={2} sx={{ overflow: 'auto', mr: '-18px', ml: '60px' }}>
          {projectList && projectList.length > 0 && projectList.map((item: any, i: any) =>
            item?.property && item?.property.length > 0 ? item?.property.map((proItem: any, index: any) =>
              <HtmlTooltip placement="right" arrow title={item?.projectName + '- ' + proItem?.nameOfWing + '-' + proItem?.unitName}>
                <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: (no === i && proNo === index) ? (word === "profile" || word === "developers" ? '#000' : '#662e91') : '#000', fontFamily: 'Montserrat-Medium' }} onClick={() => { setProject(i, index); props.setOpen(false); handleClick(item) }}>
                  <FiberManualRecord sx={{ fontSize: '13px', mr: '8px' }} /><span className={Styles['block-ellipsis']}>{item?.projectName}- {proItem?.nameOfWing + '-' + proItem?.unitName}</span>
                </div>
              </HtmlTooltip>
            ) :
              <HtmlTooltip title={item?.projectName} placement="right" arrow>
                <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer', color: no === i ? (word === "profile" || word === "developers" ? '#000' : '#662e91') : '#000', fontFamily: 'Montserrat-Medium' }} onClick={() => { setProject(i, ''); props.setOpen(false); handleClick(item) }}>
                  <FiberManualRecord sx={{ fontSize: '13px', mr: '8px' }} /><span className={Styles['block-ellipsis']}>{item?.projectName}</span>
                </div>
              </HtmlTooltip>
          )}
        </Box>}
      </Box>
      {isMobileView && <Button sx={{ bottom: '0', margin: '20px' }} variant="contained" onClick={logOut}>Log Out</Button>}
    </SwipeableDrawer>
  );
}

export default Sidebar;

import { RootState, combinedReducers } from '../redux-store';
import { Action } from 'redux';

export const rootReducer = (state: RootState | undefined, action: Action) => {
  // when a logout action is dispatched it will reset redux state
  //otherwise continue onto the usual combined reducer
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return combinedReducers(state, action);
};

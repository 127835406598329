import { NABHThunkDispatch, RootState } from "../redux-store";
import { _getHeaders } from "../api-headers";
import { url } from "../url";
import { getProjectInfoByProjectIDAction, getAllWings, getAllFloors, getAllFlats, getOwnerProperty, getProjectUpdates, GetOwnerByIdSuccess } from "../data/data-actions";
import { setSuccessAction, snackbarAlertAction } from "../display/display-actions";

var axios = require("axios");

export const getOwnerById =
    (id: any) => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {

        let headers: any = _getHeaders({ "Content-Type": "application/json" })

        axios.get(`${url}/owner/${id}`, { headers: headers })
            .then((response: any) => {
                dispatch(GetOwnerByIdSuccess(response.data.data))
            })
            .catch((err: any) => {
                dispatch(snackbarAlertAction(`Error: ${err.response && err.response.data.message}`, "error"));
            });
    };

export const updateOwner =
    (data: any, id: any) => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {

        let headers: any = _getHeaders({ "Content-Type": "application/json" })

        await axios.put(`${url}/owner/${id}`, {
            "firstName": data.firstName ? data.firstName : null,
            "middleName": data.middleName ? data.middleName : null,
            "lastName": data.lastName ? data.lastName : null,
            "image": data.image ? data.image : null,
            "gender": data.gender ? data.gender : null,
            "emailId": data.email ? data.email : null,
            "dateOfBirth": data.dob ? new Date(data.dob) : null,
        }, { headers: headers })
            .then((response: any) => {
                dispatch(snackbarAlertAction(`Data updated successfully`, "success"));
                dispatch(GetOwnerByIdSuccess(response.data.data));
                sessionStorage.setItem('forum', response.data?.forumToken?.token)
                dispatch(setSuccessAction('dataSaveSuccessfully', true));
            })
            .catch((err: any) => {
                dispatch(snackbarAlertAction(`Error: ${err.response && err.response.data.message}`, "error"));
                dispatch(setSuccessAction('dataSaveSuccessfully', false));
                return true;
            });
    };

/**Get project info by project_id */
export const getProjectInfoByProjectId = (project_id: string) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
        var config = {
            method: 'get',
            url: `${url}/project/${project_id}`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        dispatch(setSuccessAction("dataSaveSuccessfully", false))
        axios(config)
            .then((response: any) => {
                dispatch(getProjectInfoByProjectIDAction(response?.data.data))
            }).catch((error: any) => {
                dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
            })
    };

/**Get project property by project_id / wing / floor no / */
export const getProjectPropertyByProjectId = (project_id: string, wing: any, floorNumber: any, unitName: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
        var config = {
            method: 'get',
            url: `${url}/project/propertyData?projectId=${project_id}&nameOfWing=${wing}&floorNumber=${floorNumber}&unitName=${unitName}`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
        };
        dispatch(setSuccessAction("dataSaveSuccessfully", false))
        await axios(config)
            .then(async (response: any) => {
                if (project_id && wing === '' && floorNumber === '') {
                    await dispatch(getAllWings(response?.data?.data?.nameOfWingArray))
                    return response?.data?.data?.nameOfWingArray;
                }
                project_id && wing !== '' && floorNumber === '' && dispatch(getAllFloors(response?.data?.data?.floorNumberArray))
                project_id && wing !== '' && floorNumber !== '' && unitName === '' && dispatch(getAllFlats(response?.data?.data?.unitNameArray))
                if (project_id && wing !== '' && floorNumber !== '' && unitName !== '') {
                    await dispatch(getOwnerProperty(response?.data?.data?.data));
                }
            }).catch((error: any) => {
                dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
            })
    };

/**Map project property to owner*/
export const mapProjectPropertyToOwner = (ownerId: string, data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
        var config = {
            method: 'put',
            url: `${url}/owner/${ownerId}`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
            data: data
        };
        axios(config)
            .then((response: any) => {
                dispatch(setSuccessAction('propertyMapSuccess', true));
                dispatch(snackbarAlertAction(`Owner property updated successfully`, "success"));
            }).catch((error: any) => {
                dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
            })
    };

/**Get project updates by projectId*/
export const getProjectUpdatesByID = (projectId: string) =>
    async (dispatch: NABHThunkDispatch) => {
        await dispatch(getProjectUpdates(null));
        var config = {
            method: "get",
            url: `${url}/project-update/${projectId}`,
            headers: _getHeaders({
                "Content-Type": "application/json",
            }),
        };
        await axios(config)
            .then(async (response: any) => {
                dispatch(getProjectUpdates(response.data.data));
            })
            .catch((error: any) => { });
    };
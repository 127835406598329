import React, { useContext } from "react";
import Scrollbar from "./Scrollbar";
import { SidebarContext } from "../../SidebarContext";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  styled,
  Divider,
  Button,
  Typography,
  Grid,
  useMediaQuery,
  Fab,
  IconButton,
} from "@mui/material";
import { alpha, lighten, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutThunk } from "../../../redux/thunk/thunks";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SidebarMenu from "./SidebarMenu";
import HeaderUserbox from "../Header/Userbox/index";
import DreamsLogo from "../../../theme/Logo.png";
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
// import { userLogout } from "../../../redux/data/data-actions";

const SidebarWrapper = styled(Box)(
  ({ theme }: any) => `
        width: ${"28.89vh"};
        color: ${alpha("#ffffff", 0.7)};
        position: relative;
        z-index: 7;
        height: 100%;
        overflow: hidden;
        padding-bottom: 68px;
`
);

const buttonStyle = {
  color: "#000000",
  width: '90%',
  fontFamily: 'Montserrat-Medium',
  marginLeft: "10px",
  '&:hover': {
    boxShadow: '0px 9px 16px #662e9152, 0px 2px 2px #662e9154',
  }
};
//min-width: ${'100px'};

function Sidebar() {
  const location = useLocation();
  const path = location.pathname;

  console.log('path is', path);

  const isMobileView = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme: any = useTheme();
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("DREAMUSERAPP");
    navigate("/");
  };

  const menuItemDetails: any = [
    {
      path: '/developer/dr-projects',
      name: 'Redevelopment Projects'
    },
    {
      path: '/developer/favourites',
      name: 'Favourites'
    },
    {
      path: '/developers-transaction/bidding',
      name: 'My Bids & Transactions'
    },
    {
      path: '/developer/edit-profile',
      name: 'Edit Profile'
    },
  ];

  if(isMobileView)
    menuItemDetails.splice(3, 0, {path: '/developers-transaction/subscriptions', name: 'My Subscriptions'});
  
  console.log('sidebar toggle value is', sidebarToggle);
  return (
    isMobileView
    ?
    <Grid xs={12} sx={{padding: '20px 30px 30px 30px', borderBottom: '1px solid #d9d9d9'}}>
      {/* <Fab size="small" onClick={toggleSidebar}> */}
        <IconButton color="primary" onClick={toggleSidebar}>
          {!sidebarToggle ? (
            <MenuTwoToneIcon fontSize="small" />
          ) : (
            <CloseTwoToneIcon fontSize="small" />
          )}
        </IconButton>
      {/* </Fab> */}

      {menuItemDetails.filter((menuItemDetail: any) => menuItemDetail.path === path)[0]?.name}

      {sidebarToggle && (
        <Drawer
          sx={{
            boxShadow: `${theme.sidebar.boxShadow}`,
            // width: '95vw'
          }}
          PaperProps={{sx: {width: '82.33%'}}}
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper
            sx={{
              background: theme.palette.mode === "dark" ? "#ffffff" : "#fff",
              width: isMobileView ? '100%' : '28.89vh'
            }}
          >
            {/* <Scrollbar > */}
            <Box pt='calc(8vh + 20px)' sx={{height: '150px', bgcolor: '#662e91'}}>
              <Grid item container
                sx={{
                  width: '100%',
                  height: '100%',
                  ml: '30px'
                }}
              >
                <Grid item>
                  <IconButton color="primary" sx={{display: 'inline-block'}} onClick={toggleSidebar} >
                    <MenuTwoToneIcon fontSize="small" sx={{color: 'white'}} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Box>
                    <div style={{ color: 'white', fontSize: '16px', fontFamily: 'Montserrat-Medium', fontWeight: 600 }}>{userInfo?.developer?.name}</div>
                    <div style={{ color: 'white', fontSize: '12px', fontFamily: 'Montserrat-Regular' }}>{userInfo?.developer?.mobile}</div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: alpha("#ffffff", 0.1),
              }}
            />
            <SidebarMenu menuItemDetails={menuItemDetails} />
            {/* </Scrollbar> */}
            <Divider
              sx={{
                background: alpha("#ffffff", 0.1),
              }}
            />
            <Box p={2} sx={{position: 'absolute', bottom: '0px', width: '100%'}}>
              <Button
                component={RouterLink}
                onClick={() => logout()}
                to="/"
                rel="noopener noreferrer"
                variant="contained"
                style={{ background: "#993399", fontFamily: "Montserrat-Regular" }}
                size="small"
                fullWidth
              >
                Sign Out
              </Button>
            </Box>
          </SidebarWrapper>
        </Drawer>
      )}
    </Grid>
    :
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 5,
          width: "250px",
          background:
            theme.palette.mode === "dark"
              ? alpha(lighten("#ffffff", 0.1), 0.5)
              : "#fff",
          // boxShadow:
          //   theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
          // borderRight: '1px solid #efefef',
        }}
      >
        <Scrollbar>
          <Box
            height="9.91vh"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={DreamsLogo}
              alt="DREAMS REDEVELOPED"
              style={{ width: "100%", margin: '0 10px' }}
            />
          </Box>
          <Box ml={4}>
            <div style={{ color: '#000000', fontSize: '16px', fontFamily: 'Montserrat-Medium', fontWeight: 600 }}>{userInfo?.developer?.name}</div>
            <div style={{ color: '#848484', fontSize: '12px', fontFamily: 'Montserrat-Regular' }}>{userInfo?.developer?.mobile}</div>
          </Box>
          <Divider
            sx={{ background: '#1c1d211c', margin: '20px -10px', }}
          />
          <SidebarMenu menuItemDetails={menuItemDetails}/>
        </Scrollbar>
        <Box>
          <Button
            disableRipple
            component={RouterLink}
            onClick={() => logout()}
            to="/"
            rel="noopener noreferrer"
            size="small"
            sx={buttonStyle}
            startIcon={<PowerSettingsNewIcon />}
          >
            Logout
          </Button>
        </Box>
      </SidebarWrapper>
      {sidebarToggle && (
        <Drawer
          sx={{
            boxShadow: `${theme.sidebar.boxShadow}`,
          }}
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper
            sx={{
              background: theme.palette.mode === "dark" ? "#ffffff" : "#fff",
            }}
          >
            <Scrollbar>
              <Box mt={3}>
                <Box
                  mx={2}
                  sx={{
                    width: 52,
                  }}
                >
                  <img
                    alt=""
                    src={"/Assets/drfavicon.png"}
                    style={{ width: "50px" }}
                  />
                </Box>
              </Box>
              <Divider
                sx={{
                  mt: theme.spacing(3),
                  mx: theme.spacing(2),
                  background: alpha("#ffffff", 0.1),
                }}
              />
              <SidebarMenu menuItemDetails={menuItemDetails}/>
            </Scrollbar>
            <Divider
              sx={{
                background: alpha("#ffffff", 0.1),
              }}
            />
            <Box p={2}>
              <Button
                component={RouterLink}
                onClick={() => logout()}
                to="/"
                rel="noopener noreferrer"
                variant="contained"
                style={{ background: "#993399", fontFamily: "Montserrat-Regular" }}
                size="small"
                fullWidth
              >
                Sign Out
              </Button>
            </Box>
          </SidebarWrapper>
        </Drawer>
      )}
    </>
  );
}

export default Sidebar;

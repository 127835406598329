import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Provider } from "react-redux";
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { reduxStore } from "./redux/redux-store";
import router from './router/FlowRouter';
import { Alert } from './layouts/Alert';
import './App.css';

const App = () => {
  const content = useRoutes(router);
  return (
    <Provider store={reduxStore}>
      <ThemeProvider>
        <CssBaseline />
        {content}
        <Alert />
      </ThemeProvider>
    </Provider>
  );
}

export default App;

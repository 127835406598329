import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { colors } from '../styling/colors';

interface LoadingButtonProps {
    children?: React.ReactNode;
    spinnerColor?: string;
    loading?: boolean;
}

export const LoadingButton = (props: ButtonProps & LoadingButtonProps) => {
    const { children, loading, spinnerColor, ...buttonProps } = props;

    return (
        <Button {...buttonProps}>
            {children}

            {loading && (
                <CircularProgress
                    size={16}
                    style={{ color: spinnerColor ?? colors.background3, marginLeft: '0.5rem' }}
                />
            )}
        </Button>
    );
};

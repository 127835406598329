import { useEffect } from "react";
import "./DeveloperProfile.css";
import { Box, Typography, useMediaQuery, Grid, CardContent, Card, CardActions, Button, CircularProgress } from "@mui/material";
import Banner from "../Banner/Banner";
import { getAllProjects } from "../../redux/thunk/developer-thunk";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/redux-store";
import { useNavigate, useParams } from "react-router-dom";
import ContactInfo from "../../layouts/ContactInfo";
import { getDevByUrl } from "../../redux/thunk/thunks";
import drfavicon from "../../theme/drfavicon.png";
import defaultProjectMainImage from '../../theme/residential.png';
import PartnerCard from "./PartnerCard";
import DownloadBrochure from "../../layouts/DownloadBrochure";
import LandmarkImage from '../../theme/LandmarkDeveloper.png';
import NotFound from '../../theme/not-found.png';

function DeveloperProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let developerId = useParams();

  const isMobileView = useMediaQuery("(max-width:600px)");

  const loading = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);

  const developerData = useSelector((state: RootState) => state.DATA_REDUCER.developerData);

  const developerprojects = useSelector((state: RootState) => state.DATA_REDUCER.developerProjects);

  const developerPartners = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerPartners
  );

  const developerBrochure = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerBrochure
  );

  const developerCompanyLogo = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerCompanyLogo
  );

  const developerName = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerName
  );

  useEffect(() => {
    dispatch(getDevByUrl(developerId.id));

    async function getProjects() {
      await dispatch(getAllProjects({}));
    }
  }, []);

  const dateConvert = (value: any) => {
    const date = new Date(value);
    return date ? (date.toLocaleDateString('en-us', { day: 'numeric' }) + ' ' + date.toLocaleDateString('en-us', { month: 'short' }) + ' ' + date.toLocaleDateString('en-us', { year: 'numeric' })) : 'NA'
  }

  return (
    loading ? <Box display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Box> : developerData ? <><script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      {isMobileView ?
        <Box
          sx={{
            padding: isMobileView ? 0 : "0px 7.08vw",
            width: isMobileView ? "100%" : "initial",
          }}
        >
          <Box>
            {isMobileView ? "" : null}
            {
              isMobileView
                ?
                <>
                  <img src={developerCompanyLogo} style={{ height: '51px', margin: 'auto', display: 'block' }} />
                  <div
                    style={{
                      fontFamily: 'Montserrat-Medium',
                      fontSize: '30px',
                      textAlign: 'center',
                      margin: '19px 0px 32px 0px',
                      lineHeight: '34px',
                      maxHeight: '68px',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {developerName}
                  </div>
                </>
                :
                null
            }
            <Banner />
          </Box>
          <Typography
            variant="h1"
            align={"center"}
            sx={{
              fontFamily: 'Montserrat-Medium',
              mt: isMobileView ? 0 : "21vh",
              mb: "24px",
              fontSize: isMobileView ? "20px" : "30px",
              opacity: 0.8,
            }}
          >
            About Team
          </Typography>
          {
            developerPartners?.length
              ?
              <Box className="aboutMain">
                {developerPartners &&
                  developerPartners.map((partner: any, index: number) => (
                    <PartnerCard
                      partnerPhoto={partner.images[0]}
                      partnerName={partner.name}
                      partnerDesignation={partner.designation}
                      partnerDescription={partner.description}
                      partnerNumber={index + 1}
                    />
                  )
                  )
                }
              </Box>
              :
              <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
          }
          <>
            <Typography
              variant="h1"
              align="center"
              sx={{
                mt: isMobileView ? '66px' : "21vh",
                mb: "19px",
                fontFamily: 'Montserrat-Medium',
                fontSize: isMobileView ? "20px" : "30px",
                opacity: 0.8,
              }}
            >
              Projects
            </Typography>
            <Box className="cardContainer">
              {

                developerprojects?.length
                  ?
                  <Grid container spacing={6}>
                    {developerprojects && developerprojects?.length > 0 && developerprojects.filter((project: any) => project.drProjectUrl !== null).length > 0 &&
                      developerprojects.map((item: any) => {
                        if (item.drProjectUrl)
                          return (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                                {item.isLandmark ?
                                  <img src={LandmarkImage} alt='Landmark' style={{ height: '100px', position: 'absolute', top: '95px', right: '-20px', zIndex: 1 }} />
                                  : null
                                }
                                <Box
                                  sx={{
                                    display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                                    bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                                    width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                                  }}
                                >
                                  {item.constructionType}
                                </Box>
                                <Card
                                  sx={{
                                    display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      height: "92.5%",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      justifyContent: "flex-start",
                                      p: "10px 14px",
                                      position: 'relative',
                                    }}
                                  >
                                    <img
                                      src={drfavicon}
                                      style={{ position: 'relative', bottom: '7px', left: '10px', alignSelf: "flex-end", width: "37px", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                                    />

                                    <Typography
                                      variant="h5"
                                      className="project-grid-title-ellipsis"
                                      sx={{
                                        fontWeight: 800,
                                        width: '100%',
                                        fontSize: isMobileView ? "20px" : "2.22vh",
                                        mb: isMobileView ? '26px' : "3.52vh",
                                        fontFamily: 'Montserrat-Medium',
                                        opacity: 0.9
                                      }}
                                    >
                                      {item.projectName}
                                    </Typography>

                                    <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                                    <Typography
                                      className="cardText"
                                      sx={{
                                        // paddingBottom: "1.25vw",
                                        fontSize: isMobileView ? "18px" : "2.22vh",
                                        fontWeight: 100,
                                        mb: "5px",
                                      }}
                                    >
                                      <div className="project-grid-block-ellipsis">
                                        {item.address?.formatted_address}
                                      </div>
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontStyle: "lighter",
                                        fontSize: isMobileView ? "18px" : "15px",
                                        fontFamily: 'Montserrat-Regular',
                                        lineHeight: isMobileView ? "22px" : "1.5vw",
                                        color: "#838383",
                                        opacity: 0.8,
                                        mb: '25px',
                                        height: '44px',
                                        display: '-webkit-box',
                                        webkitLineClamp: 2,
                                        webkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                                    </Typography>
                                  </CardContent>
                                  <CardActions sx={{ height: "7.5%", padding: 0 }}>
                                    <Button
                                      onClick={() =>
                                        navigate(`/project/${item.drProjectUrl}`, {
                                          state: { projectId: item.drProjectUrl },
                                        })
                                      }
                                      className="card-button"
                                    >
                                      View Details
                                    </Button>
                                  </CardActions>
                                </Card>
                              </Box>
                            </Grid>
                          )
                      })}
                  </Grid>
                  :
                  <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
              }
            </Box>
          </>
          <Typography
            variant="h1"
            align={"center"}
            sx={{
              mt: isMobileView ? "94px" : "20.83vh",
              mb: isMobileView ? "19px" : "4.8vh",
              fontFamily: 'Montserrat-Medium',
              fontSize: isMobileView ? "20px" : "30px",
              opacity: 0.8,
              letterSpacing: '1px'
            }}
          >
            Ongoing Projects
          </Typography>
          <Box className="cardContainer">
            {developerprojects && developerprojects.length > 0 && developerprojects.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0 ?
              <Grid container spacing={6}>
                {developerprojects && developerprojects?.length > 0 &&
                  developerprojects.map((item: any) => {
                    if (item.drProjectUrl)
                      return (item.projectStatus === 0 &&
                        <Grid item xs={12} sm={6} md={4}>
                          <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                            {item.isLandmark ?
                              <img src={LandmarkImage} alt='Landmark' style={{ height: '100px', position: 'absolute', top: '95px', right: '-20px', zIndex: 1 }} />
                              : null
                            }
                            <Box
                              sx={{
                                display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                                bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                                width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                              }}
                            >
                              {item.constructionType}
                            </Box>
                            <Card
                              sx={{
                                display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                              }}
                            >
                              <CardContent
                                sx={{
                                  height: "92.5%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  p: "10px 14px",
                                  position: 'relative',
                                }}
                              >
                                <img
                                  src={drfavicon}
                                  style={{ position: 'relative', bottom: '7px', left: '10px', alignSelf: "flex-end", width: "37px", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                                />

                                <Typography
                                  variant="h5"
                                  className="project-grid-title-ellipsis"
                                  sx={{
                                    fontWeight: 800,
                                    width: '100%',
                                    fontSize: isMobileView ? "20px" : "2.22vh",
                                    mb: isMobileView ? '26px' : "3.52vh",
                                    fontFamily: 'Montserrat-Medium',
                                    opacity: 0.9
                                  }}
                                >
                                  {item.projectName}
                                </Typography>

                                <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                                <Typography
                                  className="cardText"
                                  sx={{
                                    // paddingBottom: "1.25vw",
                                    fontSize: isMobileView ? "18px" : "2.22vh",
                                    fontWeight: 100,
                                    mb: "5px",
                                  }}
                                >
                                  <div className="project-grid-block-ellipsis">
                                    {item.address?.formatted_address}
                                  </div>
                                </Typography>

                                <Typography
                                  sx={{
                                    fontStyle: "lighter",
                                    fontSize: isMobileView ? "18px" : "15px",
                                    fontFamily: 'Montserrat-Regular',
                                    lineHeight: isMobileView ? "22px" : "1.5vw",
                                    color: "#838383",
                                    opacity: 0.8,
                                    mb: '49px'
                                  }}
                                >
                                  Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                                </Typography>
                              </CardContent>
                              <CardActions sx={{ height: "7.5%", padding: 0 }}>
                                <Button
                                  onClick={() =>
                                    navigate(`/project/${item.drProjectUrl}`, {
                                      state: { projectId: item.drProjectUrl },
                                    })
                                  }
                                  className="card-button"
                                >
                                  View Details
                                </Button>
                              </CardActions>
                            </Card>
                          </Box>
                        </Grid>
                      )
                  })}
              </Grid> :
              <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
            }
          </Box>
          <Typography
            variant="h1"
            align={"center"}
            sx={{
              mt: isMobileView ? '75px' : "21vh",
              mb: "29px",
              fontFamily: 'Montserrat-Medium',
              fontSize: isMobileView ? "20px" : "30px",
              opacity: 0.8,
            }}
          >
            Our Brochure
          </Typography>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {developerBrochure ? <DownloadBrochure fileURL={developerBrochure} /> :
              <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
            }
          </Box>
          {/*Contact Us */}
          <Typography
            variant="h1"
            textAlign={"center"}
            sx={{
              mt: "96px",
              mb: "22px",
              fontFamily: 'Montserrat-Medium',
              fontSize: isMobileView ? "20px" : "30px",
              opacity: 0.8,
              letterSpacing: '1px',
            }}
          >
            Contact Us
          </Typography>
          <Box sx={{ width: "100%", height: '63.15vh', pb: '50px', boxSizing: 'content-box' }}>
            <ContactInfo page="developer" />
          </Box>
        </Box>
        :
        <Box
          sx={{
            padding: isMobileView ? 0 : "0px 7.08vw",
            width: isMobileView ? "100%" : "initial",
          }}
        >
          <Box>
            {isMobileView ? "" : null}
            <Banner />
          </Box>
          <Typography
            variant="h1"
            align={"center"}
            sx={{
              fontFamily: 'Montserrat-Medium',
              mt: isMobileView ? 0 : "21vh",
              mb: "4.8vh",
              fontSize: isMobileView ? "2.15vh" : "30px",
              opacity: 0.8,
            }}
          >
            About Team Members
          </Typography>
          {
            developerPartners?.length
              ?
              <Box className="aboutMain">
                {developerPartners &&
                  developerPartners.map((partner: any, index: number) => (
                    <PartnerCard
                      partnerPhoto={partner.images[0]}
                      partnerName={partner.name}
                      partnerDesignation={partner.designation}
                      partnerDescription={partner.description}
                      partnerNumber={index + 1}
                    />
                  )
                  )
                }
              </Box>
              :
              <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
          }
          <>
            <Typography
              variant="h1"
              align="center"
              sx={{
                mt: developerPartners?.length ? '21vh' : "9vh",
                mb: "4.8vh",
                fontFamily: 'Montserrat-Medium',
                fontSize: isMobileView ? "2.15vh" : "30px",
                opacity: 0.8,
              }}
            >
              Projects
            </Typography>
            <Box className="cardContainer">
              {
                developerprojects?.length
                  ?
                  <Grid container spacing={6}>
                    {developerprojects && developerprojects?.length > 0 && developerprojects.filter((project: any) => project.drProjectUrl).length > 0 &&
                      developerprojects.map((item: any) => {
                        if (item.drProjectUrl)
                          return (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                                {item.isLandmark ?
                                  <img src={LandmarkImage} alt='Landmark' style={{ height: '140px', position: 'absolute', top: '15vh', right: '-1.7vw', zIndex: 1 }} />
                                  : null
                                }
                                <Box
                                  sx={{
                                    display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                                    bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                                    width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                                  }}
                                >
                                  {item.constructionType}
                                </Box>
                                <Card
                                  sx={{
                                    display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                                  }}
                                >
                                  <CardContent
                                    sx={{
                                      height: "92.5%",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      justifyContent: "flex-start",
                                      p: "10px 3.26vh 0",
                                      position: 'relative',
                                    }}
                                  >
                                    <img
                                      src={drfavicon}
                                      style={{ alignSelf: "flex-end", width: "15%", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                                    />

                                    <Typography
                                      variant="h5"
                                      className="project-grid-title-ellipsis"
                                      sx={{
                                        fontWeight: 800,
                                        width: '100%',
                                        fontSize: isMobileView ? "2.15vh" : "2.22vh",
                                        mb: isMobileView ? 0 : "3.52vh",
                                        fontFamily: 'Montserrat-Medium',
                                        opacity: 0.9
                                      }}
                                    >
                                      {item.projectName}
                                    </Typography>

                                    <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                                    <Typography
                                      className="cardText"
                                      sx={{
                                        // paddingBottom: "1.25vw",
                                        fontSize: isMobileView ? "1.93vh" : "2.22vh",
                                        fontWeight: 100,
                                        mb: "30px",
                                      }}
                                    >
                                      <div className="project-grid-block-ellipsis">
                                        {item.address?.formatted_address}
                                      </div>
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontStyle: "lighter",
                                        fontSize: isMobileView ? "1.93vh" : "15px",
                                        fontFamily: 'Montserrat-Regular',
                                        lineHeight: isMobileView ? "2.36vh" : "1.5vw",
                                        color: "#838383",
                                        opacity: 0.8,
                                        mb: '15px'
                                      }}
                                    >
                                      Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                                    </Typography>
                                  </CardContent>
                                  <CardActions sx={{ height: "7.5%", padding: 0 }}>
                                    <Button
                                      onClick={() =>
                                        navigate(`/project/${item.drProjectUrl}`, {
                                          state: { projectId: item.drProjectUrl },
                                        })
                                      }
                                      className="card-button"
                                    >
                                      View Details
                                    </Button>
                                  </CardActions>
                                </Card>
                              </Box>
                            </Grid>
                          )
                      })}
                  </Grid>
                  :
                  <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
              }
            </Box>
          </>
          <Typography
            variant="h1"
            align={"center"}
            sx={{
              mt: developerprojects && developerprojects?.length > 0 && developerprojects.filter((project: any) => project.drProjectUrl).length > 0 ? "21vh" : "9vh",
              mb: isMobileView ? "5vh" : "4.8vh",
              fontFamily: 'Montserrat-Medium',
              fontSize: isMobileView ? "2.15vh" : "30px",
              opacity: 0.8,
              letterSpacing: '1px'
            }}
          >
            Ongoing Projects
          </Typography>
          <Box className="cardContainer">
            {developerprojects && developerprojects.length > 0 && developerprojects.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0 ?
              <Grid container spacing={6}>
                {
                  developerprojects.map((item: any) => {
                    if (item.drProjectUrl)
                      return (item.projectStatus === 0 &&
                        <Grid item xs={12} sm={6} md={4}>
                          <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                            {item.isLandmark ?
                              <img src={LandmarkImage} alt='Landmark' style={{ height: '140px', position: 'absolute', top: '15vh', right: '-1.7vw', zIndex: 1 }} />
                              : null
                            }
                            <Box
                              sx={{
                                display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                                bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                                width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                              }}
                            >
                              {item.constructionType}
                            </Box>
                            <Card
                              sx={{
                                display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                              }}
                            >
                              <CardContent
                                sx={{
                                  height: "92.5%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  p: "10px 3.26vh 0",
                                  position: 'relative',
                                }}
                              >
                                <img
                                  src={drfavicon}
                                  style={{ alignSelf: "flex-end", width: "15%", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                                />

                                <Typography
                                  variant="h5"
                                  className="project-grid-title-ellipsis"
                                  sx={{
                                    fontWeight: 800,
                                    width: '100%',
                                    fontSize: isMobileView ? "2.15vh" : "2.22vh",
                                    mb: isMobileView ? 0 : "3.52vh",
                                    fontFamily: 'Montserrat-Medium',
                                    opacity: 0.9
                                  }}
                                >
                                  {item.projectName}
                                </Typography>

                                <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                                <Typography
                                  className="cardText"
                                  sx={{
                                    // paddingBottom: "1.25vw",
                                    fontSize: isMobileView ? "1.93vh" : "2.22vh",
                                    fontWeight: 100,
                                    mb: "30px",
                                  }}
                                >
                                  <div className="project-grid-block-ellipsis">
                                    {item.address?.formatted_address}
                                  </div>
                                </Typography>

                                <Typography
                                  sx={{
                                    fontStyle: "lighter",
                                    fontSize: isMobileView ? "1.93vh" : "15px",
                                    fontFamily: 'Montserrat-Regular',
                                    lineHeight: isMobileView ? "2.36vh" : "1.5vw",
                                    color: "#838383",
                                    opacity: 0.8,
                                    mb: '15px'
                                  }}
                                >
                                  Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                                </Typography>
                              </CardContent>
                              <CardActions sx={{ height: "7.5%", padding: 0 }}>
                                <Button
                                  onClick={() =>
                                    navigate(`/project/${item.drProjectUrl}`, {
                                      state: { projectId: item.drProjectUrl },
                                    })
                                  }
                                  className="card-button"
                                >
                                  View Details
                                </Button>
                              </CardActions>
                            </Card>
                          </Box>
                        </Grid>
                      )
                  })}
              </Grid> :
              <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
            }
          </Box>
          <Typography
            variant="h1"
            align={"center"}
            sx={{
              mt: developerprojects && developerprojects.length > 0 && developerprojects.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0 ? '21vh' : "9vh",
              mb: "4.8vh",
              fontFamily: 'Montserrat-Medium',
              fontSize: isMobileView ? "2.15vh" : "30px",
              opacity: 0.8,
            }}
          >
            Our Brochure
          </Typography>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {developerBrochure ? <DownloadBrochure fileURL={developerBrochure} /> :
              <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
            }
          </Box>
          {/*Contact Us */}
          <Typography
            variant="h1"
            textAlign={"center"}
            sx={{
              mt: developerBrochure ? '21vh' : "9vh",
              mb: "4.8vh",
              fontFamily: 'Montserrat-Medium',
              fontSize: isMobileView ? "2.15vh" : "30px",
              opacity: 0.8,
              letterSpacing: '1px',
            }}
          >
            Contact Us
          </Typography>
          <Box sx={{ width: "100%", height: '63.15vh', pb: '50px', boxSizing: 'content-box' }}>
            <ContactInfo page="developer" />
          </Box>
        </Box>
      }
    </> :
      <Box mt={10} display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Sorry, Page Not Found!
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 5, width: '700px', textAlign: 'center' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
          sure to check your spelling.
        </Typography>
        <img src={NotFound} alt='not found!' />
      </Box>
  );
}

export default DeveloperProfile;

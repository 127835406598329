import {
  Box,
  Grid,
  Dialog,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
  Modal,
  Card,
  Autocomplete,
} from "@mui/material";
import {
  Field,
  Form,
  useFormik,
} from "formik";
import axios from "axios";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { _getHeaders } from "../redux/api-headers";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { ArrowBackIosNew, Delete, Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  createNonDRProject,
  updateNonDRProject,
} from "../redux/thunk/thunks";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/redux-store";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import "../styling/GlobalStyles.css";
import { setSuccessAction } from "../redux/display/display-actions";
import AmenitiesModal from "./AmenitiesModal";
import { url } from '../redux/url';


// ---styles---

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  minHeight: "50%",
  bgcolor: "background.paper",
  border: "2px solid #993399",
  borderRadius: "8px",
  boxShadow: 24,
  p: 2,
};

const errorDiv = {
  color: "red",
};

export default function AddNonDRProject(props: any) {
  // ---variables---

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: "AIzaSyAjm7oLhCvIeol2k8kNlj62c07cgNim3XU",
    });

  // ---states---

  const [isAmenitiesOpen, setIsAmenitiesOpen] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({
    imageUrl: "",
    imageName: "",
  });
  const [isViewImg, setIsViewImg] = useState<any>(null);
  const [locations, setLocation] = useState({
    label: "",
    placeId: "",
    locationName: "",
  });
  const [addressValue, setAddressValue] = useState(
    props.projectDetails &&
      props.projectDetails.address &&
      props.projectDetails.address.length
      ? `${props.projectDetails.address.location_name}, ${props.projectDetails.address.formatted_address}`
      : locations["label"]
  );
  const [placeId, setPlaceId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [selectedImage, setSelectedImage] = useState([]);
  const [profile, setProfile] = useState([]);
  const [initalValues, setInitalValues] = useState({
    projectName: "",
    shortDescription: "",
    reraNumber: "",
    completionDate: "",
    projectStatus: "",
    salesNumber: "",
  });
  const [disableAddProject, setDisableAddProject] = useState(false);

  // ---hooks---

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successAction: any = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.dataSaveSuccessfully
  );

  useEffect(() => {
    if (successAction) {
      props.setOpen(false);
      props.setNonDRProjectMode(false);
      dispatch(setSuccessAction("dataSaveSuccessfully", false));
    }
  });

  useEffect(() => {
    <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
  }, [])

  useEffect(() => {
    if (props.nonDRProjectMode) {
      if (props.projectDetails) {
        let projectDetails = props.projectDetails;
        setInitalValues({
          projectName: projectDetails.projectName
            ? projectDetails.projectName
            : "",
          shortDescription: projectDetails.shortDescription
            ? projectDetails.shortDescription
            : "",
          reraNumber: projectDetails.reraNumber
            ? projectDetails.reraNumber
            : "",
          completionDate: projectDetails.completionDate
            ? projectDetails.completionDate.split("T")[0]
            : "",
          projectStatus: projectDetails.projectStatus == null ? "" : projectDetails.projectStatus.toString(),
          salesNumber: projectDetails.salesNumber
            ? projectDetails.salesNumber.toString()
            : "",
        });
        if (projectDetails.mainImage) {
          setFile({
            imageUrl: projectDetails.mainImage,
            imageName: "Main Picture",
          });
        }
        if (projectDetails.projectImages) {
          setProfile(
            projectDetails.projectImages.map((img: any, index: any) => ({
              imageUrl: img,
              imageName: "img-" + index,
            }))
          );
        }
        if (projectDetails.address) {
          setLocation({
            label: projectDetails.address.formatted_address,
            placeId: projectDetails.address.place_id,
            locationName: projectDetails.address.location_name,
          });
          setPlaceId(projectDetails.address.place_id);
          setLocationName(projectDetails.address.location_name);
          setAddressValue(
            `${projectDetails.address.location_name}, ${projectDetails.address.formatted_address}`
          );
        }
        setAmenities(projectDetails?.amenitiesId ? projectDetails.amenitiesId : [])

        console.log(projectDetails);
      }
    }
  }, [props.projectDetails]);

  // ---methods---

  const handleCancel = () => {
    props.setEditProject(false);
    props.setOpen(false);
    props.setNonDRProjectMode(false);
  };

  function handleLocationChange(e: any) {
    if (e.target.value !== addressValue) setAddressValue(e.target.value);
    console.log(e);
    setPlaceId("");
    setLocationName("");
    setLocation({
      label: e.target.value,
      placeId: " ",
      locationName: "tempName",
    });
    getPlacePredictions({ input: e.target.value });
  }

  const handleImageUpload = (e: any) => {
    uploadProjectImage(e.target.files);
  };

  const uploadProjectImage = (info: any) => {
    if (info) {
      uploadImages(info)
        .then(function (response: any) {
          setLoading(false);
          setFile({
            imageUrl: response.data.url[0],
            imageName: info[0].name,
          });
        })
        .catch(function (error: any) {
          setLoading(false);
        });
    }
  };

  const uploadProjectImages = (info: any) => {
    if (info) {
      uploadImages(info)
        .then(function (response: any) {
          setLoading(false);
          const data: any = [...profile];
          for (let i = 0; i < response.data.url.length; i++) {
            setSelectedImage(response.data.url[i]);
            data.push({
              imageUrl: response.data.url[i],
              imageName: info[i].name,
            });
          }
          setProfile(data);
        })
        .catch(function (error: any) {
          setLoading(false);
        });
    }
  };

  const deleteProjectImages = (index: number) => {
    console.log(index);

    const images: any = [...profile];
    images.splice(index, 1);
    setProfile(images);
  };

  const uploadImages = (images: any): any => {
    if (images && images.length > 0) {
      setLoading(true);
      var data = new FormData();
      for (let i = 0; i < images.length; i++) {
        data.append("images", images[i]);
      }
      var config: any = {
        method: "post",
        url: `${url}/uploads`,
        headers: _getHeaders({ "Content-Type": "multipart/form-data" }),
        data: data,
      };
      return axios(config);
    }
  };

  const deleteMainImage = () => {
    setFile({
      imageUrl: "",
      imageName: "",
    });
  };

  const handleAddProject = (values: any) => {
    setDisableAddProject(true);
    if (placeId) {
      let place_id = placeId;
      let location_name = locationName;
      let mainImage = file.imageUrl;
      let projectImages = profile.map((item: any) => {
        return item.imageUrl;
      });
      let valuesArr = values;
      valuesArr.place_id = place_id;
      valuesArr.location_name = location_name;
      valuesArr.mainImage = mainImage;
      valuesArr.projectImages = projectImages;

      if (props.role == 'architect')
        valuesArr.initialArchitectId = [props.userId];
      else if (props.role == 'lawyer')
        valuesArr.initialLawyerId = [props.userId];
      else if (props.role == 'vastu')
        valuesArr.initialVastuId = [props.userId];
      valuesArr.developerProject = true;
      valuesArr.amenitiesId = amenities;

      dispatch(createNonDRProject(valuesArr));
    }
  };

  const handleEditProject = (values: any) => {
    let place_id = placeId;
    let location_name = locationName;
    let mainImage = file.imageUrl;
    let projectImages = profile.map((item: any) => {
      return item.imageUrl;
    });
    let valuesArr = values;
    valuesArr.place_id = place_id;
    valuesArr.location_name = location_name;
    valuesArr.mainImage = mainImage;
    valuesArr.projectImages = projectImages;
    if (props.role == 'architect')
      valuesArr.initialArchitectId = [props.userId];
    else if (props.role == 'lawyer')
      valuesArr.initialLawyerId = [props.userId];
    else if (props.role == 'vastu')
      valuesArr.initialVastuId = [props.userId];
    valuesArr.amenitiesId = amenities;

    dispatch(updateNonDRProject(valuesArr, props.projectDetails.projectId));
  };

  const isnumber = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 10);
    } else {
      return null;
    }
  };

  const formik = useFormik({
    initialValues: initalValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (props.nonDRProjectMode) {
        handleEditProject(values);
        console.log(values);
      } else {
        handleAddProject(values);
      }
    },
    validate: (values) => {
      let errors: any = {};
      if (values.projectName === "") {
        errors.projectName = "Project Name is required";
      }
      return errors;
    },
  });

  return (
    <Dialog
      open={props.open}
      sx={{ "& .MuiDialog-paper": { maxWidth: "100vw" } }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Grid item container alignItems="center">
          <Grid item>
            <IconButton onClick={() => props.setOpen(false)}>
              <ArrowBackIosNew />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{
              fontFamily: "Montserrat-Medium",
              fontWeight: "500",
              fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
              color: "#788195",
            }}
          >
            Add a Non DR Project
          </Grid>
        </Grid>
      </DialogTitle>
      <Form style={{ padding: "20px" }} onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Project Name*
              </Typography>
              <Field
                as={TextField}
                size="small"
                name="projectName"
                fullWidth
                style={{ marginTop: "5px" }}
                placeholder="Project Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectName}
              />
              {formik.errors.projectName ? (
                <div style={errorDiv}>{formik.errors.projectName}</div>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Geolocation*
              </Typography>
              <Autocomplete
                autoComplete
                openOnFocus
                freeSolo
                disableClearable
                value={locations}
                size="small"
                disablePortal
                aria-multiline
                onChange={(e: any, newValue: any) => {
                  console.log("new value is", newValue);
                  setPlaceId(newValue.placeId);
                  setLocationName(newValue.locationName);
                  setAddressValue(newValue.label);
                }}
                inputValue={addressValue}
                sx={{ width: "100%", marginTop: "5px" }}
                options={
                  placePredictions && placePredictions.length
                    ? placePredictions.map((placePrediction) => ({
                      label: placePrediction.description,
                      placeId: placePrediction.place_id,
                      locationName:
                        placePrediction.structured_formatting.main_text,
                    }))
                    : [
                      {
                        label: "",
                        placeId: "456",
                        locationName: "tempName",
                      },
                    ]
                }
                renderInput={(params: any) => (
                  <TextField
                    hiddenLabel
                    {...params}
                    placeholder="Location"
                    onChange={handleLocationChange}
                  />
                )}
              />
              {placeId ? null : (
                <div style={errorDiv}>Please enter geolocation</div>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Short Description
              </Typography>
              <Field
                as={TextArea}
                size="small"
                name="shortDescription"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.shortDescription}
                style={{ marginTop: "5px", width: "100%", minHeight: "150px" }}
                placeholder="Short Description"
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                RERA Number
              </Typography>
              <Field
                as={TextField}
                fullWidth
                size="small"
                name="reraNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reraNumber}
                style={{ marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Date of Completion
              </Typography>
              <Field
                as={TextField}
                fullWidth
                size="small"
                name="completionDate"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.completionDate}
                type="date"
                style={{ height: "38px", marginTop: "5px" }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Amenities
              </Typography>
              <Card
                style={{
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: 'default'
                }}
                onClick={() => setIsAmenitiesOpen(true)}
              >
                <Box style={{ marginRight: "15px", fontFamily: "Montserrat-Regular", fontSize: "16px", color: "#B4B4B4" }}>
                  Select Amenities
                </Box>
                <Box style={{ color: "#B4B4B4" }}>
                  <AddCircleIcon />
                </Box>
              </Card>
              {amenities.length > 0 && <span style={{}}>{amenities.length} amenities selected</span>}
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Status
              </Typography>
              <Field
                as="select"
                size="small"
                name="projectStatus"
                style={{
                  width: "100%",
                  height: "38px",
                  marginTop: "5px",
                }}
                onChange={formik.handleChange}
                value={formik.values.projectStatus}
              >
                <option value={""}>Select</option>
                <option value={"0"}>Ongoing</option>
                <option value={"1"}>Completed</option>
                <option value={"2"}>Upcoming</option>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Contact Us
              </Typography>
              <Field
                as={TextField}
                fullWidth
                size="small"
                name="salesNumber"
                // onInput={(e: any) => {
                //   e.target.value =
                //     e.target.value.length === 0
                //       ? ""
                //       : isnumber(e.target.value);
                // }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.salesNumber}
                style={{ marginTop: "5px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Main Image
              </Typography>
              <input
                name="mainImage"
                type="file"
                placeholder="upload image"
                id="mainImage"
                accept="image/*"
                onChange={(e: any) => handleImageUpload(e)}
                style={{
                  display: "none",
                }}
              />
              <label htmlFor="mainImage">
                <Card
                  style={{
                    height: "35px",
                    marginTop: "5px",
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <>
                    <Box style={{ marginRight: "20px", fontFamily: "Montserrat-Regular" }}>Upload main image</Box>
                    <CloudUploadRoundedIcon />
                  </>
                </Card>
              </label>

              {file.imageUrl !== "" ? (
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsViewImg(file)}
                  >
                    {file?.imageName}
                  </span>
                  <Delete
                    onClick={(e) => deleteMainImage()}
                    sx={{
                      color: "#ff6767",
                      marginTop: "10px",
                      marginLeft: "10px",
                      marginBottom: "-5px",
                    }}
                    fontSize="small"
                  />
                </div>
              ) : (
                ""
              )}
              {isViewImg && (
                <Modal open={isViewImg ? true : false}>
                  <Box sx={style2}>
                    <Close
                      onClick={() => setIsViewImg(null)}
                      style={{
                        position: "relative",
                        cursor: "pointer",
                        right: 0,
                        top: 0,
                      }}
                    />
                    <img
                      src={isViewImg.imageUrl}
                      style={{
                        position: "relative",
                        height: "-webkit-fill-available",
                        width: "-webkit-fill-available",
                      }}
                      alt=""
                    />
                  </Box>
                </Modal>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#788195",
                  // fontSize: "18px",
                  fontFamily: "Montserrat-Medium",
                  fontSize: "clamp(1rem, 1.38vh, 1.38vh)",
                }}
              >
                Image Gallery
              </Typography>
              <input
                name="projectImages"
                type="file"
                placeholder="upload image"
                id="galleryImageInput"
                multiple
                accept="image/*"
                onChange={(e: any) => uploadProjectImages(e.target.files)}
                style={{
                  display: "none",
                }}
              />

              <label htmlFor="galleryImageInput">
                <Card
                  style={{
                    height: "35px",
                    marginTop: "5px",
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <>
                    <Box style={{ marginRight: "20px", fontFamily: "Montserrat-Regular" }}>
                      Upload multiple images
                    </Box>

                    <Box>
                      <CloudUploadRoundedIcon />
                    </Box>
                  </>
                </Card>
              </label>

              {profile.map((galleryImage: any, index: number) => {
                return (
                  <>
                    {
                      <li
                        key={index}
                        style={{ listStyleType: "none", marginTop: "10px" }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsViewImg(galleryImage)}
                        >
                          {galleryImage.imageName}
                        </span>
                        <Delete
                          onClick={(e) => deleteProjectImages(index)}
                          sx={{
                            color: "#ff6767",
                            marginTop: "10px",
                            marginLeft: "20px",
                            marginBottom: "-5px",
                          }}
                          fontSize="small"
                        />
                      </li>
                    }
                  </>
                );
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: "2.19vw" }}>
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              border: "1px solid 788195",
              borderRadius: "3px",
              color: "#788195",
            }}
            className="button2"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              borderRadius: "3px",
              bgcolor: "#662e91",
            }}
            className="button1"
            disabled={disableAddProject}
          >
            {props.nonDRProjectMode ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Form>
      {isAmenitiesOpen && <AmenitiesModal open={isAmenitiesOpen} setOpen={setIsAmenitiesOpen} setAmenity={setAmenities} currentAmenities={amenities} />}
    </Dialog>
  );
}

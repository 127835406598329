import { FC, useEffect } from "react";
import { Box, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Search from "./Search";
import AddProjectList from "./AddProjectList";
import AddProjectItem from "./AddProjectItem";
import "./AddSearchProject.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsByName } from "../../redux/thunk/project-thunks";
import { useNavigate } from "react-router-dom";
import MessageIcon from '@mui/icons-material/Message';

const AddSearchProject: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);
  const searchProjectName = useSelector(
    (state: any) => state.DATA_REDUCER.searchProjectName
  );
  const projects = useSelector((state: any) => state.DATA_REDUCER.projects);
  const isMobileView = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    if (searchProjectName) dispatch(getProjectsByName(searchProjectName));
  }, [searchProjectName]);

  console.log("searchProjectName", searchProjectName);
  console.log("ProjectName", projects);

  return (
    <>
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Box className="Main" style={{ marginTop: isMobileView ? 0 : "12.9vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "flex-start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: isMobileView ? "20px" : "27.5vw", cursor: 'pointer',
              display: "flex", justifyContent: isMobileView ? "flex-start" : "flex-end",
              alignItems: "center",
            }}
            onClick={() => navigate(`/search/searchproject/${userInfo?.owner?.ownerId}`)}
          >
            <ArrowBackIosNewIcon />
          </Box>
          <Box
            className="Page-title"
            sx={{
              p: isMobileView ? "8px" : 0,
              width: isMobileView ? "200px" : "45vw",
              textAlign: isMobileView ? "start" : "center",
              marginLeft: isMobileView ? "20px" : "initial",
            }}
          >
            Add Project
          </Box>
          <Box
            sx={{
              width: "27.5vw",
            }}
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: isMobileView ? "0px" : "27.5vw" }}></Box>
          <Box
            sx={{
              width: isMobileView ? "90vw" : "45vw",
            }}
          >
            <Search
              placeholder={
                isMobileView
                  ? "Search"
                  : "Search projects by name, location...."
              }
            />
          </Box>
          <Box
            sx={{
              width: "27.5vw",
              display: isMobileView ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            {/* <Button color='primary' className='Custom-button-1' style={{height: '5.19vh', padding: '0px 3.13vh', borderRadius: '3px', fontSize: '1.85vh'}}>Add</Button> */}
          </Box>
        </Box>
        <AddProjectList>
          {searchProjectName &&
            projects &&
            projects.data &&
            projects.data.length
            ? projects.data.map((project: any, index: number) => (
              // <Grid item id="tempParent">
              <AddProjectItem
                // index={index}
                projectImage={project.projectImages[0]}
                projectName={project.projectName}
                projectAddress={project.address.formatted_address}
              // selectedIndex={selectedIndex}
              // setSelectedIndex={setSelectedIndex}
              />
              // </Grid>
            ))
            : null}
          <Box style={{ marginLeft: "307px", marginTop: "54px" }}>
            <Button
              color="primary"
              className="Custom-button-1"
              style={{
                width: '120px',
                height: "5.19vh",
                padding: "0px 3.13vh",
                borderRadius: "3px",
                fontSize: "1.85vh",
              }}
            >
              Next
            </Button>
          </Box>
        </AddProjectList>
      </Box>
      <div
        style={{
          position: 'fixed',
          cursor: 'pointer',
          padding: '8px 8px 2px 8px',
          backgroundColor: '#662e91',
          borderRadius: '5px',
          bottom: isMobileView ? '20px' : '20px',
          right: isMobileView ? '20px' : '100px',
        }}
        onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
      >
        <MessageIcon style={{color: 'white'}} />
      </div>
    </>
  );
};

export default AddSearchProject;

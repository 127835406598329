import { Action } from 'redux';
import {
  DisplaySuccessState,
  DisplayErrorState,
  DisplayLoadingState
} from './display-reducer-type';

// Very helpful article for derived types: https://dev.to/shakyshane/2-ways-to-create-a-union-from-an-array-in-typescript-1kd6
export const SET_GENERIC_ERROR_A = 'SET_GENERIC_ERROR_A';
export interface SetErrorAction extends Action<typeof SET_GENERIC_ERROR_A> {
  reduxField: keyof DisplayErrorState;
  errorMsg: string | null;
}
export const setErrorAction = (reduxField: keyof DisplayErrorState, errorMsg: string | null) => {
  return {
    type: SET_GENERIC_ERROR_A,
    reduxField,
    errorMsg
  };
};
export const SET_GENERIC_SUCCESS_A = 'SET_GENERIC_SUCCESS_A';
export interface SetSuccessAction extends Action<typeof SET_GENERIC_SUCCESS_A> {
  reduxField: keyof DisplaySuccessState;
  success: boolean;
}
export const setSuccessAction = (reduxField: keyof DisplaySuccessState, success: boolean) => {
  return {
    type: SET_GENERIC_SUCCESS_A,
    reduxField,
    success
  };
};
export const SET_GENERIC_LOADING_A = 'SET_GENERIC_LOADING_A';
export interface SetLoadingAction extends Action<typeof SET_GENERIC_LOADING_A> {
  reduxField: keyof DisplayLoadingState;
  loading: boolean
}
export const setLoadingAction = (
  reduxField: keyof DisplayLoadingState,
  loading: boolean
) => {
  return {
    type: SET_GENERIC_LOADING_A,
    reduxField,
    loading
  };
};

export const SNACKBAR_ALERT_A = 'SNACKBAR_ALERT_A';
export interface SnackbarAlertAction extends Action<typeof SNACKBAR_ALERT_A> {
  snackbarAlertMessage: string | null;
  snackbarAlertSeverity: any; // TODO Color   import { Color } from '@material-ui/lab/Alert';
}
export const snackbarAlertAction = (
  snackbarAlertMessage: string | null,
  snackbarAlertSeverity: any  // TODO   Color
) => {
  return {
    type: SNACKBAR_ALERT_A,
    snackbarAlertMessage,
    snackbarAlertSeverity
  };
};

export const API_LOGIN_ERROR_A = 'API_LOGIN_ERROR_A';
export interface LoginErrorAction extends Action<typeof API_LOGIN_ERROR_A> {
  loginError: string | null;
}
export const loginErrorAction = (loginError: string | null) => {
  return {
    type: API_LOGIN_ERROR_A,
    loginError
  };
};

export type DisplayAction =
  | SnackbarAlertAction
  | LoginErrorAction
  | SetErrorAction
  | SetSuccessAction
  | SetLoadingAction;

import { FC, ReactNode, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { alpha, lighten, useTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { RootState } from "../../redux/redux-store";
import { useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import Header from './Header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const button = {
  background: '#662e91',
  color: '#fff',
  fontSize: '0.926vw',
  fontFamily: 'Montserrat-Regular',
  letterSpacing: '1px',
  opacity: 0.9
}
const menuStyle = {
  cursor: 'pointer',
  '&:hover': {
    boxShadow: '0px 1px 16px #662e9152, 0px 2px 2px #662e9154',
  },
}

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery('(max-width:480px)');
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const projectList: any = useSelector((state: RootState) => state.DATA_REDUCER.projectAccessList);
  const projectInfo = projectList && projectList.length > 0 ? projectList[0] : {}

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? alpha('#ffffff', 0.02)
                : alpha('#ffffff', 0.5),
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten('#5569ff', 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  '#223354',
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  '#223354',
                  0.05
                )}`
          }
        }}
      >
        {<Header />}
        <Sidebar open={open} setOpen={setOpen}/>
        <Grid
          item container
          sx={{
            mt: isMobileView ? '8vh' : '10vh',
            // width: '100%',
            width: '100vw',
            height: isMobileView ? '100vh' : '82.3vh',
            // px: '8.38vw'
            // px: isMobileView ? '5vw' : 'initial'
            px: isMobileView ? '5vw' : '7.38vw'
            
            // mt: isMobileView ? '0vh' : '12.9vh',
            // width: '100vw',
            // height: isMobileView ? '100vh' : '82.3vh',
            // px: isMobileView ? '5vw' : '4%'
          }}
        >
          <Grid item sx={{ width: isMobileView? '5.5%':'2.08%', paddingTop: '10px' }}>
            <MenuIcon sx={{cursor: 'pointer', '&:hover': { boxShadow: '0px 1px 16px #662e9152, 0px 2px 2px #662e9154'},
          marginTop: isMobileView? "20px": "0px"}} onClick={() => setOpen(true)} />
          </Grid>
          {!isMobileView &&<Grid item container sx={{ width: '84%', pl: '2.7%' }}>
            {children || <Outlet />}
          </Grid>}
          {!isMobileView &&<Grid item sx={{ ml: 'auto', width: '13.92%' }}>
          </Grid>}
          {isMobileView &&<Grid item container sx={{ width: isMobileView? '89.5%':'93%', pl: '2.7%' }}>
            {children || <Outlet />}
          </Grid>}
        </Grid>
      </Box>
    </>
  );
};

export default SidebarLayout;
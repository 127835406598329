import { NullLiteral } from "typescript";
import {
  DataAction,
  setDeveloperAction,
  setDeveloperProjectAction,
  SetInvitedUserAction,
  SetLoggedInUserAction,
  SetProjectNameAction,
  SetProjectSpecialFeaturesAction,
  SetProjectReraNumberAction,
  SetProjectImagesAction,
  setTeaserAction,
  SetProjectAmenitiesAction,
  SetProjectLocationDetailsAction,
  SetDeveloperTelephoneAction,
  SetDeveloperCompanyLogoAction,
  SetProjectDeveloperIdAction,
  SetDeveloperEmailAction,
  SetDeveloperCompletedProjectsAction,
  SetDeveloperOngoingProjectsAction,
  SetDeveloperEstablishedYearAction,
  SetDeveloperAddressAction,
  SetDeveloperNameAction,
  SetDeveloperImageAction,
  SetDeveloperDescriptionAction,
  SetDeveloperPartnersAction,
  SetDeveloperBrochureAction,
  SetDeveloperProjectsAction,
  SetProjectMainImageAction,
  SetProjectLayoutImageAction,
  SetProjectUnitTypeAction,
  SetDeveloperMapDetailsAction,
  SetProjectSalesNumberAction,
  setDeveloperByUrlAction,
  SetProjectNumberOfUnitsAction,
  SetProjectDateOfCompletionAction,
  SetProjectStageAction,
  SetProjectNumberOfFlatsAction,
  SetProjectNumberOfShopsAction,
  SetProjectNumberOfBuildingsAction,
  SetProjectBrochureAction,
  SetDeveloperDataAction,
  SetProjectDataAction,
  SetPaymentDataAction,
  SetAllCitiesAction,
  GetOwnerByIdSuccessAction,
  SetVastuProfileDataAction,
  SetUserPropertyRolesAction,
  SetRedirectTo404Action,
  SetEditUserDetailsAction,
  GetAllMozniByIdAction,
  GET_ALL_MOZNI_BY_ID,
  SET_EDIT_USER_DETAILS_A,
  SET_REDIRECT_TO_404_A,
  SET_USER_PROPERTY_ROLES_A,
  SET_VASTU_PROFILE_DATA_A,
  GET_OWNER_BY_ID_SUCCESS,
  SET_ALL_CITIES,
  SET_PROJECT_DATA,
  SET_PAYMENT_DATA,
  SET_DEVELOPER_DATA,
  SET_PROJECT_NUMBER_OF_FLATS_A,
  SET_PROJECT_NUMBER_OF_SHOPS_A,
  SET_PROJECT_NUMBER_OF_BUILDINGS_A,
  SET_PROJECT_NUMBER_OF_UNITS_A,
  SET_PROJECT_DATE_OF_COMPLETION_A,
  SET_PROJECT_STAGE_A,
  SET_PROJECT_BROCHURE_A,
  SET_DEVELOPER_BROCHURE_A,
  SET_DEVELOPER,
  SET_DEVELOPER_PROJECT,
  SET_INVITED_USER_A,
  SET_LOGGED_IN_USER_A,
  SET_PROJECT_NAME_A,
  SET_PROJECT_SPECIAL_FEATURES_A,
  SET_PROJECT_RERA_NUMBER_A,
  SET_PROJECT_IMAGES_A,
  SET_TEASER,
  SET_PROJECT_AMENITIES_A,
  SET_DEVELOPER_TELEPHONE_A,
  SET_DEVELOPER_COMPANY_LOGO_A,
  SET_PROJECT_LOCATION_DETAILS_A,
  SET_PROJECT_DEVELOPER_ID_A,
  SET_DEVELOPER_EMAIL_A,
  SET_DEVELOPER_COMPLETED_PROJECTS_A,
  SET_DEVELOPER_ONGOING_PROJECTS_A,
  SET_DEVELOPER_ESTABLISHED_YEAR_A,
  SET_DEVELOPER_ADDRESS_A,
  SET_DEVELOPER_NAME_A,
  SET_DEVELOPER_IMAGE_A,
  SET_DEVELOPER_DESCRIPTION_A,
  SET_DEVELOPER_PARTNERS_A,
  SET_DEVELOPER_PROJECTS_A,
  SET_DEVELOPER_MAP_DETAILS_A,
  SET_PROJECT_LAYOUT_IMAGE_A,
  SET_PROJECT_MAIN_IMAGE_A,
  SET_PROJECT_UNIT_TYPE_A,
  SET_PROJECT_SALES_NUMBER_A,
  SET_DEVLOPER_BY_URL,
  locationDetailsType,
  SET_PROJECT_BY_URL,
  setProjectByUrlAction,
  SET_DEVELOPER_FORMATE_ADDRESS,
  SET_PROJECT_MAP_DETAILS,
  setprojectMapDetailsAction,
  SET_PROJECT_ADDRESS,
  SetProjectAddressAction,
  SET_PROJECT_REDEV_DEV_ID,
  setprojectRedevDevIdAction,
  SetAllArchitectAction,
  SetAllBidprojectAction,

  SET_ALL_ARCHITECT,
  SET_ALL_BIDPROJECTS,

  SetLawyerProjectsAction,
  SET_LAWYER_PROJECTS_A,
  GET_ALL_Projects,
  GetAllProjectsAction,
  SET_SEARCH_PROJECT_NAME_A,
  SetSearchProjectNameAction,
  GET_FLAT_DETAILS_BY_ID,
  getFlatDeatilsByIdAction,
  SET_SELECTED_PROJECT_INFO,
  SetSelectedProjectInfo,
  SET_SELECTED_FLOOR_DETAILS,
  SetSelectedFloordetailsActions,
  SET_SELECTED_FLAT_DETAILS,
  SetSelectedFlatActions,
  SET_ALL_FAVOURITEPROJECTS,
  SET_ALL_DRPROJECTS,
  SetAllFavouriteprojectAction,
  SetAllDrprojectAction,
  GetProjectInfoByProjectID, GET_PROJECT_INFO_PROJECT_ID,
  GetProjectAccessByUserID, GET_PROJECT_ACCESS_LIST_USER_ID,
  GetAllTransactionAction, GET_ALL_Transaction,
  GetAllWingsActions, GET_ALL_WINGS,
  GetAllFloorsAction, GET_ALL_FLOORS,
  GetAllFlatsAction, GET_ALL_FLATS,
  GetOwnerPropertyAction, GET_OWNER_PROPERTY_INFO,
  GET_PROJECT_BID_INFO, GetProjectBidAction,
  GET_UPLOAD_URL, GetUploadURLAction,
  GET_SUB_BID_INFO, GetSubBidAction,
  GetProjectUpdatesAction, GET_PROJECT_UPDATES,
  GetAllArchitectAction, GET_ALL_ARCHITECT,
  GET_ALL_LAWYERS, GetAllLawyerAction,
  BidingTransactionAction, BIDING_TRANSACTION,
  GET_ALL_VASTU, GetAllVastuAction, GET_ALL_AMENITIES, GetAllAmenitiesAction,
  SET_FORUM_TOKEN, SetForumTokenAction,
  SetChatTokenAction, SET_CHAT_TOKEN,
  ORDER_TRANSACTION,
  OrderTransactionAction,
  CAPTURE_PAYMENT,
  CapturePaymentAction
} from "./data-actions";

// manage stateful data fetched from the backend
interface DataReduxState {
  loggedInUser: any | null;
  invitedUser: any | null;
  developerdata: any | null;
  developerprojects: any | null;
  projectName: any | null;
  projectSpecialFeatures: any | null;
  projectReraNumber: any | null;
  projectImages: any | null;
  teaser: any | null;
  projectAmenities: any | null;
  projectLocationDetails: locationDetailsType;
  projectDeveloperId: any | null;
  developerTelephone: any | null;
  developerCompanyLogo: any | null;
  developerEmail: any | null;
  developerCompletedProjects: any | null;
  developerOngoingProjects: any | null;
  developerEstablishedYear: any | null;
  developerAddress: any | null;
  developerName: any | null;
  developerImage: any | null;
  developerDescription: any | null;
  developerPartners: any | null;
  developerProjects: any | null;
  projectMainImage: any | null;
  projectLayoutImage: any | null;
  projectUnitType: any | null;
  developerMapDetails: any;
  projectMapDetails: any;
  projectRedevDevId: any;
  projectSalesNumber: any;
  DeveloperByUrl: any | null;
  ProjectByUrl: any | null;
  formatted_address: any;
  projectAddress: any;
  architectProjects: any | null;
  bidProjects: any | null;
  favouriteProjects: any | null;
  drProjects: any | null;
  lawyerProjects: any | null;
  projects: any | null;
  searchProjectName: any | null;
  getFlatDeatilsById: any | null;
  selectedProjectInfo: {} | null;
  selectedFloordetails: {} | null;
  selectedFlat: {} | null;

  projectInfo: {} | null;
  projectAccessList: [] | null;
  transactions: [] | null;
  developerBrochure: any;
  projectNumberOfUnits: any;
  projectDateOfCompletion: any;
  projectStage: any;
  projectBrochure: any;
  flats: [] | null;
  floors: [] | null;
  wings: [] | null
  ownerProperty: [] | null;
  projectBid: {} | null;
  uploadURL: '' | null;
  subscriptionBid: {} | null;
  projectNumberOfFlats: any;
  projectNumberOfShops: any;
  projectNumberOfBuildings: any;
  developerData: any;
  projectData: any;
  paymentData: any;
  cities: any;
  projectUpdates: [] | null;
  lawyers: [] | null;
  architects: [] | null;
  currentOwner: any;
  vastuShastra: [] | null;
  vastuProfileData: any;
  allAmenities: [] | null;
  userPropertyRoles: any;
  redirectTo404: any;
  editUserDetails: any;
  moznisbyid: any;
  bidingTransaction: {} | null;
  orderTransaction: {} | null;
  capturePayment: {} | null;
  forumToken: {} | null;
  chatToken: '' | null
}

const defaultDataState = {
  loggedInUser: null,
  invitedUser: null,
  developerdata: null,
  developerprojects: null,
  projectName: null,
  lawyerProjects: null,
  projectSpecialFeatures: null,
  projectReraNumber: null,
  projectImages: null,
  teaser: null,
  projectAmenities: null,
  projectLocationDetails: {},
  projectDeveloperId: null,
  developerTelephone: null,
  formatted_address: null,
  developerCompanyLogo: null,
  developerEmail: null,
  developerCompletedProjects: null,
  developerOngoingProjects: null,
  developerEstablishedYear: null,
  developerAddress: null,
  developerName: null,
  developerImage: null,
  developerDescription: null,
  developerPartners: null,
  developerProjects: null,
  developerMapDetails: {},
  projectMapDetails: {},
  projectRedevDevId: null,
  projectMainImage: null,
  projectLayoutImage: null,
  projectUnitType: null,
  projectSalesNumber: null,
  DeveloperByUrl: null,
  ProjectByUrl: null,
  projectAddress: null,
  projects: null,
  architectProjects: null,
  bidProjects: null,
  favouriteProjects: null,
  drProjects: null,
  searchProjectName: null,
  getFlatDeatilsById: null,
  selectedProjectInfo: null,
  selectedFloordetails: null,
  selectedFlat: null,

  projectAccessList: null,
  projectInfo: null,
  transactions: null,
  developerBrochure: null,
  projectNumberOfUnits: null,
  projectDateOfCompletion: null,
  projectStage: null,
  projectBrochure: null,
  flats: null,
  floors: null,
  wings: null,
  ownerProperty: null,
  projectBid: null,
  uploadURL: null,
  subscriptionBid: null,
  projectNumberOfFlats: null,
  projectNumberOfShops: null,
  projectNumberOfBuildings: null,
  developerData: null,
  projectData: null,
  paymentData: null,
  cities: null,
  projectUpdates: null,
  lawyers: null,
  architects: null,
  currentOwner: null,
  vastuShastra: null,
  vastuProfileData: null,
  allAmenities: null,
  userPropertyRoles: null,
  redirectTo404: false,
  editUserDetails: null,
  moznisbyid: null,
  bidingTransaction: null,
  orderTransaction: null,
  capturePayment: null,
  forumToken: null,
  chatToken: null,
};

export const dataReducer = (
  state: DataReduxState = defaultDataState,
  action: DataAction
): DataReduxState => {
  switch (action.type) {
    case SET_FORUM_TOKEN:
      return {
        ...state,
        forumToken: (action as SetForumTokenAction).forumToken,
      };
    case SET_CHAT_TOKEN:
      return {
        ...state,
        chatToken: (action as SetChatTokenAction).chatToken,
      };
    case SET_ALL_CITIES:
      return {
        ...state,
        cities: (action as SetAllCitiesAction).cities,
      };
    case SET_LOGGED_IN_USER_A:
      return {
        ...state,
        loggedInUser: (action as SetLoggedInUserAction).user,
      };
    case SET_INVITED_USER_A:
      return {
        ...state,
        invitedUser: (action as SetInvitedUserAction).invitedUser,
      };
    case SET_DEVELOPER:
      return {
        ...state,
        developerdata: (action as setDeveloperAction).developerdata,
      };
    case BIDING_TRANSACTION:
      return {
        ...state,
        bidingTransaction: (action as BidingTransactionAction).bidingTransaction,
      };

      case ORDER_TRANSACTION:
        return {
          ...state,
          orderTransaction: (action as OrderTransactionAction).orderTransaction,
        };
        case CAPTURE_PAYMENT:
          return {
            ...state,
            capturePayment: (action as CapturePaymentAction).capturePayment,
          };

    case GET_ALL_VASTU:
      return {
        ...state,
        vastuShastra: (action as GetAllVastuAction).vastuShastra,
      };
    case GET_ALL_ARCHITECT:
      return {
        ...state,
        architects: (action as GetAllArchitectAction).architects,
      };
    case GET_ALL_LAWYERS:
      return {
        ...state,
        lawyers: (action as GetAllLawyerAction).lawyers,
      };
    case SET_PROJECT_DATA:
      return {
        ...state,
        projectData: (action as SetProjectDataAction).projectData,
      };

      case SET_PAYMENT_DATA:
        return {
          ...state,
          paymentData: (action as SetPaymentDataAction).paymentData,
        };

    case SET_DEVELOPER_PROJECT:
      return {
        ...state,
        developerprojects: (action as setDeveloperProjectAction)
          .developerprojects,
      };
    case SET_PROJECT_NAME_A:
      return {
        ...state,

        projectName: (action as SetProjectNameAction).projectName,
      };
    case SET_PROJECT_NUMBER_OF_UNITS_A:
      return {
        ...state,

        projectNumberOfUnits: (action as SetProjectNumberOfUnitsAction).projectNumberOfUnits,
      };
    case SET_PROJECT_DATE_OF_COMPLETION_A:
      return {
        ...state,

        projectDateOfCompletion: (action as SetProjectDateOfCompletionAction).projectDateOfCompletion,
      };
    case SET_PROJECT_STAGE_A:
      return {
        ...state,

        projectStage: (action as SetProjectStageAction).projectStage,
      };
    case SET_PROJECT_NUMBER_OF_FLATS_A:
      return {
        ...state,

        projectNumberOfFlats: (action as SetProjectNumberOfFlatsAction).projectNumberOfFlats,
      };
    case SET_PROJECT_NUMBER_OF_SHOPS_A:
      return {
        ...state,

        projectNumberOfShops: (action as SetProjectNumberOfShopsAction).projectNumberOfShops,
      };
    case SET_PROJECT_NUMBER_OF_BUILDINGS_A:
      return {
        ...state,

        projectNumberOfBuildings: (action as SetProjectNumberOfBuildingsAction).projectNumberOfBuildings,
      };
    case SET_PROJECT_BROCHURE_A:
      return {
        ...state,

        projectBrochure: (action as SetProjectBrochureAction).projectBrochure,
      };
    case SET_PROJECT_MAIN_IMAGE_A:
      return {
        ...state,

        projectMainImage: (action as SetProjectMainImageAction)
          .projectMainImage,
      };

    case SET_PROJECT_LAYOUT_IMAGE_A:
      return {
        ...state,

        projectLayoutImage: (action as SetProjectLayoutImageAction)
          .projectLayoutImage,
      };

    case SET_PROJECT_UNIT_TYPE_A:
      return {
        ...state,

        projectUnitType: (action as SetProjectUnitTypeAction).projectUnitType,
      };

    case SET_PROJECT_SPECIAL_FEATURES_A:
      return {
        ...state,

        projectSpecialFeatures: (action as SetProjectSpecialFeaturesAction)
          .projectSpecialFeatures,
      };

    case SET_PROJECT_RERA_NUMBER_A:
      return {
        ...state,

        projectReraNumber: (action as SetProjectReraNumberAction)
          .projectReraNumber,
      };

    case SET_PROJECT_IMAGES_A:
      return {
        ...state,

        projectImages: (action as SetProjectImagesAction).projectImages,
      };

      case SET_TEASER:
        return {
          ...state,
  
          teaser: (action as setTeaserAction).teaser,
        };

    case SET_PROJECT_AMENITIES_A:
      return {
        ...state,

        projectAmenities: (action as SetProjectAmenitiesAction).projectAmenities,
      };

    case SET_PROJECT_LOCATION_DETAILS_A:
      return {
        ...state,

        projectLocationDetails: (action as SetProjectLocationDetailsAction)
          .projectLocationDetails,
      };

    case SET_PROJECT_DEVELOPER_ID_A:
      return {
        ...state,

        projectDeveloperId: (action as SetProjectDeveloperIdAction)
          .projectDeveloperId,
      };

    case SET_DEVELOPER_TELEPHONE_A:
      return {
        ...state,

        developerTelephone: (action as SetDeveloperTelephoneAction)
          .developerTelephone,
      };

    case SET_DEVELOPER_COMPANY_LOGO_A:
      return {
        ...state,

        developerCompanyLogo: (action as SetDeveloperCompanyLogoAction)
          .developerCompanyLogo,
      };
    case SET_DEVELOPER_BROCHURE_A:
      return {
        ...state,

        developerBrochure: (action as SetDeveloperBrochureAction)
          .developerBrochure,
      };
    case SET_DEVELOPER_EMAIL_A:
      return {
        ...state,
        developerEmail: (action as SetDeveloperEmailAction).developerEmail,
      };

    case SET_DEVELOPER_COMPLETED_PROJECTS_A:
      return {
        ...state,
        developerCompletedProjects: (
          action as SetDeveloperCompletedProjectsAction
        ).developerCompletedProjects,
      };

    case SET_DEVELOPER_ONGOING_PROJECTS_A:
      return {
        ...state,
        developerOngoingProjects: (action as SetDeveloperOngoingProjectsAction)
          .developerOngoingProjects,
      };

    case SET_DEVELOPER_ESTABLISHED_YEAR_A:
      return {
        ...state,
        developerEstablishedYear: (action as SetDeveloperEstablishedYearAction)
          .developerEstablishedYear,
      };

    case SET_DEVELOPER_ADDRESS_A:
      return {
        ...state,
        developerAddress: (action as SetDeveloperAddressAction)
          .developerAddress,
      };
    case SET_DEVELOPER_NAME_A:
      return {
        ...state,
        developerName: (action as SetDeveloperNameAction).developerName,
      };
    case SET_DEVELOPER_IMAGE_A:
      return {
        ...state,
        developerImage: (action as SetDeveloperImageAction).developerImage,
      };
    case SET_DEVELOPER_DESCRIPTION_A:
      return {
        ...state,
        developerDescription: (action as SetDeveloperDescriptionAction)
          .developerDescription,
      };
    case SET_DEVELOPER_PARTNERS_A:
      return {
        ...state,
        developerPartners: (action as SetDeveloperPartnersAction)
          .developerPartners,
      };
    case SET_DEVELOPER_PROJECTS_A:
      return {
        ...state,
        developerProjects: (action as SetDeveloperProjectsAction)
          .developerProjects,
      };
    case SET_DEVELOPER_MAP_DETAILS_A:
      return {
        ...state,
        developerMapDetails: (action as SetDeveloperMapDetailsAction)
          .developerMapDetails,
      };
    case SET_PROJECT_MAP_DETAILS:
      return {
        ...state,
        projectMapDetails: (action as setprojectMapDetailsAction)
          .projectMapDetails,
      };
    case SET_PROJECT_REDEV_DEV_ID:
      return {
        ...state,
        projectRedevDevId: (action as setprojectRedevDevIdAction)
          .projectRedevDevId,
      };
    case SET_PROJECT_SALES_NUMBER_A:
      return {
        ...state,
        projectSalesNumber: (action as SetProjectSalesNumberAction)
          .projectSalesNumber,
      };
    case SET_DEVLOPER_BY_URL:
      return {
        ...state,
        DeveloperByUrl: (action as setDeveloperByUrlAction).DeveloperByUrl,
      };
    case SET_PROJECT_BY_URL:
      return {
        ...state,
        ProjectByUrl: (action as setProjectByUrlAction).ProjectByUrl,
      };
    case SET_DEVELOPER_FORMATE_ADDRESS:
      return {
        ...state,
        formatted_address: action.address,
      };
    case SET_DEVELOPER_DATA:
      return {
        ...state,
        developerData: (action as SetDeveloperDataAction).developerData,
      };
    case SET_PROJECT_ADDRESS:
      return {
        ...state,
        projectAddress: (action as SetProjectAddressAction).projectAddress,
      };

    case SET_ALL_ARCHITECT:
      return {
        ...state,
        architectProjects: (action as SetAllArchitectAction).architectProjects,
      };

    case SET_ALL_BIDPROJECTS:
      return {
        ...state,
        bidProjects: (action as SetAllBidprojectAction).bidProjects,
      };

    case SET_ALL_FAVOURITEPROJECTS:
      return {
        ...state,
        favouriteProjects: (action as SetAllFavouriteprojectAction).favouriteProjects,
      };

    case SET_ALL_DRPROJECTS:
      return {
        ...state,
        drProjects: (action as SetAllDrprojectAction).drProjects,
      };


    case SET_LAWYER_PROJECTS_A:
      return {
        ...state,
        lawyerProjects: (action as SetLawyerProjectsAction).lawyerProjects,
      };

    case GET_ALL_Projects:
      return {
        ...state,
        projects: (action as GetAllProjectsAction).projects,
      };
    case SET_SEARCH_PROJECT_NAME_A:
      return {
        ...state,
        searchProjectName: (action as SetSearchProjectNameAction)
          .searchProjectName,
      };
    case GET_FLAT_DETAILS_BY_ID:
      return {
        ...state,
        getFlatDeatilsById: (action as getFlatDeatilsByIdAction)
          .getFlatDeatilsById,
      };
    case SET_SELECTED_PROJECT_INFO:
      return {
        ...state,
        selectedProjectInfo: (action as SetSelectedProjectInfo)
          .selectedProjectInfo,
      };
    case SET_SELECTED_FLOOR_DETAILS:
      return {
        ...state,
        selectedFloordetails: (action as SetSelectedFloordetailsActions)
          .selectedFloordetails,
      };
    case SET_SELECTED_FLAT_DETAILS:
      return {
        ...state,
        selectedFlat: (action as SetSelectedFlatActions).selectedFlat,
      };
    case GET_PROJECT_INFO_PROJECT_ID:
      return {
        ...state,
        projectInfo: (action as GetProjectInfoByProjectID).projectInfo,
      };
    case GET_PROJECT_ACCESS_LIST_USER_ID:
      return {
        ...state,
        projectAccessList: (action as GetProjectAccessByUserID).projectAccessList,
      };
    case GET_ALL_Transaction:
      return {
        ...state,
        transactions: (action as GetAllTransactionAction).transactions,
      };
    case GET_ALL_WINGS:
      return {
        ...state,
        wings: (action as GetAllWingsActions).wings,
      };
    case GET_ALL_FLOORS:
      return {
        ...state,
        floors: (action as GetAllFloorsAction).floors,
      };
    case GET_ALL_FLATS:
      return {
        ...state,
        flats: (action as GetAllFlatsAction).flats,
      };
    case GET_OWNER_PROPERTY_INFO:
      return {
        ...state,
        ownerProperty: (action as GetOwnerPropertyAction).ownerProperty,
      };
    case GET_PROJECT_BID_INFO:
      return {
        ...state,
        projectBid: (action as GetProjectBidAction).projectBid,
      };
    case GET_UPLOAD_URL:
      return {
        ...state,
        uploadURL: (action as GetUploadURLAction).uploadURL,
      };
    case GET_SUB_BID_INFO:
      return {
        ...state,
        subscriptionBid: (action as GetSubBidAction).subscriptionBid,
      };
    case GET_PROJECT_UPDATES:
      return {
        ...state,
        projectUpdates: (action as GetProjectUpdatesAction).projectUpdates,
      };
    case GET_OWNER_BY_ID_SUCCESS: {
      return {
        ...state,
        currentOwner: (action as GetOwnerByIdSuccessAction).payload,
      };
    }
    case SET_VASTU_PROFILE_DATA_A: {
      return {
        ...state,
        vastuProfileData: (action as SetVastuProfileDataAction).vastuProfileData,
      };
    };
    case GET_ALL_AMENITIES:
      return {
        ...state,
        allAmenities: (action as GetAllAmenitiesAction).allAmenities,
      };
    case SET_USER_PROPERTY_ROLES_A: {
      return {
        ...state,
        userPropertyRoles: (action as SetUserPropertyRolesAction).userPropertyRoles,
      };
    };
    case SET_REDIRECT_TO_404_A: {
      return {
        ...state,
        redirectTo404: (action as SetRedirectTo404Action).redirectTo404,
      };
    };
    case SET_EDIT_USER_DETAILS_A:
      return {
        ...state,
        editUserDetails: (action as SetEditUserDetailsAction)
          .editUserDetails,
      };
    case GET_ALL_MOZNI_BY_ID: {
      return {
        ...state,
        moznisbyid: (action as GetAllMozniByIdAction).payload,
      };
    }
    default:
      return state;
  }
};

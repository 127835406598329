import React, { FC, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { styled, alpha } from '@mui/material/styles';
import { useCookies } from 'react-cookie';
import {
  AppBar, Container, Toolbar, Box, Tooltip, Avatar, MenuItem, Typography, ListItemAvatar, ListItem, ListItemText, Button, useMediaQuery, Fab, Modal, TextField
} from "@mui/material";
import Menu, { MenuProps } from '@mui/material/Menu';
import { KeyboardArrowDown, Widgets, Close } from '@mui/icons-material';
import Logo from '../../../theme/Logo.png';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProjectAccessByUserID, setLoggedInUser } from '../../../redux/data/data-actions';
import { LoadingButton } from "../../../layouts/LoadingButton";
import { updateOwner } from "../../../redux/thunk/thunks";

const CustomizeMenuItem = styled(MenuItem)({
  background: '#fff',
  "&.MuiButtonBase-root:hover": {
    background: '#fff',
  },
  "&.MuiButtonBase-root": {
    background: '#fff',
  },
  "&.Mui-selected.MuiButtonBase-root:hover": {
    background: '#fff',
  },
  "&.Mui-selected.MuiButtonBase-root": {
    background: '#fff',
  },
});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 500,
  minWidth: 380,
  textAlign: 'center',
  borderRadius: '10px',
  bgcolor: 'background.paper',
  border: '2px solid #662e91 !important',
  boxShadow: 24,
  p: 2,
};
const blinkAnimation = {
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
};

const blinkingStyle = {
  ...blinkAnimation,
  color: 'yellow',
  alignItems: 'center',
  justifyContent: 'center',
  animation: 'blink 1s infinite',
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginLeft: '18px',
    minWidth: 'auto',
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
const textFont = {
  color: '#000000',
  opacity: 1,
  fontSize: '16px',
  lineHeight: 'initial',
  fontFamily: 'Montserrat-Medium',
};
const CustomContainer = styled(Container)({
  '&.MuiContainer-root': {
    padding: 0
  }
});
const textFont1 = {
  color: '#788195',
  opacity: 1,
  fontSize: '14px',
  lineHeight: 2,
  fontFamily: 'Montserrat-Regular',
};
const textFont2 = {
  color: '#788195',
  opacity: 1,
  fontSize: '14px',
  lineHeight: 2,
  fontFamily: 'Montserrat-Regular',
  marginTop: '-3%',
  marginLeft: '32%',
  textTransform: 'capitalize'
};
const fabButton = {
  color: '#606060',
  boxShadow: 'rgba(149, 157, 165, 0.2) 1px 3px 13px',
  letterSpacing: '1px',
  opacity: 1,
  fontSize: '16px',
  textTransform: 'capitalize',
  fontFamily: 'Montserrat-Regular',
  width: '120px',
  background: '#fff'
};
const feasibilityButton = {
  color: '#606060',
  boxShadow: 'rgba(149, 157, 165, 0.2) 1px 3px 13px',
  letterSpacing: '1px',
  opacity: 1,
  fontSize: '15px',
  textTransform: 'capitalize',
  fontFamily: 'Montserrat-Regular',
  width: '200px',
  background: '#fff',
  position: "relative"
};
const downloadButton = {
  color: '#606060',
  boxShadow: 'rgba(149, 157, 165, 0.2) 1px 3px 13px',
  letterSpacing: '1px',
  opacity: 1,
  fontSize: '15px',
  textTransform: 'capitalize',
  fontFamily: 'Montserrat-Regular',
  width: '250px',
  background: '#fff'
}
const cancelButton = {
  backgroundColor: "#ffffff",
  color: "gray",
  height: "30px",
  border: "1px solid gray",
  borderRadius: "3px",
  marginRight: "20px",
  "&:hover": {},
};

const submitButton = {
  backgroundColor: "#662E91",
  color: "#fff",
  height: "30px",
  borderRadius: "3px",
  fontFamily: "Montserrat-Regular",
  "&:hover": {
    backgroundColor: "#662E91",
    color: "#fff",
  },
};

const Header: FC = () => {
  const [cookie, removeCookie] = useCookies();
  const getCookie = (name:any) => {
    const value = `; ${document.cookie}`;
    const parts:any = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [cookies, setCookie] = useCookies(['token']);
  const user: any = sessionStorage.getItem('DREAMUSERAPP')|| getCookie('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);
  const role = localStorage.getItem('role');
  const token = localStorage.getItem("token");
  const [tokenState, setTokenState] = React.useState<any>(null);
  const [isOpen, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const initialValues = { email: '' };

  useEffect(() => {
    const cookieValue = getCookie('DREAMUSERAPP');
    console.log("cookieValue",cookieValue);
    
    if (cookieValue) {
    const cake=  sessionStorage.setItem('DREAMUSERAPP', cookieValue);
    console.log("cake",cake);
    }

    const tokenCookie = getCookie('token'); 
    console.log("tokenCookie",tokenCookie);
    
    if (tokenCookie) {
     const cake2 = localStorage.setItem('token', tokenCookie);
     console.log("cake2",cake2);
     
    }

    const forum = getCookie('forum');
    console.log("forum",forum);
    if(forum){
      sessionStorage.setItem('forum',forum);
    }
  }, []);

  useEffect(() => {
    if(token && !tokenState)
      setTokenState(token);
  });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = async () => {
    dispatch(getProjectAccessByUserID(null));
    dispatch(setLoggedInUser(null));
    navigate('/');
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    sessionStorage.removeItem('DREAMUSERAPP');
    localStorage.removeItem('role');
    handleCloseUserMenu();
    removeCookie('token', { path: '/', domain: '*.dreamsredeveloped.com' });
    removeCookie('token', { path: '/', domain: '.dreamsredeveloped.com' });
    removeCookie('token', { path: '/', domain: 'dreamsredeveloped.com'})
      // Clear cookies
  // document.cookie.split(";").forEach((cookie) => {
  //   const [name] = cookie.split("=");
  //   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  // });

  document.cookie.split(";").forEach((cookie) => {
    const [name] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.dreamsredeveloped.com`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=dreamsredeveloped.com`;
});
  }

  const handleOpenForum = () => {
    const token = sessionStorage.getItem('forum');
    if (token) {
      window.open('https://forum.dreamsredeveloped.com', '_blank');
      handleClose();
    } else {
      setOpen(true);
      handleClose();
    }
  }

  const editProfile = () => {
    handleCloseUserMenu();
    navigate(`/owner/owner-dashboard/${userInfo.owner.ownerId}/edit-owner`)
  }

  const redirectGoogleForm =(value: any) =>{
    window.open(value, '_blank');
  }

  const redirectNewWindow = (value: any) => {
    const token = sessionStorage.getItem('forum');
    if (token) {
      window.open(value, '_blank');
    } else {
      setOpen(true);
    }
  }

  const userSupportSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required('Required')
  });

  const isMobileView = useMediaQuery('(max-width:600px)');

  const updateEmail = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    const data = { email: values.email }
    await dispatch(updateOwner(data, params?.id));
    const token = await sessionStorage.getItem('forum');
    if (token) {
      /**Update forum token */
      await setCookie('token', token, { path: '/', domain: '*.dreamsredeveloped.com' });
      await setCookie('token', token, { path: '/', domain: '.dreamsredeveloped.com' });
      await setCookie('token', token, { path: '/', domain: 'forum.dreamsredeveloped.com' });
      await setOpen(false);
      await window.open('https://forum.dreamsredeveloped.com', '_blank');
    }
    actions.setSubmitting(true);
  }

  return (
    <>
      <AppBar sx={{ bgcolor: "white", height: "8vh" }} elevation={0}>
        <CustomContainer sx={{ p: isMobileView ? 0 : '0px 27px', height: "100%" }} maxWidth="xl">
          <Toolbar sx={{ height: "100%", display: 'flex', justifyContent: 'space-between' }} disableGutters>
            <Box sx={{ m: isMobileView ? 'auto' : 'initial', height: "100%", display: "flex", alignItems: "center" }}>
              <img
                src={Logo}
                alt="Dreams Redeveloped"
                style={{
                  height: "100%",
                  cursor: 'pointer'
                }}
                onClick={() => window.location.href = 'https://dreamsredeveloped.com'}
              />
            </Box>
            {userInfo?.owner && <Box mr={3} sx={{ flexGrow: 0 }} display='flex' alignItems='center'>
              {isMobileView ? <Box mr={3}>
                <Fab variant="extended" size="small" sx={fabButton}><a href='https://dreamsredeveloped.com/tag/offers/' target='_blank' style={{ textDecoration: 'unset', color: '#5c5c5c' }}>Offers</a><KeyboardArrowDown onClick={(e: any) => handleClick(e)} sx={{ ml: 1 }} /></Fab>
                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleOpenForum}><Widgets sx={{ color: '#5c5c5c' }} />Forum</MenuItem>
                  <MenuItem onClick={() => redirectGoogleForm('https://docs.google.com/forms/d/e/1FAIpQLSeDocLO7qDkxKUSaDAjCLBGdx8CnKM_hTkUhEsR8d4lZbJuBg/viewform')}><Widgets sx={{ color: '#5c5c5c' }} />Detailed Feasibility</MenuItem>
                  {/* <MenuItem onClick={() => redirectGoogleForm('')}><Widgets sx={{ color: '#5c5c5c' }} />Download Feasibility Form</MenuItem> */}
                </StyledMenu>
              </Box> :
                <Box mr={5} display='flex'>
                  <Fab style={{ marginRight: '10px' }} onClick={() => redirectNewWindow('https://forum.dreamsredeveloped.com/')} variant="extended" size="small" sx={fabButton}>Forum</Fab>
                  <Fab style={{ marginRight: '10px' }} onClick={() => redirectNewWindow('https://dreamsredeveloped.com/tag/offers/')} variant="extended" size="small" sx={fabButton}>Offers</Fab>
                  <Fab style={{ marginRight: '10px' }} onClick={() => redirectGoogleForm('https://docs.google.com/forms/d/e/1FAIpQLSeDocLO7qDkxKUSaDAjCLBGdx8CnKM_hTkUhEsR8d4lZbJuBg/viewform')} variant="extended" size="small" sx={feasibilityButton}>Detailed Feasibility
                    <div style={{position: "absolute", borderRadius: " 50%", width: "48px", height: "26px", backgroundColor: "red",display: "inline-flex", alignItems: "center", justifyContent: "center", top: "-8px", right:"-24px"}}>
                    <Box sx={blinkingStyle}>New</Box>
                    </div>

                  </Fab>

                  {/* <Fab onClick={() => redirectGoogleForm('')} variant="extended" size="small" sx={downloadButton}>Download Feasibility Form</Fab> */}
                </Box>
              }
              <Tooltip title="Open settings">
                <ListItem sx={{ padding: 0 }} onClick={handleOpenUserMenu}>
                  <ListItemAvatar sx={{ marginTop: isMobileView ? '0px' : '-5px' }}>
                    {userInfo?.owner?.image ? <Avatar sx={{ width: isMobileView ? '30px' : '42px', height: isMobileView ? '30px' : '42px' }} alt="img" src={userInfo?.owner?.image} /> :
                      <Avatar alt="img" src={''} sx={{ width: isMobileView ? '30px' : '42px', height: isMobileView ? '30px' : '42px' }} />}
                  </ListItemAvatar>
                  {
                    isMobileView ? null :
                      <ListItemText primary={<Typography sx={textFont}>{userInfo?.owner?.firstName ? (userInfo?.owner?.firstName + ' ' + userInfo?.owner?.lastName) : ''}</Typography>} secondary={<Typography sx={textFont1}>{userInfo?.owner?.mobile || ''}</Typography>} />
                  }
                </ListItem>
              </Tooltip>
              <Menu
                sx={{ mt: '50px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <CustomizeMenuItem sx={{ padding: 0 }}>
                  <Box px={2.5} py={1.5} pt={1}>
                    <ListItem sx={{ padding: 0 }}>
                      <ListItemAvatar sx={{ marginTop: '-5px' }}>
                        {userInfo?.owner?.image ? <Avatar sx={{ width: '43px', height: '43px' }} alt="img" src={userInfo?.owner?.image} /> :
                          <Avatar alt="img" src={''} sx={{ width: '43px', height: '43px' }} />}
                      </ListItemAvatar>
                      <ListItemText primary={<Typography sx={textFont}>{userInfo?.owner?.firstName ? (userInfo?.owner?.firstName + ' ' + userInfo?.owner?.lastName) : 'NA'}</Typography>} secondary={<Typography sx={textFont1}>{userInfo?.owner?.mobile}</Typography>} />
                    </ListItem>
                    <Typography sx={textFont2}>{role || ''}</Typography>
                    {
                      role === 'owner' &&
                      <>
                        <Button sx={{ marginTop: '20px' }} variant="outlined" fullWidth onClick={editProfile}>Edit Profile</Button><br />
                      </>
                    }
                    <Button sx={{ marginTop: '10px' }} variant="contained" fullWidth onClick={logOut}>Log Out</Button>
                  </Box>
                </CustomizeMenuItem>
              </Menu>
            </Box>}
            {
              tokenState
                ?
                null
                :
                <Button
                  sx={{ marginTop: isMobileView ? '0px' : '5px', width: '150px', marginRight: '25px' }}
                  variant="contained"
                  onClick={() => navigate('/')}
                >
                  Log In
                </Button>
            }
          </Toolbar>
        </CustomContainer>
      </AppBar>
      {isOpen && <Modal open={isOpen}>
        <Box sx={style}>
          <Box display='flex' justifyContent='space-between'>
            <Typography sx={{ fontSize: '16px', fontFamily: "Montserrat-Regular", fontWeight: 600, textAlign: 'left' }}>
              Please provide your DR email address to required the Forum registration process and access Forum.
            </Typography>
            <Close onClick={() => setOpen(false)} sx={{ cursor: 'pointer', color: 'gray' }} />
          </Box>
          <Box>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange={false}
              onSubmit={updateEmail}
              validationSchema={userSupportSchema}
            >
              {({ errors, touched, isSubmitting, setSubmitting }: any) => (
                <Form>
                  <Box my={4} alignItems='flex-start'>
                    <Typography
                      style={{
                        color: "#788195",
                        fontSize: "16px",
                        fontFamily: "Montserrat-Medium",
                        fontWeight: "unset",
                        textAlign: 'left'
                      }}
                    >
                      Email ID*
                    </Typography>
                    <Field
                      as={TextField}
                      size="small"
                      type="email"
                      name="email"
                      style={{ marginTop: "5px", width: "100%" }}
                      placeholder="Enter your email..."
                    />
                    {errors.email && touched.email ? (
                      <Typography color="error" variant="subtitle1" sx={{ textAlign: 'left', ml: 2 }}>
                        {errors.email}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box sx={{ textAlign: 'end' }}>
                    <LoadingButton
                      style={cancelButton}
                      variant="contained"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </LoadingButton>

                    <LoadingButton
                      style={submitButton}
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>}
    </>
  );
};

export default Header;
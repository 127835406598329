import {
  Box,
  Paper,
  Grid,
  Card,
  CardActions,
  CardContent, Button, Modal, TextField, Typography, useMediaQuery,
} from "@mui/material";
import drfavicon from "../../../theme/drfavicon.png";
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from "react-router-dom";
import {
  Search,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/redux-store";
import { getfavouriteByDevId, favUnFavProjectThunk } from "../../../redux/thunk/thunks";
import Style from "../Favourites/Favourites.module.css";
import { LoadingButton } from '../../../layouts/LoadingButton';
import BgImg from '../../../theme/residential.png';
import MessageIcon from '@mui/icons-material/Message';

const submitButton = {
  minWidth: "120px",
  padding: '5px 10px',
  backgroundColor: "#993399",
  color: "#fff",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#993399",
    color: "#fff",
  },
};
const cancelButton = {
  minWidth: "120px",
  padding: '5px 10px',
  backgroundColor: "#fff",
  color: "#993399",
  border: '1px solid #993399',
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#993399",
  },
};

const Favourites = () => {
  const [search, setSearch] = useState("");
  const handleSearch = (event: any) => { };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [info, setInfo] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);
  const [openModal, setOpenModel] = useState(false);

  const favouriteProjects = useSelector(
    (state: RootState) => state.DATA_REDUCER.favouriteProjects
  );

  useEffect(() => {
    if(sessionStorage.DREAMUSERAPP)
      dispatch(getfavouriteByDevId(JSON.parse(sessionStorage.DREAMUSERAPP).developer.developerId));
  }, []);

  const handelFavourite = async () => {
    setIsSubmit(true);
    const filterData = info && info.favouriteId.filter((item: any) => item !== info?.projectId);
    const data = {
      "projectId": filterData,
      "userType": "developer",
      "developerId": userInfo?.developer?.developerId
    }
    await dispatch(favUnFavProjectThunk(data));
    setIsSubmit(false);
    setOpenModel(false);
  }

  const isMobileView = useMediaQuery('(max-width:600px)');

  const style = {
    textAlign: 'center',
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobileView ? '90vw' : 400,
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: '2px solid #993399',
    boxShadow: 24,
    p: 2,
  };

  console.log('favourite projects are', favouriteProjects?.data?.project);
  
  return (
    <><script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      {isMobileView ?
        <Box>
          <Paper sx={{ mb: "20px", boxShadow: 'none', borderRadius: "0", backgroundColor: "white" }}>
            <Box className={Style["cardContainer"]} sx={{ padding: "7.5px 40px 40px 40px", justifyContent: "center" }}>
              <Grid container spacing={2}>
                {
                  favouriteProjects?.data?.project?.length
                    ?
                    favouriteProjects?.data?.project?.map(
                      (item: any, index: number) => {
                        return (
                          <Grid item xs={12} sm={4} md={3}>
                            <Box
                              sx={{
                                display: "flex", lineHeight: "2vh", fontSize: "12px", p: "0.74vh 1.62vw",
                                bgcolor: "#00DB2D", color: "white", position: "relative", top: "1.9vh", ml: "18px",
                                width: "fit-content", borderRadius: "30px", fontFamily: 'Montserrat-Regular'
                              }}
                            >
                              {item?.projectStage[0]?.replace('_', ' ')}
                            </Box>
                            <Card
                              sx={{
                                display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '10px', borderTop: '1px solid #f2f2f2', boxShadow: '2px 2px 10px #00000029'
                              }}
                            >
                              <CardContent
                                sx={{
                                  display: "flex", flexDirection: "column", padding: "10px 10px 0px 10px"
                                }}
                              >
                                <img
                                  src={drfavicon}
                                  style={{ alignSelf: "flex-end", width: "14%", height: "auto" }}
                                />

                                <div className={Style["title-ellipsis"]}>
                                  {item.projectName}
                                </div>

                                <img className={Style["imgcard"]} src={item?.mainImage || BgImg} />
                                <Box sx={{ display: "flex", flexDirection: "column", height: "fit-content" }}>
                                  <div className={Style["block-ellipsis"]}>
                                    {item.address.formatted_address}
                                  </div>
                                  <span className={Style["sub-text"]}>
                                    Total Size:
                                  </span>

                                  <Box sx={{ display: 'flex' }}>
                                    <span className={Style["sub-text"]}>
                                      No of Flats: {item.noOfFlats}
                                    </span>
                                  </Box>

                                  <Box sx={{ display: 'flex' }}>
                                    <span className={Style["sub-text"]}>
                                      No of Shops: {item.noOfShops}
                                    </span>
                                    {
                                      item.maxPotential
                                        ?
                                        <span className={Style["sub-text"]}>
                                          Max. Potential: {item.maxPotential}
                                        </span>
                                        :
                                        null
                                    }
                                  </Box>
                                </Box>
                                <span
                                  style={{
                                    textAlign: "right",
                                    color: "#F07F25",
                                    cursor: "pointer",
                                    alignSelf: "flex-end"
                                  }}
                                >
                                  <StarIcon sx={{ width: '0.8em', height: '0.8em' }} onClick={() => { setInfo(item); setOpenModel(true); }} />
                                </span>

                                {/* <span class="material-icons">star</span> */}
                              </CardContent>
                              <CardActions sx={{ display: "flex", width: "100%", padding: 0 }}>
                                <Button
                                  onClick={() =>
                                    navigate(`/project/${item.drProjectUrl}`, {
                                      state: { projectId: item.drProjectUrl },
                                    })
                                  }
                                  className={Style['card-button']}
                                >
                                  View Details
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        )
                      })
                    :
                    <span style={{ margin: "auto", color: "#788195", marginTop: '50px' }}>
                      Favourites List is Empty
                    </span>
                }
              </Grid>
            </Box>
          </Paper>
          {openModal &&
            <Modal open={openModal}>
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to remove favourite project?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {info.projectName}
                </Typography>
                <Box pt={2} display='flex' justifyContent="space-between">
                  <LoadingButton sx={cancelButton} onClick={() => { setOpenModel(false); setInfo(null) }}>No</LoadingButton>
                  <LoadingButton disabled={isSubmit} loading={isSubmit} sx={submitButton} onClick={(e) => handelFavourite()}>Yes</LoadingButton>
                </Box>
              </Box>
            </Modal>
          }
        </Box>
        :
        <Box>
          <Box style={{ marginTop: "10px", marginBottom: "12px", marginRight: "20px", height: '40px' }}>
          </Box>
          <Box
            pb={0.5}
            style={{
              backgroundColor: "#F2F3F6",
              minHeight: "90vh",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-between", padding: "20px 30px 0" }}>
              <Typography className={Style['page-title']}>
                Favourites List
              </Typography>
            </Box>
            <Paper style={{ margin: "30px 30px 20px 30px", borderRadius: "0", backgroundColor: "white" }}>
              <Box className={Style["cardContainer"]} sx={{ padding: "40px", justifyContent: "center" }}>
                <Grid container spacing={2}>
                  {favouriteProjects?.data?.project?.map(
                    (item: any, index: number) => {
                      return (
                        <Grid item xs={12} sm={4} md={3}>
                          <Box
                            sx={{
                              display: "flex", lineHeight: "2vh", fontSize: "12px", p: "0.74vh 1.62vw",
                              bgcolor: "#00DB2D", color: "white", position: "relative", top: "1.9vh", ml: "18px",
                              width: "fit-content", borderRadius: "30px", fontFamily: 'Montserrat-Regular'
                            }}
                          >
                            {item?.projectStage[0]?.replace('_', ' ')}
                          </Box>
                          <Card
                            sx={{
                              display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '10px', borderTop: '1px solid #f2f2f2', boxShadow: '2px 2px 10px #00000029'
                            }}
                          >
                            <CardContent
                              sx={{
                                display: "flex", flexDirection: "column", padding: "10px 10px 0px 10px"
                              }}
                            >
                              <img
                                src={drfavicon}
                                style={{ alignSelf: "flex-end", width: "14%", height: "auto" }}
                              />

                              <div className={Style["title-ellipsis"]}>
                                {item.projectName}
                              </div>

                              <img className={Style["imgcard"]} src={item?.mainImage || BgImg} />
                              <Box sx={{ display: "flex", flexDirection: "column", height: "fit-content" }}>
                                <div className={Style["block-ellipsis"]}>
                                  {item.address.formatted_address}
                                </div>
                                <span className={Style["sub-text"]}>
                                  Total Size:
                                </span>

                                <Box sx={{ display: 'flex' }}>
                                  <span className={Style["sub-text"]}>
                                    No of Flats: {item.noOfFlats}
                                  </span>
                                </Box>

                                <Box sx={{ display: 'flex' }}>
                                  <span className={Style["sub-text"]}>
                                    No of Shops: {item.noOfShops}
                                  </span>
                                  {
                                    item.maxPotential
                                      ?
                                      <span className={Style["sub-text"]}>
                                        Max. Potential: {item.maxPotential}
                                      </span>
                                      :
                                      null
                                  }
                                </Box>
                              </Box>
                              <span
                                style={{
                                  textAlign: "right",
                                  color: "#F07F25",
                                  cursor: "pointer",
                                  alignSelf: "flex-end"
                                }}
                              >
                                <StarIcon sx={{ width: '0.8em', height: '0.8em' }} onClick={() => { setInfo(item); setOpenModel(true); }} />
                              </span>

                              {/* <span class="material-icons">star</span> */}
                            </CardContent>
                            <CardActions sx={{ display: "flex", width: "100%", padding: 0 }}>
                              <Button
                                onClick={() =>
                                  navigate(`/project/${item.drProjectUrl}`, {
                                    state: { projectId: item.drProjectUrl },
                                  })
                                }
                                className={Style['card-button']}
                              >
                                View Details
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Box>
            </Paper>
            {openModal &&
              <Modal open={openModal}>
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to remove favourite project?
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {info.projectName}
                  </Typography>
                  <Box pt={2} display='flex' justifyContent="space-between">
                    <LoadingButton sx={cancelButton} onClick={() => { setOpenModel(false); setInfo(null) }}>No</LoadingButton>
                    <LoadingButton disabled={isSubmit} loading={isSubmit} sx={submitButton} onClick={(e) => handelFavourite()}>Yes</LoadingButton>
                  </Box>
                </Box>
              </Modal>
            }
          </Box>
        </Box>
      }
      <div
        style={{
          position: 'fixed',
          cursor: 'pointer',
          padding: '8px 8px 2px 8px',
          backgroundColor: '#662e91',
          borderRadius: '5px',
          bottom: isMobileView ? '20px' : '20px',
          right: isMobileView ? '20px' : '100px',
        }}
        onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
      >
        <MessageIcon style={{color: 'white'}} />
      </div>
    </>
  )
}
export default Favourites;
import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import SidebarLayout from "../layouts/SidebarLayout";
import BaseLayout from "../layouts/BaseLayout";
import SuspenseLoader from "../pages/SuspenseLoader/index";
import DeveloperProfile from "../pages/DeveloperProfile/DeveloperProfile";
import ArchitectProfile from "../pages/ArchitectProfile/ArchitectProfile";
import LawyerProfile from "../pages/LawyerProfile/LawyerProfile";
import SearchProject from "../pages/SearchProject/SearchProject";
import SignUP from "../pages/SignUp/SignupForm";
import AddSearchProject from "../pages/AddProject/AddSearchProject";
import FlatDetails from "../pages/FlatDetail/FlatDetails";
import AdminApproval from "../pages/AdminApproval/AdminApproval";
import ProjectApprovalPending from "../pages/AdminApproval/ProjectProperty";
import SimpleLayout from "../layouts/simpleLayout/simpleLayout";
// import Transactions from "../pages/TransactionsList/Transactions";
// import Bids from "../pages/MyBids/Bids";
// import Favourites from "../pages/Favourites/Favourites";
import SidebarLayout2 from "../layouts/SidebarLayout2";
import SidebarLayout1MI from "../layouts/SidebarLayout1MI";
import DrProjectList from "../pages/developerDashboard/DrProjects/DrProjectsList";
import EditDeveloper from '../pages/developerDashboard/EditDeveloper/EditDeveloper';
import { Favorite } from "@mui/icons-material";
import Favourites from "../pages/developerDashboard/Favourites/Favourites";
import BiddingTransactions from "../pages/developerDashboard/Transaction/BiddingTransactions";
import SubscriptionsTransactions from "../pages/developerDashboard/Transaction/SubscriptionsTransactions";
import Bids from "../pages/developerDashboard/MyBids/Bids";
import EditLawyer from "../pages/lawyerDashboard/EditLawyer/EditLawyer";
import EditArchitect from "../pages/architectDashboard/EditArchitect/EditArchitect";
import GoogleFormTransaction from "../pages/GoogleFormTransaction/GoogleFormTransaction";

const Loader = (Component: any) => (props: any) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loader(lazy(() => import("../pages/Login/LoginForm")));

const ProjectProfilePage = Loader(
  lazy(() => import("../pages/ProjectProfile"))
);

const OwnerDashboard = Loader(lazy(() => import('../pages/OwnerDashboard/OwnerDashboard')));
const OwnerProfile = Loader(lazy(() => import('../pages/OwnerDashboard/OwnerProfile')));
const VastuProfile = Loader(lazy(() => import('../pages/VastuProfile/VastuProfile')));
const DevelopersList = Loader(lazy(() => import('../pages/OwnerDashboard/DeveloperList')));
const EditOwner = Loader(lazy(() => import('../pages/OwnerDashboard/EditOwner/EditOwner')));
const EditVastu = Loader(lazy(() => import('../pages/vastuDashboard/EditVastu/EditVastu')));
const EditMozni = Loader(lazy(() => import('../pages/mozniDashboard/EditMozni/EditMozni')));
const Chat = Loader(lazy(() => import('../pages/Chat/Chat')));

// const EditDeveloper = Loader(lazy(() => import('../pages/developerDashboard/EditDeveloper/EditDeveloper')));
const SearchProjectPage = Loader(
  lazy(() => import("../pages/SearchProject/SearchProject"))
);

const ErrorPage = Loader(lazy(() => import("../layouts/ErrorPage")));
const ChangePasswordPage = Loader(
  lazy(() => import("../pages/Login/ChangePassword"))
);
// const Transactions = Loader(lazy(() => import("../pages/TransactionsList/Transactions")));

const MapTesting = Loader(lazy(() => import("../pages/MapTesting")));

const routes: any[] = [
  {
    path: "",
    // element: <SidebarLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      // {
      //   path: "/change-password",
      //   element: <ChangePasswordPage />,
      // },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
        ],
      },
    ],
  },

  {
    path: "/signup",
    // element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <SignUP />,
      },
    ],
  },

  {
    path: "/developer/:id",
    element: <SimpleLayout />,
    children: [
      {
        path: "",
        element: <DeveloperProfile />,
      },
    ],
  },

  {
    path: "/architect/:id",
    element: <SimpleLayout />,
    children: [
      {
        path: "",
        element: <ArchitectProfile />,
      },
    ],
  },

  {
    path: "/lawyer/:id",
    element: <SimpleLayout />,
    children: [
      {
        path: "",
        element: <LawyerProfile />,
      },
    ],
  },
  {
    path: "/vastu/:id",
    element: <SimpleLayout />,
    children: [
      {
        path: "",
        element: <VastuProfile />,
      },
    ],
  },
  {
    path: "/project/:projectId/:accessToken?/:role?",
    element: <SimpleLayout />,
    children: [
      { path: "", element: <ProjectProfilePage /> },

      {
        path: "map",
        element: <MapTesting />,
      },
    ],
  },
  {
    path: "/search",
    element: <SimpleLayout />,
    children: [
      { path: "", element: <ProjectProfilePage /> },

      {
        path: "searchproject/:id?",
        element: <SearchProject />,
      },
    ],
  },
  {
    path: "/addsearch",
    element: <SimpleLayout />,
    children: [
      {
        path: "addsearchproject",
        element: <AddSearchProject />,
      },
    ],
  },
  {
    path: "/flat",
    element: <SimpleLayout />,
    children: [
      {
        path: "flatdetails/:id",
        element: <FlatDetails />,
      },
    ],
  },
  {
    path: "/admin",
    element: <SimpleLayout />,
    children: [
      {
        path: "adminapproval/:id",
        element: <AdminApproval />,
      },
      {
        path: "project-approval-pending/:id",
        element: <ProjectApprovalPending />,
      },
    ],
  },
  {
    path: '/chat',
    element: <Chat />,
    children: [
      {
        path: '',
        element: <></>
      }
    ]
  },
  {
    path: '/owner',
    element: <SidebarLayout />,
    children: [
      {
        path: 'owner-dashboard/:id/:no?/:proNo?',
        element: <OwnerDashboard />
      },
      {
        path: 'owner-dashboard/:id/profile',
        element: <OwnerProfile />
      },
      {
        path: 'owner-dashboard/:id/developers',
        element: <DevelopersList />
      },
      {
        path: 'owner-dashboard/:id/edit-owner',
        element: <EditOwner />
      },
    ]
  },
  {
    path: "developer",
    element: <SidebarLayout2 />,
    children: [
      {
        path: "",
        element: <Navigate to="dr-projects" replace />,
      },
      {
        path: "dr-projects",
        element: <DrProjectList />,
      },
      {
        path: "favourites",
        element: <Favourites />,
      },
      {
        path: "mybids",
        element: <Bids />,
      },
      {
        path: 'edit-profile',
        element: <EditDeveloper />
      }
    ],
  },
  {
    path: "lawyer",
    element: <SidebarLayout1MI />,
    children: [
      {
        path: 'edit-profile',
        element: <EditLawyer />
      }
    ],
  },
  {
    path: "architect",
    element: <SidebarLayout1MI />,
    children: [
      {
        path: 'edit-profile',
        element: <EditArchitect />
      }
    ],
  },
  {
    path: "vastu",
    element: <SidebarLayout1MI />,
    children: [
      {
        path: 'edit-profile',
        element: <EditVastu />
      }
    ],
  },
  {
    path: "mozni",
    element: <SidebarLayout1MI />,
    children: [
      {
        path: 'edit-profile',
        element: <EditMozni />
      }
    ],
  },
  {
    path: "developers-transaction",
    element: <SidebarLayout2 />,
    children: [
      {
        path: "",
        element: <Navigate to="bidding" replace />,
      },
      {
        path: "bidding",
        element: <BiddingTransactions />,
      },
      {
        path: "subscriptions",
        element: <SubscriptionsTransactions />,
      },
    ],
  },

  {
    path: "/google-form-transaction",
    children: [
      {
        path: "",
        element: <GoogleFormTransaction />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage statusCode="404" />,
  },
];

export default routes;

import Card from "@mui/material/Card";
import React from "react";
import "./SignupForm.css";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import icongoogle24 from "../../theme/icongoogle24.png";
// import style from './LoginForm.module.css'


const validationSchema = yup.object({
  email: yup
  .string()
  .email("Enter a valid email")
  .matches(/^(?!.*@[^,]*,)/)
  .required("Email is required"),
password: yup
  .string()
  .required("Password is required")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  });

function SignUP() {

  const formik = useFormik({
    initialValues: {
      email: "",
      password:""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values, "values");
      //   alert(JSON.stringify(values, null, 2));
    },
  });
  return (

<div>

<form className="email" onSubmit={formik.handleSubmit}>
          <TextField
            className="emailfield"
            id="email"
            label="Email Address"
            variant="standard"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            className="passwordfield"
            id="password"
            label="Password"
            variant="standard"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        <Button className="btn-signup" variant="contained">
          Sign Up
        </Button>
        </form>
</div>



    // <div
    //   className="maindiv"
    //   style={{ backgroundImage: "url('/assets/main-bg-img.jpg')" }}
    // >
    //   <Card className="formdisplay">
    //     <h1 className="h1text">Welcome to Dreams Redeveloped</h1>
    //     <p className="ptext">
    //       Sign in to the India’s First Tech and one-stop solution Redevelopment
    //       Platform promoting Sustainable Living
    //     </p>
    //     <form onSubmit={formik.handleSubmit}>
    //       <TextField
    //         className="emailfield"
    //         id="email"
    //         label="Email Address"
    //         variant="standard"
    //         value={formik.values.email}
    //         onChange={formik.handleChange}
    //         error={formik.touched.email && Boolean(formik.errors.email)}
    //         helperText={formik.touched.email && formik.errors.email}
    //       />
    //       <TextField
    //         className="passwordfield"
    //         id="password"
    //         label="Password"
    //         variant="standard"
    //         value={formik.values.password}
    //         onChange={formik.handleChange}
    //         error={formik.touched.password && Boolean(formik.errors.password)}
    //         helperText={formik.touched.password && formik.errors.password}
    //       />
    //     <Button className="btn" variant="contained">
    //       Sign Up
    //     </Button>
    //     </form>
    //     <p className="ortext">Or</p>
    //     <div className="googlediv">
    //       <div className="googleimgdiv">
    //         <img className="googleimg" src={icongoogle24} />
    //       </div>
    //       <p className="googletext">Sign In With Google</p>
    //     </div>
    //     <div className="facebookdiv">
    //     <div className="googleimgdiv">
            
    //       <img className="facebookimg" src="/assets/icons8-facebook-30.png" />
    //       </div>
    //       <p className="facebooktext">Sign In With Facebook</p>
    //     </div>
    //     <div className="signuptextdiv">
    //     Don’t have an account?
    //     <p className="signuptext" >Sign Up</p>
    //     </div>
    //   </Card>
    // </div>
  );
}

export default SignUP;
/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, TablePagination, TextField, Typography, useMediaQuery } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Search } from '@mui/icons-material';
import "./transaction.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/redux-store";
import { getTransactionData } from "../../../redux/thunk/thunks";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import AttachFile from '../../../theme/attach-file.png'
import MessageIcon from '@mui/icons-material/Message';

const pendingBox = {
  color: '#faad14',
  background: '#fff50026',
  border: '1px solid #ffbf40',
  padding: '2px 5px',
  textAlign: 'center',
  borderRadius: '5px',
  fontSize: '12px',
  opacity: 0.8,
  maxWidth: '140px'
}
const approveBox = {
  color: '#00a339',
  background: '#00A03B1A',
  border: '1px solid #00A03BB3',
  padding: '2px 5px',
  textAlign: 'center',
  borderRadius: '5px',
  fontSize: '12px',
  opacity: 0.8,
  maxWidth: '140px'
}
const unapprovedBox = {
  color: '#b40000',
  background: '#ff19431a',
  border: '1px solid #cf0000b3',
  padding: '2px 5px',
  textAlign: 'center',
  borderRadius: '5px',
  fontSize: '12px',
  opacity: 0.8,
  maxWidth: '140px'
}

const BiddingTransactions = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("BIDDING");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { transactions }: any = useSelector((state: RootState) => state.DATA_REDUCER);

  const handleChangeCategory = (event: any) => {
    setCategory(event.target.value);
    if (event.target.value === "SUBSCRIPTION") {
      navigate('/developers-transaction/subscriptions');
    }
  };

  const handleSearch = (event: any) => { };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
    dispatch(getTransactionData(newPage + 1, rowsPerPage, category));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPageNumber(0);
    dispatch(getTransactionData(1, parseInt(event.target.value), category));
  };
  useEffect(() => {
    dispatch(getTransactionData(1, rowsPerPage, category));
  }, []);

  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <><script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      {isMobileView ?
        <Grid item container flexDirection='column' sx={{ width: '92vw', margin: '20px auto', border: '1px solid #d9d9d9' }}>
          {
            transactions && transactions?.transactions && transactions?.transactions.map((transaction: any, index: number) => (
              <Grid item container flexDirection='column'>
                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: index > 0 ? '69px 19px 19px' : '19px 19px' }}>
                  <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                    Project Name
                  </Grid>
                  <Grid item container xs='auto' sx={{ width: '29vw' }} flexDirection='column'>
                    {transaction?.project && transaction.project?.projectName}
                  </Grid>
                </Grid>
                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                  <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                    Refunds
                  </Grid>
                  <Grid item container xs='auto' sx={{ width: '29vw' }}>
                    {transaction?.amountType === "DEBIT" ? (new Intl.NumberFormat('en-US').format(transaction?.amount) + ' INR') : null}
                  </Grid>
                </Grid>
                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                  <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                    Payments
                  </Grid>
                  <Grid item container sx={{ width: '29vw' }} xs='auto'>
                    {transaction.amountType === "CREDIT" ? (new Intl.NumberFormat('en-US').format(transaction?.amount) + ' INR') : null}
                  </Grid>
                </Grid>
                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                  <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                    Date & Time
                  </Grid>
                  <Grid item container xs='auto' flexDirection='column' sx={{ width: '29vw' }}>
                    <div>{moment(new Date(transaction.createdAt)).format('L')}</div>
                    <small>{moment(new Date(transaction.createdAt)).format('LT')}</small>
                  </Grid>
                </Grid>
                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                  <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                    Transaction Number
                  </Grid>
                  <Grid item container xs='auto' sx={{ width: '29vw' }}>
                    {transaction?.transactionNumber}
                  </Grid>
                </Grid>
                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                  <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                    Status
                  </Grid>
                  <Grid item container xs='auto' sx={{ width: '29vw' }}>
                    <Box sx={transaction?.approvalStatus === 'APPROVED' ? approveBox : transaction?.approvalStatus === 'NOT_APPROVED' ? unapprovedBox : pendingBox}>{transaction?.approvalStatus?.replace(/_/g, " ")}</Box>
                  </Grid>
                </Grid>
                {/* <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                  <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                    Photo
                  </Grid>
                  <Grid item container xs='auto' sx={{ width: '29vw' }}>
                    {transaction?.imageUrl && <img src={AttachFile} alt='attach' style={{ width: '30px', marginRight: '20px' }} onClick={() => window.open(transaction?.imageUrl, '_blank')} />}
                  </Grid>
                </Grid> */}
              </Grid>
            ))
          }
        </Grid>
        :
        <>
          <Box style={{ marginTop: "10px", marginBottom: "12px", marginRight: "20px", height: '40px' }}>
          </Box>
          <Box className="transaction-wrapper">
            <Grid container className="MainContainer" spacing={2}>
              <Grid item xs={10}>
                <Typography className='page-title'>Transactions - Bidding</Typography>
              </Grid>
              <Grid item xs={2}>
                <Select
                  fullWidth
                  size="small"
                  value={category}
                  onChange={handleChangeCategory}
                  className="selectBox"
                >
                  <MenuItem className='menu-item' value={"SUBSCRIPTION"}>Subscriptions</MenuItem>
                  <MenuItem className='menu-item' value={"BIDDING"}>Bidding</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <TableContainer component={Paper} className="transaction-wrapper-table">
              <Table >
                <TableHead>
                  <TableRow>
                    <TableCell className='heading-title'>Project Name</TableCell>
                    <TableCell className='heading-title'>Refunds</TableCell>
                    <TableCell className='heading-title'>Payments</TableCell>
                    <TableCell className='heading-title'>Date & Time</TableCell>
                    <TableCell className='heading-title'>Transaction Number</TableCell>
                    <TableCell className='heading-title'>Status</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions && transactions?.transactions && transactions?.transactions.map((row: any) => {
                    return (
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, marginLeft: '10px' }}>
                        <TableCell className='cell-title' component="th" scope="row">
                          {row?.project && row.project?.projectName}
                        </TableCell>
                        <TableCell className='cell-title'>
                          {row?.amountType === "DEBIT" ? (new Intl.NumberFormat('en-US').format(row?.amount) + ' INR') : null}
                        </TableCell>
                        <TableCell className='cell-title'>{row.amountType === "CREDIT" ? (new Intl.NumberFormat('en-US').format(row?.amount) + ' INR') : null}</TableCell>
                        <TableCell className='cell-title'>
                          <div>{moment(new Date(row.createdAt)).format('L')}</div>
                          <small>{moment(new Date(row.createdAt)).format('LT')}</small>
                        </TableCell>
                        <TableCell className='cell-title'>{row?.transactionNumber}</TableCell>
                        <TableCell className='cell-title'>
                          <Box sx={row?.approvalStatus === 'APPROVED' ? approveBox : row?.approvalStatus === 'NOT_APPROVED' ? unapprovedBox : pendingBox}>{row?.approvalStatus?.replace(/_/g, " ")}</Box>
                        </TableCell>
                        <TableCell sx={{ padding: '5px', cursor: 'pointer' }}>
                          {row?.imageUrl && <img src={AttachFile} alt='attach' style={{ width: '30px', marginRight: '20px' }} onClick={() => window.open(row?.imageUrl, '_blank')} />}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={transactions && transactions?.count}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </>
      }
      <div
        style={{
          position: 'fixed',
          cursor: 'pointer',
          padding: '8px 8px 2px 8px',
          backgroundColor: '#662e91',
          borderRadius: '5px',
          bottom: isMobileView ? '20px' : '20px',
          right: isMobileView ? '20px' : '100px',
        }}
        onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
      >
        <MessageIcon style={{color: 'white'}} />
      </div>
    </>
  )
};
export default BiddingTransactions;
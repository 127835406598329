import { useContext } from 'react';
import {
  Box,
  Stack,
  Divider,
  IconButton,
  styled,
  Fab,
  useMediaQuery,
  Grid
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from '../../SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useLocation } from 'react-router-dom';
import Logo from '../../../theme/Logo.png';
import FilterListIcon from '@mui/icons-material/FilterList';

const HeaderWrapper = styled(Box)(
  ({ theme }: any) => `
        // height: ${'60px'};
        // color: ${'#6E759F'};
        // padding: ${theme.spacing(0, 2)};
        // right: 0;
        // z-index: 6;
        // background-color: ${alpha('#ffffff', 0.95)};
        // backdrop-filter: unset;
        // position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${'250px'};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const isMobileView = useMediaQuery('(max-width:600px)');
  const location = useLocation();
  const path = location.pathname;

  return (
    isMobileView
    ?
    <Grid item container justifyContent='flex-end' sx={{height: '8vh'}}>
      <Grid item container xs='auto'justifyContent='center' sx={{width: '50vw', height: '100%'}}>
        <img src={Logo} style={{ height: '100%' }}/>
      </Grid>
      <Grid item container xs='auto' justifyContent='flex-end' sx={{visibility: path === '/developer/dr-projects' ? 'visible' : 'hidden', width: '25vw'}}>
        <IconButton sx={{px: '30px', visibility: 'hidden'}}>
          <FilterListIcon />
        </IconButton>
      </Grid>
    </Grid>
    :
    <HeaderWrapper
      display="flex"
      alignItems="center"
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
      </Stack>
      <Box display="flex" alignItems="center">
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Fab size="small" onClick={toggleSidebar}>
            <IconButton color="primary" >
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Fab>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;

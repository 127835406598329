import React, { useEffect, useState } from "react";
import "./FlatDetails.css";
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import * as yup from "yup";
import { LoadingButton } from "../../layouts/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/redux-store";
import { mapProjectPropertyToOwner, getProjectPropertyByProjectId } from '../../redux/thunk/thunks';
import { useNavigate, useParams } from "react-router-dom";
import { getAllProject, setSearchProjectName } from '../../redux/data/data-actions';
import MessageIcon from '@mui/icons-material/Message';

const validationSchema = yup.object({
  Wing: yup.string().required("Wing is required"),
  FlatNumber: yup.string().required("Flat Number is required"),
  FloorNumber: yup.string().required("Floor Number Required"),
});

const FlatDetails = () => {
  const isMobileView = useMediaQuery("(max-width:480px)");
  const dispatch = useDispatch();
  const params: any = useParams();
  const navigate = useNavigate();
  const [wing, setWing] = React.useState("");
  const [floor, setFloor] = React.useState("");
  const [flat, setFlat] = React.useState("");
  const [projectId, setProjectId] = React.useState<any>([]);
  const [propertyId, setPropertyId] = React.useState<any>([]);
  const [isSubmit, setSubmit] = useState<any>(false);
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);

  const wings = useSelector((state: RootState) => state.DATA_REDUCER.wings);
  const floors = useSelector((state: RootState) => state.DATA_REDUCER.floors);
  const flats = useSelector((state: RootState) => state.DATA_REDUCER.flats);
  const ownerProperty = useSelector((state: RootState) => state.DATA_REDUCER.ownerProperty);
  const projectList: any = useSelector((state: RootState) => state.DATA_REDUCER.projectAccessList);

  const propertyMapSuccess = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.propertyMapSuccess);

  useEffect(() => {
    !wings && dispatch(getProjectPropertyByProjectId(params.id, '', '', ''));
  }, []);

  useEffect(() => {
    if (projectList && projectList.length > 0) {
      const projectId = [];
      const propertyId = [];
      for (let i = 0; i < projectList.length; i++) {
        projectId.push(projectList[i].projectId);
        if (projectList[i].property?.length > 0) {
          for (let j = 0; j < projectList[i].property.length; j++) {
            propertyId.push(projectList[i].property[j].propertyId);
          }
        }
      }
      setProjectId(projectId);
      setPropertyId(propertyId);
    }
  }, []);

  useEffect(() => {
    if (propertyMapSuccess) {
      if (projectId?.length > 0) {
        navigate(`/owner/owner-dashboard/${userInfo?.owner?.ownerId}/${0}`);
      } else { navigate(`/admin/adminapproval/${params.id}`); }
    }
  }, [propertyMapSuccess])

  const handleSubmit = async () => {
    setSubmit(true);
    projectId.push(params.id);
    propertyId.push(ownerProperty);
    const info = {
      "projectId": [params.id],
      "propertyId": [ownerProperty]
    }
    await dispatch(mapProjectPropertyToOwner(userInfo?.owner?.ownerId, info));
    setSubmit(false);
  };

  const handleChange = (event: any) => {
    setWing(event.target.value); setFloor(''); setFlat('');
    dispatch(getProjectPropertyByProjectId(params.id, event.target.value, '', ''));
  };
  const handleChangeFloor = (event: any) => {
    setFloor(event.target.value); setFlat('');
    dispatch(getProjectPropertyByProjectId(params.id, wing, event.target.value, ''));
  };
  const handleChangeFlat = (event: any) => {
    setFlat(event.target.value);
    dispatch(getProjectPropertyByProjectId(params.id, wing, floor, event.target.value));
  };

  const handleAddMoreProject = async () => {
    dispatch(setSearchProjectName(null));
    await dispatch(getAllProject(null));
    navigate(`/search/searchproject/${userInfo?.owner?.ownerId}`)
  }

  return (
    <Box display='flex' flexDirection='column' alignContent='center' flexWrap='wrap' className='mobile-view'>
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Grid container>
        <Grid item xs={12} md={3}>

        </Grid>
        <Grid item xs={12} md={6}>
          <Box display='flex' flexDirection='column' alignContent='center' flexWrap='wrap' alignItems='center'>
            <span className="Typotext-flat">Flat Details</span>
            <span className="subtext">
              Select the flat details below
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingButton onClick={() => handleAddMoreProject()}
            className="moredetail-btn"
            type="submit"
            variant="contained"
            aria-label="sign in"
          >
            Back
          </LoadingButton>
        </Grid>
      </Grid>
      <Box pt={10} display='flex' flexDirection='column' alignContent='center' flexWrap='wrap'>
        <Box pb={3}>
          <FormControl sx={{ minWidth: '27.240vw' }} size="small" fullWidth>
            <InputLabel sx={{ fontFamily: 'Montserrat-Medium', opacity: 0.7, color: '#788195' }}>Wing</InputLabel>
            <Select value={wing} label='wing' name='wing' onChange={handleChange}>
              {wings &&
                wings.map((item: any) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Box>
        <Box pb={3}>
          <FormControl sx={{ minWidth: '27.240vw' }} size="small" fullWidth>
            <InputLabel sx={{ fontFamily: 'Montserrat-Medium', opacity: 0.7, color: '#788195' }}>Floor Number</InputLabel>
            <Select value={floor} label='Floor Number' name='floor' onChange={handleChangeFloor}>
              {floors &&
                floors.map((item: any, i: number) => {
                  return (<MenuItem value={item}>{item}</MenuItem>);
                })}
            </Select>
          </FormControl>
        </Box>
        <Box pb={3}>
          <FormControl sx={{ minWidth: '27.240vw' }} size="small" fullWidth>
            <InputLabel sx={{ fontFamily: 'Montserrat-Medium', opacity: 0.7, color: '#788195' }}>Flat Number</InputLabel>
            <Select value={flat} label='Flat Number' name='flat' onChange={handleChangeFlat}>
              {flats &&
                flats.map((item: any) => {
                  return (<MenuItem value={item}>{item}</MenuItem>);
                })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <LoadingButton onClick={() => handleSubmit()}
            className="form-btn-flat"
            disabled={isSubmit} loading={isSubmit}
            variant="contained"
            aria-label="sign in"
          >
            Next
          </LoadingButton>
        </Box>
      </Box>
      <div
        style={{
          position: 'fixed',
          cursor: 'pointer',
          padding: '8px 8px 2px 8px',
          backgroundColor: '#662e91',
          borderRadius: '5px',
          bottom: isMobileView ? '20px' : '20px',
          right: isMobileView ? '20px' : '100px',
        }}
        onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
      >
        <MessageIcon style={{color: 'white'}} />
      </div>
    </Box>
  );
};

export default FlatDetails;

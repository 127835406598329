import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { alpha, lighten, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../SidebarLayout/Header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SimpleLayout: FC<SidebarLayoutProps> = ({children}) => {
  const isMobileView = useMediaQuery('(max-width:600px)');

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? alpha('#ffffff', 0.02)
                : alpha('#ffffff', 0.5),
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten('#5569ff', 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  '#223354',
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  '#223354',
                  0.05
                )}`
          }
        }}
      >
        {<Header />}
        <Box
          sx={{
            mt: '10vh',
            width: '100vw',
            height: isMobileView ? '100vh' : '82.3vh',
            px: isMobileView ? '5vw' : 'initial'
          }}
        >
          {children || <Outlet /> }
        </Box>
      </Box>
    </>
  );
};

export default SimpleLayout;

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { RootState } from "../../redux/redux-store";
import { getDveloper } from "../../redux/thunk/developer-thunk";
import { setDeveloperCompanyLogo } from "../../redux/data/data-actions";
import getPublicURL from "../../utils/getPublicURL";

const BannerContent = () => {
  const isMobileView = useMediaQuery("(max-width:480px)");
  const dispatch = useDispatch();

  const developerdata = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerdata
  );

  const completedProjects = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerCompletedProjects
  );

  const ongoingProjects = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerOngoingProjects
  );

  const establishedYear = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerEstablishedYear
  );

  const [developerCompanyLogoState, setDeveloperCompanyLogoState] = useState('');

  const developerCompanyLogo = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerCompanyLogo
  );

  const developerName = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerName
  );

  const developerDescription = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerDescription
  )

  useEffect(() => {
    async function getProjectCount() {
      await dispatch(getDveloper());
    }

    getProjectCount();
  }, []);

  console.log('ongoing projects are', ongoingProjects);

  console.log('logo and name is', developerCompanyLogo, developerName);
  return isMobileView
  ?
  (
    <Grid container
      direction="column"
      justifyContent="space-evenly"
      alignItems={isMobileView ? "center" : "flex-start"}
      gap="19px"
      sx={{
        width: isMobileView ? "100%" : "63.5%",
        height: "100%",
        paddingRight: isMobileView ? 0 : "30px",
        paddingTop: isMobileView ? "30px" : 0,
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0.9) 85%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 95%)",
      }}
    >
      <Grid
        item
        sx={{ display: isMobileView ? 'none' : 'initial', height: "calc(0.3546 * 44.66%)", paddingLeft: isMobileView ? 0 : "21%" }}
      >
        {developerCompanyLogo && <img
          src={developerCompanyLogo}
          alt="Logo"
          style={{ width: isMobileView ? "20.23vw" : "auto", height: "100%" }}
        />}
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Typography
          variant="h2"
          className="grid-2"
          sx={{
            // textAlign: "center",
            fontFamily: 'Montserrat-Medium',
            fontWeight: "bold",
            fontSize: isMobileView ? "30px" : "4.5vh",
            opacity: 0.8,
            lineHeight: "6.85vh",
            width: '85%'
          }}
        >
          {developerName}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          className="headText"
          variant="h5"
          style={{
            fontFamily: 'Montserrat-Medium',
            width: isMobileView ? "100%" : "85%",
            fontWeight: "normal",
            fontSize: isMobileView ? "17px" : "2.2vh",
            lineHeight: isMobileView ? "28px" : "3.5vh",
            color: isMobileView ? "#878787" : "black",
            opacity: 0.8
          }}
        >
          {developerDescription}
        </Typography>
      </Grid>
      <Grid item alignSelf="flex-start" className="countDiv">
        <Typography
          variant="h5"
          sx={{
            color: isMobileView ? "#878787" : "black",
            fontWeight: "normal",
            fontSize: isMobileView ? "1.8vh" : "2.59vh",
            lineHeight: isMobileView ? "3.65vh" : "3.8vh",
            width: '100%',
          }}
        >
          <Box className="home-icon-div" sx={{ margin: isMobileView ? 'auto' : 'initial', width: isMobileView ? '60%' : '80%' }}>
            <Box className="home-icon" sx={{ width: isMobileView ? '100%' : '33.32%' }}>
              <Box className="home-name">Projects Completed</Box>
              <Box className="home-number">{completedProjects || 0}</Box>
            </Box>
            <Box className="home-icon" sx={{ width: isMobileView ? '100%' : '33.32%' }}>
              <Box className="home-name">Ongoing Projects</Box>
              <Box className="home-number">{ongoingProjects || 0}</Box>
            </Box>
            <Box className="home-icon" sx={{ width: isMobileView ? '100%' : '33.32%' }}>
              <Box className="home-name">Established since</Box>
              <Box className="home-number">{establishedYear || 0}</Box>
            </Box>
          </Box>
        </Typography>
      </Grid>
    </Grid>
  )
  
  :

  (
    <Grid container
      direction="column"
      justifyContent="space-evenly"
      alignItems={isMobileView ? "center" : "flex-start"}
      gap="19px"
      sx={{
        width: isMobileView ? "100%" : "63.5%",
        height: "100%",
        paddingRight: isMobileView ? 0 : "30px",
        paddingTop: isMobileView ? "30px" : 0,
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0.9) 85%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 95%)",
      }}
    >
      <Grid
        item
        sx={{ display: isMobileView ? 'none' : 'initial', height: "calc(0.3546 * 44.66%)", paddingLeft: isMobileView ? 0 : "21%" }}
      >
        {developerCompanyLogo && <img
          src={developerCompanyLogo}
          alt="Logo"
          style={{ width: isMobileView ? "20.23vw" : "auto", height: "100%" }}
        />}
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Typography
          variant="h2"
          className="grid-2"
          sx={{
            // textAlign: "center",
            fontFamily: 'Montserrat-Bold',
            fontWeight: 400,
            fontSize: isMobileView ? "3.22vh" : "4.5vh",
            opacity: 0.8,
            lineHeight: "6.85vh",
            width: '85%'
          }}
        >
          {developerName}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          className="headText"
          variant="h5"
          style={{
            fontFamily: 'Montserrat-Medium',
            width: isMobileView ? "100%" : "85%",
            fontWeight: "normal",
            fontSize: isMobileView ? "1.8vh" : "2.2vh",
            lineHeight: isMobileView ? "3.65vh" : "3.5vh",
            color: isMobileView ? "#878787" : "black",
            opacity: 0.8
          }}
        >
          {developerDescription}
        </Typography>
      </Grid>
      <Grid item alignSelf="flex-start" className="countDiv">
        <Typography
          variant="h5"
          sx={{
            color: isMobileView ? "#878787" : "black",
            fontWeight: "normal",
            fontSize: isMobileView ? "1.8vh" : "2.59vh",
            lineHeight: isMobileView ? "3.65vh" : "3.8vh",
            width: '100%',
          }}
        >
          {isMobileView ? <br /> : null}
          <Box className="home-icon-div" sx={{ margin: isMobileView ? 'auto' : 'initial', width: isMobileView ? '60%' : '80%' }}>
            <Box className="home-icon" sx={{ width: isMobileView ? '100%' : '33.32%' }}>
              <Box className="home-name">Projects Completed</Box>
              <Box className="home-number">{completedProjects || 0}</Box>
            </Box>
            <Box className="home-icon" sx={{ width: isMobileView ? '100%' : '33.32%' }}>
              <Box className="home-name">Ongoing Projects</Box>
              <Box className="home-number">{ongoingProjects || 0}</Box>
            </Box>
            <Box className="home-icon" sx={{ width: isMobileView ? '100%' : '33.32%' }}>
              <Box className="home-name">Established since</Box>
              <Box className="home-number">{establishedYear || 0}</Box>
            </Box>
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BannerContent;

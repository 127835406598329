import { useState, useEffect } from 'react';
import { Grid, useMediaQuery } from "@mui/material";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/redux-store';
import BannerContent from "./BannerContent";
import MaskGroup76 from "../../theme/MaskGroup76.png";
import defaultProjectMainImage from '../../theme/residential.png';
import "./Banner.css";

//font sizes and weights pairs on project page
// 60/74 bold(700), 40/49 medium(500),28/41 normal(400), 22/41 normal(400), 30/37 medium(500), 60/74 medium(500), 50/61 medium(500), 26/32 medium(500), 28/36 medium(500)
export default function Banner() {

  const [developerImageState, setDeveloperImageState] = useState('');

  const developerImage = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerImage
  );

  const isMobileView = useMediaQuery("(max-width:600px)");
  return (
    <>
    <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Grid
        container
        className="Banner-container"
        sx={{
          backgroundImage: `url('${developerImage || defaultProjectMainImage}')`,
          backgroundPosition: isMobileView ? '0% 0%' : 'right',
          backgroundSize: isMobileView ? "cover" : "48.83% 100%",
          height: isMobileView ? "374px" : "80.93vh",
          width: '100%'
        }}
      >
        {isMobileView ? null : <BannerContent />}
      </Grid>
      {isMobileView ? <BannerContent /> : null}
    </>
  );
}

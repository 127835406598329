import MuiAlert from '@mui/material/Alert';
import React, { useState } from 'react';
import { RootState } from '../redux/redux-store';
import { Snackbar } from '@mui/material';
import { snackbarAlertAction } from '../redux/display/display-actions';
import { useDispatch, useSelector } from 'react-redux';

export const Alert = () => {
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useDispatch();

    const message = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage
    );
    const severity = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertSeverity
    );

    const clearMessage = () => {
        dispatch(snackbarAlertAction('', 'info'));
    };
    const handleClose = () => {
        setTimeout(() => clearMessage(), 200);
        setOpen(false);
    };

    React.useEffect(() => {
        if (message && message.length > 0) {
            setOpen(true);
        }
    }, [message]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={10000}
            onClose={handleClose}
            open={open}
        >
            <MuiAlert elevation={6} severity={severity} variant="filled">
                {message}
            </MuiAlert>
        </Snackbar>
    );
};
import { setLoggedInUser, getProjectAccessByUserID, setForumToken, setChatToken } from "./../data/data-actions";
import { NABHThunkDispatch, RootState } from "../redux-store";
import { _getHeaders, updateToken } from "../api-headers";
import {
  setErrorAction,
  setSuccessAction,
  snackbarAlertAction,
} from "../display/display-actions";
import { url } from "../url";

var axios = require("axios");

export const verifyMobile =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config = {
        method: "post",
        url: `${url}/users/login`,
        headers: _getHeaders({ "Content-Type": "application/json" }),
        data,
      };
      axios(config)
        .then((response: any) => {
          console.log('response is 928', response);
          dispatch(getProjectAccessByUserID(null));
          dispatch(setLoggedInUser(data));
          dispatch(setSuccessAction('getProjectAccess', false));
          if(response.data.data.message === 'Service temporarily unavailable, Please try via email.')
            dispatch(snackbarAlertAction(`${response?.data?.data?.message}`, "error"));
          else {
            dispatch(setSuccessAction("signupSuccess", true));
            dispatch(snackbarAlertAction(response.data.data.message, "success"));
          }
        })
        .catch((e: any) => {
          dispatch(snackbarAlertAction(`${e.response?.data?.message}`, "error"));
          dispatch(setErrorAction("loginError", "Failed to verify"));
        });
    };

export const verifyEmail =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config = {
        method: "post",
        url: `${url}/users/emailLogin`,
        headers: _getHeaders({ "Content-Type": "application/json" }),
        data,
      };
      axios(config)
        .then((response: any) => {
          dispatch(getProjectAccessByUserID(null));
          dispatch(setLoggedInUser(data));
          dispatch(setSuccessAction("signupSuccess", true));
          dispatch(snackbarAlertAction(`OTP sent successfully`, "success"));
        })
        .catch((e: any) => {
          dispatch(snackbarAlertAction(`${e.response?.data?.message}`, "error"));
          dispatch(setErrorAction("loginError", "Failed to verify"));
        });
    };

export const resendOTPThunk = (mobile: any) => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
  var config = {
    method: "get",
    url: `${url}/users/resendotp/${mobile}`,
    headers: _getHeaders({ "Content-Type": "application/json" }),
  };
  dispatch(setLoggedInUser(null));
  const data: any = await axios(config)
    .then((response: any) => {
      dispatch(setLoggedInUser({ mobile: mobile }));
      dispatch(setSuccessAction("signupSuccess", true));
      dispatch(snackbarAlertAction(`OTP sent successfully`, "success"));
    })
    .catch((e: any) => {
      dispatch(snackbarAlertAction(`${e?.response?.data?.message}`, "error"));
      dispatch(setErrorAction("loginError", "Failed to verify"));
    });
};

export const verifyOTP = (data: any) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    await dispatch(setSuccessAction("signupSuccess", false));
    var config = {
      method: "get",
      url: `${url}/users/verifyotpinlogin/${data.mobile}/${data.otp}`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
    };
    await axios(config)
      .then(async (response: any) => {
        if (response.data?.data) {
          await dispatch(setChatToken(response.data?.data?.tokenData?.token));
          await updateToken(response.data?.data?.tokenData?.token);
          await dispatch(setLoggedInUser(response.data?.data?.data));
          await dispatch(setForumToken(response.data?.data?.forumToken));
          await sessionStorage.setItem("DREAMUSERAPP", JSON.stringify(response.data?.data?.data));
          response.data?.data?.forumToken && await sessionStorage.setItem('forum', response.data?.data?.forumToken?.token);
          await dispatch(setSuccessAction("signupSuccess", true));
          return response.data?.data?.data;
        }
      })
      .catch((e: any) => {
        dispatch(snackbarAlertAction(`${e?.response?.data?.message}`, "error"));
        dispatch(setErrorAction("loginError", "Failed to verify otp"));
      });
  };

export const logoutThunk =
  () => async (dispatch: NABHThunkDispatch, getState: () => RootState) => { };

export const forgotPasswordThunk =
  (email: string) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => { };

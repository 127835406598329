import { _getHeaders } from "../api-headers";
import { getAllArchitect, getArchitects, getLawyers, getVastuShastra, setEditUserDetails } from "../data/data-actions";
import { setLoadingAction, setSuccessAction, snackbarAlertAction } from "../display/display-actions";
import { NABHThunkDispatch, RootState } from "../redux-store";
import { url } from "../url";
var axios = require("axios");

export const getArchitectByUrl =
  (data: any) => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    // dispatch(setLoadingAction('getDataLoading', true));
    var config = {
      method: "get",
      url: `${url}/architect/getByUrl/${data}`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
    };
    await axios(config)
      .then(async (response: any) => {
        dispatch(getAllArchitect(response.data));
        dispatch(setLoadingAction('getDataLoading', false));
      })
      .catch((e: any) => {
        dispatch(setLoadingAction('getDataLoading', false));
      });
  };

/**Get all architect */
export const getArchitect = () => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
  var config = {
    method: "get",
    url: `${url}/architect/visibleArchitects`,
    headers: _getHeaders({ "Content-Type": "application/json" }),
  };
  axios(config)
    .then(async (response: any) => {
      dispatch(getArchitects(response.data?.data));
    })
    .catch((e: any) => {
    });
};

/**Get all Lawyer */
export const getLawyersThunk = () => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
  var config = {
    method: "get",
    url: `${url}/lawyer`,
    headers: _getHeaders({ "Content-Type": "application/json" }),
  };
  axios(config)
    .then(async (response: any) => {
      dispatch(getLawyers(response.data?.data?.allLawyer));
    })
    .catch((e: any) => {
    });
};

/**Get all Vastu Shastra */
export const getVastuShastraThunk = () => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
  var config = {
    method: "get",
    url: `${url}/vastu`,
    headers: _getHeaders({ "Content-Type": "application/json" }),
  };
  axios(config)
    .then(async (response: any) => {
      dispatch(getVastuShastra(response.data?.data?.allVastu));
    })
    .catch((e: any) => {
    });
};

export const editArchitect =
  (id: string, data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      dispatch(setLoadingAction("editUserLoading", true));

      var config = {
        method: "put",
        url: `${url}/architect/${id}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
        }),
        data,
      };
      axios(config)
        .then((response: any) => {
          dispatch(setLoadingAction("editUserLoading", false));
          dispatch(snackbarAlertAction(`Architect updated successfully`, "success"));
          if (response)
            dispatch(setSuccessAction("editUserSuccess", true));
        })
        .catch((error: any) => {
          dispatch(setLoadingAction("editUserLoading", false));
          dispatch(snackbarAlertAction(`Error: ${error?.response?.data.message}`, "error"));
        });
    };


export const getArchitectById =
  (data: any) =>
  
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    console.log(data)
    var config = {
      method: "get",
      url: `${url}/architect/${data.id}`,
      headers: _getHeaders({
        "Content-Type": "application/json",
        accept: "*/*",
      }),
    };

    axios(config)
      .then(async (response: any) => {
        const architect = response.data.data;
        
        dispatch(setEditUserDetails(architect));

        async function modifyDeveloperProjectDetailsetEditUserDetails(projects: any) {
          for (let i = 0; i < projects.length; i++)
            projects[i].projectImages = projects[i].projectImages;

          return projects;
        }

        async function modifyDeveloperPartnerDetails(partners: any) {
          for (let i = 0; i < partners.length; i++)
            partners[i].images = partners[i].images;

          return partners;
        }
      })
      .catch((e: any) => {
        console.log("error is", e);
      });
  };
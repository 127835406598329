import {
    Box,
    Paper,
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    ButtonGroup,
    TextField,
    Modal,
    InputAdornment,
    OutlinedInput,
    Typography,
    Select,
    MenuItem,
    InputBase,
    IconButton,
    FormControl,
    InputLabel,
    Checkbox,
    useMediaQuery,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
  } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "../../layouts/LoadingButton";
import { createOrder } from "../../redux/thunk/bidprojects-thunks";
import { capturePaymentStatus, createOrders } from "../../redux/thunk/transaction-thunks";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useRazorpay from "react-razorpay";


const submitButton = {
    minWidth: "120px",
    padding: "5px 10px",
    backgroundColor: "#993399",
    color: "#fff",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#993399",
      color: "#fff",
    },
  };
  const cancelButton = {
    minWidth: "120px",
    padding: "5px 10px",
    backgroundColor: "#fff",
    color: "#993399",
    border: "1px solid #993399",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#993399",
    },
  };

const GoogleFormTransaction = () =>{
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [openModal1, setOpenModel1] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");
  // const [success1, setSuccess] = useState(false);
  const getCookie = (name:any) => {
    const value = `; ${document.cookie}`;
    const parts:any = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
  };

  useEffect(() => {
    const cookieValue = getCookie('DREAMUSERAPP');
    console.log("cookieValue",cookieValue);
    
    if (cookieValue) {
    const cake=  sessionStorage.setItem('DREAMUSERAPP', cookieValue);
    console.log("cake",cake);
    }

    const tokenCookie = getCookie('token'); 
    console.log("tokenCookie",tokenCookie);
    
    if (tokenCookie) {
     const cake2 = localStorage.setItem('token', tokenCookie);
     console.log("cake2",cake2);
     
    }
  }, []);
  const user: any = sessionStorage.getItem("DREAMUSERAPP") || getCookie('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);
  const ownerId = userInfo?.owner?.ownerId;
    const style = {
        textAlign: "center",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // width: isMobileView ? '90vw' : 400,
        borderRadius: "10px",
        bgcolor: "background.paper",
        border: "2px solid #993399",
        boxShadow: 24,
        p: 2,
      };

    const handlePayment = async() => {
      try {
        sessionStorage.setItem('clear', 'YES');
          setDisabled(true)
        const info : any ={}
             info.personaId = userInfo?.owner?.ownerId
             info.amountType= "CREDIT"
             info.category= "GOOGLEFORM"
             info.persona= "owner"
             info.projectId= null
             info.amount= 1
             info.isPaymentOnline= true
        const orderDetails:any = await dispatch(createOrders(info)); // Example with 500 INR
        console.log("orderDetails",orderDetails);
        setDisabled(false);
      const options:any = {
        key: "rzp_live_kh7YrXfgjdYbk9", 
        amount: "999", // Amount in paise (50000 paise = INR 500)
        currency: "INR",
        name: "Dreams Redeveloped",
        description: "Test Transaction",
        image: "",
        order_id: orderDetails?.data?.data?.id, 
        handler: async function (response: any) {
            try{
              // setSuccess(true);
              setOpenModel1(false);
              setDisabled(false);
              const info = {
                "razorpay_payment_id": response?.razorpay_payment_id,
                "razorpay_order_id": response?.razorpay_order_id,
                "razorpay_signature": response?.razorpay_signature,
                "orderId": orderDetails?.data?.data?.id,
                "code": 200
              }
              console.log("info606",info);
              const updateOrderStatus: any = await dispatch(capturePaymentStatus(info));
              console.log("updatedStatus",updateOrderStatus);
              console.log("update",updateOrderStatus?.data);
              
              if (updateOrderStatus?.data) {
                setDialogTitle("Payment Successful");
                setDialogMessage("Payment Successful! Sending Feasibility Report to your email");
                setRedirectPath(`/owner/owner-dashboard/${ownerId}`);
                setOpenSuccessDialog(true);
                setTimeout(() => navigate(`/owner/owner-dashboard/${ownerId}`), 3000);
              } 
            }catch (err) {
                setDialogTitle("Payment Failure");
                setDialogMessage("Payment Failed! Please try again.");
                setRedirectPath(`/google-form-transaction`);
                setOpenSuccessDialog(true);
                setTimeout(() => navigate(`/google-form-transaction`), 3000);
              } 
              // console.log("response is,",updateOrderStatus?.data?.data);
              // console.log("updateorderstatus",updateOrderStatus);
            //   setSuccess(true);
            //   if(updateOrderStatus?.data?.data?.code)
            //     navigate(`/owner/owner-dashboard/${ownerId}`);
            // } catch (err) {
            //   console.log('error is', err);
            // }
            
        },
        modal: {
          confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
          // This function is executed when checkout modal is closed
          // There can be 3 reasons when this modal is closed.
          ondismiss: async (reason:any) => {
            console.log('in ondismiss', reason);
            // When modal is auto closed because of time out
            if (reason === 'timeout') {
              console.log('in timeout');
              sessionStorage.removeItem('clear');
              // router.push('/');
              navigate('/');
            } else {
              const info = {
                "orderId": orderDetails?.data?.data?.id,
                "code": 400
              }
              console.log("modelinfo",info);
              
              const updateOrderStatus: any = await dispatch(capturePaymentStatus(info));
              setDialogTitle("Payment Failure");
              setDialogMessage("Payment Failed! Please try again.");
              setRedirectPath(`/google-form-transaction`);
              setOpenSuccessDialog(true);
              setTimeout(() => navigate(`/google-form-transaction`), 5000);
              // const updateOrderStatus = await triggerCheck(info);
              console.log("9899",updateOrderStatus);
             
            }
          },
        },
        prefill: {
          name: userInfo?.developer?.name,
          email: userInfo?.emailId,
          contact: userInfo?.mobile
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#662e91",
        },
        timeout: 300,
      };
    
      const paymentObject = new Razorpay(options);
      paymentObject.open();
    }catch (error) {
      console.error('Error during payment:', error);
      setDialogTitle("Payment Failure");
      setDialogMessage("Payment Failed! Please try again.");
      setRedirectPath(`/google-form-transaction`);
      setOpenSuccessDialog(true);
      setTimeout(() => navigate(`/google-form-transaction`), 3000);
  }
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
    navigate(redirectPath);
  };

    return(
        <div>
           {openModal1 && (
          <Modal open={openModal1}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontSize: "18px" }}
              >
                Are you sure you want to submit this google form?{" "}
              {/*todo: change font size: 18px*/}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, fontSize: "22px" }}
              >
                {/* {info?.projectName} */}
                {/*todo: change font size: 22px*/}
              </Typography>
              <Box pt={2} display="flex" justifyContent="space-between">
                <LoadingButton
                  sx={cancelButton}
                  onClick={() => {
                    setOpenModel1(false);
                    navigate(`/owner/owner-dashboard/${ownerId}`);
                  }}
                >
                  No
                </LoadingButton>
                <LoadingButton
                  disabled={isDisabled}
                  loading={isDisabled}
                  sx={submitButton}
                  // onClick={(e) => {
                  //   handleReturnBidDailog();
                  // }}
                  onClick={() => handlePayment()}
                >
                  Yes
                </LoadingButton>
              </Box>
            </Box>
          </Modal>
        )}
  {openSuccessDialog && (
    <Dialog
        open={openSuccessDialog}
        onClose={handleCloseSuccessDialog}
        sx={{borderColor: "red"}}
      >
        <DialogTitle sx={{fontWeight: "bold", color: dialogTitle=== "Payment Successful" ? "green": "red"}}>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontSize: "15px"}}>
            {dialogMessage}
            {/* Payment Successful! sending Feasibility Report to your email. */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} sx={submitButton}>OK</Button>
        </DialogActions>
      </Dialog>
  )}
        </div>
    )
}
export default GoogleFormTransaction;
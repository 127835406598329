import axios, { AxiosRequestConfig } from 'axios';

import {
  SetDeveloperData,
  setDeveloper,
  setDeveloperProject,
} from "./../data/data-actions";
import { NABHThunkDispatch, RootState } from "../redux-store";
import { _getHeaders } from "../api-headers";
import {
  setErrorAction,
  setLoadingAction,
  setSuccessAction,
  snackbarAlertAction,
} from "../display/display-actions";
import { url } from "../url";

export const verifyDeveloper =
  (id: string, data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config: AxiosRequestConfig = {
        method: "put",
        url: `${url}/developer/${id}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
        }),
        data,
      };
      axios(config)
        .then((response: any) => {
          dispatch(
            setSuccessAction("developerVerificationSuccess", true)
          );
          dispatch(setSuccessAction("editDeveloperSuccess", true));
        })
        .catch((error: any) => {
          console.log("error while editing developer is", error);
          dispatch(
            setLoadingAction("editDeveloperLoading", false)
          );
          // dispatch(
          //   setErrorAction(
          //     "editDeveloperError",
          //     "Failed to edit developer details"
          //   )
          // );
          dispatch(snackbarAlertAction(`Error: ${error?.response?.data?.message}`, "error"));
        });
    };

export const editDeveloper =
  (id: string, data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      dispatch(setLoadingAction("editDeveloperLoading", true));

      var config: AxiosRequestConfig = {
        method: "put",
        url: `${url}/developer/${id}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
        }),
        data,
      };
      axios(config)
        .then((response: any) => {
          dispatch(
            setLoadingAction("editDeveloperLoading", false)
          );
          dispatch(setSuccessAction("editDeveloperSuccess", true));
          dispatch(snackbarAlertAction(`Developer details updated successfully`, "success"));
        })
        .catch((error: any) => {
          console.log("error while editing developer is", error);
          dispatch(
            setLoadingAction("editDeveloperLoading", false)
          );
          dispatch(snackbarAlertAction(`Error: ${error?.response?.data?.message}`, "error"));
        });
    };

export const getAllProjects =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config: AxiosRequestConfig = {
        method: "get",
        url: `${url}/project`,
        headers: _getHeaders({
          "Content-Type": "application/json",
        }),
        // data
      };
      axios(config)
        .then((response: any) => {

          // updateToken(response.tokenData.token);
          dispatch(setDeveloperProject(response.data));
          // sessionStorage.setItem("DREAM", JSON.stringify(response.data));
          // dispatch(setSuccessAction("logoutSuccess", false));
        })
        .catch((e: any) => {
          console.log(e, "error");

          // dispatch(snackbarAlertAction(`Error: Failed to verify otp`, "error"));
          // dispatch(setErrorAction("loginError", "Failed to verify otp"));
        });
    };

export const getDveloper =
  () =>// (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config: AxiosRequestConfig = {
        method: "get",
        url: `${url}/developer/visibleDevelopers`,
        headers: _getHeaders({
          "Content-Type": "application/json",
        }),
        // data
      };
      axios(config)
        .then((response: any) => {
          //   updateToken(response.tokenData.token);
          dispatch(setDeveloper(response.data?.data));
          //   sessionStorage.setItem("DREAM", JSON.stringify(response.data));
          //   dispatch(setSuccessAction("logoutSuccess", false));
        })
        .catch((e: any) => {
          console.log(e, "error");

          //   dispatch(snackbarAlertAction(`Error: Failed to verify otp`, "error"));
          //   dispatch(setErrorAction("loginError", "Failed to verify otp"));
        });
    };

export const getDevById =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config: AxiosRequestConfig = {
        method: "get",
        url: `${url}/developer/${data.id}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
          accept: "*/*",
        }),
      };
      axios(config)
        .then(async (response: any) => {
          await dispatch(SetDeveloperData(response.data?.data));
        })
        .catch((e: any) => {
          console.log("error is", e);
        });
    };
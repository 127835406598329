import { useRef } from "react";
import { Paper } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import "./Search.css";
import { useDispatch } from "react-redux";
import { setSearchProjectName } from "../redux/data/data-actions";

interface SearchProps {
  placeholder?: string;
}

const Search = (props: SearchProps) => {
  const searchFieldRef = useRef<any>();
  const dispatch = useDispatch();

  return (
    <Paper
      component="form"
      className="Search-paper"
      elevation={0}
      sx={{
        height: "48px",
      }}
    >
      <InputBase
        onChange={(e) => dispatch(setSearchProjectName(e.target.value))}
        sx={{ ml: 1, flex: 1, fontSize: "2vh" }}
        placeholder={props.placeholder}
        inputProps={{ "aria-label": "search google maps" }}
        ref={searchFieldRef}
      />
    </Paper>
  );
};

export default Search;

import { useEffect } from "react";
import "./LawyerProfile.css";
import {
  Box, CircularProgress,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Banner from './Banner/Banner'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/redux-store";
import { useNavigate, useParams } from "react-router-dom";
import ContactInfo from "../../layouts/ContactInfo";
import { getLawyerByUrl } from "../../redux/thunk/thunks";
import BannerContentPart1 from "../ProjectProfile/BannerContentPart1";
import PartnerCard from "../DeveloperProfile/PartnerCard";
import LandmarkImage from '../../theme/LandmarkDeveloper.png';
import drfavicon from "../../theme/drfavicon.png";
import defaultProjectMainImage from '../../theme/residential.png';
import DownloadBrochure from "../../layouts/DownloadBrochure";
import NotFound from '../../theme/not-found.png';

function LawyerProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let lawyerId = useParams();

  const isMobileView = useMediaQuery("(max-width:600px)");

  const loading = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);
  const lawyerData = useSelector(
    (state: RootState) => state.DATA_REDUCER.lawyerProjects
  );

  useEffect(() => {
    dispatch(getLawyerByUrl(lawyerId.id));
  }, []);

  const dateConvert = (value: any) => {
    const date = new Date(value);
    return date ? (date.toLocaleDateString('en-us', { day: 'numeric' }) + ' ' + date.toLocaleDateString('en-us', { month: 'short' }) + ' ' + date.toLocaleDateString('en-us', { year: 'numeric' })) : 'NA'
  }

  return (
    loading ? <Box display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Box> :
      lawyerData ? <Box
        sx={{
          padding: isMobileView ? 0 : "0px 7.08vw",
          width: isMobileView ? "100%" : "initial",
        }}
      >
        <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
        <Box className="bannerdiv">
          {
            isMobileView
              ?
              <>
                <img src={lawyerData?.data?.companyLogo} style={{ height: '51px', margin: 'auto', display: 'block' }} />
                <div
                  style={{
                    fontFamily: 'Montserrat-Medium',
                    fontSize: '30px',
                    textAlign: 'center',
                    margin: '19px 0px 32px 0px',
                    lineHeight: '34px',
                    maxHeight: '68px',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {lawyerData?.data?.lawyerName}
                </div>
              </>
              :
              null
          }
          {isMobileView ? <BannerContentPart1 page="lawyer" /> : null}
          <Banner />
        </Box>

        {/* Ongoing Projects */}

        <Typography
          variant="h1"
          align={"center"}
          sx={{
            fontFamily: 'Montserrat-Medium',
            mt: isMobileView ? 0 : "21vh",
            mb: "4.8vh",
            fontSize: isMobileView ? "20px" : "30px",
            opacity: 0.8,
          }}
        >
          About Team {isMobileView ? null : 'Members'}
        </Typography>
        {
          lawyerData?.data?.member?.length
            ?
            <Box className="aboutMain">
              {lawyerData?.data?.member &&
                lawyerData?.data?.member.map((partner: any, index: number) => (
                  <PartnerCard
                    partnerPhoto={partner.images[0]}
                    partnerName={partner.name}
                    partnerDesignation={partner.designation}
                    partnerDescription={partner.description}
                    partnerNumber={index + 1}
                  />
                )
                )
              }
            </Box>
            :
            <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
        }
        <Typography
          variant="h1"
          align={"center"}
          sx={{
            mt: isMobileView ? "7.08vh" : (lawyerData?.data?.member?.length ? '21vh' : "9vh"),
            mb: isMobileView ? "5vh" : "4.8vh",
            fontSize: isMobileView ? '20px' : '30px',
            fontFamily: 'Montserrat-Medium',
            opacity: 0.8
          }}
        >
          Projects
        </Typography>
        <Box className="cardContainer">
          {
            (lawyerData?.data?.redevelopProject?.length && lawyerData.data.redevelopProject.filter((project: any) => project.drProjectUrl).length > 0) || (lawyerData?.data?.initialProject?.length && lawyerData.data.initialProject.filter((project: any) => project.drProjectUrl).length > 0)
              ?
              <Grid container spacing={6}>
                {
                  lawyerData?.data?.redevelopProject && lawyerData?.data?.redevelopProject?.length > 0 && lawyerData?.data?.redevelopProject.map((item: any) => {
                    if (item.drProjectUrl)
                      return (<Grid item xs={12} sm={6} md={4}>
                        <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                          {item.isLandmark ?
                            <img src={LandmarkImage} alt='Landmark' style={{ height: '140px', position: 'absolute', top: '15vh', right: '-1.7vw', zIndex: 1 }} />
                            : null
                          }
                          <Box
                            sx={{
                              display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                              bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                              width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                            }}
                          >
                            {item.constructionType}
                          </Box>
                          <Card
                            sx={{
                              display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                            }}
                          >
                            <CardContent
                              sx={{
                                height: "92.5%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                p: "10px 3.26vh 0",
                                position: 'relative',
                              }}
                            >
                              <img
                                src={drfavicon}
                                style={{ alignSelf: "flex-end", width: "15%", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                              />

                              <Typography
                                variant="h5"
                                className="project-grid-title-ellipsis"
                                sx={{
                                  fontWeight: 800,
                                  width: '100%',
                                  fontSize: isMobileView ? "2.15vh" : "2.22vh",
                                  mb: isMobileView ? 0 : "3.52vh",
                                  fontFamily: 'Montserrat-Medium',
                                  opacity: 0.9
                                }}
                              >
                                {item.projectName}
                              </Typography>

                              <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                              <Typography
                                className="cardText"
                                sx={{
                                  // paddingBottom: "1.25vw",
                                  fontSize: isMobileView ? "1.93vh" : "2.22vh",
                                  fontWeight: 100,
                                  mb: "30px",
                                }}
                              >
                                <div className="project-grid-block-ellipsis">
                                  {item.address?.formatted_address}
                                </div>
                              </Typography>

                              <Typography
                                sx={{
                                  fontStyle: "lighter",
                                  fontSize: isMobileView ? "1.93vh" : "15px",
                                  fontFamily: 'Montserrat-Regular',
                                  lineHeight: isMobileView ? "2.36vh" : "1.5vw",
                                  color: "#838383",
                                  opacity: 0.8,
                                  mb: '15px'
                                }}
                              >
                                Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ height: "7.5%", padding: 0 }}>
                              <Button
                                onClick={() =>
                                  navigate(`/project/${item.drProjectUrl}`, {
                                    state: { projectId: item.drProjectUrl },
                                  })
                                }
                                className="card-button"
                              >
                                View Details
                              </Button>
                            </CardActions>
                          </Card>
                        </Box>
                      </Grid>)
                  })
                }

                {
                  lawyerData?.data?.initialProject && lawyerData?.data?.initialProject?.length > 0 && lawyerData?.data?.initialProject.map((item: any) => {
                    if (item.drProjectUrl)
                      return (<Grid item xs={12} sm={6} md={4}>
                        <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                          {item.isLandmark ?
                            <img src={LandmarkImage} alt='Landmark' style={{ height: '140px', position: 'absolute', top: '15vh', right: '-1.7vw', zIndex: 1 }} />
                            : null
                          }
                          <Box
                            sx={{
                              display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                              bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                              width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                            }}
                          >
                            {item.constructionType}
                          </Box>
                          <Card
                            sx={{
                              display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                            }}
                          >
                            <CardContent
                              sx={{
                                height: "92.5%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                p: "10px 3.26vh 0",
                                position: 'relative',
                              }}
                            >
                              <img
                                src={drfavicon}
                                style={{ alignSelf: "flex-end", width: "15%", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                              />

                              <Typography
                                variant="h5"
                                className="project-grid-title-ellipsis"
                                sx={{
                                  fontWeight: 800,
                                  width: '100%',
                                  fontSize: isMobileView ? "2.15vh" : "2.22vh",
                                  mb: isMobileView ? 0 : "3.52vh",
                                  fontFamily: 'Montserrat-Medium',
                                  opacity: 0.9
                                }}
                              >
                                {item.projectName}
                              </Typography>

                              <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                              <Typography
                                className="cardText"
                                sx={{
                                  // paddingBottom: "1.25vw",
                                  fontSize: isMobileView ? "1.93vh" : "2.22vh",
                                  fontWeight: 100,
                                  mb: "30px",
                                }}
                              >
                                <div className="project-grid-block-ellipsis">
                                  {item.address?.formatted_address}
                                </div>
                              </Typography>

                              <Typography
                                sx={{
                                  fontStyle: "lighter",
                                  fontSize: isMobileView ? "1.93vh" : "15px",
                                  fontFamily: 'Montserrat-Regular',
                                  lineHeight: isMobileView ? "2.36vh" : "1.5vw",
                                  color: "#838383",
                                  opacity: 0.8,
                                  mb: '15px'
                                }}
                              >
                                Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ height: "7.5%", padding: 0 }}>
                              <Button
                                onClick={() =>
                                  navigate(`/project/${item.drProjectUrl}`, {
                                    state: { projectId: item.drProjectUrl },
                                  })
                                }
                                className="card-button"
                              >
                                View Details
                              </Button>
                            </CardActions>
                          </Card>
                        </Box>
                      </Grid>)
                  })
                }
              </Grid>
              :
              <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
          }
        </Box>

        <Typography
          variant="h1"
          align={"center"}
          sx={{
            mt: isMobileView ? "7.08vh" : ((lawyerData?.data?.redevelopProject?.length && lawyerData.data.redevelopProject.filter((project: any) => project.drProjectUrl).length > 0) || lawyerData?.data?.initialProject?.length && lawyerData.data.initialProject.filter((project: any) => project.drProjectUrl).length > 0 ? "21vh" : '9vh'),
            mb: isMobileView ? "5vh" : "4.8vh",
            fontFamily: 'Montserrat-Medium',
            fontSize: isMobileView ? "20px" : "30px",
            opacity: 0.8,
            letterSpacing: '1px'
          }}
        >
          Ongoing Projects
        </Typography>
        <Box className="cardContainer">
          {(lawyerData?.data?.redevelopProject && lawyerData?.data?.redevelopProject.length > 0 && lawyerData?.data?.redevelopProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) || (lawyerData?.data?.initialProject && lawyerData?.data?.initialProject.length > 0 && lawyerData?.data?.initialProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) ?
            <Grid container spacing={6}>
              {lawyerData?.data?.redevelopProject && lawyerData?.data?.redevelopProject?.length > 0 &&
                lawyerData?.data?.redevelopProject.map((item: any) => {
                  if (item.drProjectUrl)
                    return (item.projectStatus === 0 &&
                      <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                          {item.isLandmark ?
                            <img src={LandmarkImage} alt='Landmark' style={{ height: '140px', position: 'absolute', top: '15vh', right: '-1.7vw', zIndex: 1 }} />
                            : null
                          }
                          <Box
                            sx={{
                              display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                              bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                              width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                            }}
                          >
                            {item.constructionType}
                          </Box>
                          <Card
                            sx={{
                              display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                            }}
                          >
                            <CardContent
                              sx={{
                                height: "92.5%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                p: "10px 3.26vh 0",
                                position: 'relative',
                              }}
                            >
                              <img
                                src={drfavicon}
                                style={{ alignSelf: "flex-end", width: "15%", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                              />

                              <Typography
                                variant="h5"
                                className="project-grid-title-ellipsis"
                                sx={{
                                  fontWeight: 800,
                                  width: '100%',
                                  fontSize: isMobileView ? "2.15vh" : "2.22vh",
                                  mb: isMobileView ? 0 : "3.52vh",
                                  fontFamily: 'Montserrat-Medium',
                                  opacity: 0.9
                                }}
                              >
                                {item.projectName}
                              </Typography>

                              <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                              <Typography
                                className="cardText"
                                sx={{
                                  // paddingBottom: "1.25vw",
                                  fontSize: isMobileView ? "1.93vh" : "2.22vh",
                                  fontWeight: 100,
                                  mb: "30px",
                                }}
                              >
                                <div className="project-grid-block-ellipsis">
                                  {item.address?.formatted_address}
                                </div>
                              </Typography>

                              <Typography
                                sx={{
                                  fontStyle: "lighter",
                                  fontSize: isMobileView ? "1.93vh" : "15px",
                                  fontFamily: 'Montserrat-Regular',
                                  lineHeight: isMobileView ? "2.36vh" : "1.5vw",
                                  color: "#838383",
                                  opacity: 0.8,
                                  mb: '15px'
                                }}
                              >
                                Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ height: "7.5%", padding: 0 }}>
                              <Button
                                onClick={() =>
                                  navigate(`/project/${item.drProjectUrl}`, {
                                    state: { projectId: item.drProjectUrl },
                                  })
                                }
                                className="card-button"
                              >
                                View Details
                              </Button>
                            </CardActions>
                          </Card>
                        </Box>
                      </Grid>
                    )
                })
              }

              {lawyerData?.data?.initialProject && lawyerData?.data?.initialProject?.length > 0 &&
                lawyerData?.data?.initialProject.map((item: any) => {
                  if (item.drProjectUrl)
                    return (item.projectStatus === 0 &&
                      <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{ position: 'relative', height: "fit-content", overflow: 'visible' }}>
                          {item.isLandmark ?
                            <img src={LandmarkImage} alt='Landmark' style={{ height: '140px', position: 'absolute', top: '15vh', right: '-1.7vw', zIndex: 1 }} />
                            : null
                          }
                          <Box
                            sx={{
                              display: "flex", lineHeight: "2vh", fontSize: "15px", p: "10px 20px", opacity: 0.9, borderRadius: '3px',
                              bgcolor: "#662e91", color: "white", position: "relative", top: "1.9vh", ml: "20px", textTransform: 'capitalize',
                              width: "fit-content", fontFamily: 'Montserrat-Medium', letterSpacing: '0.5px'
                            }}
                          >
                            {item.constructionType}
                          </Box>
                          <Card
                            sx={{
                              display: "flex", flexDirection: "column", alignItems: "space-between", borderRadius: '5px', border: '1px solid #acacace0', boxShadow: '2px 2px 10px #00000029'
                            }}
                          >
                            <CardContent
                              sx={{
                                height: "92.5%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                p: "10px 3.26vh 0",
                                position: 'relative',
                              }}
                            >
                              <img
                                src={drfavicon}
                                style={{ alignSelf: "flex-end", width: "15%", height: "auto", visibility: item.developerProject ? 'hidden' : 'visible' }}
                              />

                              <Typography
                                variant="h5"
                                className="project-grid-title-ellipsis"
                                sx={{
                                  fontWeight: 800,
                                  width: '100%',
                                  fontSize: isMobileView ? "2.15vh" : "2.22vh",
                                  mb: isMobileView ? 0 : "3.52vh",
                                  fontFamily: 'Montserrat-Medium',
                                  opacity: 0.9
                                }}
                              >
                                {item.projectName}
                              </Typography>

                              <img className="imgcard" src={item.mainImage ? item.mainImage : defaultProjectMainImage} />

                              <Typography
                                className="cardText"
                                sx={{
                                  // paddingBottom: "1.25vw",
                                  fontSize: isMobileView ? "1.93vh" : "2.22vh",
                                  fontWeight: 100,
                                  mb: "30px",
                                }}
                              >
                                <div className="project-grid-block-ellipsis">
                                  {item.address?.formatted_address}
                                </div>
                              </Typography>

                              <Typography
                                sx={{
                                  fontStyle: "lighter",
                                  fontSize: isMobileView ? "1.93vh" : "15px",
                                  fontFamily: 'Montserrat-Regular',
                                  lineHeight: isMobileView ? "2.36vh" : "1.5vw",
                                  color: "#838383",
                                  opacity: 0.8,
                                  mb: '15px'
                                }}
                              >
                                Date Of Completion :&nbsp; {item.completionDate ? dateConvert(item.completionDate) : 'NA'}
                              </Typography>
                            </CardContent>
                            <CardActions sx={{ height: "7.5%", padding: 0 }}>
                              <Button
                                onClick={() =>
                                  navigate(`/project/${item.drProjectUrl}`, {
                                    state: { projectId: item.drProjectUrl },
                                  })
                                }
                                className="card-button"
                              >
                                View Details
                              </Button>
                            </CardActions>
                          </Card>
                        </Box>
                      </Grid>
                    )
                })
              }
            </Grid> :
            <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
          }
        </Box>

        {/* Our Brochure */}
        {/* <ProjectGridItem projectItem={undefined}/> */}
        <Typography
          variant="h1"
          align={"center"}
          sx={{ mt: isMobileView ? "8.05vh" : ((lawyerData?.data?.redevelopProject && lawyerData?.data?.redevelopProject.length > 0 && lawyerData?.data?.redevelopProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) || (lawyerData?.data?.initialProject && lawyerData?.data?.initialProject.length > 0 && lawyerData?.data?.initialProject.filter((project: any) => project.projectStatus === 0 && project.drProjectUrl).length > 0) ? "21vh" : '9vh'), mb: isMobileView ? '29px' : '4.8vh', fontWeight: 700, fontFamily: 'Montserrat-Medium', opacity: 0.8, fontSize: isMobileView ? '20px' : '30px' }}
        >
          Our Brochure
        </Typography>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {lawyerData?.data?.brochure ? <DownloadBrochure fileURL={lawyerData?.data?.brochure} /> :
            <Box display='flex' justifyContent='center' sx={{ width: '100%' }}><Typography sx={{ fontFamily: 'Montserrat-Medium', fontSize: '20px', opacity: 0.7 }}>NA</Typography></Box>
          }
        </Box>
        {/*Contact Us */}
        <Typography
          variant="h1"
          textAlign={"center"}
          sx={{ mt: isMobileView ? "96px" : (lawyerData?.data?.brochure ? "21vh" : '9vh'), mb: isMobileView ? '22px' : '4.8vh', fontSize: isMobileView ? '20px' : '30px', fontWeight: 700, opacity: 0.8, fontFamily: 'Montserrat-Medium' }}
        >
          Contact Us
        </Typography>
        <Box sx={{ width: '100%', height: '63.15vh', }}>
          <ContactInfo page="lawyer" />
        </Box>
      </Box> :
        <Box mt={10} display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Sorry, Page Not Found!
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 5, width: '700px', textAlign: 'center' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
            sure to check your spelling.
          </Typography>
          <img src={NotFound} alt='not found!' />
        </Box>
  );
}

export default LawyerProfile;
import { Box, Typography, useMediaQuery } from '@mui/material';
import DefaultAvatarImage from '../../theme/AvatarImage.png';
import './PartnerCard.css';
interface PartnerCardProps {
  partnerNumber : number;
  partnerPhoto ?: string;
  partnerName ?: string;
  partnerDesignation ?: string;
  partnerDescription ?: string;
}

export default function PartnerCard(props: PartnerCardProps) {

  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <Box className="card card-flex" sx={{border: props.partnerNumber > 1 ? '1px solid #f1f1f1' : 0}}>
      {
        props.partnerNumber % 2 === 0 || isMobileView
        ?
        <Box className="imgdiv">
          <img src={props.partnerPhoto ? props.partnerPhoto : DefaultAvatarImage} style={{width: isMobileView ? '28.84vw' : '23.15vh', height: isMobileView ? '31.63vw' : '23.15vh', borderRadius: isMobileView ? '3px' : '0.5vh'}} />
        </Box>
        :
        null
      }

      <div className="content">
        <span className="headname" >{props.partnerName}</span>
        <span className="designation" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{props.partnerDesignation}</span>
        <span style={{fontWeight: 'normal'}} className="partner-description">
          {props.partnerDescription}
        </span>
      </div>

      {
        props.partnerNumber % 2 !== 0 && !isMobileView
        ?
        <Box className="imgdiv">
          <img className="imglogo" src={props.partnerPhoto ? props.partnerPhoto : DefaultAvatarImage} style={{width: isMobileView ? '28.84vw' : '23.15vh', height: isMobileView ? '31.63vw' : '23.15vh', borderRadius: '0.5vh'}} />
        </Box>
        :
        null
      }
    </Box>
  );
}

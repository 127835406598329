import { Grid, useMediaQuery } from "@mui/material";
import BannerContent from "./BannerContent";
import { useEffect, useState } from "react";
import styles from "./Banner.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../../redux/redux-store";
import { getArchitectByUrl } from "../../../redux/thunk/thunks";
import defaultArchitectImage from '../../../theme/architectBg.png';

export default function Banner() {
  const [developerImageState, setDeveloperImageState] = useState("");

  const isMobileView = useMediaQuery("(max-width:480px)");

  const dispatch = useDispatch();
  let architectId = useParams();
  const architect: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.architectProjects
  );

  // useEffect(() => {
  //   dispatch(getArchitectByUrl(architectId.id));
  // }, []);
  return (
    <>
      <Grid
        container
        className={styles["Banner-container"]}
        sx={{
          backgroundImage: `url('${architect?.data?.image || defaultArchitectImage}')`,
          backgroundSize: isMobileView ? "cover" : "48.83% 100%",
          backgroundPosition: isMobileView ? 'center center' : 'right',
          height: isMobileView ? "374px" : "80.93vh",
          width: "100%",
        }}
      >
        {isMobileView ? null : <BannerContent />}
      </Grid>
      {isMobileView ? <BannerContent /> : null}
    </>
  );
}

import { useEffect, useState } from "react";
import { Box, useMediaQuery, CardContent, Card, CardMedia, Typography } from "@mui/material";
import "./ProjectItem.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProjectInfo
} from "../../redux/data/data-actions";
import { getProjectPropertyByProjectId } from '../../redux/thunk/thunks';
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/redux-store";
import Img from '../../theme/residential.png';

const ProjectItem = ({ projectInfo }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [project, setProject] = useState<any>(null);
  const isMobileView = useMediaQuery("(max-width:480px)");

  const Wings = useSelector((state: RootState) => state.DATA_REDUCER.wings);

  const onClickProject = async (project: any) => {
    setProject(project)
    await dispatch(getProjectPropertyByProjectId(project.projectId, '', '', ''));
    await dispatch(setSelectedProjectInfo(project));
  };

  useEffect(() => {
    if (Wings && Wings.length !== 0) {
      project && navigate(`/flat/flatdetails/${project?.projectId}`);
    } else if (Wings && Wings.length === 0) {
      project && navigate(`/admin/project-approval-pending/${project?.projectId}`);
    }
  }, [Wings])

  return (
    projectInfo && (
      <Card sx={{
        cursor: 'pointer', display: 'flex', padding: '0', marginTop: '20px', boxShadow: 'unset',
      }} onClick={(e) => onClickProject(projectInfo)}>
        <Box mr={3} sx={{ width: 110, height: 110 }}>
          <CardMedia
            component="img"
            sx={{ width: 110, height: 110, borderRadius: '2px' }}
            image={projectInfo && projectInfo.projectImages && projectInfo.projectImages.length > 0 ? projectInfo.projectImages[0] : Img}
            alt="Image"
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto', padding: '10px' }}>
            <Typography className='Project-name'>
              {projectInfo.projectName}
            </Typography>
            <Typography className='Project-description'>
              {projectInfo.address && projectInfo.address.formatted_address}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    )
  );
};

export default ProjectItem;

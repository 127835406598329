import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import MemberItem from './MemberItem';

interface MemberTableProps {
  members: any;
  setIsAddMemberOpen: (arg0: boolean) => void;
  setMemberToBeEdited: (arg0: string) => void;
}

const MemberTable = ({ members, setIsAddMemberOpen, setMemberToBeEdited }: MemberTableProps) => {

  return (
    <Grid item container xs={12}>
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      {
        members.length
          ?
          <Grid
            item container
            alignItems='center'
            xs={12}
            sx={{ height: '70px', pr: '1%', pl: '2%', bgcolor: '#f2f3f6', fontFamily: 'Montserrat-Medium' }}
          >
            <Grid item sx={{ width: '18.33vw', fontFamily: 'Montserrat-Medium', fontSize: "16px" }}>Name</Grid>
            <Grid item sx={{ width: '14.68vw', fontFamily: 'Montserrat-Medium', fontSize: "16px" }}>Role / Designation</Grid>
            <Grid item sx={{ width: '25.67vw', fontFamily: 'Montserrat-Medium', fontSize: "16px" }}>Description</Grid>
            <Grid item sx={{ width: '10.99vw', fontFamily: 'Montserrat-Medium', fontSize: "16px" }}>Image</Grid>
            <Grid item sx={{ width: '2.5vw', fontFamily: 'Montserrat-Medium', fontSize: "16px" }}></Grid>
          </Grid>
          :
          null
      }

      {
        members.length
          ?
          members.map((member: any, index: number) => {
            return (
              <MemberItem
                member={member}
                index={index}
                totalMembersCount={members.length}
                setMemberToBeEdited={setMemberToBeEdited}
                setIsAddMemberOpen={setIsAddMemberOpen}
              />
            )

          })
          :
          null
      }

    </Grid>
  );
}

export default MemberTable;
import { _getHeaders } from '../redux/api-headers';
import { url } from '../redux/url';
var axios = require('axios');

const uploadDeveloperFiles = async (files: any[]) => {
  var data = new FormData();
  for (let i = 0; i < files.length; i++) {
    data.append("images", files[i]);
  }

  var config = {
    method: 'post',
    url: `${url}/uploads`,
    headers: _getHeaders({ "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>" }),
    data: data
  };
  const link = await axios(config);
  return link.data.url;
}

export default uploadDeveloperFiles;
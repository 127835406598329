import { FC } from "react";
import { Box, useMediaQuery } from "@mui/material";
import './AddProjectList.css';

const ProjectList: FC = (props) => {

  const isMobileView = useMediaQuery('(max-width:480px)');

  return (
    <Box
      className='Container'
      sx={{
        // width: isMobileView ? '83.25vw' : '45vw'
        width: isMobileView ? '90vw' : '45vw'
      }}
    >
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      {props.children}
    </Box>
  );
};

export default ProjectList;

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { getArchitectByUrl } from "../../../redux/thunk/thunks";
import { useParams } from "react-router-dom";
import { RootState } from "../../../redux/redux-store";
import { useDispatch, useSelector } from "react-redux";

function BannerContent() {
  const isMobileView = useMediaQuery("(max-width:480px)");
  const dispatch = useDispatch();
  let architectId = useParams();
  const architect = useSelector(
    (state: RootState) => state.DATA_REDUCER.architectProjects
  );
  console.log("aaab111", architect);

  // useEffect(() => {
  //   dispatch(getArchitectByUrl(architectId.id));
  // }, []);
  return (
    <Grid
      item
      container
      // className="grid-main 2023"
      direction="column"
      justifyContent="space-evenly"
      alignItems={isMobileView ? "center" : "flex-start"}
      gap="19px"
      sx={{
        width: isMobileView ? "100%" : "63.5%",
        height: "100%",
        paddingRight: isMobileView ? 0 : "30px",
        paddingTop: isMobileView ? "30px" : 0,
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0.9) 85%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 95%)",
      }}
    >
      <Grid
        item
        sx={{
          display: isMobileView ? "none" : "initial",
          height: "calc(0.3546 * 44.66%)",
          paddingLeft: isMobileView ? 0 : "21%",
        }}
      >
        {architect?.data?.companyLogo && <img
          // src={isMobileView ? NirmanNameLogo : NirmanLogo}
          src={architect?.data?.companyLogo}
          alt="Logo"
          style={{ width: isMobileView ? "20.23vw" : "auto", height: "100%" }}
        />}
      </Grid>

      <Grid item sx={{ width: '100%'}}>
        <Typography
          variant="h2"
          className="grid-2"
          sx={{
            fontFamily: 'Montserrat-Bold',
            fontWeight: 400,
            fontSize: isMobileView ? "3.22vh" : "4.5vh",
            lineHeight: "6.85vh",
            opacity: 0.8,
            width: '85%'
          }}
        >
          {" "}
          {architect?.data?.architectName}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          className="headText"
          variant="h5"
          sx={{
            width: isMobileView ? "100%" : "45vw",
            fontWeight: "normal",
            fontSize: isMobileView ? "1.8vh" : "2.2vh",
            lineHeight: isMobileView ? "3.65vh" : "3.5vh",
            color: isMobileView ? "#878787" : "black",
            fontFamily: 'Montserrat-Medium',
            opacity: 0.8
          }}
        >
          {architect?.data?.description}
        </Typography>
      </Grid>
      <Grid item alignSelf="flex-start" className="countDiv">
        <Typography
          variant="h5"
          sx={{
            color: isMobileView ? "#878787" : "black",
            fontWeight: "normal",
            fontSize: isMobileView ? "1.8vh" : "2.59vh",
            lineHeight: isMobileView ? "3.65vh" : "3.8vh",
            width: "100%",
          }}
        >
          {isMobileView ? <br /> : null}
          <Box
            className="home-icon-div"
            sx={{
              margin: isMobileView ? "auto" : "initial",
              width: isMobileView ? "60%" : "80%",
            }}
          >
            {/* {
                completedProjects
                ? */}
            <Box
              className="home-icon"
              sx={{ width: isMobileView ? "100%" : "33.32%" }}
            >
              <Box className="home-name">Projects Completed</Box>
              <Box className="home-number">
                {architect?.data?.completedProjects || 0}
              </Box>
            </Box>
            {/* :
                null
              }
   */}
            {/* {
                ongoingProjects
                ? */}
            <Box
              className="home-icon"
              sx={{ width: isMobileView ? "100%" : "33.32%" }}
            >
              <Box className="home-name">Ongoing Projects</Box>
              <Box className="home-number">
                {architect?.data?.ongoingProjects || 0}
              </Box>
            </Box>
            {/* :
                null
              }
              {
                establishedYear
                ? */}
            <Box
              className="home-icon"
              sx={{ width: isMobileView ? "100%" : "33.32%" }}
            >
              <Box className="home-name">Established since</Box>
              <Box className="home-number">
                {architect?.data?.establishedYear || 0}
              </Box>
            </Box>
            {/* :
                null
              }  */}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}
export default BannerContent;

import { useRef, useState } from "react";
import {
  Autocomplete,
  IconButton,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./AddSearch.css";
import { useDispatch } from "react-redux";
import { setSearchProjectName } from "../../redux/data/data-actions";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

interface SearchProps {
  placeholder?: string;
}

const Search = (props: SearchProps) => {
  const addressRef: any = useRef();
  const addressErrorRef: any = useRef();
  const searchFieldRef = useRef<any>();
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width:480px)");
  const [location, setLocation] = useState<any>("Baner");
  const [placeId, setPlaceId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: "AIzaSyAjm7oLhCvIeol2k8kNlj62c07cgNim3XU",
    });
  function clearAddressDetails() {
    setPlaceId("");
    setLocationName("");
  }
  function handleAddressBlur() {
    if(addressErrorRef?.current?.style) {
      if (placeId && locationName) {
        addressErrorRef.current.style.visibility = "hidden";
      } else addressErrorRef.current.style.visibility = "visible";
    }
  }

  function handleAddressFocus(e: any) {
    e.target.value = "";
    clearAddressDetails();
    setAddressValue("");
  }
  function handleLocationChange(e: any) {
    if (e.target.value !== addressValue) setAddressValue(e.target.value);
    setLocation({
      label: e.target.value,
      placeId: "123",
      locationName: "tempName",
    });
    getPlacePredictions({ input: e.target.value });
  }

  function handleAddressTextfieldFocus(e: any) {
    e.target.value = "";
  }

  return (
    <Paper
      component="form"
      className="Search-paper"
      elevation={2}
      sx={{
        p: isMobileView ? 0 : '2px 4px',
        bgcolor: isMobileView ? '#f3f4f6' : 'white',
        height: isMobileView ? '5vh' : '7vh',
        cursor: 'pointer'
      }}
    >
      <IconButton disabled={isMobileView ? false : true} type="button" sx={{ bgcolor: isMobileView ? '#662e91' : 'white', height: '100%', p: isMobileView ? 'initial' : '10px', order: isMobileView ? 2 : 1, width: isMobileView ? '40px' : 'initial', borderRadius: '2px' }} aria-label="search">
        <SearchIcon sx={{ height: isMobileView ? '5vh' : '8.61vh', color: isMobileView ? 'white' : '#afafaf', }} />
      </IconButton>
      <Autocomplete
        className="abc"
        id="address"
        ref={addressRef}
        autoComplete
        openOnFocus
        freeSolo
        disableClearable
        value={location}
        disablePortal
        onChange={(e, newValue: any) => {
          setPlaceId(newValue.placeId);
          setLocationName(newValue.locationName);
          setAddressValue(newValue.label);
        }}
        onFocus={(e) => handleAddressFocus(e)}
        onBlur={handleAddressBlur}
        inputValue={addressValue}
        sx={{
          ml: 1,
          flex: 1,
          fontSize: isMobileView ? "2vh" : "2.31vh",
          order: isMobileView ? 1 : 2,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'unset !important'
          }
        }}
        options={
          placePredictions && placePredictions.length
            ? placePredictions.map((placePrediction: any) => ({
              label: placePrediction.description,
              placeId: placePrediction.place_id,
              locationName: placePrediction.structured_formatting.main_text,
            }))
            : [
              {
                label: "",
                placeId: "456",
                locationName: "temp text 2",
              },
            ]
        }
        renderInput={(params) => (
          <TextField
            className="abc"
            onFocus={handleAddressTextfieldFocus}
            hiddenLabel
            {...params}
            name="address"
            placeholder={props.placeholder}
            onChange={handleLocationChange}
          />
        )}
      />
    </Paper>
  );
};

export default Search;

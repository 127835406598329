import { NABHThunkDispatch, RootState } from "../redux-store";
import { _getHeaders, updateToken } from "../api-headers";
import { url } from "../url";
import {
  setDeveloperTelephone,
  setDeveloperCompanyLogo,
  setDeveloperEmail,
  setDeveloperCompletedProjects,
  setDeveloperOngoingProjects,
  setDeveloperEstablishedYear,
  setDeveloperAddress,
  setDeveloperName,
  setDeveloperImage,
  setDeveloperDescription,
  setDeveloperPartners,
  setDeveloperProjects,
  setDeveloperMapDetails,
  setDeveloperFormateAddress,
  setDeveloperBrochure,
  SetDeveloperData,
} from "../data/data-actions";
import { setLoadingAction } from "../display/display-actions";

var axios = require("axios");

export const getDevByUrl =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      dispatch(setLoadingAction('getDataLoading', true));
      var config = {
        method: "get",
        url: `${url}/developer/getByUrl/${data}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
          accept: "*/*",
        }),
      };
      await axios(config)
        .then(async (response: any) => {
          const developer = response.data.data;
          dispatch(setDeveloperBrochure(developer.brochure));
          dispatch(setDeveloperTelephone(developer.mobile));
          dispatch(setDeveloperCompanyLogo(developer.companyLogo));
          dispatch(setDeveloperEmail(developer.email));
          dispatch(setDeveloperCompletedProjects(developer.completedProjects));
          dispatch(setDeveloperOngoingProjects(developer.ongoingProjects));
          dispatch(setDeveloperEstablishedYear(developer.establishedYear));
          dispatch(setDeveloperAddress(developer.address));
          dispatch(setDeveloperName(developer.name));
          dispatch(setDeveloperImage(developer.image));
          dispatch(setDeveloperDescription(developer.description));
          dispatch(SetDeveloperData(developer));
          dispatch(
            setDeveloperMapDetails({
              lat: developer.address[0].geometry.location.lat,
              lng: developer.address[0].geometry.location.lng,
              place_id: developer.googlePlaceId,
            })
          );
          dispatch(setDeveloperFormateAddress(developer.address[0].formatted_address));
          async function modifyDeveloperProjectDetails(projects: any) {
            for (let i = 0; i < projects.length; i++)
              projects[i].projectImages = projects[i].projectImages;

            return projects;
          }
          dispatch(
            setDeveloperProjects([
              ...developer.initialProject,
              ...developer.redevelopProject.filter((redevelopmentProjectItem: any) => developer.initialProjectId.includes(redevelopmentProjectItem.projectId) === false
              )
            ])
          );
          async function modifyDeveloperPartnerDetails(partners: any) {
            for (let i = 0; i < partners.length; i++)
              partners[i].images = partners[i].images;
            return partners;
          }

          dispatch(
            setDeveloperPartners(
              await modifyDeveloperPartnerDetails(developer.member)
            )
          );
          dispatch(setLoadingAction('getDataLoading', false));
        })
        .catch((e: any) => {
          dispatch(setLoadingAction('getDataLoading', false));
        });
    };

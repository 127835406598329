import { FC, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import "./AddProjectItem.css";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsByName } from "../../redux/thunk/project-thunks";

interface ProjectItemProps {
  projectImage?: string;
  projectName?: string;
  projectAddress?: string;
}

const ProjectItem = (props: ProjectItemProps) => {
  const isMobileView = useMediaQuery("(max-width:480px)")

  return (
    <Box
      className="Project-item"
      sx={{
        height: isMobileView ? "auto" : "33.32%",
      }}
    >
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Box sx={{ width: isMobileView ? "30%" : "16%" }}>
        <Box
          sx={{
            height: 0,
            pb: isMobileView ? "91.86%" : "initial",
            backgroundImage: `url(${props.projectImage})`,
            backgroundSize: "cover",
          }}
        >
          <img
            src={props.projectImage}
            alt="building"
            style={{
              width: "100%",
              height: isMobileView ? "100%" : "initial",
            }}
          />
        </Box>
      </Box>
      <Box
        className="Project-text-content-container"
        sx={{ p: isMobileView ? "0px 15px" : "15px 50px" }}
      >
        <Box
          className="Project-name"
          sx={{
            marginBottom: isMobileView ? "4px" : "15px",
            lineHeight: isMobileView ? "initial" : "2.22vh",
          }}
        >
          {props.projectName}
        </Box>
        <Box
          className="Project-description"
          sx={{ fontSize: isMobileView ? "1.6vh" : "2.04vh" }}
        >
          {props.projectAddress}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectItem;

import { FC, useEffect } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Search from "./Search";
import ProjectItem from "./ProjectItem";
import styles from "./SearchProject.module.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsByName } from "../../redux/thunk/project-thunks";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/redux-store";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getAllProject, setSearchProjectName } from '../../redux/data/data-actions';
import MessageIcon from '@mui/icons-material/Message';

const SearchProject: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchProjectName = useSelector(
    (state: RootState) => state.DATA_REDUCER.searchProjectName
  );
  const projects = useSelector((state: RootState) => state.DATA_REDUCER.projects);
  const isMobileView = useMediaQuery("(max-width:480px)");
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);

  useEffect(() => {
    if (searchProjectName) dispatch(getProjectsByName(searchProjectName));
  }, [searchProjectName]);

  const handleAddMoreProject = async () => {
    dispatch(setSearchProjectName(null));
    await dispatch(getAllProject(null));
    navigate("/addsearch/addsearchproject");
  }

  return (
    <>
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Box className={styles["Main"]} style={{ marginTop: isMobileView ? 0 : "12.9vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "flex-start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: isMobileView ? "20px" : "27.5vw",
              display: "flex",
              justifyContent: isMobileView ? "flex-start" : "flex-end",
              alignItems: "center",
              cursor: 'pointer'
            }}
            onClick={() => {
              // navigate(`/owner/owner-dashboard/${ownerId}/`);
              navigate(`/owner/owner-dashboard/${userInfo?.owner?.ownerId}/${0}`);
            }}
          >
            {/* sx={{display: 'none'}} */}
            <ArrowBackIosNewIcon sx={{ fontSize: '35px' }} />
          </Box>
          <Box
            className={styles["page-title"]}
            sx={{
              p: isMobileView ? "8px" : 0,
              width: isMobileView ? "200px" : "45vw",
              textAlign: isMobileView ? "start" : "center",
              marginLeft: isMobileView ? "20px" : "initial",
            }}
          >
            <Typography sx={{ fontFamily: 'Montserrat-SemiBold', fontSize: '35px', color: '#000000', fontWeight: 'bold' }}>Projects</Typography>
          </Box>
          <Box
            sx={{
              width: "27.5vw",
            }}
          />
        </Box>

        <Box mt={2} mb={5} sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: isMobileView ? "0px" : "27.5vw" }}></Box>
          <Box
            sx={{
              width: isMobileView ? "90vw" : "45vw",
            }}
          >
            <Search
              placeholder={
                isMobileView
                  ? "Search"
                  : "Search projects by name, location...."
              }
            />
          </Box>
          <Box
            sx={{
              width: "27.5vw",
              display: isMobileView ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <Button
              color="primary"
              className={styles["Custom-button-1"]}
              onClick={() => handleAddMoreProject()}
              style={{
                height: "5.19vh",
                padding: "0px 3.13vh",
                borderRadius: "3px",
                fontSize: "1.85vh",
                minWidth: '120px'
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Box sx={{ width: isMobileView ? '90vw' : '45vw', minHeight: '10vh', overflow: 'auto' }}>
          {searchProjectName &&
            projects &&
            projects.data &&
            projects.data.length
            ? projects.data.map((project: any, index: number) => (
              <>
                <ProjectItem projectInfo={project} />
                <Divider />
              </>
            ))
            : null}
        </Box>
        {/* {isMobileView && <AddCircleIcon onClick={() => handleAddMoreProject()} className={styles["add-button"]} sx={{ fontSize: '50px' }} />} */}
      </Box>
      <div
        style={{
          position: 'fixed',
          cursor: 'pointer',
          padding: '8px 8px 2px 8px',
          backgroundColor: '#662e91',
          borderRadius: '5px',
          bottom: isMobileView ? '20px' : '20px',
          right: isMobileView ? '20px' : '100px',
        }}
        onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
      >
        <MessageIcon style={{color: 'white'}} />
      </div>
    </>
  );
};

export default SearchProject;

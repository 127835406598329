import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Grid, InputAdornment, styled } from '@mui/material';
import { LoadingButton } from '../../../layouts/LoadingButton';
import { getProjectBidTotal, getSubscriptionTotal } from "../../../redux/thunk/thunks";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';
import Bank from '../../../theme/bank.png';
import Bank1 from '../../../theme/hsbc.png';

const CustomizeTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#788195',
    },
    '& .MuiInputBase-input': {
        color: '#50495A',
        fontSize: '0.950vw',
        fontFamily: 'Montserrat-Regular',
        fontWeight: 500
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#788195',
            borderRadius: '12px',
        },
        '&:hover fieldset': {
            borderColor: '#788195',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#788195',
        },
    },
});

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: '2px solid #993399',
    boxShadow: 24,
    p: 2,
};
const submitButton = {
    minWidth: "120px",
    padding: '5px 10px',
    backgroundColor: "#993399",
    color: "#fff",
    borderRadius: "10px",
    fontFamily: 'Montserrat-Regular',
    letterSpacing: '1px',
    "&:hover": {
        backgroundColor: "#993399",
        color: "#fff",
    },
};
const cancelButton = {
    minWidth: "120px",
    padding: '5px 10px',
    backgroundColor: "#fff",
    color: "#993399",
    border: '1px solid #993399',
    borderRadius: "10px",
    fontFamily: 'Montserrat-Regular',
    letterSpacing: '1px',
    "&:hover": {
        backgroundColor: "#fff",
        color: "#993399",
    },
};

export const BiddingDialog = ({ info, handleClose, handleNext }: any) => {
    const dispatch = useDispatch();

    const projectBids: any = useSelector((state: RootState) => state.DATA_REDUCER.projectBid);
    const subscriptionBid: any = useSelector((state: RootState) => state.DATA_REDUCER.subscriptionBid);
    const projectBid: any = projectBids ? projectBids : subscriptionBid ? subscriptionBid : null;

    useEffect(() => {
        if (info) {
            dispatch(getProjectBidTotal(info?.projectId));
        } else {
            dispatch(getSubscriptionTotal());
        }
    }, [])

    return (
        <Modal open={true}>
            <Box sx={style}>
                <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: '18px', color: '#788195', fontFamily: 'Montserrat-Regular' }}>
                    {info ? 'Bidding amount for this project' : 'Extend subscription'}
                </Typography>
                <Typography sx={{ mt: 2, fontSize: '18px', fontWeight: 600, textAlign: 'center', marginBottom: '20px' }}>
                    {info?.projectName}
                </Typography>
                <Grid container spacing={2} sx={{ padding: '0 30px', height: '500px', overflow: 'auto' }}>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>{info ? 'Bidding Amount' : 'Subscription Amt.'}:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomizeTextField name='no' value={info ? projectBid?.biddingAmount?.toLocaleString('en-IN') : projectBid?.subscriptionAmount?.toLocaleString('en-IN')} size="small"
                            InputProps={{ startAdornment: <InputAdornment position="start">₹</InputAdornment> }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>GST:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomizeTextField name='no' value={projectBid?.gstAmount?.toLocaleString('en-IN')} size="small"
                            InputProps={{ startAdornment: <InputAdornment position="start">₹</InputAdornment> }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>Total:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomizeTextField name='no' value={info ? projectBid?.totalBidAmount?.toLocaleString('en-IN') : projectBid?.totalAmount?.toLocaleString('en-IN')} size="small"
                            InputProps={{ startAdornment: <InputAdornment position="start">₹</InputAdornment> }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>Please pay {info ? projectBid?.totalBidAmount?.toLocaleString('en-IN') : projectBid?.totalAmount?.toLocaleString('en-IN')} INR to the following bank</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {projectBid?.bankDetails?.bankName === 'ICICI Bank' ? <img src={Bank} alt='bank' style={{ width: '150px' }} /> :
                            <img src={Bank1} alt='bank' style={{ width: '150px' }} />}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>Account Name:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomizeTextField name='no' rows={2} multiline value={projectBid?.bankDetails?.accountName} size="small" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>Account Number:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomizeTextField name='no' value={projectBid?.bankDetails?.accountNumber} size="small" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>IFSC Code:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomizeTextField name='no' value={projectBid?.bankDetails?.IFSCCode} size="small" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>Swift Code:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomizeTextField name='no' value={projectBid?.bankDetails?.SWIFTCode} size="small" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>Micr:</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomizeTextField name='no' value={projectBid?.bankDetails?.MICR} size="small" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ color: '#788195', fontFamily: 'Montserrat-Regular' }}>Branch:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomizeTextField name='no' multiline rows={2} value={projectBid?.bankDetails?.branch} size="small" fullWidth />
                    </Grid>
                </Grid>
                <Box pt={2} display='flex' justifyContent="space-between">
                    <LoadingButton sx={cancelButton} onClick={() => handleClose()}>Cancel</LoadingButton>
                    <LoadingButton disabled={projectBid ? false : true} sx={submitButton} onClick={(e) => handleNext()}>Next</LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}
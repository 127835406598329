import "./AdminApproval.css";
import { Box, Grid, useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import routineicon from "../../theme/routineicon.png";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { RootState } from "../../redux/redux-store";
import { useDispatch, useSelector } from "react-redux";
import { getAllWings } from '../../redux/data/data-actions';
import MessageIcon from '@mui/icons-material/Message';

function AdminApproval() {
  const isMobileView = useMediaQuery("(max-width:480px)");
  const { pathname } = useLocation();
  const param = useParams();
  const proNo = param && param.proNo ? parseInt(param.proNo) : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);

  const selectedProjectInfo: any = useSelector((state: RootState) => state.DATA_REDUCER.selectedProjectInfo);

  const handelChange = () => {
    dispatch(getAllWings(null));
    navigate(`/search/searchproject/${userInfo?.owner?.ownerId}`);
  }

    return (
        <Box className='mobile-view'>
            <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
            <Grid container>
                <Grid item xs={12} md={3}>
                </Grid>
                <Grid item xs={12} md={6} sx={{ position: 'relative', textAlign: 'center' }}>
                  {
                    pathname.includes('admin')
                    ?
                    <ArrowBackIosNewIcon
                      sx={{ position: 'absolute', top: '0px', left: '0px', fontSize: '35px' }}
                      onClick={() => {
                        // navigate(`/owner/owner-dashboard/${ownerId}/`);
                        navigate(`/owner/owner-dashboard/${userInfo?.owner?.ownerId}/${0}`);
                      }}
                    />
                    :
                    null
                  }
                  <span className="adminaprovetext">Admin Approval</span>
                  <br />
                  <span className="secondsmalltext">
                      The admin will verify with the society and then approve your request
                  </span>
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Box className="imgbox">
                            <img className="adminaproveimg" src={routineicon} />
                            <span className="amnoratext">{selectedProjectInfo?.projectName}</span>
                            <span className="belowamnora">Awaiting Admin verification and approval</span>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <div
              style={{
                position: 'fixed',
                cursor: 'pointer',
                padding: '8px 8px 2px 8px',
                backgroundColor: '#662e91',
                borderRadius: '5px',
                bottom: isMobileView ? '20px' : '20px',
                right: isMobileView ? '20px' : '100px',
              }}
              onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
            >
              <MessageIcon style={{color: 'white'}} />
            </div>
        </Box>
    );
}

export default AdminApproval;

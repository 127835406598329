import { Action } from "redux";

//------ Get loged in user info
export const SET_LOGGED_IN_USER_A = "SET_LOGGED_IN_USER_A";
export interface SetLoggedInUserAction
  extends Action<typeof SET_LOGGED_IN_USER_A> {
  user: any | null;
}
export const setLoggedInUser = (user: any | null) => {
  return {
    type: SET_LOGGED_IN_USER_A,
    user,
  };
};

//------ Save forum token actions
export const SET_FORUM_TOKEN = "SET_FORUM_TOKEN";
export interface SetForumTokenAction
  extends Action<typeof SET_FORUM_TOKEN> {
  forumToken: {} | null;
}
export const setForumToken = (forumToken: {} | null) => {
  return {
    type: SET_FORUM_TOKEN,
    forumToken,
  };
};

//------ Save chat token actions
export const SET_CHAT_TOKEN = "SET_CHAT_TOKEN";
export interface SetChatTokenAction
  extends Action<typeof SET_CHAT_TOKEN> {
  chatToken: '' | null;
}
export const setChatToken = (chatToken: '' | null) => {
  return {
    type: SET_CHAT_TOKEN,
    chatToken,
  };
};

//--- login user
export const SET_INVITED_USER_A = "SET_INVITED_USER_A";
export interface SetInvitedUserAction
  extends Action<typeof SET_INVITED_USER_A> {
  invitedUser: any | null;
}
export const setInvitedUser = (invitedUser: any | null) => {
  return {
    type: SET_INVITED_USER_A,
    invitedUser,
  };
};

//--- Developer Api
export const SET_DEVELOPER = "SET_DEVELOPER";
export interface setDeveloperAction extends Action<typeof SET_DEVELOPER> {
  developerdata: any | null;
}
export const setDeveloper = (developerdata: any | null) => {
  return {
    type: SET_DEVELOPER,
    developerdata,
  };
};

// Devloper projects ,ongoing and feature
export const SET_DEVELOPER_PROJECT = "SET_DEVELOPER_PROJECT";
export interface setDeveloperProjectAction
  extends Action<typeof SET_DEVELOPER_PROJECT> {
  developerprojects: any | null;
}
export const setDeveloperProject = (developerprojects: any | null) => {
  return {
    type: SET_DEVELOPER_PROJECT,
    developerprojects,
  };
};

export const SET_PROJECT_NAME_A = "SET_PROJECT_NAME_A";

export interface SetProjectNameAction
  extends Action<typeof SET_PROJECT_NAME_A> {
  projectName: any | null;
}

export const setProjectName = (projectName: any | null) => {
  return {
    type: SET_PROJECT_NAME_A,

    projectName,
  };
};

export const SET_PROJECT_SPECIAL_FEATURES_A = "SET_PROJECT_SPECIAL_FEATURES_A";

export interface SetProjectSpecialFeaturesAction
  extends Action<typeof SET_PROJECT_SPECIAL_FEATURES_A> {
  projectSpecialFeatures: any | null;
}

export const setProjectSpecialFeatures = (
  projectSpecialFeatures: any | null
) => {
  return {
    type: SET_PROJECT_SPECIAL_FEATURES_A,

    projectSpecialFeatures,
  };
};

export const SET_PROJECT_RERA_NUMBER_A = "SET_PROJECT_RERA_NUMBER_A";

export interface SetProjectReraNumberAction
  extends Action<typeof SET_PROJECT_RERA_NUMBER_A> {
  projectReraNumber: any | null;
}

export const setProjectReraNumber = (projectReraNumber: any | null) => {
  return {
    type: SET_PROJECT_RERA_NUMBER_A,

    projectReraNumber,
  };
};

export const SET_PROJECT_IMAGES_A = "SET_PROJECT_IMAGES_A";

export interface SetProjectImagesAction
  extends Action<typeof SET_PROJECT_IMAGES_A> {
    projectImages: any | null;
}

export const setProjectImages = (projectImages: any | null) => {
  return {
    type: SET_PROJECT_IMAGES_A,

    projectImages,
  };
};

export const SET_TEASER = "SET_TEASER";

export interface setTeaserAction
  extends Action<typeof SET_TEASER> {
    teaser: any | null;
}

export const setTeaser = (teaser: any | null) => {
  return {
    type: SET_TEASER,

    teaser,
  };
};

export const SET_PROJECT_AMENITIES_A = "SET_PROJECT_AMENITIES_A";

export interface SetProjectAmenitiesAction
  extends Action<typeof SET_PROJECT_AMENITIES_A> {
  projectAmenities: any | null;
}

export const setProjectAmenities = (projectAmenities: any | null) => {
  return {
    type: SET_PROJECT_AMENITIES_A,

    projectAmenities,
  };
};


export const SET_PROJECT_MAIN_IMAGE_A = "SET_PROJECT_MAIN_IMAGE_A";

export interface SetProjectMainImageAction
  extends Action<typeof SET_PROJECT_MAIN_IMAGE_A> {
  projectMainImage: any | null;
}

export const setProjectMainImage = (projectMainImage: any | null) => {
  return {
    type: SET_PROJECT_MAIN_IMAGE_A,

    projectMainImage,
  };
};

export const SET_PROJECT_LAYOUT_IMAGE_A = "SET_PROJECT_LAYOUT_IMAGE_A";

export interface SetProjectLayoutImageAction
  extends Action<typeof SET_PROJECT_LAYOUT_IMAGE_A> {
  projectLayoutImage: any | null;
}

export const setProjectLayoutImage = (projectLayoutImage: any | null) => {
  return {
    type: SET_PROJECT_LAYOUT_IMAGE_A,

    projectLayoutImage,
  };
};

export const SET_PROJECT_UNIT_TYPE_A = "SET_PROJECT_UNIT_TYPE_A";

export interface SetProjectUnitTypeAction
  extends Action<typeof SET_PROJECT_UNIT_TYPE_A> {
  projectUnitType: any | null;
}

export const setProjectUnitType = (projectUnitType: any | null) => {
  return {
    type: SET_PROJECT_UNIT_TYPE_A,

    projectUnitType,
  };
};

export const SET_PROJECT_NUMBER_OF_UNITS_A = "SET_PROJECT_NUMBER_OF_UNITS_A";

export interface SetProjectNumberOfUnitsAction
  extends Action<typeof SET_PROJECT_NUMBER_OF_UNITS_A> {
  projectNumberOfUnits: any | null;
}

export const setProjectNumberOfUnits = (projectNumberOfUnits: any | null) => {
  return {
    type: SET_PROJECT_NUMBER_OF_UNITS_A,

    projectNumberOfUnits,
  };
};

export const SET_PROJECT_DATE_OF_COMPLETION_A = "SET_PROJECT_DATE_OF_COMPLETION_A";

export interface SetProjectDateOfCompletionAction
  extends Action<typeof SET_PROJECT_DATE_OF_COMPLETION_A> {
  projectDateOfCompletion: any | null;
}

export const setProjectDateOfCompletion = (projectDateOfCompletion: any | null) => {
  return {
    type: SET_PROJECT_DATE_OF_COMPLETION_A,

    projectDateOfCompletion,
  };
};

export const SET_PROJECT_STAGE_A = "SET_PROJECT_STAGE_A";

export interface SetProjectStageAction
  extends Action<typeof SET_PROJECT_STAGE_A> {
  projectStage: any | null;
}

export const setProjectStage = (projectStage: any | null) => {
  return {
    type: SET_PROJECT_STAGE_A,

    projectStage,
  };
};

export const SET_PROJECT_BROCHURE_A = "SET_PROJECT_BROCHURE_A";

export interface SetProjectBrochureAction
  extends Action<typeof SET_PROJECT_BROCHURE_A> {
  projectBrochure: any | null;
}

export const setProjectBrochure = (projectBrochure: any | null) => {
  return {
    type: SET_PROJECT_BROCHURE_A,

    projectBrochure,
  };
};

export const SET_PROJECT_DEVELOPER_ID_A = "SET_PROJECT_DEVELOPER_ID_A";

export interface SetProjectDeveloperIdAction
  extends Action<typeof SET_PROJECT_DEVELOPER_ID_A> {
  projectDeveloperId: any | null;
}

export const setProjectDeveloperId = (projectDeveloperId: any | null) => {
  return {
    type: SET_PROJECT_DEVELOPER_ID_A,

    projectDeveloperId,
  };
};

export interface locationDetailsType {
  landMark?: any | null;

  address?: any | null;

  country?: any | null;

  city?: any | null;

  pincode?: any | null;
}

export interface developerMapDetailsType {
  lat?: number;
  lng?: number;
  place_id?: string;
}

export const SET_PROJECT_LOCATION_DETAILS_A = "SET_PROJECT_LOCATION_DETAILS_A";

export interface SetProjectLocationDetailsAction
  extends Action<typeof SET_PROJECT_LOCATION_DETAILS_A> {
  projectLocationDetails: locationDetailsType;
}

export const setProjectLocationDetails = (
  projectLocationDetails: locationDetailsType
) => {
  return {
    type: SET_PROJECT_LOCATION_DETAILS_A,

    projectLocationDetails,
  };
};

export const SET_DEVELOPER_TELEPHONE_A = "SET_DEVELOPER_TELEPHONE_A";

export interface SetDeveloperTelephoneAction
  extends Action<typeof SET_DEVELOPER_TELEPHONE_A> {
  developerTelephone: any | null;
}

export const setDeveloperTelephone = (developerTelephone: any | null) => {
  return {
    type: SET_DEVELOPER_TELEPHONE_A,

    developerTelephone,
  };
};



export const SET_DEVELOPER_COMPANY_LOGO_A = "SET_DEVELOPER_COMPANY_LOGO_A";

export interface SetDeveloperCompanyLogoAction
  extends Action<typeof SET_DEVELOPER_COMPANY_LOGO_A> {
  developerCompanyLogo: any | null;
}

export const setDeveloperCompanyLogo = (developerCompanyLogo: any | null) => {
  return {
    type: SET_DEVELOPER_COMPANY_LOGO_A,

    developerCompanyLogo,
  };
};

export const SET_DEVELOPER_EMAIL_A = "SET_DEVELOPER_EMAIL_A";

export interface SetDeveloperEmailAction
  extends Action<typeof SET_DEVELOPER_EMAIL_A> {
  developerEmail: any | null;
}

export const setDeveloperEmail = (developerEmail: any | null) => {
  return {
    type: SET_DEVELOPER_EMAIL_A,
    developerEmail,
  };
};

export const SET_DEVELOPER_COMPLETED_PROJECTS_A =
  "SET_DEVELOPER_COMPLETED_PROJECTS_A";
export interface SetDeveloperCompletedProjectsAction
  extends Action<typeof SET_DEVELOPER_COMPLETED_PROJECTS_A> {
  developerCompletedProjects: any | null;
}
export const setDeveloperCompletedProjects = (
  developerCompletedProjects: any | null
) => {
  return {
    type: SET_DEVELOPER_COMPLETED_PROJECTS_A,
    developerCompletedProjects,
  };
};

export const SET_DEVELOPER_ONGOING_PROJECTS_A =
  "SET_DEVELOPER_ONGOING_PROJECTS_A";
export interface SetDeveloperOngoingProjectsAction
  extends Action<typeof SET_DEVELOPER_ONGOING_PROJECTS_A> {
  developerOngoingProjects: any | null;
}
export const setDeveloperOngoingProjects = (
  developerOngoingProjects: any | null
) => {
  return {
    type: SET_DEVELOPER_ONGOING_PROJECTS_A,
    developerOngoingProjects,
  };
};

export const SET_DEVELOPER_ESTABLISHED_YEAR_A =
  "SET_DEVELOPER_ESTABLISHED_YEAR_A";
export interface SetDeveloperEstablishedYearAction
  extends Action<typeof SET_DEVELOPER_ESTABLISHED_YEAR_A> {
  developerEstablishedYear: any | null;
}
export const setDeveloperEstablishedYear = (
  developerEstablishedYear: any | null
) => {
  return {
    type: SET_DEVELOPER_ESTABLISHED_YEAR_A,
    developerEstablishedYear,
  };
};

export const SET_DEVELOPER_BROCHURE_A = "SET_DEVELOPER_BROCHURE_A";

export interface SetDeveloperBrochureAction
  extends Action<typeof SET_DEVELOPER_BROCHURE_A> {
  developerBrochure: any | null;
}

export const setDeveloperBrochure = (developerBrochure: any | null) => {
  return {
    type: SET_DEVELOPER_BROCHURE_A,
    developerBrochure,
  };
};

export const SET_DEVELOPER_ADDRESS_A = "SET_DEVELOPER_ADDRESS_A";
export interface SetDeveloperAddressAction
  extends Action<typeof SET_DEVELOPER_ADDRESS_A> {
  developerAddress: any | null;
}
export const setDeveloperAddress = (developerAddress: any | null) => {
  return {
    type: SET_DEVELOPER_ADDRESS_A,
    developerAddress,
  };
};

export const GET_OWNER_BY_ID_SUCCESS = "GET_OWNER_BY_ID_SUCCESS";
export interface GetOwnerByIdSuccessAction
  extends Action<typeof GET_OWNER_BY_ID_SUCCESS> {
  payload: [] | null;
}
export const GetOwnerByIdSuccess = (owner: any) => {

  return {
    type: GET_OWNER_BY_ID_SUCCESS,
    payload: owner,
  };
};

export const SET_PROJECT_ADDRESS = "SET_PROJECT_ADDRESS";
export interface SetProjectAddressAction
  extends Action<typeof SET_PROJECT_ADDRESS> {
  projectAddress: any | null;
}
export const setProjectAddress = (projectAddress: any | null) => {
  return {
    type: SET_PROJECT_ADDRESS,
    projectAddress,
  };
};

export const SET_DEVELOPER_NAME_A = "SET_DEVELOPER_NAME_A";
export interface SetDeveloperNameAction
  extends Action<typeof SET_DEVELOPER_NAME_A> {
  developerName: any | null;
}
export const setDeveloperName = (developerName: any | null) => {
  return {
    type: SET_DEVELOPER_NAME_A,
    developerName,
  };
};

export const SET_DEVELOPER_IMAGE_A = "SET_DEVELOPER_IMAGE_A";
export interface SetDeveloperImageAction
  extends Action<typeof SET_DEVELOPER_IMAGE_A> {
  developerImage: any | null;
}
export const setDeveloperImage = (developerImage: any | null) => {
  return {
    type: SET_DEVELOPER_IMAGE_A,
    developerImage,
  };
};

export const SET_DEVELOPER_DESCRIPTION_A = "SET_DEVELOPER_DESCRIPTION_A";

export interface SetDeveloperDescriptionAction
  extends Action<typeof SET_DEVELOPER_DESCRIPTION_A> {
  developerDescription: any | null;
}

export const setDeveloperDescription = (developerDescription: any | null) => {
  return {
    type: SET_DEVELOPER_DESCRIPTION_A,
    developerDescription,
  };
};

export const SET_DEVELOPER_PARTNERS_A = "SET_DEVELOPER_PARTNERS_A";

export interface SetDeveloperPartnersAction
  extends Action<typeof SET_DEVELOPER_PARTNERS_A> {
  developerPartners: any | null;
}

export const setDeveloperPartners = (developerPartners: any | null) => {
  return {
    type: SET_DEVELOPER_PARTNERS_A,
    developerPartners,
  };
};

export const SET_DEVELOPER_PROJECTS_A = "SET_DEVELOPER_PROJECTS_A";

export interface SetDeveloperProjectsAction
  extends Action<typeof SET_DEVELOPER_PROJECTS_A> {
  developerProjects: any | null;
}

export const setDeveloperProjects = (developerProjects: any | null) => {
  return {
    type: SET_DEVELOPER_PROJECTS_A,
    developerProjects,
  };
};

export const SET_DEVELOPER_MAP_DETAILS_A = "SET_DEVELOPER_MAP_DETAILS_A";
export interface SetDeveloperMapDetailsAction
  extends Action<typeof SET_DEVELOPER_MAP_DETAILS_A> {
  developerMapDetails: any;
}
export const setDeveloperMapDetails = (developerMapDetails: any) => {
  return {
    type: SET_DEVELOPER_MAP_DETAILS_A,
    developerMapDetails,
  };
};

export const SET_PROJECT_MAP_DETAILS = "SET_PROJECT_MAP_DETAILS";
export interface setprojectMapDetailsAction
  extends Action<typeof SET_PROJECT_MAP_DETAILS> {
  projectMapDetails: any;
}
export const setProjectMapDetails = (projectMapDetails: any) => {
  return {
    type: SET_PROJECT_MAP_DETAILS,
    projectMapDetails,
  };
};

export const SET_PROJECT_REDEV_DEV_ID = "SET_PROJECT_REDEV_DEV_ID";
export interface setprojectRedevDevIdAction
  extends Action<typeof SET_PROJECT_REDEV_DEV_ID> {
  projectRedevDevId: any;
}
export const setProjectRedevDevId = (projectRedevDevId: any) => {
  return {
    type: SET_PROJECT_REDEV_DEV_ID,
    projectRedevDevId,
  };
};

export const SET_PROJECT_SALES_NUMBER_A = "SET_PROJECT_SALES_NUMBER_A";

export interface SetProjectSalesNumberAction
  extends Action<typeof SET_PROJECT_SALES_NUMBER_A> {
  projectSalesNumber: any;
}

export const setProjectSalesNumber = (projectSalesNumber: any | null) => {
  return {
    type: SET_PROJECT_SALES_NUMBER_A,
    projectSalesNumber,
  };
};

export const SET_DEVLOPER_BY_URL = "SET_DEVLOPER_BY_URL";
export interface setDeveloperByUrlAction
  extends Action<typeof SET_DEVLOPER_BY_URL> {
  DeveloperByUrl: any;
  user: any | null;
}
export const setDeveloperByUrl = (user: any | null) => {
  return {
    type: SET_DEVLOPER_BY_URL,
    user,
  };
};

export const SET_PROJECT_BY_URL = "SET_PROJECT_BY_URL";
export interface setProjectByUrlAction
  extends Action<typeof SET_PROJECT_BY_URL> {
  ProjectByUrl: any;
  user: any | null;
}
export const setProjectByUrl = (user: any | null) => {
  return {
    type: SET_PROJECT_BY_URL,
    user,
  };
};

export const SET_ALL_ARCHITECT = "SET_ALL_ARCHITECT";
export interface SetAllArchitectAction
  extends Action<typeof SET_ALL_ARCHITECT> {
  architectProjects: {} | null;
}

export const getAllArchitect = (architectProjects: {} | null) => {
  return {
    type: SET_ALL_ARCHITECT,
    architectProjects,
  };
};

/**Get all architect */
export const GET_ALL_ARCHITECT = "GET_ALL_ARCHITECT";
export interface GetAllArchitectAction
  extends Action<typeof GET_ALL_ARCHITECT> {
  architects: [] | null;
}

export const getArchitects = (architects: [] | null) => {
  return {
    type: GET_ALL_ARCHITECT,
    architects,
  };
};

/**Get all lawyers */
export const GET_ALL_LAWYERS = "GET_ALL_LAWYERS";
export interface GetAllLawyerAction
  extends Action<typeof GET_ALL_LAWYERS> {
  lawyers: [] | null;
}

export const getLawyers = (lawyers: [] | null) => {
  return {
    type: GET_ALL_LAWYERS,
    lawyers,
  };
};

/**Get all Vastu Shastra */
export const GET_ALL_VASTU = "GET_ALL_VASTU";
export interface GetAllVastuAction
  extends Action<typeof GET_ALL_VASTU> {
  vastuShastra: [] | null;
}

export const getVastuShastra = (vastuShastra: [] | null) => {
  return {
    type: GET_ALL_VASTU,
    vastuShastra,
  };
};

//bids
export const SET_ALL_BIDPROJECTS = "SET_ALL_BIDPROJECTS";
export interface SetAllBidprojectAction
  extends Action<typeof SET_ALL_BIDPROJECTS> {
  bidProjects: [] | null;
}

export const getProjectByStage = (bidProjects: [] | null) => {
  return {
    type: SET_ALL_BIDPROJECTS,
    bidProjects,
  };
};

//favourites
export const SET_ALL_FAVOURITEPROJECTS = "SET_ALL_FAVOURITEPROJECTS";
export interface SetAllFavouriteprojectAction
  extends Action<typeof SET_ALL_FAVOURITEPROJECTS> {
  favouriteProjects: [] | null;
}

export const getProjectByFavourite = (favouriteProjects: [] | null) => {
  return {
    type: SET_ALL_FAVOURITEPROJECTS,
    favouriteProjects,
  };
};

//Drprojects
export const SET_ALL_DRPROJECTS = "SET_ALL_DRPROJECTS";
export interface SetAllDrprojectAction
  extends Action<typeof SET_ALL_DRPROJECTS> {
  drProjects: [] | null;
}

export const getDrProject = (drProjects: [] | null) => {
  return {
    type: SET_ALL_DRPROJECTS,
    drProjects,
  };
};

export const SET_ALL_CITIES = "SET_ALL_CITIES";
export interface SetAllCitiesAction
  extends Action<typeof SET_ALL_CITIES> {
  cities: [] | null;
}

export const setAllCities = (cities: [] | null) => {
  return {
    type: SET_ALL_CITIES,
    cities,
  };
};

export const SET_DEVELOPER_FORMATE_ADDRESS = "SET_DEVELOPER_FORMATE_ADDRESS";
export interface setDeveloperFormateAddress
  extends Action<typeof SET_DEVELOPER_FORMATE_ADDRESS> {
  address: any;
}
export const setDeveloperFormateAddress = (address: any) => {
  return {
    type: SET_DEVELOPER_FORMATE_ADDRESS,
    address,
  };
};

export const SET_LAWYER_PROJECTS_A = "SET_LAWYER_PROJECTS_A";
export interface SetLawyerProjectsAction
  extends Action<typeof SET_LAWYER_PROJECTS_A> {
  lawyerProjects: any | null;
}
export const SetLawyerProjectsAction = (lawyerProjects: any | null) => {
  return {
    type: SET_LAWYER_PROJECTS_A,
    lawyerProjects,
  };
};

export const SET_VASTU_PROFILE_DATA_A = "SET_VASTU_PROFILE_DATA_A";
export interface SetVastuProfileDataAction
  extends Action<typeof SET_VASTU_PROFILE_DATA_A> {
  vastuProfileData: any | null;
}
export const SetVastuProfileDataAction = (vastuProfileData: any | null) => {
  return {
    type: SET_VASTU_PROFILE_DATA_A,
    vastuProfileData,
  };
};

export const GET_ALL_Projects = "GET_ALL_Projects";
export interface GetAllProjectsAction extends Action<typeof GET_ALL_Projects> {
  projects: any;
}

export const getAllProject = (projects: [] | null) => {
  return {
    type: GET_ALL_Projects,
    projects,
  };
};

export const SET_SELECTED_PROJECT_INFO = "SET_SELECTED_PROJECT_INFO";
export interface SetSelectedProjectInfo
  extends Action<typeof SET_SELECTED_PROJECT_INFO> {
  selectedProjectInfo: {};
}

export const setSelectedProjectInfo = (selectedProjectInfo: {} | null) => {
  return {
    type: SET_SELECTED_PROJECT_INFO,
    selectedProjectInfo,
  };
};

export const SET_SELECTED_FLOOR_DETAILS = "SET_SELECTED_FLOOR_DETAILS";
export interface SetSelectedFloordetailsActions
  extends Action<typeof SET_SELECTED_FLOOR_DETAILS> {
  selectedFloordetails: {};
}

export const setSelectedFloordetaiis = (selectedFloordetails: {} | null) => {
  return {
    type: SET_SELECTED_FLOOR_DETAILS,
    selectedFloordetails,
  };
};

export const SET_SELECTED_FLAT_DETAILS = "SET_SELECTED_FLAT_DETAILS";
export interface SetSelectedFlatActions
  extends Action<typeof SET_SELECTED_FLAT_DETAILS> {
  selectedFlat: {};
}

export const setselectedFlat = (selectedFlat: {} | null) => {
  return {
    type: SET_SELECTED_FLAT_DETAILS,
    selectedFlat,
  };
};

export const SET_SEARCH_PROJECT_NAME_A = "SET_SEARCH_PROJECT_NAME_A";
export interface SetSearchProjectNameAction
  extends Action<typeof SET_SEARCH_PROJECT_NAME_A> {
  searchProjectName: any | null;
}
export const setSearchProjectName = (searchProjectName: any | null) => {
  return {
    type: SET_SEARCH_PROJECT_NAME_A,
    searchProjectName,
  };
};

export const SET_PROJECT_NUMBER_OF_FLATS_A = "SET_PROJECT_NUMBER_OF_FLATS_A";
export interface SetProjectNumberOfFlatsAction
  extends Action<typeof SET_PROJECT_NUMBER_OF_FLATS_A> {
  projectNumberOfFlats: any | null;
}
export const setProjectNumberOfFlats = (projectNumberOfFlats: any | null) => {
  return {
    type: SET_PROJECT_NUMBER_OF_FLATS_A,
    projectNumberOfFlats,
  };
};

export const SET_PROJECT_NUMBER_OF_SHOPS_A = "SET_PROJECT_NUMBER_OF_SHOPS_A";
export interface SetProjectNumberOfShopsAction
  extends Action<typeof SET_PROJECT_NUMBER_OF_SHOPS_A> {
  projectNumberOfShops: any | null;
}
export const setProjectNumberOfShops = (projectNumberOfShops: any | null) => {
  return {
    type: SET_PROJECT_NUMBER_OF_SHOPS_A,
    projectNumberOfShops,
  };
};

export const SET_PROJECT_NUMBER_OF_BUILDINGS_A = "SET_PROJECT_NUMBER_OF_BUILDINGS_A";
export interface SetProjectNumberOfBuildingsAction
  extends Action<typeof SET_PROJECT_NUMBER_OF_BUILDINGS_A> {
  projectNumberOfBuildings: any | null;
}
export const setProjectNumberOfBuildings = (projectNumberOfBuildings: any | null) => {
  return {
    type: SET_PROJECT_NUMBER_OF_BUILDINGS_A,
    projectNumberOfBuildings,
  };
};

export const SET_DEVELOPER_DATA = "SET_DEVELOPER_DATA";

export interface SetDeveloperDataAction
  extends Action<typeof SET_DEVELOPER_DATA> {
  developerData: any;
}

export const SetDeveloperData = (developerData: any) => {
  return {
    type: SET_DEVELOPER_DATA,
    developerData,
  };
};

export const SET_PROJECT_DATA = "SET_PROJECT_DATA";

export interface SetProjectDataAction
  extends Action<typeof SET_PROJECT_DATA> {
  projectData: any;
}

export const setProjectData = (projectData: any) => {
  return {
    type: SET_PROJECT_DATA,
    projectData,
  };
};

export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA";

export interface SetPaymentDataAction
  extends Action<typeof SET_PAYMENT_DATA> {
  paymentData: any;
}

export const setPaymentData = (paymentData: any) => {
  return {
    type: SET_PAYMENT_DATA,
    paymentData,
  };
};

export const GET_FLAT_DETAILS_BY_ID = "GET_FLAT_DETAILS_BY_ID";
export interface getFlatDeatilsByIdAction
  extends Action<typeof GET_FLAT_DETAILS_BY_ID> {
  getFlatDeatilsById: any | null;
}
export const getFlatDeatilsById = (getFlatDeatilsById: any | null) => {
  return {
    type: GET_FLAT_DETAILS_BY_ID,
    getFlatDeatilsById,
  };
};

/**----------------------------------------------------------------------------------------------- */
/**Get project info by project_id action*/
export const GET_PROJECT_INFO_PROJECT_ID = "GET_PROJECT_INFO_PROJECT_ID";
export interface GetProjectInfoByProjectID extends Action<typeof GET_PROJECT_INFO_PROJECT_ID> {
  projectInfo: any;
}

export const getProjectInfoByProjectIDAction = (projectInfo: {} | null) => {
  return {
    type: GET_PROJECT_INFO_PROJECT_ID,
    projectInfo,
  };
};

/**Get project access list by ownerId / developerId action */
export const GET_PROJECT_ACCESS_LIST_USER_ID = "GET_PROJECT_ACCESS_LIST_USER_ID";
export interface GetProjectAccessByUserID extends Action<typeof GET_PROJECT_ACCESS_LIST_USER_ID> {
  projectAccessList: [] | null;
}

export const getProjectAccessByUserID = (projectAccessList: [] | null) => {
  return {
    type: GET_PROJECT_ACCESS_LIST_USER_ID,
    projectAccessList,
  };
};

/**Get all developer transactions action */
export const GET_ALL_Transaction = "GET_ALL_Transaction";
export interface GetAllTransactionAction
  extends Action<typeof GET_ALL_Transaction> {
  transactions: [] | null;
}

export const getAllTransaction = (transactions: [] | null) => {
  return {
    type: GET_ALL_Transaction,
    transactions,
  };
}

/**Get all wings action */
export const GET_ALL_WINGS = "GET_ALL_WINGS";
export interface GetAllWingsActions
  extends Action<typeof GET_ALL_WINGS> {
  wings: [] | null;
}

export const getAllWings = (wings: [] | null) => {
  return {
    type: GET_ALL_WINGS,
    wings,
  };
}

/**Get all Floor NO action */
export const GET_ALL_FLOORS = "GET_ALL_FLOORS";
export interface GetAllFloorsAction
  extends Action<typeof GET_ALL_FLOORS> {
  floors: [] | null;
}

export const getAllFloors = (floors: [] | null) => {
  return {
    type: GET_ALL_FLOORS,
    floors,
  };
}

/**Get all Flats action */
export const GET_ALL_FLATS = "GET_ALL_FLATS";
export interface GetAllFlatsAction
  extends Action<typeof GET_ALL_FLATS> {
  flats: [] | null;
}

export const getAllFlats = (flats: [] | null) => {
  return {
    type: GET_ALL_FLATS,
    flats,
  };
}

/**Get owner property info action */
export const GET_OWNER_PROPERTY_INFO = "GET_OWNER_PROPERTY_INFO";
export interface GetOwnerPropertyAction
  extends Action<typeof GET_OWNER_PROPERTY_INFO> {
  ownerProperty: [] | null;
}

export const getOwnerProperty = (ownerProperty: [] | null) => {
  return {
    type: GET_OWNER_PROPERTY_INFO,
    ownerProperty,
  };
}

/**Get project total bid info action */
export const GET_PROJECT_BID_INFO = "GET_PROJECT_BID_INFO";
export interface GetProjectBidAction
  extends Action<typeof GET_PROJECT_BID_INFO> {
  projectBid: [] | null;
}
export const getProjectBid = (projectBid: [] | null) => {
  return {
    type: GET_PROJECT_BID_INFO,
    projectBid,
  };
}

/**Get Subscription info action */
export const GET_SUB_BID_INFO = "GET_SUB_BID_INFO";
export interface GetSubBidAction
  extends Action<typeof GET_SUB_BID_INFO> {
  subscriptionBid: {} | null;
}
export const getSubBid = (subscriptionBid: {} | null) => {
  return {
    type: GET_SUB_BID_INFO,
    subscriptionBid,
  };
}

/**Get upload URL action */
export const GET_UPLOAD_URL = "GET_UPLOAD_URL";
export interface GetUploadURLAction
  extends Action<typeof GET_UPLOAD_URL> {
  uploadURL: '' | null;
}
export const getUploadURL = (uploadURL: '' | null) => {
  return {
    type: GET_UPLOAD_URL,
    uploadURL,
  };
}

/**Biding transactions action */
export const BIDING_TRANSACTION = "BIDING_TRANSACTION";
export interface BidingTransactionAction
  extends Action<typeof BIDING_TRANSACTION> {
  bidingTransaction: {} | null;
}
export const bidingTransactionAction = (bidingTransaction: {} | null) => {
  return {
    type: BIDING_TRANSACTION,
    bidingTransaction,
  };
}

/**create Order*/
export const ORDER_TRANSACTION = "ORDER_TRANSACTION";
export interface OrderTransactionAction
  extends Action<typeof ORDER_TRANSACTION> {
  orderTransaction: {} | null;
}
export const orderTransactionAction = (orderTransaction: {} | null) => {
  return {
    type: ORDER_TRANSACTION,
    orderTransaction,
  };
}

//**capture payment */
export const CAPTURE_PAYMENT = "CAPTURE_PAYMENT";
export interface CapturePaymentAction
  extends Action<typeof CAPTURE_PAYMENT> {
  capturePayment: {} | null;
}
export const capturePaymentAction = (capturePayment: {} | null) => {
  return {
    type: CAPTURE_PAYMENT,
    capturePayment,
  };
}



/**Get project updates by projectID action */
export const GET_PROJECT_UPDATES = "GET_PROJECT_UPDATES";
export interface GetProjectUpdatesAction extends Action<typeof GET_PROJECT_UPDATES> {
  projectUpdates: any;
}
export const getProjectUpdates = (projectUpdates: [] | null) => {
  return {
    type: GET_PROJECT_UPDATES,
    projectUpdates,
  };
};

export const GET_ALL_AMENITIES = "GET_ALL_AMENITIES";
export interface GetAllAmenitiesAction extends Action<typeof GET_ALL_AMENITIES> {
  allAmenities: [] | null;
}

export const getAllAmenities = (allAmenities: [] | null) => {
  return {
    type: GET_ALL_AMENITIES,
    allAmenities,
  };
};

export const SET_USER_PROPERTY_ROLES_A = "SET_USER_PROPERTY_ROLES_A";

export interface SetUserPropertyRolesAction
  extends Action<typeof SET_USER_PROPERTY_ROLES_A> {
  userPropertyRoles: any | null;
}

export const setUserPropertyRoles = (userPropertyRoles: any | null) => {
  return {
    type: SET_USER_PROPERTY_ROLES_A,

    userPropertyRoles,
  };
};

export const SET_REDIRECT_TO_404_A = "SET_REDIRECT_TO_404_A";

export interface SetRedirectTo404Action
  extends Action<typeof SET_REDIRECT_TO_404_A> {
  redirectTo404: any | null;
}

export const setRedirectTo404 = (redirectTo404: any | null) => {
  return {
    type: SET_REDIRECT_TO_404_A,

    redirectTo404,
  };
};

export const SET_EDIT_USER_DETAILS_A = "SET_EDIT_USER_DETAILS_A";

export interface SetEditUserDetailsAction
  extends Action<typeof SET_EDIT_USER_DETAILS_A> {
  editUserDetails: any | null;
}

export const setEditUserDetails = (editUserDetails: any | null) => {
  return {
    type: SET_EDIT_USER_DETAILS_A,

    editUserDetails,
  };
};

export const GET_ALL_MOZNI_BY_ID = "GET_ALL_MOZNI_BY_ID";
export interface GetAllMozniByIdAction
  extends Action<typeof GET_ALL_MOZNI_BY_ID> {
  payload: [] | null;
}
export const GetAllMozniById = (moznisbyid: any) => {
  console.log(moznisbyid);

  return {
    type: GET_ALL_MOZNI_BY_ID,
    payload: moznisbyid,
  };
};

export type DataAction =
  | GetProjectUpdatesAction
  | SetForumTokenAction
  | GetAllArchitectAction
  | BidingTransactionAction
  | OrderTransactionAction
  | CapturePaymentAction
  | GetAllVastuAction
  | GetAllLawyerAction
  | GetSubBidAction
  | GetUploadURLAction
  | GetProjectBidAction
  | GetAllFlatsAction
  | GetAllFloorsAction
  | GetAllWingsActions
  | GetOwnerPropertyAction
  | SetLoggedInUserAction
  | SetInvitedUserAction
  | setDeveloperAction
  | setDeveloperProjectAction
  | SetProjectNameAction
  | SetProjectSpecialFeaturesAction
  | SetProjectReraNumberAction
  | SetProjectImagesAction
  | setTeaserAction
  | SetProjectAmenitiesAction
  | SetChatTokenAction
  | SetProjectLocationDetailsAction
  | SetProjectDeveloperIdAction
  | SetDeveloperTelephoneAction
  | SetDeveloperCompanyLogoAction
  | SetDeveloperEmailAction
  | SetDeveloperCompletedProjectsAction
  | SetDeveloperOngoingProjectsAction
  | SetDeveloperEstablishedYearAction
  | SetDeveloperAddressAction
  | SetDeveloperNameAction
  | SetDeveloperImageAction
  | SetDeveloperDescriptionAction
  | SetDeveloperPartnersAction
  | SetDeveloperProjectsAction
  | SetProjectMainImageAction
  | SetProjectLayoutImageAction
  | SetProjectUnitTypeAction
  | SetDeveloperMapDetailsAction
  | SetProjectSalesNumberAction
  | setDeveloperByUrlAction
  | setProjectByUrlAction
  | setDeveloperFormateAddress
  | setprojectMapDetailsAction
  | setprojectRedevDevIdAction
  | SetProjectAddressAction
  | GetAllProjectsAction
  | SetAllArchitectAction
  | SetLawyerProjectsAction
  | SetSearchProjectNameAction
  | getFlatDeatilsByIdAction
  | SetSelectedProjectInfo
  | SetSelectedFloordetailsActions
  | SetSelectedFlatActions
  | SetAllBidprojectAction
  | SetAllFavouriteprojectAction
  | SetAllDrprojectAction
  | GetProjectInfoByProjectID
  | GetProjectAccessByUserID
  | GetAllTransactionAction
  | SetDeveloperBrochureAction
  | SetProjectNumberOfUnitsAction
  | SetProjectDateOfCompletionAction
  | SetProjectStageAction
  | SetProjectBrochureAction
  | SetProjectNumberOfFlatsAction
  | SetProjectNumberOfShopsAction
  | SetProjectNumberOfBuildingsAction
  | SetDeveloperDataAction
  | SetProjectDataAction
  | SetPaymentDataAction
  | SetAllCitiesAction
  | GetOwnerByIdSuccessAction
  | SetVastuProfileDataAction
  | GetAllAmenitiesAction
  | SetUserPropertyRolesAction
  | SetRedirectTo404Action
  | SetEditUserDetailsAction
  | GetAllMozniByIdAction
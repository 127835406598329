import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Button,
  ListItemIcon,
  Grid,
  Dialog,
  DialogContent,
  TableBody,
  TableContainer,
  TableCell,
  Table,
  Divider,
  TableRow,
  TextField,
  TableHead,
  TablePagination,
  Paper,
  Avatar,
  Fade,
  IconButton,
} from "@mui/material";
import {
  Search,
  MoreVert,
  Edit,
  ErrorOutlineOutlined,
  Delete,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import UploadIcon from "@mui/icons-material/Upload";
import MenuItem from "@mui/material/MenuItem";
import CreateIcon from "@mui/icons-material/Create";
import { Field, Form, Formik, validateYupSchema } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { LoadingButton } from "../../../layouts/LoadingButton";
import { input1 } from "../../../styling/GlobalStylesMui";
import { createMember, editMember } from "../../../redux/thunk/member-thunk";
import { RootState } from "../../../redux/redux-store";
import Styles from "./AddMemberModal.module.css";
import uploadPublicFiles from "../../../utils/uploadPublicFiles";

const editButtonStyle = {
  position: "absolute",
  bottom: "0px",
  left: "70%",
  bgcolor: "#662e91",
  borderRadius: "50%",
  width: "34px",
  height: "34px",
};
const paper1 = {
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};
const avtar = {
  height: "100%",
  width: "100%",
  border: "1px solid #efefef",
  background: "#662E911A",
  cursor: "pointer",
};
const cancelButton = {
  height: "30px",
  marginRight: "15px",
  backgroundColor: "#fff",
  color: "#000000",
  border: "1px solid #9D9D9D",

  "&:hover": {
    backgroundColor: "#fff",
    color: "#000000",
  },
};

const style1 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "45.89%",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 2,
};

const submitButton = {
  backgroundColor: "#662E91",
  color: "#fff",
  height: "30px",
  borderRadius: "3px",
  fontFamily: "Montserrat-Regular",

  "&:hover": {
    backgroundColor: "#662E91",
    color: "#fff",
  },
};

interface AddMemberProps {
  open: boolean;
  handleClose: () => void;
  developerId: string;
  memberDetails?: any;
}

interface FormValues {
  name: string;
  designation: string;
  description: any;
}
const AddMemberModal = ({
  open,
  handleClose,
  developerId,
  memberDetails,
}: AddMemberProps) => {
  console.log("member details are", memberDetails);
  const [openModal, setOpenModal] = React.useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [photoURL, setPhotoURL] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const createMemberSuccess = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.createMemberSuccess
  );

  useEffect(() => {
    if (memberDetails) setPhotoURL(memberDetails.images[0]);
  }, [memberDetails]);
  const initialValues: FormValues = {
    name: memberDetails ? memberDetails.name : "",
    designation: memberDetails ? memberDetails.designation : "",
    description: memberDetails ? memberDetails.description : "",
  };

  const userSupportSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    designation: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (!open) setPhotoURL("");
  }, [open]);

  const handleSubmit = (values: any, actions: any) => {
    if(memberDetails)
      dispatch(
        editMember(memberDetails.memberId, { ...values, images: [photoURL], developerId: developerId })
      );
    else
      dispatch(
        createMember({ ...values, images: [photoURL], developerId: developerId })
      );
  };

  const handleImageUpload = async (fileList: any) => {
    const result: any = await uploadPublicFiles(fileList);

    if (result && result.length > 0) {
      setPhotoURL(result[0]);
    }
  };

  const selectImageFile = () => {
    fileInputRef.current?.click();
  };

  return (
    <Modal
      open={open}
      data-testid="userModal"
      onClose={handleClose}
      closeAfterTransition
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={userSupportSchema}
      >
        {({ errors, isSubmitting, setSubmitting, touched }) => {
          return (
            <Form>
              <Paper sx={style1}>
                <Typography
                  sx={{ fontWeight: "unset", color: "#788195", fontSize: "15px", fontFamily: "Montserrat-Medium" }}
                >
                  Add Member
                </Typography>
                <Divider sx={{ marginTop: "8px" }} />

                <Grid
                  item
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                  sx={{ mt: "2.5vh" }}
                >
                  <Grid
                    item
                    container
                    flexDirection="column"
                    justifyContent="space-evenly"
                    sx={{ width: "16.88vw" }}
                  >
                    <Grid flexDirection="column">
                      <Grid item xs={true}>
                        <Typography variant="inputTitle" sx={{fontFamily: "Montserrat-Medium", fontWeight: "unset", fontSize: "16px"}}>Name</Typography>
                      </Grid>
                      <Grid item xs={true}>
                        {/* <Field as={TextField} fullwidth={true} size="small" name="name" sx={input1} /> */}

                        <Field name="name">
                          {(props: any) => {
                            const { field } = props;
                            return (
                              <TextField
                                size="small"
                                sx={input1}
                                fullWidth
                                {...field}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={true}>
                        {errors.name && touched.name ? (
                          <Typography color="error" variant="subtitle1">
                            {"Please enter name"}
                          </Typography>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="inputTitle" sx={{fontFamily: "Montserrat-Medium", fontWeight: "unset", fontSize: "16px"}} >
                        Role/Designation
                      </Typography>
                      <Field
                        as={TextField}
                        size="small"
                        fullWidth={true}
                        name="designation"
                        sx={input1}
                      />
                      {errors.designation && touched.designation ? (
                        <Typography color="error" variant="subtitle1">
                          {"Please enter designation"}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={false}
                    sx={{
                      width: "12.76vw",
                      height: "13.02vw",
                      background: "#FFFFFF",
                      border: "1px solid #DDDDDD",
                      borderRadius: "0.42vw",
                      p: "1vw",
                    }}
                  >
                    <Grid
                      item
                      container
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Grid>
                        <Grid
                          sx={{
                            position: "relative",
                            width: "7.29vw",
                            height: "7.29vw",
                          }}
                        >
                          <Avatar component="label" sx={avtar}>
                            {photoURL ? (
                              <img
                                src={photoURL}
                                alt="member photo"
                                style={{
                                  borderRadius: "0.42vw",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            ) : null}
                          </Avatar>
                          <IconButton
                            sx={{
                              ...editButtonStyle,
                              ":hover": {
                                ...editButtonStyle,
                              },
                            }}
                            onClick={selectImageFile}
                          >
                            <CreateIcon
                              sx={{ fontSize: "18px", color: "white" }}
                            />
                          </IconButton>
                          <input
                            id="fileUploader"
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e: any) => {
                              console.log("okay. files are", e.target.files);
                              if (e.target.files.length)
                                handleImageUpload(e.target.files);
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* </Upload.Dragger> */}

                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#afafaf",
                          marginTop: "10px",
                        }}
                      >
                        Upload Photo
                      </Typography>
                    </Grid>

                    <Typography sx={{ fontSize: "12px", color: "#afafaf" }}>
                      Allowed *.jpeg, *.png, *.jpg
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container flexDirection="column">
                  <Typography variant="inputTitle" sx={{fontFamily: "Montserrat-Medium", fontWeight: "unset", fontSize: "16px"}}>About Member</Typography>
                  <Field name="description">
                    {(props: any) => {
                      const { field } = props;

                      return (
                        <TextField
                          size="medium"
                          // placeholder="About Member"
                          fullWidth
                          sx={input1}
                          multiline
                          rows={3}
                          {...field}
                        />
                      );
                    }}
                  </Field>
                  {errors.description && touched.description ? (
                    <Typography color="error" variant="subtitle1">
                      {"Please enter Information"}
                    </Typography>
                  ) : null}
                </Grid>
                <Box pt={2} display="flex" justifyContent="flex-end">
                  <Button
                    className="button2"
                    onClick={() => handleClose()}
                    sx={{ mr: "0.89vw", fontFamily: "Montserrat-Regular" }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    sx={submitButton}
                    type="submit"
                  >
                    {memberDetails ? "Edit" : "Add"}
                  </LoadingButton>
                </Box>
              </Paper>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
export default AddMemberModal;

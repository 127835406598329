import { NABHThunkDispatch, RootState } from "../redux-store";
import { _getHeaders } from "../api-headers";
import {
  setErrorAction,
  setLoadingAction,
  setSuccessAction,
  snackbarAlertAction,
} from "../display/display-actions";

import { url } from "../url";

import axios, { AxiosRequestConfig } from 'axios';

export const editMember =
  (memberId: string, data: any) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    dispatch(setLoadingAction("editMemberLoading", true));
    var config: AxiosRequestConfig = {
      method: "put",
      url: `${url}/member/${memberId}`,
      headers: _getHeaders({
        "Content-Type": "application/json",
      }),
      data,
    };

    axios(config)
      .then((response: any) => {
        dispatch(
          setLoadingAction("createMemberLoading", false)
        );
        dispatch(setSuccessAction("editMemberSuccess", true));
        dispatch(snackbarAlertAction(`Member edited successfully`, "success"));
      })
      .catch((error: any) => {
        console.log("error while creating member is", error);
        dispatch(
          setLoadingAction("editMemberLoading", false)
        );
        // dispatch(
        //   setErrorAction('createMemberError', )
        // )
        dispatch(snackbarAlertAction(`Error: ${error?.response?.data.message}`, "error"));
      });
  };

  export const createMember =
  (data: any) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    dispatch(setLoadingAction("createMemberLoading", true));
    var config: AxiosRequestConfig = {
      method: "post",
      url: `${url}/member`,
      headers: _getHeaders({
        "Content-Type": "application/json",
      }),
      data,
    };

    axios(config)
      .then((response: any) => {
        dispatch(
          setLoadingAction("createMemberLoading", false)
        );
        dispatch(setSuccessAction("createMemberSuccess", true));
        dispatch(snackbarAlertAction(`Member created successfully`, "success"));
      })
      .catch((error: any) => {
        console.log("error while creating member is", error);
        dispatch(
          setLoadingAction("createMemberLoading", false)
        );
        // dispatch(
        //   setErrorAction('createMemberError', )
        // )
        dispatch(snackbarAlertAction(`Error: ${error?.response?.data.message}`, "error"));
      });
  };

// export const editMember =
//   (id: string, data: any) =>
//   async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
//     dispatch(setLoadingAction("editMemberLoading", true, "getDataError"));

//     var config: AxiosRequestConfig = {
//       method: "put",
//       url: `${url}/member/${id}`,
//       headers: _getHeaders({
//         "Content-Type": "application/json",
//       }),
//       data,
//     };
//     axios(config)
//       .then((response: any) => {
//         dispatch(
//           setLoadingAction("editMemberLoading", false, "getDataError")
//         );
//         dispatch(setSuccessAction("editMemberSuccess", true));
//       })
//       .catch((error: any) => {
//         console.log("error while editing member is", error);
//         dispatch(
//           setLoadingAction("editMemberLoading", false, "getDataError")
//         );

//         dispatch(snackbarAlertAction(`Error: ${error?.response?.data.message}`, "error"));
//       });
//   };

export const deleteMember =
  (memberId: string) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    var config: AxiosRequestConfig = {
      method: "delete",
      url: `${url}/member/${memberId}`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
    };
    axios(config)
      .then((response: any) => {
        dispatch(setSuccessAction("deleteMemberSuccess", true));
        dispatch(snackbarAlertAction(`Member deleted successfully`, "success"));
      })
      .catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error?.response?.data.message}`, "error"));
      });
  };

// export const getDevById =
//   (data: any) =>
//   async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
//     var config: AxiosRequestConfig = {
//       method: "get",
//       url: `${url}/developer/${data.id}`,
//       headers: _getHeaders({
//         "Content-Type": "application/json",
//         accept: "*/*",
//       }),
//     };
//     axios(config)
//       .then(async (response: any) => {
//         await dispatch(SetDeveloperData(response.data.data));
//       })
//       .catch((e: any) => {
//         console.log("error is", e);
//       });
//   };
import { NABHThunkDispatch, RootState } from "../redux-store";
import { _getHeaders } from "../api-headers";
import { url } from "../url";
import {
  setProjectName,
  setProjectData,
  setProjectReraNumber,
  setProjectSpecialFeatures,
  setProjectLocationDetails,
  setProjectImages,
  setTeaser,
  setProjectAmenities,
  setProjectDeveloperId,
  setProjectMainImage,
  setProjectLayoutImage,
  setProjectUnitType,
  setProjectSalesNumber,
  setProjectMapDetails,
  setProjectAddress,
  getProjectAccessByUserID,
  setDeveloperCompanyLogo,
  getAllProject,
  getProjectByFavourite,
  getDrProject,
  setProjectNumberOfUnits,
  setProjectDateOfCompletion,
  setProjectStage,
  setProjectBrochure,
  setProjectNumberOfFlats,
  setProjectNumberOfBuildings,
  setProjectNumberOfShops,
  setAllCities, getUploadURL, getAllAmenities as getAllAmenitiesImported, setUserPropertyRoles, setRedirectTo404,
  setPaymentData,
} from "../data/data-actions";
import { setLoadingAction, setSuccessAction, snackbarAlertAction } from "../display/display-actions";

var axios = require("axios");

export const createNonDRProject = (data: any) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {

    // const projectData = {
    //   "projectName": data.projectName ? data.projectName : null,
    //   "shortDescription": data.shortDescription ? data.shortDescription : null,
    //   "reraNumber": data.reraNumber ? data.reraNumber : null,
    //   "completionDate": data.completionDate ? new Date(data.completionDate) : null,
    //   "projectStatus": data.projectStatus ? Number(data.projectStatus) : null,
    //   "salesNumber": data.salesNumber ? Number(data.salesNumber) : null,
    //   "place_id": data.place_id ? data.place_id : null,
    //   "location_name": data.location_name ? data.location_name : null,
    //   "projectImages": data.projectImages ? data.projectImages : null,
    //   "mainImage": data.mainImage ? data.mainImage : null,
    //   "initialDevId": data.initialDevId ? [data.initialDevId] : [],
    //   "developerProject": true,
    //   "amenitiesId":data.amenitiesId
    // }
    data['projectStatus'] = data['projectStatus'] ? Number(data['projectStatus']) : null;
    data['salesNumber'] = data['salesNumber'] ? Number(data['salesNumber']) : null;
    data['completionDate'] = data['completionDate'] ? new Date(data['completionDate']) : null;
    var config = {
      method: 'post',
      url: `${url}/project`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
      data: data
    };
    axios(config)
      .then((response: any) => {
        dispatch(setSuccessAction("dataSaveSuccessfully", true));
        dispatch(snackbarAlertAction(`Project created successfully`, "success"));
      }).catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
        dispatch(setSuccessAction("dataSaveSuccessfully", false));
      })
  };

export const updateNonDRProject = (data: any, id: string) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {

    const projectData = {
      "projectName": data.projectName ? data.projectName : null,
      "shortDescription": data.shortDescription ? data.shortDescription : null,
      "reraNumber": data.reraNumber ? data.reraNumber : null,
      "completionDate": data.completionDate ? new Date(data.completionDate) : null,
      "amenitiesId": data.amenitiesId,
      "projectStatus": data.projectStatus ? Number(data.projectStatus) : null,
      "salesNumber": data.contactUs ? Number(data.contactUs) : null,
      "place_id": data.place_id ? data.place_id : null,
      "location_name": data.location_name ? data.location_name : null,
      "projectImages": data.projectImages ? data.projectImages : null,
      "mainImage": data.mainImage ? data.mainImage : null,
    }
    var config = {
      method: 'put',
      url: `${url}/project/${id}`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
      data: projectData
    };
    axios(config)
      .then((response: any) => {
        dispatch(setSuccessAction("dataSaveSuccessfully", true));
        dispatch(snackbarAlertAction(`Project updated successfully`, "success"));
      }).catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
        dispatch(setSuccessAction("dataSaveSuccessfully", false));
      })
  };

export const getProjectById =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config = {
        method: "get",
        url: `${url}/project/${data.projectId}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
          accept: "*/*",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiI3MDM4MTAyNzk4IiwiaWF0IjoxNjc3MjMyNjY3LCJleHAiOjE2NzcyMzM1Njd9.ar3nnnPd5MZG-RjdVrhq5FU9EKyLhZDWRM77IGdDR94",
        }),
      };
      axios(config)
        .then(async (response: any) => {
          const project = response.data.data;
          dispatch(setProjectName(project.projectName));
          dispatch(setProjectSpecialFeatures(project.shortDescription));
          dispatch(setProjectReraNumber(project.reraNumber));
          dispatch(setProjectSalesNumber(project.salesNumber));
          dispatch(
            setProjectLocationDetails({
              landMark: project.landMark,
              address: project.address,
              country: project.country,
              city: project.city,
              pincode: project.pincode,
            })
          );

          dispatch(setProjectImages(project.projectImages));
          dispatch(setTeaser(project.teaser));
          dispatch(setProjectDeveloperId(project.devId));

          dispatch(setProjectMainImage([project.mainImage]));
          dispatch(setProjectLayoutImage([project.layoutImage]));
          dispatch(setProjectUnitType(project.unitType));
          dispatch(
            setProjectMapDetails({
              lat: project?.address?.geometry.location.lat,
              lng: project?.address?.geometry.location.lng,
              place_id: project?.address?.placeId,
            })
          );
          if (project.redevelopDev[0]?.companyLogo) {
            dispatch(setDeveloperCompanyLogo(project.redevelopDev[0].companyLogo));
          }
          else if (project.initialDev[0]?.companyLogo) {
            dispatch(setDeveloperCompanyLogo(project.initialDev[0].companyLogo));
          }
          dispatch(setProjectAddress(project?.address?.formatted_address));
        })
        .catch((e: any) => {
        });
    };

export const getProjectByUrl =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      dispatch(setLoadingAction('getDataLoading', true));
      var config = {
        method: "post",
        url: `${url}/project/getByUrl/${data.projectId}`,
        data: {
          accessToken: data.accessToken || localStorage.getItem('token'),
          role: data.role || localStorage.getItem('role')
        },
        //?accessToken=${data.accessToken || localStorage.getItem('token')}&role=${data.role || localStorage.getItem('role')}
        headers: _getHeaders({ "Content-Type": "application/json", accept: "*/*" }),
      };
      await axios(config)
        .then(async (response: any) => {
          const project1 = response.data.data.paymentStatusCheck;
          dispatch(setPaymentData(project1));
          const project = response.data.data.findProject;
          dispatch(setProjectName(project.projectName));
          dispatch(setProjectData(project));
          dispatch(setProjectSpecialFeatures(project.shortDescription));
          dispatch(setProjectReraNumber(project.reraNumber));
          dispatch(setProjectSalesNumber(project.salesNumber));
          dispatch(setProjectNumberOfUnits(project.noOfUnits));
          dispatch(setProjectNumberOfFlats(project.noOfFlats));
          dispatch(setProjectNumberOfBuildings(project.noOfBuilding));
          dispatch(setProjectNumberOfShops(project.noOfShops));
          dispatch(setProjectDateOfCompletion(project.completionDate));
          dispatch(setProjectStage(response.data.data.findStage.projectStages));
          dispatch(setProjectBrochure(project.brochure));
          dispatch(
            setProjectLocationDetails({
              landMark: project?.address?.formatted_address
                ? project?.address?.formatted_address
                : "",
              // address: project?.address?.address ? project?.address?.address : "",
              country: project?.address?.country ? project?.address?.country : "",
              // city: project?.address?.city ? project?.address?.city : '',
              pincode: project?.address?.postal_code ? project?.address?.postal_code : '',
            })
          );

          dispatch(setProjectImages(project.projectImages));
          dispatch(setTeaser(project.teaser));
          dispatch(setProjectAmenities(project.amenitiesId));
          dispatch(setProjectDeveloperId(project.devId));
          dispatch(setProjectMainImage([project.mainImage]));
          dispatch(setProjectLayoutImage([project.layoutImage]));
          dispatch(setProjectUnitType(project.unitType));
          dispatch(
            setProjectMapDetails({
              lat: project?.address?.geometry?.location?.lat,
              lng: project?.address?.geometry?.location?.lng,
              place_id: project.address?.placeId,
            })
          );
          dispatch(setProjectAddress(project.address.formatted_address));
          dispatch(setLoadingAction('getDataLoading', false));
          if (project.initialDev[0]?.companyLogo) {
            dispatch(setDeveloperCompanyLogo(project.initialDev[0].companyLogo));
          }
          if (project.redevelopDev[0]?.companyLogo) {
            dispatch(setDeveloperCompanyLogo(project.redevelopDev[0].companyLogo));
          }
        })
        .catch((error: any) => {
          dispatch(setLoadingAction('getDataLoading', false));
          if (error?.response?.data?.message === 'This Project URL doesn\'t exist...!') {
            dispatch(setRedirectTo404(true));
          } else {
          }
        });
    };

export const getAllProjectsList = (pageNumber: number, pageSize: number) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {

    var config = {
      method: 'get',
      url: `${url}/project?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      headers: _getHeaders({
        "Content-Type": "application/json",
        accept: "*/*"
      }),

    };
    dispatch(setSuccessAction("dataSaveSuccessfully", false))
    axios(config)
      .then((response: any) => {
        dispatch(getAllProject(response.data))

        // dispatch(setSuccessAction('signupSuccess', true));
        // dispatch(snackbarAlertAction(`OTP send sucessfully`, "success"));
      }).catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
        // dispatch(setErrorAction("loginError", "Failed to verify")); 
      })
  };


//get dr projects
export const getDrProjects = (isVerified: Boolean) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    var config = {
      method: "get",
      url: `${url}/project?isVerified=${isVerified}`,
      headers: _getHeaders({
        "Content-Type": "application/json",
        //  Authorization:
        //    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiI3NzgwMjE0MjcwIiwiaWF0IjoxNjc3MjM1NTAyLCJleHAiOjE2NzcyMzY0MDJ9.mBuK0TN1JfMrN1wUcA5npeTMuDDpFoyHkF5i0NgLVfY",
      }),
    };
    axios(config)
      .then(async (response: any) => {
        dispatch(getDrProject(response.data));
      })
      .catch((e: any) => {
      });
  };

export const getProjectsByName = (projectName: string) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    var config = {
      method: 'get',
      url: `${url}/project/getByName/${projectName}`,
      headers: _getHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiI3NzgwMjE0MjcwIiwiaWF0IjoxNjc3MjM1NTAyLCJleHAiOjE2NzcyMzY0MDJ9.mBuK0TN1JfMrN1wUcA5npeTMuDDpFoyHkF5i0NgLVfY'
      }),

    };
    axios(config)
      .then((response: any) => {
        dispatch(getAllProject(response.data));
      }).catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
      })
  };

/**Get project access by ownerId/developerId */
export const getProjectAccessByUserId = (data: any) => async (dispatch: NABHThunkDispatch) => {
  var config = {
    method: "post",
    url: `${url}/project/specificUserProjects`,
    headers: _getHeaders({ "Content-Type": "application/json" }),
    data: data
  };
  dispatch(setLoadingAction('getDataLoading', true));
  await axios(config)
    .then(async (response: any) => {
      if (response.data?.data?.project?.length > 0) {
        for (let i = 0; i < response.data.data.project.length; i++) {
          const property = response.data?.data?.property?.length > 0 && response.data.data.property.filter((item: any) => item.projectId === response.data.data.project[i].projectId)
          response.data.data.project[i].property = property;
        }
      }
      dispatch(getProjectAccessByUserID(response.data.data.project));
      dispatch(setUserPropertyRoles(response.data.data.propertyrole));
      dispatch(setLoadingAction('getDataLoading', false));
      dispatch(setSuccessAction('getProjectAccess', true));
    })
    .catch((e: any) => {
    });
};

//get favourites projects
export const getfavouriteByDevId =
  (developerId: any) => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    var config = {
      method: "get",
      url: `${url}/favourite/${developerId}`,
      headers: _getHeaders({
        "Content-Type": "application/json",
      }),
    };
    axios(config)
      .then(async (response: any) => {
        dispatch(getProjectByFavourite(response.data));
      })
      .catch((e: any) => {
      });
  };


export const saveBidding = (amount: any, projectId: string, personaId: string, transactionNumber: string, imageUrl: any) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    var config = {
      method: "post",
      url: `${url}/transaction`,
      data: {
        "amount": parseInt(amount),
        "amountType": "CREDIT",
        "transactionNumber": transactionNumber,
        "additionalInfo": `Dummy text- ${transactionNumber}`,
        "projectId": projectId,
        "persona": "developer",
        "personaId": personaId,
        "category": "BIDDING",
        "paymentStatus": "SUCCESS",
        "imageUrl": imageUrl
      },
      headers: _getHeaders({ "Content-Type": "application/json" }),
    };
    await axios(config)
      .then((response: any) => {
        dispatch(snackbarAlertAction(`Project bidding successful`, "success"));
        dispatch(getUploadURL(null));
      })
      .catch((e: any) => {
        dispatch(snackbarAlertAction(`Error: ${e?.response?.data?.message}`, "error"));
      });
  };

export const getAllCities = () =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    var config = {
      method: "get",
      url: `${url}/filter/getAllLocality`,
      headers: _getHeaders({
        "Content-Type": "application/json",
      }),
    };
    axios(config)
      .then(async (response: any) => {
        dispatch(setAllCities(response.data.data));
        // dispatch(getDrProject(response.data));
      })
      .catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
      });
  };

export const getProjectsByArea = (city: string, area: string, totalLandAreaMin: number, totalLandAreaMax: number, stages: string | string[]) =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    const tempURL =
      `${url}/project?developerProject=false` +
      (city === 'None' ? '' : `&locality=${city}`) +
      (area ? `&sublocality=${area}` : '') +
      (totalLandAreaMin ? `&totalLandAreaMin=${totalLandAreaMin}` : '') +
      (totalLandAreaMax ? `&totalLandAreaMax=${totalLandAreaMax}` : '')
      + `&stages=${stages}`;
    var config = {
      method: "get",
      url: tempURL,
      headers: _getHeaders({
        "Content-Type": "application/json",
      }),
    };
    axios(config)
      .then(async (response: any) => {

        console.log("filtered dr projects are", response);
        // dispatch(setAllCities(response.data));
        dispatch(getDrProject(response.data));
      })
      .catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
      });
  };

// ---fetch amenities---
export const getAllAmenities = () =>
  async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    var config = {
      method: 'get',
      url: `${url}/amenities`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
    };
    await axios(config)
      .then((response: any) => {
        console.log('amenities', response.data.data);
        dispatch(getAllAmenitiesImported(response.data.data));
      }).catch((error: any) => {
        dispatch(snackbarAlertAction(`Error: ${error.response && error.response.data.message}`, "error"));
      })
  };
import { Avatar, Grid, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { borderBottom } from '@mui/system';
import { Delete, Edit } from '@mui/icons-material';
import { deleteMember } from '../../../redux/thunk/member-thunk';
import Styles from './MemberItem.module.css';
import { useDispatch } from 'react-redux';

const MemberItem = ({ member, index, totalMembersCount, setMemberToBeEdited, setIsAddMemberOpen }: any) => {

  console.log('member(in member item) is', member);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  function handleMoreButtonClick(e: any) {
    setAnchorEl(e.target);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleEditMember = (memberId: string) => {
    console.log('member to be added is', memberId);
    setMemberToBeEdited(memberId);
    setIsAddMemberOpen(true);
  }

  async function handleDelete(memberId: string) {
    // dispatch(setLoadingAction('unlinkingProject', true, 'getDataError'));
    // if(props.role === 'Developer')
    //   await dispatch(editDeveloper(props.id, { redevelopProjectId: props.mappedProjects.filter((project: any) => project.projectId !== props.projectDetails.projectId).map((project: any) => project.projectId) }));
    // else if(props.role === 'Lawyer')
    //   await dispatch(editLawyer(props.id, { projectId: props.mappedProjects.filter((project: any) => project.projectId !== props.projectDetails.projectId).map((project: any) => project.projectId) }));
    // else if(props.role === 'Architect')
    //   await dispatch(updateArchitect(props.id, {projectId: props.mappedProjects.filter((project: any) => project.projectId !== props.projectDetails.projectId).map((project: any) => project.projectId) }));
    dispatch(deleteMember(memberId));

    handleClose();
  }

  return (
    <Grid
      item
      container
      alignItems="center"
      xs={12}
      sx={{
        // height: '70px',
        pr: "1%",
        pl: "2%",
        // maxHeight: '175px',
        fontFamily: "Montserrat-Medium",
        borderBottom: index < totalMembersCount - 1 ? "1px solid #c1c1c1" : "none",
      }}
    >
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Grid
        item
        sx={{
          width: "18.33vw",
          fontFamily: "Montserrat-Regular",
          pr: "50px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px"
        }}
        title={member.name}
      >
        {member.name}
      </Grid>
      <Grid
        item
        sx={{
          width: "14.68vw",
          fontFamily: "Montserrat-Regular",
          pr: "40px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px"
        }}
      >
        {member.designation}
      </Grid>
      <Grid item className={Styles["Member-description"]}>
        {member.description}
      </Grid>
      <Grid
        item
        sx={{
          width: "5vw",
          fontFamily: "Montserrat-Regular",
          pr: "15px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Avatar style={{ width: '30px', height: '30px', margin: '5px 10px 5px 0' }} src={member.images[0]} />
      </Grid>
      <Grid item sx={{ width: "2.5vw" }}>
        <IconButton onClick={handleMoreButtonClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          sx={{ padding: "1px" }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => handleEditMember(member.memberId)}
            style={{ color: "#9b009b", padding: "8px" }}
          >
            <ListItemIcon>
              <Edit sx={{ color: "#9b009b" }} fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => handleDelete(member.memberId)}
            style={{ color: "#ff6767", padding: "8px" }}
          >
            <ListItemIcon>
              <Delete sx={{ color: "#ff6767" }} fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
};

export default MemberItem;
// export const url = 'https://devapi.dreamsredeveloped.com';   /** Dev URL */
// export const url = 'https://testapi.dreamsredeveloped.com';   /**Test */
export const url = 'https://api.dreamsredeveloped.com';   /** Prod URL */


export const appFrontendURL = 'https://app.dreamsredeveloped.com'; /**PROD USER APP */
// export const appFrontendURL = 'https://devapp.dreamsredeveloped.com'; /**DEV USER APP */
// export const appFrontendURL = 'https://test.d360vnsj5zfb69.amplifyapp.com'; /**TEST USER APP */
// export const appFrontendURL = 'https://devapp.dreamsredeveloped.com';
// export const appFrontendURL = 'https://test.d360vnsj5zfb69.amplifyapp.com';
export const adminURL = 'https://testadmin.dreamsredeveloped.com';

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { RootState} from '../../../redux/redux-store';
import { getLawyerByUrl } from "../../../redux/thunk/lawyer-thunks";
import NirmanNameLogo from "../../../theme/NirmanLogoWithName.png";
import { useParams } from "react-router-dom";
const BannerContent = () => {
  const isMobileView = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  let  lawyerId  = useParams();
  const lawyerData = useSelector(
    (state: RootState) => state.DATA_REDUCER.lawyerProjects
  );

  const datas = lawyerData?.data
  const dt = datas?.companyLogo

  // useEffect(() => {
  //   dispatch(getLawyerByUrl(lawyerId.id));
  // }, []);


  return (
    <>
  
           
    <Grid
      item
      container
      className="grid-main 2023"
      direction="column"
      justifyContent="space-evenly"
      alignItems={isMobileView ? "center" : "flex-start"}
      gap="19px"
      sx={{
        width: isMobileView ? "100%" : "63.5%",
        height: "100%",
        display:'flex',
        justifyContent:'space-between',
        paddingRight: isMobileView ? 0 : "30px",
        paddingTop: isMobileView ? "30px" : 0,
        backgroundImage:
          "linear-gradient(to right, rgba(255,255,255,1) 80%, rgba(255,255,255,0.9) 85%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 95%)",
      }}
    >
     
      <Grid
        item
        sx={{ display: isMobileView ? 'none' : 'initial', height: "calc(0.3546 * 44.66%)", paddingLeft: isMobileView ? 0 : "21%" }}
      >
        <img
          // src={isMobileView ? NirmanNameLogo : NirmanLogo}
          src={isMobileView ? dt:dt}
          alt="Logo"
          style={{ visibility: dt ? 'visible' : 'hidden', width: isMobileView ? "20.23vw" : "auto", height: "100%" }}
        />
      </Grid>

      <Grid item sx={{width: '100%'}}>
        <Typography
          variant="h2"
          className="grid-2"
          sx={{
            fontWeight: "400",
            fontFamily: 'Montserrat-Bold',
            opacity: 0.8,
            fontSize: isMobileView ? "3.22vh" : "4.5vh",
            lineHeight: "6.85vh",
            width: '85%',
          }}
        >
       {datas?.lawyerName}
          
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          className="headText"
          variant="h5"
          sx={{
            width: isMobileView ? "100%" : "90%",
            fontWeight: "normal",
            opacity: 0.8,
            fontFamily: 'Montserrat-Medium',
            fontSize: isMobileView ? "17px" : "2.2vh",
            lineHeight: isMobileView ? "28px" : "3.5vh",
            color: isMobileView ? "#878787" : "black",
          }}
        >
          {datas?.description}
        </Typography>
      </Grid>
      <Grid item alignSelf="flex-start" className="countDiv">
        <Typography
          variant="h5"
          sx={{
            color: isMobileView ? "#878787" : "black",
            fontWeight: "normal",
            fontSize: isMobileView ? "1.8vh" : "2.59vh",
            lineHeight: isMobileView ? "3.65vh" : "3.8vh",
            width: '100%',
          }}
        >
          <Box className="home-icon-div" sx={{margin: isMobileView ? 'auto' : 'initial', width: isMobileView ? '60%' : '80%'}}>
            <Box className="home-icon" sx={{width: isMobileView ? '100%' : '33.32%'}}>
              <Box className="home-name">Projects Completed</Box>
              <Box className="home-number">{datas?.completedProjects || 0}</Box>
            </Box>

            <Box className="home-icon" sx={{width: isMobileView ? '100%' : '33.32%'}}>
              <Box className="home-name">Ongoing Projects</Box>
              <Box className="home-number">{datas?.ongoingProjects || 0}</Box>
            </Box>

            <Box className="home-icon" sx={{width: isMobileView ? '100%' : '33.32%'}}>
              <Box className="home-name">Established since</Box>
              <Box className="home-number">{datas?.establishedYear || 0}</Box>
            </Box>
          </Box>
        </Typography>
      </Grid>
    </Grid>
    
     
  
    </>
  );
};

export default BannerContent;

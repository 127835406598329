export const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
  let headers = inputHeaders || {};
  headers["Accept"] = "application/json";
  const headersWithAuth = _getToken()
    ? {
      ...headers,
      Authorization: `Bearer ${_getToken()}`,
    }
    : headers;
  return headersWithAuth;
};

// Auth token
const getCookie = (name:any) => {
  const value = `; ${document.cookie}`;
  const parts:any = value.split(`; ${name}=`);
  if (parts.length === 2) {
      return parts.pop().split(';').shift();
  }
  return null;
};

let token: string | null = localStorage.getItem("token");
if(!token)
  token = getCookie('token');

const _getToken = () => {
  return token;
};
export const updateToken = (newToken: string) => {
  localStorage.setItem("token", newToken);
  token = newToken;
};
export const isLoggedIn = () => {
  return token !== null;
};


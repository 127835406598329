import React, { useContext } from "react";
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  useMediaQuery
} from "@mui/material";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { SidebarContext } from "../../../SidebarContext";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import { LibraryAddOutlined } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { Location } from "react-router-dom";
import OwnersIcon from '../../../../theme/OwnersIcon.png';
import ProjectsIcon from '../../../../theme/ProjectsIcon.png';
import DevelopersIcon from '../../../../theme/DevelopersIcon.png';
import ArchitectsIcon from '../../../../theme/ArchitectsIcon.png';
import LawyersIcon from '../../../../theme/LawyersIcon.png';
const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(0)};
    }
  }

    .MuiListSubheader-root {
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(15)};
      color: #000000;
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      
      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${"#5569ff"};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: #1C1D21;
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${alpha("#1C1D21", 0.7)};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0.1, 5)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${alpha("#1C1D21", 0.3)};
            font-size: ${theme.typography.pxToRem(20)};
           
          }
          
          .MuiButton-endIcon {
            color: ${alpha("#1C1D21", 0.5)};
          
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha("#993399", 0.08)};
            color: ${"#1C1D21"};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${"#1C1D21"};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;
            border-radius: 10px;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${"#99339921"};
                opacity: 0;
                transition: ${theme.transitions.create([
    "transform",
    "opacity",
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
              
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 0.8, fontSize: '16px', letterSpacing: '-0.5px';
                }
              }
            }
          }
        }
      }
    }
    }
`
);

function SidebarMenu(props: any) {
  const location = useLocation();
  const path = location.pathname;
  const { closeSidebar } = useContext(SidebarContext);
  const logUser: any = sessionStorage.getItem("DREAMUSERAPP");
  const userInfo: any = JSON.parse(logUser);
  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <>
      <MenuWrapper>
      {
        props?.menuItemDetails?.map((menuItemDetail: any) => (
          <List style={{ padding: 0, margin: "1% 4%" }}>
            <SubMenuWrapper>
              <List style={{ padding: 0, margin: 0 }}>
                <ListItem>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={menuItemDetail.path}
                    style={{ padding: "10px 20px" }}
                  >
                    <span style={{ marginLeft: "5px", color: path === menuItemDetail.path ? '#662E91' : '#000000', fontFamily: 'Montserrat-Medium', opacity: 0.8, fontSize: '16px', letterSpacing: '-0.5px' }}>{menuItemDetail.name}</span>
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        ))
      }
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;

const sideBarText = {
  color: "#2E4765",
  textDecoration: "none",
};
const activeSideBarText = {
  color: "red",
  textDecoration: "none",
};

const sideBar = {
  minWidth: "11rem",
  minHeight: "-webkit-fill-available",
  maxHeight: "-webkit-fill-available",
};

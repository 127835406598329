/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, TablePagination, TextField, Typography, Modal, useMediaQuery } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Search } from '@mui/icons-material';
import "./transaction.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/redux-store";
import { getTransactionData, uploadFile, initiatePayment, capturePaymentStatus, createOrders } from "../../../redux/thunk/thunks";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AttachFile from '../../../theme/attach-file.png'
import { BiddingDialog } from '../DrProjects/BiddingDialog';
import { getProjectBid } from "../../../redux/data/data-actions";
import { LoadingButton } from '../../../layouts/LoadingButton';
import { setSuccessAction } from '../../../redux/display/display-actions';
import MessageIcon from '@mui/icons-material/Message';
import { _getHeaders } from '../../../redux/api-headers';
import useRazorpay, { RazorpayOptions } from "react-razorpay";

const pendingBox = {
    color: '#faad14',
    background: '#fff50026',
    border: '1px solid #ffbf40',
    padding: '2px 5px',
    textAlign: 'center',
    borderRadius: '5px',
    fontSize: '12px',
    opacity: 0.8,
    maxWidth: '140px'
}
const approveBox = {
    color: '#00a339',
    background: '#00A03B1A',
    border: '1px solid #00A03BB3',
    padding: '2px 5px',
    textAlign: 'center',
    borderRadius: '5px',
    fontSize: '12px',
    opacity: 0.8,
    maxWidth: '140px'
}
const unapprovedBox = {
    color: '#b40000',
    background: '#ff19431a',
    border: '1px solid #cf0000b3',
    padding: '2px 5px',
    textAlign: 'center',
    borderRadius: '5px',
    fontSize: '12px',
    opacity: 0.8,
    maxWidth: '140px'
}

const submitButton = {
    minWidth: "120px",
    padding: '5px 10px',
    backgroundColor: "#993399",
    color: "#fff",
    borderRadius: "10px",
    "&:hover": {
        backgroundColor: "#993399",
        color: "#fff",
    },
};
const cancelButton = {
    minWidth: "120px",
    padding: '5px 10px',
    backgroundColor: "#fff",
    color: "#993399",
    border: '1px solid #993399',
    borderRadius: "10px",
    "&:hover": {
        backgroundColor: "#fff",
        color: "#993399",
    },
};

const SubscriptionsTransactions = () => {
    const isMobileView = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const Razorpay = useRazorpay();
    const user: any = sessionStorage.getItem("DREAMUSERAPP");
    const userInfo: any = JSON.parse(user);
    const [category, setCategory] = useState("SUBSCRIPTION");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [openBidding, setOpenBidding] = useState(false);
    const [openModal1, setOpenModel1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [no, setNo] = useState('');
    const navigate = useNavigate();
    const uploadURL = useSelector((state: RootState) => state.DATA_REDUCER.uploadURL);
    const transactions: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.transactions
    );
    const projectBids: any = useSelector((state: RootState) => state.DATA_REDUCER.projectBid);
    const subscriptionBid: any = useSelector((state: RootState) => state.DATA_REDUCER.subscriptionBid);
    const projectBid: any = projectBids ? projectBids : subscriptionBid ? subscriptionBid : null;
    console.log("projectBId",projectBid);
    console.log("biiiid",projectBid?.totalAmount)
    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.dataSaveSuccessfully);
    const [success1, setSuccess] = useState(false);
    const bidingTransaction: any = useSelector((state: RootState) => state.DATA_REDUCER.bidingTransaction);

    useEffect(() => {
        if (success) {
            dispatch(setSuccessAction('dataSaveSuccessfully', false));
            dispatch(getTransactionData(1, rowsPerPage, category));
            const url = bidingTransaction?.data?.instrumentResponse?.redirectInfo?.url;
            // window.open(url, '_blank');
            setOpenModel1(false);
        }
    }, [success])

    const style = {
        textAlign: 'center',
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobileView ? '90vw' : 400,
        borderRadius: '10px',
        bgcolor: 'background.paper',
        border: '2px solid #993399',
        boxShadow: 24,
        p: 2,
    };

    const handleChangeCategory = (event: any) => {
        setCategory(event.target.value);
        if (event.target.value === "BIDDING") {
            navigate('/developers-transaction/bidding');
        }
    };

    const handleSearch = (event: any) => { };

    const handlePageChange = (event: unknown, newPage: number) => {
        setPageNumber(newPage);
        dispatch(getTransactionData(newPage + 1, rowsPerPage, category));
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPageNumber(0);
        dispatch(getTransactionData(1, parseInt(event.target.value), category));
    };
    useEffect(() => {
        dispatch(getTransactionData(1, rowsPerPage, category));
    }, []);

    const handleClose = () => {
        setOpenBidding(false);
    }

    const handleNext = () => {
        setOpenModel1(true);
    }

    const handleUpload = async (value: any) => {
        setLoading(true);
        await dispatch(uploadFile(value));
        setLoading(false);
    }

    // const handleExtendBidDailog = async () => {
    //     // setDisabled(true);
    //     // await dispatch(saveSubscription(no, uploadURL));
    //     // setNo("");
    //     // setOpenModel1(false);
    //     // setOpenBidding(false);
    //     // setDisabled(false);
    //     setDisabled(true);
    //     const data = {
    //         "amount": parseInt(projectBid?.totalAmount),
    //         "amountType": "CREDIT",
    //         "additionalInfo": "Extend Subscription",
    //         "persona": "developer",
    //         "projectId": null,
    //         "personaId": userInfo?.developer?.developerId,
    //         "category": "SUBSCRIPTION",
    //         "paymentStatus": "INITIATED",
    //         "imageUrl": "",
    //         "approvalStatus": "PENDING",
    //         "isPaymentOnline": true
    //     }
    //     await dispatch(initiatePayment(data));
    //     setOpenModel1(false);
    //     setOpenBidding(false);
    //     setDisabled(false);
    //     dispatch(getProjectBid(null));
    // }

    const dateConvert = (value: any) => {
        const date = new Date(value);
        return date ? (date.toLocaleDateString('en-us', { day: 'numeric' }) + ' ' + date.toLocaleDateString('en-us', { month: 'short' }) + ' ' + date.toLocaleDateString('en-us', { year: 'numeric' })) : 'NA'
    }

    // const handleCreateOrder = async () => {
    //     setOpenBidding(false);
    //     setOpenModel1(false);
    //     sessionStorage.setItem('clear', 'YES');
    //     const info1: any = {}
    //     info1.personaId = userInfo?.developer?.developerId
    //     info1.projectId= null
    //     info1.amountType= "CREDIT"
    //     info1.category = "SUBSCRIPTION"
    //     info1.persona = "developer"
    //     info1.amount = parseInt(projectBid?.totalAmount);
    //     info1.isPaymentOnline = true
    //     // info.userId = parseInt(userInfo?.userId);
    //     console.log("info1",info1);
        
    //     const orderDetails: any = await dispatch(createOrders(info1));
    //     console.log("orderdetails",orderDetails);
        
    //     if (orderDetails?.status === 200) {
    //       makePayment(orderDetails?.data?.data);
    //       console.log("orderDetails id",orderDetails?.data?.data?.id);
          
    //     }
    //     // setIsMutating(false);
    //   // }0
    // }
  
  
    // const initializeRazorpay = () => {
    //   return new Promise((resolve) => {
    //     const script = document.createElement("script");
    //     script.src = "https://checkout.razorpay.com/v1/checkout.js";
    //     // script.src = "https://checkout.razorpay.com/v1/magic-checkout.js";
    //     script.onload = () => {
    //       resolve(true);
    //     };
    //     script.onerror = () => {
    //       resolve(false);
    //     };
  
    //     document.body.appendChild(script);
    //   });
    // };
  
    // const makePayment = async (order: any) => {
    //   const orderId = order?.id;
    //   const res = await initializeRazorpay();
    //   console.log("470", res, orderId);
    //   if (!res) {
    //     alert("Razorpay SDK Failed to load");
    //     return;
    //   }
    //   // Make API call to the serverless API
    
    //     const razorpayResult = await fetch("/api/razorpay", {
    //       method: "POST",
    //       headers: _getHeaders(),
    //       body: JSON.stringify({ orderId }),
    //     }).then((t) => { }); 
    //     // const orderId :any= razorpayResult.orderId 
    //     console.log("razerpay",razorpayResult);
        
    //   const options:any = {
    //     name: "Dreams Redeveloped",
    //     currency: order?.currency,
    //     amount: order?.amount,
    //     order_id: orderId,
    //     description: "Thank You for booking a seat.",
    //     image: "",
    //     key: 'rzp_live_kh7YrXfgjdYbk9',
    //     handler: async function (response:any) {
    //       console.log("rrr",response);
          
    //       try {
    //         setSuccess(true);;
    //         const info = {
    //           "razorpay_payment_id": response?.razorpay_payment_id,
    //           "razorpay_order_id": response?.razorpay_order_id,
    //           "razorpay_signature": response?.razorpay_signature,
    //           // "orderId": order?.id,
    //           "code": 200
    //         }
    //         console.log("info",info);
    //         const updateOrderStatus: any = await capturePaymentStatus(info);
    //       } catch (err) {
    //         console.log('error is', err);
    //       }
    //     },
    //     modal: {
    //       confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
    //       // This function is executed when checkout modal is closed
    //       // There can be 3 reasons when this modal is closed.
    //       ondismiss: async (reason:any) => {
    //         // When modal is auto closed because of time out
    //         if (reason === 'timeout') {
    //           sessionStorage.removeItem('clear');
    //           // router.push('/');
    //           navigate('/');
    //         } else {
    //           const info = {
    //             "orderId": orderId,
    //             "status": 400
    //           }
    //           const updateOrderStatus: any = await capturePaymentStatus(info);
    //           // const updateOrderStatus = await triggerCheck(info);
    //         }
    //       },
    //     },
    //     timeout: 300,
    //     theme: {
    //       color: "#662e91",
    //     },
    //     prefill: {
    //       // name: `${userInfo?.lastName}${' '}${userInfo?.firstName}`,
    //       email: userInfo?.emailId,
    //       contact: userInfo?.mobile,
    //     },
    //   };
    //   console.log("options",options);
      
    //   const paymentObject = new Razorpay(options);
    //   paymentObject.open();
    
    // // catch (error) {
    // //   console.error('Error fetching /api/razorpay:', error);
    // //   alert('Failed to initiate payment');
    // // }
    // };

    const handlePayment = async () => {
        try{
        sessionStorage.setItem('clear', 'YES');
          setDisabled(true)
          const info1: any = {}
          info1.personaId = userInfo?.developer?.developerId
          info1.projectId= null
          info1.amountType= "CREDIT"
          info1.category = "SUBSCRIPTION"
          info1.persona = "developer"
          info1.amount = parseInt(projectBid?.totalAmount);
          info1.isPaymentOnline = true
          // info.userId = parseInt(userInfo?.userId);
          console.log("info1",info1);
          const orderDetails: any = await dispatch(createOrders(info1));
          console.log("orderdetails",orderDetails);
          
          setDisabled(false)
       
        const options:any = {
          key:"rzp_live_kh7YrXfgjdYbk9", 
          amount: orderDetails?.data?.data?.amount,
          currency: orderDetails?.data?.data?.currency,
          name: 'Dreams Redeveloped',
          description: 'Test Transaction',
          image: '',
          order_id: orderDetails?.data?.data?.id,
          handler: async function (response:any) {
            console.log("reeee",response)
            
              try{
                setSuccess(true);
                setOpenModel1(false);
                setDisabled(false);
                      const info = {
                        "razorpay_payment_id": response?.razorpay_payment_id,
                        "razorpay_order_id": response?.razorpay_order_id,
                        "razorpay_signature": response?.razorpay_signature,
                        "orderId": orderDetails?.data?.data?.id,
                        "code": 200
                      }
                      console.log("info606",info);
                      const updateOrderStatus: any = await dispatch(capturePaymentStatus(info));
                      console.log("updateorderstatus",updateOrderStatus);
                      setSuccess(true);
                      navigate('/developers-transaction/bidding');
                    } catch (err) {
                      console.log('error is', err);
                    }
                  },
         
                  modal: {
                    confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
                    // This function is executed when checkout modal is closed
                    // There can be 3 reasons when this modal is closed.
                    ondismiss: async (reason:any) => {
                      // When modal is auto closed because of time out
                      if (reason === 'timeout') {
                        sessionStorage.removeItem('clear');
                        // router.push('/');
                        navigate('/');
                      } else {
                        const info = {
                          "orderId": orderDetails?.data?.data?.id,
                          "code": 400
                        }
                        console.log("modelinfo",info);
                        
                        const updateOrderStatus: any = await capturePaymentStatus(info);
                        // const updateOrderStatus = await triggerCheck(info);
                        console.log("9899",updateOrderStatus);
                        
                      }
                    },
                  },
                  timeout: 500,
                  theme: {
                    color: "#662e91",
                  },
          
          prefill: {
            name: userInfo?.developer?.name,
            email: userInfo?.emailId,
            contact: userInfo?.mobile
          },
         
          
          // notes: {
          //   address: 'Your Address'
          // },
          
        };
        console.log("prefill",options?.prefill);
    console.log("optionssss",options);
    
        // const paymentObject = new window.Razorpay(options);
        // paymentObject.open();
        const paymentObject = new Razorpay(options);
        paymentObject.open();
        console.log("paymentobject",paymentObject);
      }catch (error) {
        console.error('Error during payment:', error);
        alert('Payment failed');
      }
      };


    return (
        <><script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
            {isMobileView ?
                <Grid item container flexDirection='column' sx={{ width: '92vw', margin: '20px auto', border: '1px solid #d9d9d9' }}>
                    {
                        transactions && transactions?.transactions && transactions?.transactions.map((transaction: any, index: number) => (
                            <Grid item container flexDirection='column'>
                                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: index > 0 ? '69px 19px 19px' : '19px 19px' }}>
                                    <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                                        Transaction Date
                                    </Grid>
                                    <Grid item container xs='auto' sx={{ width: '29vw' }} flexDirection='column'>
                                        <div>{dateConvert(transaction.createdAt)}</div>
                                        <small>{moment(new Date(transaction.createdAt)).format('LT')}</small>
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                                    <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                                        Payments
                                    </Grid>
                                    <Grid item container xs='auto' sx={{ width: '29vw' }}>
                                        {new Intl.NumberFormat('en-US').format(transaction?.amount) + ' INR'}
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                                    <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                                        End Date
                                    </Grid>
                                    <Grid item container flexDirection='column' sx={{ width: '29vw' }} xs='auto'>
                                        <div>{dateConvert(transaction.subscriptionEndDate)}</div>
                                        <small>{moment(new Date(transaction.subscriptionEndDate)).format('LT')}</small>
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                                    <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                                        Offline / Online
                                    </Grid>
                                    <Grid item container xs='auto' sx={{ width: '29vw' }}>
                                        {transaction.isPaymentOnline ? 'Online' : 'Offline'}
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                                    <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                                        Status
                                    </Grid>
                                    <Grid item container xs='auto' sx={{ width: '29vw' }}>
                                        <Box sx={transaction?.approvalStatus === 'APPROVED' ? approveBox : transaction?.approvalStatus === 'NOT_APPROVED' ? unapprovedBox : pendingBox}>{transaction?.approvalStatus}</Box>
                                    </Grid>
                                </Grid>
                                <Grid item container sx={{ borderBottom: '1px solid #d9d9d9', p: '19px 19px' }}>
                                    <Grid item container xs='auto' alignItems='center' sx={{ width: '47vw', fontSize: '14px', color: '#788195', }}>
                                        Photo
                                    </Grid>
                                    <Grid item container xs='auto' sx={{ width: '29vw' }}>
                                        {transaction?.imageUrl && <img src={AttachFile} alt='attach' style={{ width: '30px', marginRight: '20px' }} onClick={() => window.open(transaction?.imageUrl, '_blank')} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                :
                <>
                    <Box style={{ marginTop: "10px", marginBottom: "12px", marginRight: "20px", height: '40px' }}>
                    </Box>
                    <Box className="transaction-wrapper">
                        <Grid container className="MainContainer" spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={7}>
                                <Typography className='page-title'>Transactions - Subscriptions</Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                <Button variant='contained' color='primary' sx={{ borderRadius: '10px', height: '38px', fontFamily: 'Montserrat-Medium', letterSpacing: '1px' }} onClick={() => setOpenBidding(true)}>Extend Subscription</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    fullWidth
                                    size="small"
                                    value={category}
                                    onChange={handleChangeCategory}
                                    className="selectBox"
                                >
                                    <MenuItem className='menu-item' value={"SUBSCRIPTION"}>Subscriptions</MenuItem>
                                    <MenuItem className='menu-item' value={"BIDDING"}>Bidding</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} className="transaction-wrapper-table">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='heading-title'>Transaction Date</TableCell>
                                        <TableCell className='heading-title'>Payments</TableCell>
                                        <TableCell className='heading-title'>Subscription End Date</TableCell>
                                        <TableCell className='heading-title'>Offline / Online</TableCell>
                                        <TableCell className='heading-title'>Status</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transactions && transactions?.transactions && transactions?.transactions.map((row: any) => {
                                        return (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className='cell-title'>
                                                    <div>{dateConvert(row.createdAt)}</div>
                                                    <small>{moment(new Date(row.createdAt)).format('LT')}</small>
                                                </TableCell>
                                                <TableCell className='cell-title'>
                                                    {new Intl.NumberFormat('en-US').format(row?.amount) + ' INR'}
                                                </TableCell>
                                                <TableCell className='cell-title'>
                                                    <div>{dateConvert(row.subscriptionEndDate)}</div>
                                                    <small>{moment(new Date(row.subscriptionEndDate)).format('LT')}</small>
                                                </TableCell>
                                                <TableCell className='cell-title'>{row.isPaymentOnline ? 'Online' : 'Offline'}</TableCell>
                                                <TableCell className='cell-title'>
                                                    <Box sx={row?.approvalStatus === 'APPROVED' ? approveBox : row?.approvalStatus === 'NOT_APPROVED' ? unapprovedBox : pendingBox}>{row?.approvalStatus}</Box>
                                                </TableCell>
                                                <TableCell sx={{ padding: '5px', cursor: 'pointer' }}>
                                                    {row?.imageUrl && <img src={AttachFile} alt='attach' style={{ width: '30px', marginRight: '20px' }} onClick={() => window.open(row?.imageUrl, '_blank')} />}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={transactions && transactions?.count}
                            rowsPerPage={rowsPerPage}
                            page={pageNumber}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                    {openModal1 &&
                        <Modal open={openModal1}>
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: '18px' }}>
                                    Are you sure you want to extend subscription?
                                </Typography>
                                {/* <Box m={2} display='flex' alignItems='center'>
                                    <TextField name='no' value={no} onChange={(e) => setNo(e.target.value)} size="small" placeholder="Enter Transaction / Cheque No." />
                                    <Box ml={1} mr={1} sx={{ cursor: 'pointer' }}>
                                        <input accept="*" style={{ display: 'none' }} id="contained-button-file" type="file" onChange={(e: any) => handleUpload(e.target.files[0])} />
                                        <label htmlFor="contained-button-file">
                                            <Button component="span" sx={{ padding: 0 }} disabled={loading}>
                                                <img src={AttachFile} alt='attach' style={{ width: '40px' }} />
                                            </Button>
                                        </label>
                                    </Box>
                                    {uploadURL && <a href={uploadURL} target="_blank">View</a>}
                                </Box> */}
                                <Box pt={2} display='flex' justifyContent="space-between">
                                    <LoadingButton sx={cancelButton} loading={isDisabled} onClick={() => { setOpenModel1(false); }}>No</LoadingButton>
                                    <LoadingButton disabled={isDisabled} loading={isDisabled} sx={submitButton} 
                                    // onClick={(e) => { handleExtendBidDailog() }}
                                    onClick={() => handlePayment()}
                                    >Yes</LoadingButton>
                                </Box>
                            </Box>
                        </Modal>
                    }
                    {openBidding && <BiddingDialog info={null} handleClose={handleClose} handleNext={handleNext} />}
                </>
            }
          <div
            style={{
              position: 'fixed',
              cursor: 'pointer',
              padding: '8px 8px 2px 8px',
              backgroundColor: '#662e91',
              borderRadius: '5px',
              bottom: isMobileView ? '20px' : '20px',
              right: isMobileView ? '20px' : '100px',
            }}
            onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
          >
            <MessageIcon style={{color: 'white'}} />
          </div>
        </>
    )
};
export default SubscriptionsTransactions;
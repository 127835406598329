import { NABHThunkDispatch, RootState } from "../redux-store";
import { _getHeaders, updateToken } from "../api-headers";
import { url } from "../url";
import { SetLawyerProjectsAction, setEditUserDetails } from "../data/data-actions";
import { setLoadingAction, setSuccessAction, snackbarAlertAction } from "../display/display-actions";

var axios = require("axios");

export const editLawyer =
  (id: string, data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      // dispatch(setLoadingAction("editUserLoading", true));

      var config = {
        method: "put",
        url: `${url}/lawyer/${id}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
        }),
        data,
      };
      axios(config)
        .then((response: any) => {
          dispatch(setLoadingAction("editUserLoading", false));
          dispatch(snackbarAlertAction(`Lawyer updated successfully`, "success"));
          if (response)
            dispatch(setSuccessAction("editUserSuccess", true));
        })
        .catch((error: any) => {
          dispatch(setLoadingAction("editUserLoading", false));
          dispatch(snackbarAlertAction(`Error: ${error?.response?.data.message}`, "error"));
        });
    };


export const getLawyerById =
  (data: any) =>

    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      console.log(data)
      var config = {
        method: "get",
        url: `${url}/lawyer/${data.id}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
          accept: "*/*",
        }),
      };

      axios(config)
        .then(async (response: any) => {
          const lawyer = response.data.data;

          dispatch(setEditUserDetails(lawyer));

          async function modifyDeveloperProjectDetails(projects: any) {
            for (let i = 0; i < projects.length; i++)
              projects[i].projectImages = projects[i].projectImages;

            return projects;
          }

          async function modifyDeveloperPartnerDetails(partners: any) {
            for (let i = 0; i < partners.length; i++)
              partners[i].images = partners[i].images;

            return partners;
          }
        })
        .catch((e: any) => {
          console.log("error is", e);
        });
    };

export const getLawyerByUrl =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      // dispatch(setLoadingAction('getDataLoading', true));
      var config = {
        method: "get",
        url: `${url}/lawyer/getByUrl/${data}`,
        headers: _getHeaders({
          "Content-Type": "application/json",
          accept: "*/*",
        }),
      };
      await axios(config)
        .then(async (response: any) => {
          dispatch(SetLawyerProjectsAction(response.data));
          dispatch(setLoadingAction('getDataLoading', false));
        })
        .catch((e: any) => {
          dispatch(setLoadingAction('getDataLoading', false));
        });
    };

export const getAllLawyers =
  (data: any) =>
    async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
      var config = {
        method: "get",
        url: `${url}/lawyer`,
        headers: _getHeaders({
          "Content-Type": "application/json",
        }),
      };
      axios(config)
        .then((response: any) => {
          console.log(response)
        })
        .catch((e: any) => {
          console.log(e, "error");
        });
    };

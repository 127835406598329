import { useState, useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styles from "./EditDeveloper.module.css";
import { LoadingButton } from "../../../layouts/LoadingButton";
import { Formik, Form, Field, setNestedObjectValues } from "formik";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Delete } from '@mui/icons-material';
import Section from "./Section";
import {
  editDeveloper,
  getDevById,
} from "../../../redux/thunk/developer-thunk";
import { EditDeveloperSchema } from "./EditDeveloperSchema";
import FormattedErrorMessage from "./FormattedErrorMessage";
import uploadDeveloperFiles from "../../../utils/uploadDeveloperFiles";
import { RootState } from "../../../redux/redux-store";
import {
  setErrorAction,
  setLoadingAction,
  setSuccessAction,
  snackbarAlertAction,
} from "../../../redux/display/display-actions";
import { appFrontendURL } from "../../../redux/url";
import ProjectListItem from "./ProjectListItem";
import AddNonDRProject from "./AddNonDRProject";
import { SetDeveloperData } from "../../../redux/data/data-actions";
import { input1 } from "../../../styling/GlobalStylesMui";
import AddMemberModal from "./AddMemberModal";
import Copy from '../../../theme/copy.png';
import MemberTable from "./MemberTable";
import { _getHeaders } from "../../../redux/api-headers";
import axios from "axios";
import { url } from '../../../redux/url';
import MessageIcon from '@mui/icons-material/Message';

const CustomizeTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#788195',
  },
  '& .MuiInputBase-input': {
    fontSize: '15px',
    fontFamily: 'Montserrat-Regular',
    fontWeight: 500,
    opacity: 0.9
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#788195',
    },
    '&:hover fieldset': {
      borderColor: '#788195',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#788195',
    },
  },
});

export default function EditDeveloper() {

  const [search, setSearch] = useState("");
  const handleSearch = (event: any) => { };
  const user: any = sessionStorage.getItem('DREAMUSERAPP');
  const userInfo: any = JSON.parse(user);

  const isMD = useMediaQuery("(min-width:960px)");
  const isEditDeveloperEditing = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.editDeveloperLoading
  );
  const isDeveloperEdited = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.editDeveloperSuccess
  );
  const developerEditingError = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayErrors.editDeveloperError
  );

  const developerVerificationSuccess = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.developerVerificationSuccess
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (developerVerificationSuccess)
      dispatch(getDevById({ id: userInfo?.developer.developerId }));
  }, [developerVerificationSuccess]);

  useEffect(() => {
    if (isDeveloperEdited) {
      dispatch(setSuccessAction("editDeveloperSuccess", false));
    }
  }, [isDeveloperEdited]);

  const projects = useSelector((state: any) => state.DATA_REDUCER.projects);

  const nonDRProjectSavedSuccessfully = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.dataSaveSuccessfully
  );

  useEffect(() => {
    if (nonDRProjectSavedSuccessfully) {
      dispatch(getDevById({ id: userInfo?.developer.developerId }));
      setSuccessAction('dataSaveSuccessfully', false);
    }
  }, [nonDRProjectSavedSuccessfully]);

  const isUnlinkingProject = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.unlinkingProject
  );

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: "AIzaSyAjm7oLhCvIeol2k8kNlj62c07cgNim3XU",
    });

  const routerLocation = useLocation();
  const developerData = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerData
  );

  const developer: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.developerData
  );

  const createMemberSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.createMemberSuccess
  );

  const editMemberSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.editMemberSuccess
  );

  const deleteMemberSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteMemberSuccess
  );

  const verificationIndicatorButtonStyle = {
    fontFamily: "Montserrat-Regular", padding: '2px 20px', letterSpacing: '1px',
    borderRadius: "3px",
    cursor: 'auto',
    border:
      developer && developer.verified
        ? "1px solid #00A03BB3"
        : "1px solid #cf0000b3",
    color: developer && developer.verified ? "#00a339" : "#b40000",
    bgcolor: developer && developer.verified ? "#00A03B1A" : "#ff19431a",
    fontSize: "15px",
  };

  const [location, setLocation] = useState({
    label:
      developer && developer.address && developer.address.length
        ? `${developer.address[0].location_name}, ${developer.address[0].formatted_address}`
        : "",
    placeId:
      developer && developer.address && developer.address.length
        ? developer.address[0].place_id
        : "",
    locationName:
      developer && developer.address && developer.address.length
        ? developer.address[0].location_name
        : "",
  });
  const [placeId, setPlaceId] = useState(
    developer && developer.address && developer.address.length
      ? developer.address[0].place_id
      : ""
  );
  const [locationName, setLocationName] = useState(
    developer && developer.address && developer.address.length
      ? developer.address[0].location_name
      : ""
  );
  const [addressValue, setAddressValue] = useState(
    developer && developer.address && developer.address.length
      ? `${developer.address[0].location_name}, ${developer.address[0].formatted_address}`
      : location["label"]
  );
  const [companyLogoURL, setCompanyLogoURL] = useState<any>({
    imageUrl: "",
    imageName: "",
  });
  const [mainImage, setMainImage] = useState<any>(null);
  const [mainImageURL, setMainImageURL] = useState<any>({
    imageUrl: "",
    imageName: "",
  });
  const [brochure, setBrochure] = useState<any>(null);
  const [brochureURL, setBrochureURL] = useState<any>({
    imageUrl: '',
    imageName: ''
  });
  const [isDRProject, setIsDRProject] = useState(null);
  const [isDRProjectOpen, setIsDRProjectOpen] = useState(false);
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [addDRProjectOpen, setAddDRProjectOpen] = useState(false);
  const [addNonDRProjectOpen, setAddNonDRProjectOpen] = useState(false);
  const [isNonDRProject, setIsNonDRProject] = useState(null);
  const [editProjectDetails, setEditProjectDetails] = useState({});
  const [memberToBeEdited, setMemberToBeEdited] = useState('');
  const [isNonDRedit, setIsNonDRedit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isAddMemberOpen)
      setMemberToBeEdited('');
  }, [isAddMemberOpen]);

  useEffect(() => {
    if (!addNonDRProjectOpen)
      setEditProjectDetails({});
  }, [addNonDRProjectOpen]);

  const dispatch = useDispatch();

  const addressErrorRef: any = useRef();
  const addressRef: any = useRef();
  const developerURLRef: any = useRef();

  useEffect(() => {
    if (createMemberSuccess) {
      setIsAddMemberOpen(false);
      dispatch(getDevById({ id: userInfo?.developer.developerId }));
      dispatch(setSuccessAction("createMemberSuccess", false));
    }
  }, [createMemberSuccess]);

  useEffect(() => {
    if (editMemberSuccess) {
      setIsAddMemberOpen(false);
      dispatch(getDevById({ id: userInfo?.developer.developerId }));
      dispatch(setSuccessAction("editMemberSuccess", false));
    }
  }, [editMemberSuccess]);

  useEffect(() => {
    if (deleteMemberSuccess) {
      dispatch(getDevById({ id: userInfo?.developer.developerId }));
      dispatch(setSuccessAction("deleteMemberSuccess", false));
    }
  }, [deleteMemberSuccess]);

  function clearAddressDetails() {
    setPlaceId("");
    setLocationName("");
  }

  function handleLocationChange(e: any) {
    if (e.target.value !== addressValue) setAddressValue(e.target.value);
    setLocation({
      label: e.target.value,
      placeId: "123",
      locationName: "tempName",
    });
    getPlacePredictions({ input: e.target.value });
  }

  const initialValue: any = {
    name: developer ? developer.name : "",
    website: developer ? developer.website : "",
    mobile: developer ? developer.mobile : "",
    mobile2: developer ? developer.mobile2 : "",
    mobile3: developer ? developer.mobile3 : "",
    email: developer ? developer.email : null,
    email2: developer ? developer.email2 : null,
    email3: developer ? developer.email3 : null,
    description: developer ? developer.description : "",
    contactUs: developer ? developer.contactUs : "",
    completedProjects: developer ? developer.completedProjects : "",
    ongoingProjects: developer ? developer.ongoingProjects : "",
    establishedYear: developer ? developer.establishedYear : "",
    onlinePayment: developer ? Number(developer.onlinePayment) : 0,
    adminOffline: developer ? Number(developer.adminOffline) : 0,
    subscriptionDetails:
      developer && developer.subscriptionDetails
        ? developer.subscriptionDetails.split("T")[0]
        : "",
    companyLogo: developer ? developer.companyLogo : "",
    image: developer ? developer.image : "",
  };

  useEffect(() => {
    if (Object.keys(editProjectDetails).length) {
      setAddNonDRProjectOpen(true);
    }
  }, [editProjectDetails]);

  useEffect(() => {
    dispatch(setSuccessAction("editDeveloperSuccess", false));
    dispatch(setErrorAction("editDeveloperError", null));

    if (userInfo?.developer?.developerId) dispatch(getDevById({ id: userInfo.developer.developerId }));
    else dispatch(SetDeveloperData(null));
  }, []);

  useEffect(() => {
    if (isDRProject === true) {
      setIsDRProjectOpen(false);
      setAddDRProjectOpen(true);
    }
  }, [isDRProject]);

  useEffect(() => {
    if (isDeveloperEdited) {
      dispatch(setSuccessAction("editDeveloperSuccess", false));
      if (addDRProjectOpen === true) {
        dispatch(getDevById({ id: userInfo?.developer.developerId }));
        setAddDRProjectOpen(false);
      } else {
        if (isUnlinkingProject === true) {
          dispatch(getDevById({ id: userInfo?.developer.developerId }));
          dispatch(setLoadingAction("unlinkingProject", false));
        }
      }
    }
  }, [isDeveloperEdited]);

  useEffect(() => {
    if (developer && developer.address.length) {
      setAddressValue(
        `${developer.address[0].location_name}, ${developer.address[0].formatted_address}`
      );
      setLocationName(developer.address[0].location_name);
      setPlaceId(developer.address[0].place_id);
    }

    if (developer && developer.companyLogo) {
      setCompanyLogoURL({
        imageUrl: developer.companyLogo,
        imageName: "Company Logo",
      });
    }
    if (developer && developer.image) {
      setMainImageURL({
        imageUrl: developer.image,
        imageName: "Main Image",
      });
    }
    if (developer && developer.brochure) {
      setBrochureURL({
        imageUrl: developer.brochure,
        imageName: "Brochure",
      });
    }
  }, [developer]);

  async function uploadImage(image: any) {
    return await uploadDeveloperFiles([image]);
  }

  function handleAddressBlur() {
    if (placeId && locationName) {
      addressErrorRef.current.style.visibility = "hidden";
    } else addressErrorRef.current.style.visibility = "visible";
  }

  function handleAddressFocus(e: any) {
    e.target.value = "";
    clearAddressDetails();
    setAddressValue("");
  }

  function handleAddressTextfieldFocus(e: any) {
    e.target.value = "";
  }

  const handleSubmit = async (values: any, actions: any) => {
    if (developer) {
      await dispatch(
        editDeveloper(developer.developerId, {
          ...values,
          place_id: placeId,
          location_name: locationName,
          mobile: values.mobile ? +values.mobile : null,
          completedProjects: values.completedProjects
            ? +values.completedProjects
            : null,
          ongoingProjects: values.ongoingProjects
            ? +values.ongoingProjects
            : null,
          establishedYear: values.establishedYear
            ? +values.establishedYear
            : null,
          mobile2: values.mobile2 ? +values.mobile2 : null,
          mobile3: values.mobile3 ? +values.mobile3 : null,
          contactUs: values.contactUs ? +values.contactUs : null,
          onlinePayment: Boolean(values.onlinePayment),
          adminOffline: Boolean(values.adminOffline),
          subscriptionDetails: values.subscriptionDetails
            ? new Date(values.subscriptionDetails)
            : null,
          companyLogo: companyLogoURL
            ? companyLogoURL.imageUrl
            : developer && developer.companyLogo
              ? developer.companyLogo
              : "",
          image: mainImageURL
            ? mainImageURL.imageUrl
            : developer && developer.image
              ? developer.image
              : "",
          brochure: brochureURL
            ? brochureURL.imageUrl
            : developer && developer.brochure
              ? developer.brochure
              : "",
          initialProjectId: developer.initialProjectId,
          redevelopProjectId: developer.redevelopProjectId,
        })
      );
    }
  };

  function validateFormFields(
    defaultValidationFunction: any,
    submitForm: any,
    setTouched: any
  ) {
    defaultValidationFunction()
      .then((response: any) => {
        const keys = Object.keys(response);

        if (keys.length !== 0) {
          setTouched(setNestedObjectValues(response, true));
        }

        if (!(placeId && locationName))
          addressErrorRef.current.style.visibility = "visible";

        if (placeId && locationName && keys.length === 0) submitForm();
      })
      .catch((error: any) =>
        dispatch(
          snackbarAlertAction(
            `Error: ${error?.response?.data.message}`,
            "error"
          )
        )
      );
  }

  let developerCompanyLogoName = "";
  if (developer && developer.companyLogo) {
    let urlTokens = developer.companyLogo.split("/");
    developerCompanyLogoName = urlTokens[urlTokens.length - 1];
  }

  let developerImageName = "";
  if (developer && developer.image) {
    let urlTokens = developer.image.split("/");
    developerImageName = urlTokens[urlTokens.length - 1];
  }

  let developerBrochureName = "";
  if (developer && developer.brochure) {
    let urlTokens = developer.brochure.split("/");
    developerBrochureName = urlTokens[urlTokens.length - 1];
  }

  const isnumber = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 4);
    } else {
      return null;
    }
  };

  const handleAddMemberModalClose = () => {
    setIsAddMemberOpen(false);
  }

  const handleSetNonDRProject = (value: boolean) => {
    setIsNonDRedit(value);
  }

  const isMobileView = useMediaQuery('(max-width:600px)');

  const uploadImages = (images: any): any => {
    if (images && images.length > 0) {
      setLoading(true);
      var data = new FormData();
      for (let i = 0; i < images.length; i++) {
        data.append("images", images[i]);
      }
      var config: any = {
        method: "post",
        url: `${url}/uploads`,
        headers: _getHeaders({ "Content-Type": "multipart/form-data" }),
        data: data,
      };
      return axios(config);
    }
  };

  const uploadProjectImage = (info: any) => {
    if (info) {
      uploadImages(info)
        .then(function (response: any) {
          setLoading(false);
          setCompanyLogoURL({
            imageUrl: response.data.url[0],
            imageName: info[0].name,
          });
        })
        .catch(function (error: any) {
          setLoading(false);
        });
    }
  };

  const uploadMainImage = (info: any) => {
    if (info) {
      uploadImages(info)
        .then(function (response: any) {
          setLoading(false);
          setMainImageURL({
            imageUrl: response.data.url[0],
            imageName: info[0].name,
          });
        })
        .catch(function (error: any) {
          setLoading(false);
        });
    }
  };

  const uploadBrochure = (info: any) => {
    if (info) {
      uploadImages(info)
        .then(function (response: any) {
          setLoading(false);
          setBrochureURL({
            imageUrl: response.data.url[0],
            imageName: info[0].name,
          });
        })
        .catch(function (error: any) {
          setLoading(false);
        });
    }
  };

  const deleteCompanyLogo = () => {
    setCompanyLogoURL({
      imageUrl: "",
      imageName: "",
    });
  };

  const deleteMainImage = () => {
    setMainImageURL({
      imageUrl: "",
      imageName: "",
    });
  };

  const deleteBrochure = () => {
    setBrochureURL({
      imageUrl: "",
      imageName: "",
    });
  };

  return (
    <>
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      {isMobileView ?
        <Grid sx={{ height: '80vh', px: '30px', pt: '50px' }} alignItems='center'>
          Developer Profile can only be edited on Desktop. Please login from desktop.
        </Grid>
        :
        <>
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={false}
            validationSchema={EditDeveloperSchema}
          >
            {({
              errors,
              isSubmitting,
              setSubmitting,
              touched,
              validateForm,
              submitForm,
              setTouched,
            }: any) => {
              return (
                <Form>
                  <Box display="flex" justifyContent="flex-end">
                    <Box style={{ marginTop: "10px", marginBottom: "12px", marginRight: "20px", height: '40px' }}>
                    </Box>
                  </Box>
                  <Box className={styles["mainbody"]}>
                    <Grid
                      item
                      container
                      sx={{ width: "100%" }}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid container alignItems="center" display='flex'>
                        <Grid item className={styles["Page-title"]} sx={{ mr: '48px' }}>
                          Edit Developer Details
                        </Grid>
                        <Button variant="outlined" size="small"
                          sx={{
                            ":hover": verificationIndicatorButtonStyle, ...verificationIndicatorButtonStyle,
                          }}
                        >
                          {developer && developer?.verified ? "Verified" : "Unverified"}
                        </Button>
                        {developer?.drDeveloperUrl && developer?.verified && <Typography style={{ alignItems: "center", display: 'flex', color: "#8291ff", fontFamily: "Montserrat-Regular", marginLeft: '10%', fontSize: "16px", fontWeight: 600, cursor: 'copy' }} onClick={() => navigator.clipboard.writeText(`${appFrontendURL}/developer/${developer.drDeveloperUrl}`)}>
                          <img alt='img' src={Copy} style={{ height: '30px', marginRight: '10px' }} />Developer Link
                        </Typography>}
                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        sx={{ width: "auto" }}
                      >
                        <Grid
                          item
                          container
                          justifyContent="flex-end"
                          sx={{ width: "30vw", mr: "50px" }}
                        >
                          {(developer && isEditDeveloperEditing) ? (
                            <CircularProgress />
                          ) : (developer && isDeveloperEdited) ||
                            isDeveloperEdited ? (
                            <Alert severity="success">
                              The developer is edited successfully
                            </Alert>
                          ) : (developer && developerEditingError) ||
                            developerEditingError ? (
                            <Alert severity="error">
                              Error occurred while editing developer
                            </Alert>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Box className={styles["secondbox-upload"]}>
                      <Box
                        sx={{
                          mr: "1.35vw",
                        }}
                      >
                        <Typography className={styles["inputTitle"]}>
                          Company Logo
                        </Typography>
                        <input
                          id="companyLogoInput"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e: any) => uploadProjectImage(e.target.files)}
                        />

                        <label htmlFor="companyLogoInput">
                          <Card className="uploadFile">
                            {companyLogoURL && companyLogoURL.imageName ? (
                          <>{companyLogoURL.imageName}</>
                        ) : (
                              <>
                                <Box
                                  className="uploadFileLabel"
                                  sx={{
                                    fontSize: "16px",
                                    color: "#B4B4B4",
                                    fontFamily: "Montserrat-Regular",
                                  }}
                                >
                                  Upload company logo
                                </Box>
                                <Box className="Upload-icon-box-arc" style={{ color: "#B4B4B4" }}>
                                  <CloudUploadIcon />
                                </Box>
                              </>
                            )}
                          </Card>
                        </label>

                        {companyLogoURL.imageUrl ? (
                          <div>
                            <span>
                              {companyLogoURL ? (
                                <>{companyLogoURL.imageName}</>
                              ) : (
                                ""
                              )}
                            </span>
                            <Delete
                              onClick={(e) => deleteCompanyLogo()}
                              sx={{
                                color: "#ff6767",
                                marginTop: "10px",
                                marginLeft: "10px",
                                marginBottom: "-5px",
                                cursor: "pointer",
                              }}
                              fontSize="small"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Box>

                      <Box
                        sx={{
                          mr: "1.35vw",
                        }}
                      >
                        <Typography className={styles["inputTitle"]}>
                          Brochure
                        </Typography>
                        <input
                          id="brochure"
                          type="file"
                          accept="application/pdf,image/*"
                          style={{ display: "none" }}
                          onChange={(e: any) => uploadBrochure(e.target.files)}
                        />

                        <label htmlFor="brochure">
                          <Card className="uploadFile">
                          {brochureURL && brochureURL.imageName ? (
                            <>{brochureURL.imageName}</>
                            ) : (
                              <>
                                <Box className="uploadFileLabel">
                                  Upload brochure
                                </Box>
                                <Box className="Upload-icon-box-arc"
                              style={{ color: "#B4B4B4" }}>
                                  <CloudUploadIcon />
                                </Box>
                              </>
                            )}
                          </Card>
                        </label>

                        {brochureURL.imageUrl ? (
                          <div>
                            <span>
                              {brochureURL ? <>{brochureURL.imageName}</> : ""}
                            </span>
                            <Delete
                              onClick={(e) => deleteBrochure()}
                              sx={{
                                color: "#ff6767",
                                marginTop: "10px",
                                marginLeft: "10px",
                                marginBottom: "-5px",
                                cursor: "pointer",
                              }}
                              fontSize="small"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Box>

                      <Box
                        sx={{
                          mr: "1.35vw",
                        }}
                      >
                        <Typography className={styles["inputTitle"]}>
                          Image
                        </Typography>
                        <input
                          id="imageInput"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e: any) => uploadMainImage(e.target.files)}
                        />
                        <label htmlFor="imageInput">
                          <Card className="uploadFile">
                          {mainImageURL && mainImageURL.imageName ? (
                            <>{mainImageURL.imageName}</>
                          ) : (
                            <>
                              <Box
                                className="uploadFileLabel"
                                sx={{
                                  fontSize: "16px",
                                  color: "#B4B4B4",
                                  fontFamily: "Montserrat-Regular",
                                }}
                              >
                                Upload image
                              </Box>
                              <Box className="Upload-icon-box-arc"
                              style={{ color: "#B4B4B4" }}>
                                <CloudUploadIcon />
                              </Box>
                            </>
                          )}
                          </Card>
                        </label>
                        {mainImageURL.imageUrl ? (
                          <div>
                            <span>
                              {mainImageURL ? <>{mainImageURL.imageName}</> : ""}
                            </span>
                            <Delete
                              onClick={(e) => deleteMainImage()}
                              sx={{
                                color: "#ff6767",
                                marginTop: "10px",
                                marginLeft: "10px",
                                marginBottom: "-5px",
                                cursor: "pointer",
                              }}
                              fontSize="small"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>

                    <Section sectionWidth="100%" sectionTitle="Contact Details">
                      <Grid item container xs={12} md={4}>
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Developer Name*
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid item xs={12}>
                            <Field name="name">
                              {(props: any) => {
                                const { field } = props;
                                return (
                                  <CustomizeTextField
                                    size="small"
                                    placeholder="Developer Name"
                                    sx={input1}
                                    fullWidth
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <FormattedErrorMessage name="name" />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        sx={{ mt: isMD ? 0 : "1rem" }}
                      >
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Website URL
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid item xs={12}>
                            <Field name="website">
                              {(props: any) => {
                                const { field } = props;
                                return (
                                  <CustomizeTextField
                                    size="small"
                                    placeholder="Website URL"
                                    sx={input1}
                                    fullWidth
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <FormattedErrorMessage name="website" />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Typography
                        className={styles["inputTitle"]}
                        sx={{ width: "100%", pt: isMD ? 0 : "1rem" }}
                      >
                        Primary Number*
                      </Typography>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                        xs={isMD ? true : 12}
                        spacing={isMD ? 5 : 1}
                      >
                        <Grid item xs={12} md={4}>
                          <Field name="mobile">
                            {(props: any) => {
                              const { field } = props;

                              return (
                                <CustomizeTextField
                                  type="tel"
                                  size="small"
                                  placeholder="Phone No.1"
                                  disabled={developer ? true : false}
                                  fullWidth
                                  sx={input1}
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                          <FormattedErrorMessage name="mobile" />
                        </Grid>
                        {/* todo phone number 2*/}
                        <Grid item xs={12} md={4}>
                          <Field name="mobile2">
                            {(props: any) => {
                              const { field } = props;

                              return (
                                <CustomizeTextField
                                  size="small"
                                  placeholder="Phone No.2"
                                  sx={input1}
                                  fullWidth
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                          <FormattedErrorMessage name="mobile2" />
                        </Grid>
                        {/* todo phone number 3*/}
                        <Grid item xs={12} md={4}>
                          <Field name="mobile3">
                            {(props: any) => {
                              const { field } = props;

                              return (
                                <CustomizeTextField
                                  size="small"
                                  placeholder="Phone No.3"
                                  sx={input1}
                                  fullWidth
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                          <FormattedErrorMessage name="mobile3" />
                        </Grid>
                      </Grid>
                      <Typography
                        className={styles["inputTitle"]}
                        sx={{ width: "100%", pt: isMD ? 0 : "1rem" }}
                      >
                        Primary Email*
                      </Typography>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        sx={{ width: "100%" }}
                        xs={isMD ? true : 12}
                        spacing={isMD ? 5 : 1}
                      >
                        <Grid item xs={12} md={4}>
                          <Field name="email">
                            {(props: any) => {
                              const { field } = props;

                              return (
                                <CustomizeTextField
                                  size="small"
                                  placeholder="Email 1"
                                  sx={input1}
                                  fullWidth
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                          <FormattedErrorMessage name="email" />
                        </Grid>
                        {/* todo: Email 2 */}
                        <Grid item xs={12} md={4}>
                          <Field name="email2">
                            {(props: any) => {
                              const { field } = props;

                              return (
                                <CustomizeTextField
                                  size="small"
                                  placeholder="Email 2"
                                  sx={input1}
                                  fullWidth
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                          <FormattedErrorMessage name="email2" />
                        </Grid>
                        {/* todo: Email 3 */}
                        <Grid item xs={12} md={4}>
                          <Field name="email3">
                            {(props: any) => {
                              const { field } = props;

                              return (
                                <CustomizeTextField
                                  size="small"
                                  placeholder="Email 3"
                                  sx={input1}
                                  fullWidth
                                  {...field}
                                />
                              );
                            }}
                          </Field>
                          <FormattedErrorMessage name="email3" />
                        </Grid>
                      </Grid>
                    </Section>

                    <Section sectionWidth="100%" sectionTitle="Developer Details">
                      <Grid item container xs={12} md={4}>
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Description*
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid item xs={12}>
                            <Field name="description">
                              {(props: any) => {
                                const { field } = props;

                                return (
                                  <CustomizeTextField
                                    size="small"
                                    placeholder="Description"
                                    fullWidth
                                    sx={input1}
                                    multiline
                                    rows={4}
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <FormattedErrorMessage name="description" />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        sx={{ mt: isMD ? 0 : "1rem" }}
                      >
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Address*
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid item xs={12}>
                            <Autocomplete
                              id="address"
                              size="small"
                              ref={addressRef}
                              autoComplete
                              openOnFocus
                              freeSolo
                              disableClearable
                              value={location}
                              disablePortal
                              onChange={(e, newValue: any) => {
                                setPlaceId(newValue.placeId);
                                setLocationName(newValue.locationName);
                                setAddressValue(newValue.label);
                              }}
                              onFocus={(e) => handleAddressFocus(e)}
                              onBlur={handleAddressBlur}
                              inputValue={addressValue}
                              sx={input1}
                              options={
                                placePredictions && placePredictions.length
                                  ? placePredictions.map((placePrediction) => ({
                                    label: placePrediction.description,
                                    placeId: placePrediction.place_id,
                                    locationName:
                                      placePrediction.structured_formatting
                                        .main_text,
                                  }))
                                  : [
                                    {
                                      label: "",
                                      placeId: "456",
                                      locationName: "temp text 2",
                                    },
                                  ]
                              }
                              renderInput={(params) => (
                                <CustomizeTextField
                                  onFocus={handleAddressTextfieldFocus}
                                  multiline
                                  rows={4}
                                  hiddenLabel
                                  {...params}
                                  placeholder="Address"
                                  onChange={handleLocationChange}
                                />
                              )}
                            />
                            <Box
                              ref={addressErrorRef}
                              sx={{
                                visibility: "hidden",
                                minHeight: "1.16vw",
                                fontSize: "1vw",
                                color: "red",
                              }}
                            >
                              Please select a valid address
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        sx={{ mt: isMD ? 0 : "1rem" }}
                      >
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Contact us
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%", height: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              "& .css-1sttk36-MuiGrid-root>.MuiGrid-item": {
                                paddingTop: 0,
                              },
                            }}
                          >
                            <Field name="contactUs">
                              {(props: any) => {
                                const { field } = props;

                                return (
                                  <CustomizeTextField
                                    size="small"
                                    placeholder="Contact us"
                                    sx={input1}
                                    fullWidth
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <FormattedErrorMessage name="contactUs" />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        sx={{ mt: isMD ? 0 : "1rem" }}
                      >
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Completed Projects
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid item xs={12}>
                            <Field name="completedProjects">
                              {(props: any) => {
                                const { field } = props;

                                return (
                                  <CustomizeTextField
                                    size="small"
                                    placeholder="Completed projects"
                                    sx={input1}
                                    fullWidth
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <FormattedErrorMessage name="completedProjects" />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        sx={{ mt: isMD ? 0 : "1rem" }}
                      >
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Ongoing Projects
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid item xs={12}>
                            <Field name="ongoingProjects">
                              {(props: any) => {
                                const { field } = props;

                                return (
                                  <CustomizeTextField
                                    size="small"
                                    placeholder="Ongoing projects"
                                    sx={input1}
                                    fullWidth
                                    {...field}
                                  />
                                );
                              }}
                            </Field>
                            <FormattedErrorMessage name="ongoingProjects" />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        sx={{ mt: isMD ? 0 : "1rem" }}
                      >
                        <div className={styles["inputTitle"]} style={{ width: "100%" }}>
                          Established Year
                        </div>
                        <Grid
                          item
                          container
                          justifyContent="space-between"
                          sx={{ width: "100%" }}
                          spacing={5}
                          xs={12}
                          md={true}
                        >
                          <Grid item xs={12}>
                            <Field name="establishedYear">
                              {(props: any) => {
                                const { field } = props;

                                return (
                                  <CustomizeTextField
                                    size="small"
                                    placeholder="Established year"
                                    type="number"
                                    sx={input1}
                                    fullWidth
                                    {...field}
                                    onInput={(e: any) => {
                                      e.target.value = e.target.value.length === 0
                                        ? ""
                                        : isnumber(e.target.value);

                                    }
                                    }

                                  />
                                );
                              }}
                            </Field>
                            <FormattedErrorMessage name="establishedYear" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Section>

                    {
                      developer
                        ?
                        <Section
                          sectionWidth="100%"
                          sectionTitle="Member Details"
                          buttonTitle="Add Member"
                          onButtonClick={() => {
                            setIsAddMemberOpen(true);
                          }}
                        >
                          <MemberTable
                            members={developer.member}
                            setIsAddMemberOpen={setIsAddMemberOpen}
                            setMemberToBeEdited={setMemberToBeEdited}
                          />
                        </Section>
                        :
                        null
                    }
                    {developer ? (
                      <>
                        <Section
                          sectionTitle="Mapped Projects"
                          sectionWidth="100%"
                          buttonTitle="Add Project"
                          onButtonClick={() => {
                            setAddNonDRProjectOpen(true);
                          }}
                        >
                          {
                            developer?.initialProjectId.length || developer?.redevelopProjectId.length
                              ?
                              <Grid
                                item container
                                alignItems='center'
                                xs={12}
                                sx={{ height: '70px', pl: '3.5%', bgcolor: '#f2f3f6', fontFamily: 'Montserrat-Medium', fontSize: "16px" }}
                              >
                                <Grid
                                  item
                                  sx={{
                                    width: "22.33vw",
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "16px",
                                    fontWeight: "unset",
                                  }}
                                >
                                  Project Name
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    width: "12.68vw",
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "16px",
                                    fontWeight: "unset",
                                  }}
                                >
                                  DR/Non-DR
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    width: "12vw",
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "16px",
                                    fontWeight: "unset",
                                  }}
                                >
                                  Landmark
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    width: "20.67vw",
                                    fontFamily: "Montserrat-Medium",
                                    fontSize: "16px",
                                    fontWeight: "unset",
                                  }}
                                >
                                  Address
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    width: "2.5vw",
                                    fontFamily: "Montserrat-Medium",
                                  }}
                                ></Grid>
                              </Grid>
                              :
                              null
                          }
                          {developer.initialProject.map((project: any, index: number) => (
                            <ProjectListItem
                              role='Developer'
                              id={developer ? developer.developerId : null}
                              showBorderBottom={index < developer.initialProject.length - 1 || developer.redevelopProject.length ? true : false}
                              projectDetails={project}
                              mappedProjects={developer.initialProject}
                              setEditProject={setEditProjectDetails}
                              setNonDRProjectMode={setIsNonDRedit}
                              redevelopProjectId={developer.redevelopProjectId}
                            />
                          ))}

                          {developer.redevelopProject.map((project: any, index: number) => (
                            <ProjectListItem
                              role='Developer'
                              id={developer ? developer.developerId : null}
                              showBorderBottom={index < developer.redevelopProject.length - 1 ? true : false}
                              projectDetails={project}
                              mappedProjects={developer.redevelopProject}
                              setEditProject={setEditProjectDetails}
                              setNonDRProjectMode={setIsNonDRedit}
                              redevelopProjectId={developer.redevelopProjectId}
                            />
                          ))}
                        </Section>
                        {isAddMemberOpen && <AddMemberModal
                          open={isAddMemberOpen}
                          handleClose={handleAddMemberModalClose}
                          developerId={developer.developerId}
                          memberDetails={developer?.member.filter((member: any) => member.memberId === memberToBeEdited)[0]}
                        />}
                        {addNonDRProjectOpen && <AddNonDRProject
                          open={addNonDRProjectOpen}
                          setOpen={setAddNonDRProjectOpen}
                          setIsDRDialog={setIsDRProjectOpen}
                          developerId={developer ? developer.developerId : null}
                          projectDetails={editProjectDetails}
                          setEditProject={setEditProjectDetails}
                          nonDRProjectMode={isNonDRedit}
                          setNonDRProjectMode={setIsNonDRedit}
                        />}
                      </>
                    ) : null}

                    <Box sx={{ display: 'block', width: '100%', mb: 'clamp(1.25rem, 2.08vw, 2.08vw)' }}>
                      <LoadingButton
                        variant="contained"
                        className="button1"
                        onClick={() =>
                          validateFormFields(validateForm, submitForm, setTouched)
                        }
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </>
      }
      <div
        style={{
          position: 'fixed',
          cursor: 'pointer',
          padding: '8px 8px 2px 8px',
          backgroundColor: '#662e91',
          borderRadius: '5px',
          bottom: isMobileView ? '20px' : '20px',
          right: isMobileView ? '20px' : '100px',
        }}
        onClick={() => window.location.href = 'https://chat.dreamsredeveloped.com/'}  
      >
        <MessageIcon style={{color: 'white'}} />
      </div>
    </>
  );
}
import { Box, Button, Grid } from '@mui/material';
import '../../../styling/GlobalStyles.css';

export default function Section(props: any) {

  return (
    <Box
      sx={{
        px: 'clamp(1rem, 2.19vw, 2.69rem)',
        width: props.sectionWidth,
        bgcolor: 'white',
        fontSize: 'clamp(1rem, 1.15vw, 1.375rem)',//'1.15vw',
        color: '#788195',
        mb: 'clamp(1.25rem, 2.08vw, 2.08vw)',
        border: props.removeBorder ? 'none' : '1px solid #e2e2e2',
        borderRadius: '3px',
        alignSelf: props.alignSelf ? props.alignSelf : 'auto'
      }}>
      <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
      <Grid
        container
        sx={{ width: '100%' }}
      >
        <Grid
          item container
          sx={{
            width: '100%',
            minHeight: '3rem',
            height: '3.65vw',
            borderBottom: '1px solid #f0f0f0'
          }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid
            item
            sx={{ color: '#788195', fontFamily: 'Montserrat-Medium', fontSize: "16px" }}
          >
            {props.sectionTitle}
          </Grid>
          {
            props.buttonTitle
              ?
              <Grid
                item
              >
                <Button
                  className='button1'
                  onClick={props.onButtonClick}
                  variant='contained'
                  sx={{
                    borderRadius: '3px',
                    bgcolor: '#662e91',
                    fontFamily: "Montserrat-Regular"
                  }}
                >
                  {props.buttonTitle}
                </Button>
              </Grid>
              :
              null
          }
        </Grid>
        <Grid
          item container
          sx={{ py: 'clamp(1rem, 1.15vw, 2.69rem)' }}
        >
          {props.children}
        </Grid>
      </Grid>

    </Box>)
}
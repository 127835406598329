import { _getHeaders } from "../api-headers";
import { NABHThunkDispatch, RootState } from "../redux-store";
import { url } from "../url";
import { getProjectByStage } from "../data/data-actions";
import { setLoadingAction, setSuccessAction, snackbarAlertAction } from "../display/display-actions";

var axios = require("axios");

export const getProjectByStages =
  (data: any) => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    dispatch(setLoadingAction('getDataLoading', true));
    var config = {
      method: "get",
      url: `${url}/project/getByBiddingStage`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
    };
    axios(config)
      .then(async (response: any) => {
        dispatch(getProjectByStage(response.data));
        dispatch(setLoadingAction('getDataLoading', false));
      })
      .catch((e: any) => {
      });
  };

export const favUnFavProjectThunk =
  (data: any) => async (dispatch: NABHThunkDispatch, getState: () => RootState) => {
    console.log('data is', data);
    dispatch(setLoadingAction('getDataLoading', true));
    var config = {
      method: "post",
      url: `${url}/favourite`,
      headers: _getHeaders({ "Content-Type": "application/json" }),
      data: data
    };
    axios(config)
      .then(async (response: any) => {
        console.log('after updating favourites list, data is', response);
        dispatch(getProjectByStage(response.data));
        dispatch(setSuccessAction('favouriteSuccess', true));
        dispatch(setLoadingAction('getDataLoading', false));
        dispatch(snackbarAlertAction(`Favourite project successfully`, "success"));
      })
      .catch((e: any) => {
        dispatch(snackbarAlertAction(`Error: ${e.response && e.response.data.message}`, "error"));
      });
  };


export const createOrder = (data: any) => async (dispatch: NABHThunkDispatch) => {
  const result = await fetch(`${url}/payment/createOrder`, {
    method: 'POST',
    headers: _getHeaders({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(data)
  });
  return await result.json();
};
import { ArrowBackIosNew } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectsByName,
} from "../redux/thunk/project-thunks";
import AddDRProjectItem from "./AddDRProjectItem";
import Search from "./Search";
import Section from "./Section";
import projectImage from "../../../theme/ProjectItem1.png";
import { RootState } from "../redux/redux-store";
import {
  setErrorAction,
  setSuccessAction,
} from "../redux/display/display-actions";
import { getAllProject } from "../redux/data/data-actions";
import "../styling/GlobalStyles.css";

export default function AddDRProjectDialog(props: any) {
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const dispatch = useDispatch();
  const searchProjectName = useSelector(
    (state: any) => state.DATA_REDUCER.searchProjectName
  );

  useEffect(() => {
    if (!props.open) {
      setSelectedIndex(null);
    }

    if (props.open) {
      dispatch(getAllProject(null));
      dispatch(setSuccessAction("editDeveloperSuccess", false));
    }
  }, [props.open]);

  useEffect(() => {
    <script src="https://cdn-in.pagesense.io/js/dreamsredeveloped/41cffa0244ca46019aba35e160a004d5.js"></script>
  }, [])

  const projects = useSelector((state: any) => state.DATA_REDUCER.projects);

  useEffect(() => {
    if (searchProjectName) dispatch(getProjectsByName(searchProjectName));
  }, [searchProjectName]);

  const searchedProjects = useSelector(
    (state: any) => state.DATA_REDUCER.searchedProjects
  );

  const isEditDeveloperEditing = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.editDeveloperLoading
  );

  const isDeveloperEdited = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.editDeveloperSuccess
  );

  const developerEditingError = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayErrors.editDeveloperError
  );

  function handleCancel() {
    props.setOpen(false);
  }

  function handleTryAgain() {
    dispatch(setErrorAction("editDeveloperError", null));
  }

  return (
    <Dialog
      open={props.open}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "100vw",
          maxHeight: "none",
          py: "1.5vw",
        },
      }}
    >
      {
        <>
          <Section
            removeBorder={true}
            sectionTitle={
              <Grid item container alignItems="center">
                <Grid item>
                  <IconButton
                    onClick={() => {
                      props.setOpen(false);
                      // dispatch(getAllProject(null));
                      // if (props.setIsDRProjectOpen)
                      //   props.setIsDRProjectOpen(true);
                    }}
                  >
                    <ArrowBackIosNew />
                  </IconButton>
                </Grid>
                <Grid item>Add DR Project</Grid>
              </Grid>
            }
            sectionWidth="39.43vw"
          >
            <Grid
              item
              container
              direction="column"
              sx={{ minHeight: "10vw", height: "25vw" }}
            >
              {isEditDeveloperEditing ? (
                <Grid
                  item
                  container
                  sx={{ width: "100%", height: "100%" }}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                  <Grid item sx={{ pt: "1vw" }}>
                    Loading, Please wait
                  </Grid>
                </Grid>
              ) : developerEditingError ? (
                <Grid
                  item
                  container
                  sx={{ width: "100%", height: "100%" }}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Alert
                    sx={{
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    severity="error"
                  >
                    Error occurred while mapping project to a {props.role || 'user'}
                  </Alert>
                </Grid>
              ) : isDeveloperEdited ? (
                <Grid
                  item
                  container
                  sx={{ width: "100%", height: "100%" }}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Alert
                    sx={{
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    severity="success"
                  >
                    Project mapped successfully to the {props.role || 'user'}
                  </Alert>
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <Search placeholder="Search DR project…" />
                  </Grid>

                  <Grid
                    item
                    container
                    direction="column"
                    wrap="nowrap"
                    gap="1vw"
                    sx={{
                      minHeight: "9.27vw",
                      height: "20vw",
                      maxHeight: "20vw",
                      overflowY: "auto",
                      marginTop: "1vw",
                    }}
                  >
                    {searchProjectName &&
                      projects &&
                      projects.data &&
                      projects.data.length
                      ? projects.data.map((project: any, index: number) => (
                        <Grid item id="tempParent">
                          <AddDRProjectItem
                            index={index}
                            projectImage={project.mainImage}
                            projectName={project.projectName}
                            projectAddress={project.address.formatted_address}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                          />
                        </Grid>
                      ))
                      : null}
                  </Grid>
                </>
              )}
            </Grid>
          </Section>
          <DialogActions sx={{ px: "2.19vw" }}>
            {isEditDeveloperEditing ? null : isDeveloperEdited ? (
              <Button
                className="button1"
                onClick={() => {
                  props.setOpen(false);
                  dispatch(setSuccessAction("editDeveloperSuccess", false));
                }}
                variant="contained"
              >
                Done
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  className="button2"
                >
                  Cancel
                </Button>

                {developerEditingError ? (
                  <Button
                    className="button1"
                    onClick={handleTryAgain}
                    variant="contained"
                  // sx={{
                  //   borderRadius: '3px',
                  //   bgcolor: '#662e91'
                  // }}
                  >
                    Try again
                  </Button>
                ) : (
                  <Button
                    onClick={() => props.handleAdd(selectedIndex)}
                    variant="contained"
                    className="button1"
                  >
                    Add
                  </Button>
                )}
              </>
            )}
          </DialogActions>
        </>
      }
    </Dialog>
  );
}
